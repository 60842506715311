import * as React from 'react'
import LabeledWithHelp from 'common/components/LabelWithHelp'
import {Form, TextArea, TextAreaProps} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import {Row, StyledLabel} from './StyledComponents'
import InputErrorMessage from './InputErrorMessage'
import {RequiredAsterisk} from './InputWithLabel'

type Props = {
  setValue?: (
    name: string,
    value:
      | string
      | number
      | boolean
      | (string | number | boolean)[]
      | undefined,
  ) => void
  updateState?: (e: {target: {name: string; value: string | number}}) => void
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  defaultValue: number | string
  name: string
  label: string
  disabled?: boolean
  rows?: string | number
  tooltip?: string
  required?: boolean
  message?: string
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any
  maxCharacters?: number
  length?: number
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}
const TextAreaForm = styled(Form.Field)`
  width: 100%;
`
const TextAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  textarea:disabled {
    background-color: var(--grey-300);
    opacity: 1;
  }
  textarea {
    resize: none;
    border: 1px solid var(--table-borders);
    border-radius: 3px;
  }
  textarea:focus {
    outline: 1px solid var(--asc-keylime);
    border: 1px solid var(--asc-moss);
    border-right-color: var(--asc-moss) !important;
  }
`
const LabelRow = styled(Row)`
  justify-content: space-between;
`

const CharacterCount = styled.div`
  font-size: 12px;
  color: var(--asc-coolgray);
`
const TextAreaWithLabel = ({
  setValue,
  onChange,
  defaultValue,
  name,
  label,
  disabled,
  rows = 3,
  tooltip,
  className,
  errors,
  required,
  maxCharacters,
  length,
  onClick,
}: Props) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let inputValue = e.currentTarget.value

    if (
      maxCharacters &&
      inputValue &&
      inputValue.toString().length > maxCharacters
    ) {
      inputValue = inputValue.toString().slice(0, maxCharacters)
    }
    if (setValue) {
      setValue(name, inputValue)
    } else {
      onChange && onChange(e)
    }
  }
  return (
    <TextAreaForm className={className}>
      {tooltip ? (
        <LabelRow>
          <Row>
            <LabeledWithHelp label={label} htmlFor={name} tooltip={tooltip} />
            {required && <RequiredAsterisk />}
          </Row>
          <div>
            {maxCharacters && (
              <CharacterCount>{`${length}/${maxCharacters}`}</CharacterCount>
            )}
          </div>
        </LabelRow>
      ) : (
        <LabelRow>
          <Row>
            <StyledLabel htmlFor={name}>{label}</StyledLabel>
            {required && <RequiredAsterisk />}
          </Row>
          <div>
            {maxCharacters && (
              <CharacterCount>{`${length}/${maxCharacters}`}</CharacterCount>
            )}
          </div>
        </LabelRow>
      )}
      <TextAreaContent>
        <textarea
          disabled={disabled}
          name={name}
          id={name}
          defaultValue={defaultValue}
          rows={Number(rows) || 3}
          onInput={handleInputChange}
          onClick={onClick ? onClick : undefined}
        />
        <InputErrorMessage
          message={errors ? errors[name]?.message : null}
        ></InputErrorMessage>
      </TextAreaContent>
    </TextAreaForm>
  )
}
export default TextAreaWithLabel
