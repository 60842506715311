import * as React from 'react'
import styled from 'styled-components/macro'
interface ToggleProps {
  isActive: boolean
}
const Container = styled.div`
  display: flex;
  flex-direction: row;
`
const ButtonLeft = styled.div<ToggleProps>`
  display: flex;
  border: solid 1px
    ${({isActive}) => (isActive ? 'var(--primary)' : 'var(--asc-graphite)')};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({isActive}) =>
    isActive ? 'var(--primary)' : '#ffffff'};
  color: ${({isActive}) => (isActive ? 'var(--secondary)' : '#000000')};
  cursor: pointer;
  border-radius: 5px 0 0 5px;
  user-select: none;
  width: 100px;
  height: 30px;
  span {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
`
const ButtonRight = styled(ButtonLeft)`
  border-radius: 0px 5px 5px 0px;
  width: 140px;
`

export enum LocationInputType {
  Address,
  Coordinates,
}
interface Props {
  selected: LocationInputType
  onClick: (selection: LocationInputType) => void
  addressText?: string
  coordinatesText?: string
  className?: string
}

const AddressCoordinatesToggle: React.FC<Props> = ({
  selected,
  onClick,
  className = '',
  addressText = '',
  coordinatesText = '',
}) => {
  return (
    <Container className={className}>
      <ButtonLeft
        onClick={() => onClick(LocationInputType.Address)}
        isActive={selected === LocationInputType.Address}
        data-cy={'toggle-address'}
        className="toggle-button"
      >
        <span>{addressText || 'Address'}</span>
      </ButtonLeft>
      <ButtonRight
        onClick={() => onClick(LocationInputType.Coordinates)}
        isActive={selected === LocationInputType.Coordinates}
        data-cy={'toggle-address'}
        className="toggle-button"
      >
        <span>{coordinatesText || 'Coordinates'}</span>
      </ButtonRight>
    </Container>
  )
}
export default AddressCoordinatesToggle
