import * as React from 'react'
import {HistoryTrailButtonsContainer} from '../routeDetailStyles'
import Controls from 'historyTrail/Controls'
import {useRouteDetailStore} from '../hooks/useRouteDetailStore'
import {HistoryTrailPoint} from 'historyTrail/types'

interface HistoryTrailButtonProps {
  // map: any
  historyTrailPoints: HistoryTrailPoint[]
}

const HistoryTrailButtons: React.FC<HistoryTrailButtonProps> = ({
  // map,
  historyTrailPoints,
}) => {
  const map = useRouteDetailStore(state => state.map)
  return (
    <HistoryTrailButtonsContainer data-cy={'history-trail-buttons'}>
      {historyTrailPoints && map && (
        <Controls
          map={map}
          data={historyTrailPoints}
          toggleShowCards={() => null}
          showCards={false}
          disabled={historyTrailPoints && historyTrailPoints.length === 0}
          hideExport={true}
        />
      )}
    </HistoryTrailButtonsContainer>
  )
}
export default HistoryTrailButtons
