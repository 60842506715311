import * as React from 'react'
import {useLocation, NavLink, useNavigate} from 'react-router-dom'
import shallow from 'zustand/shallow'
import {
  initialState,
  reducer,
  getMenuItems,
  setAndOpenMenuItem,
} from './mainMenuHelpers'
import {
  Container,
  MenuItem,
  MenuItemContainer,
  MenuBanner,
  MenuNameAndIcon,
  CollapseToggle,
  MainMenuTitle,
  TooltipLabel,
  SubMenuContainer,
  CollapseIcon,
  IconHolder,
  LogOutButton,
  BrandingImage,
  BottomSection,
  MobileMenuContainer as MobileHeader,
  HomeIcon,
  Title,
  BannerLink,
} from './mainMenuStyles'
import {useStore} from 'common/useStore'
import {
  LEFT_MENU_WIDTH_COLLAPSED,
  LEFT_MENU_WIDTH_EXPANDED,
  AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE,
  APP_FLAVOR,
  QUERIES,
} from 'common/constants'
import AscendLogo from 'images/Ascend-Logo-No-Slogan.svg'
import TemedaLogo from 'images/temeda-logo-2020.png'
import {
  ArrowCircleLeft,
  CaretDown,
  CaretUp,
  ArrowUpRight,
  House,
  List,
  SignOut,
  X,
  CaretRight,
  CaretLeft,
  // Headset,
} from 'phosphor-react'
import ConversationToast from 'routingAndDispatch/conversations/Popups/ConversationToast'
// import ConversationWidget from 'routingAndDispatch/conversations/ConversationWidget'
import Tooltip from 'common/components/Tooltip'
import CompanyDropdownMenu from './CompanyDropdownMenu'
import styled from 'styled-components/macro'

import FlyoutMenu from './FlyoutMenu'
import {useQueryClient} from '@tanstack/react-query'

const DesktopOnly = styled.div`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const MobileOnly = styled(MenuBanner)`
  width: 100%;
  @media ${QUERIES.tabletAndUp} {
    display: none;
  }
`

const MainMenu = ({title, showMainMenuHeader, isMobile, className = null}) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    showNav: !isMobile,
  })
  const location = useLocation()
  const navigate = useNavigate()
  const userConfig = useStore(state => state.userConfig, shallow)
  const setLeftMenuWidth = useStore(state => state.setLeftMenuWidth)
  const cache = useQueryClient()

  const toggleCollapse = () => {
    if (state.collapse) {
      setLeftMenuWidth(LEFT_MENU_WIDTH_EXPANDED)
    } else {
      setLeftMenuWidth(LEFT_MENU_WIDTH_COLLAPSED)
    }
    dispatch({type: 'setState', data: {collapse: !state.collapse}})
  }

  const toggleMenuItem = e => {
    if (!isMobile) {
      const name = e.currentTarget.dataset.name
      const newMenuItems = state.menuItems.map(item => {
        if (item.name === name) {
          item.open = !item.open
        }

        return item
      })
      dispatch({type: 'setState', data: {menuItems: newMenuItems}})
    } else {
      if (!state.subMenuOpen.isOpen) {
        const name = e.currentTarget.dataset.name
        let newMenuItems = state.menuItems.find(
          item => item.name === name,
        )?.subMenu
        if (newMenuItems) {
          const displayName = `${name.charAt(0).toUpperCase()}${name.slice(1)}`
          dispatch({
            type: 'setState',
            data: {
              subMenuOpen: {isOpen: true, name: displayName},
              menuItems: newMenuItems,
            },
          })
          // } else if (e.currentTarget.dataset.link) {
          //   newMenuItems = getMenuItems(userConfig, dispatch, state)
          //   dispatch({
          //     type: 'setState',
          //     data: {
          //       subMenuOpen: {isOpen: false, name: ''},
          //       menuItems: newMenuItems,
          //       showNav: false,
          //     },
          //   })
        }
        // } else if (e.currentTarget.dataset.link) {
        //   const newMenuItems = getMenuItems(userConfig, dispatch)
        //   dispatch({
        //     type: 'setState',
        //     data: {
        //       subMenuOpen: {isOpen: false, name: ''},
        //       menuItems: newMenuItems,
        //       showNav: false,
        //     },
        //   })
      } else {
        dispatch({
          type: 'setState',
          data: {
            subMenuOpen: {isOpen: false, name: ''},
            menuItems: getMenuItems(userConfig, dispatch),
            showNav: false,
          },
        })
      }
    }
    if (state.collapse) {
      toggleCollapse()
    }
  }

  const logOutClick = () => {
    document.body.style.setProperty('--primary', 'var(--asc-darkolive)')
    document.body.style.setProperty('--secondary', 'var(--asc-lime')
    navigate('/logout')
  }

  React.useEffect(() => {
    if (
      location.pathname.startsWith('/maintenance') &&
      state.activeTab !== 'maintenance'
    ) {
      setAndOpenMenuItem('maintenance', dispatch, state.menuItems)
    } else if (
      location.pathname.startsWith('/reports') &&
      state.activeTab !== 'reports'
    ) {
      setAndOpenMenuItem('reports', dispatch, state.menuItems)
    } else if (
      location.pathname.startsWith('/alert') &&
      state.activeTab !== 'alerts'
    ) {
      setAndOpenMenuItem('alerts', dispatch, state.menuItems)
    } else if (
      location.pathname.startsWith('/map') &&
      state.activeTab !== 'map'
    ) {
      setAndOpenMenuItem('map', dispatch, state.menuItems)
    } else if (
      location.pathname.startsWith('/dashboards') &&
      state.activeTab !== 'dashboards'
    ) {
      setAndOpenMenuItem('dashboards', dispatch, state.menuItems)
    } else if (
      (location.pathname.startsWith('/heirarchy') ||
        location.pathname.startsWith('/contacts') ||
        location.pathname.startsWith('/integrations') ||
        location.pathname.startsWith('/customizeviews')) &&
      state.activeTab !== 'account'
    ) {
      setAndOpenMenuItem('account', dispatch, state.menuItems)
    } else if (
      (location.pathname.startsWith('/asset') ||
        location.pathname.startsWith('/cameraevent') ||
        location.pathname.startsWith('/driver') ||
        location.pathname.startsWith('/fuel-card') ||
        location.pathname.startsWith('/group') ||
        location.pathname.startsWith('/location') ||
        location.pathname.startsWith('/project') ||
        location.pathname.startsWith('/sensor') ||
        // location.pathname.startsWith('/timesheets') ||
        location.pathname.startsWith('/devices') ||
        location.pathname.startsWith('/user') ||
        location.pathname.startsWith('/zone')) &&
      state.activeTab !== 'admin'
    ) {
      setAndOpenMenuItem('admin', dispatch, state.menuItems)
    } else if (
      location.pathname.startsWith('/routing') &&
      state.menuItems.find(item => item.name === 'routing') &&
      state.activeTab !== 'routing'
    ) {
      setAndOpenMenuItem('routing', dispatch, state.menuItems)
    }
  }, [location.pathname, state.activeTab, state.menuItems])

  React.useEffect(() => {
    if (
      userConfig?.idUser &&
      state.menuSet !== userConfig?.idUser &&
      !isMobile
    ) {
      const newData = {}
      newData.menuItems = getMenuItems(userConfig, dispatch, cache)
      if (userConfig.Branding.Logo) {
        newData.logoSource = `${AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE}/images/${userConfig.Branding.Logo}`
      } else {
        newData.logoSource = APP_FLAVOR === 'ascend' ? AscendLogo : TemedaLogo
      }
      dispatch({
        type: 'setState',
        data: newData,
        menuSet: userConfig?.idUser,
      })
    }
  }, [state.menuSet, userConfig, userConfig?.idUser, isMobile, cache])

  if (showMainMenuHeader === false) {
    return null
  }
  return state.showNav || !isMobile ? (
    <Container collapse={String(state.collapse)} className={className}>
      <MenuBanner collapse={String(state.collapse)}>
        <DesktopOnly>
          {state.collapse ? (
            state.logoSource === AscendLogo ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36.8313 23.0905"
              >
                <path
                  d="M34.0568,23.0905l-.5508-5.2852L26.6734,11.995,8.91471,14.183,0,18.0387l24.6822-2.8006Z"
                  fill="#b9cf34"
                />
                <path d="M27.5127,10.619l6.0392,5.1345Z" fill="#788837" />
                <path
                  d="M3.43652,13.5898l9.79408-1.2107L27.5123,10.619l6.0392,5.1345,3.2798,2.786L34.8857,0Z"
                  fill="#788837"
                />
              </svg>
            ) : (
              <CollapseIcon onClick={() => navigate('/map')}>
                <House weight="fill" color="var(--primary)" />
              </CollapseIcon>
            )
          ) : (
            <BannerLink href="/map">
              <img src={state.logoSource} />
            </BannerLink>
          )}
        </DesktopOnly>
        <MobileOnly>
          {!state.subMenuOpen.isOpen ? (
            <>
              <IconHolder
                onClick={() =>
                  dispatch({type: 'setState', data: {showNav: false}})
                }
              >
                <X size={25} color={'var(--primary)'} weight="fill" />
              </IconHolder>
              <div>Menu</div>
              <LogOutButton onClick={logOutClick}>
                Logout <SignOut size={20} />
              </LogOutButton>
            </>
          ) : (
            <>
              <IconHolder
                onClick={() => {
                  dispatch({
                    type: 'setState',
                    data: {subMenuOpen: {isOpen: false, name: ''}},
                  })
                  const newMenuItems = getMenuItems(userConfig, dispatch, cache)
                  dispatch({type: 'setState', data: {menuItems: newMenuItems}})
                }}
              >
                <CaretLeft size={20} color={'var(--primary)'} /> Menu
              </IconHolder>
              <div>{state.subMenuOpen.name}</div>
              <div> </div> {/*keeps spacing uniform*/}
            </>
          )}
        </MobileOnly>
      </MenuBanner>
      {state.logoSource && (
        <MobileOnly>
          <BrandingImage>
            {!state.subMenuOpen.isOpen && (
              <a href="/map">
                <img src={state.logoSource} />
              </a>
            )}
          </BrandingImage>
        </MobileOnly>
      )}
      <MenuItemContainer collapse={String(state.collapse)}>
        {state.menuItems.map(item => (
          <React.Fragment key={item.name}>
            {item.link ? (
              <Tooltip
                tooltip={
                  <TooltipLabel>
                    <span>{item.text}</span>
                    {item.external && (
                      <ArrowUpRight size={16} style={{display: 'inline'}} />
                    )}
                  </TooltipLabel>
                }
                position={'right center'}
                disabled={!state.collapse}
                trigger={
                  <NavLink
                    to={item.link}
                    target={item.newWindow ? '_blank' : ''}
                  >
                    <MenuItem
                      collapse={String(state.collapse)}
                      open={item.open}
                      activeTab={state.activeTab}
                      name={item.name}
                      isActive={item.isActive}
                      onClick={isMobile ? toggleMenuItem : () => null}
                      data-link={true}
                      data-name={item.name}
                    >
                      <MenuNameAndIcon
                        activeTab={state.activeTab}
                        open={item.open}
                        name={item.name}
                        isActive={item.isActive}
                      >
                        {item.icon}
                        {!state.collapse && (
                          <MainMenuTitle>{item.text}</MainMenuTitle>
                        )}
                      </MenuNameAndIcon>
                      {item.external && !state.collapse && (
                        <ArrowUpRight size={16} />
                      )}
                    </MenuItem>
                  </NavLink>
                }
              />
            ) : (
              <Tooltip
                tooltip={item.text}
                position={'right center'}
                disabled={!state.collapse}
                trigger={
                  <MenuItem
                    collapse={String(state.collapse)}
                    open={item.open}
                    data-name={item.name}
                    onClick={toggleMenuItem}
                    activeTab={state.activeTab}
                    name={item.name}
                    isActive={item.isActive}
                  >
                    <MenuNameAndIcon
                      activeTab={state.activeTab}
                      open={item.open}
                      name={item.name}
                      isActive={item.isActive}
                    >
                      {item.icon}
                      {!state.collapse && (
                        <MainMenuTitle>{item.text}</MainMenuTitle>
                      )}
                    </MenuNameAndIcon>
                    {!isMobile ? (
                      !state.collapse && (
                        <>
                          {item.open ? (
                            <CaretUp size={16} />
                          ) : (
                            <CaretDown size={16} />
                          )}
                        </>
                      )
                    ) : (
                      <CaretRight size={20} />
                    )}
                  </MenuItem>
                }
              />
            )}
            {item.open && !isMobile && (
              <SubMenuContainer>
                {item.subMenu.map((subitem, index) => (
                  <FlyoutMenu
                    key={`${subitem.name}-${index}`}
                    subitem={subitem}
                    collapse={state.collapse}
                  />
                ))}
              </SubMenuContainer>
            )}
          </React.Fragment>
        ))}
      </MenuItemContainer>
      <MobileOnly>
        <BottomSection>
          {!state.subMenuOpen.isOpen && (
            <CompanyDropdownMenu setHasDropdown={() => null} />
          )}
        </BottomSection>
      </MobileOnly>
      <DesktopOnly>
        <CollapseToggle
          onClick={() => toggleCollapse()}
          collapse={String(state.collapse)}
        >
          <ArrowCircleLeft
            color={'var(--asc-moss)'}
            weight={'fill'}
            size={30}
            mirrored={state.collapse}
          />
        </CollapseToggle>
      </DesktopOnly>
      <ConversationToast />
    </Container>
  ) : (
    <MobileHeader>
      <HomeIcon onClick={() => navigate('/map')}>
        <House weight="fill" color="var(--primary)" size={25} />
      </HomeIcon>
      <Title>{title}</Title>
      <IconHolder
        onClick={() => dispatch({type: 'setState', data: {showNav: true}})}
      >
        <List size={30} color={'var(--primary)'} />
      </IconHolder>
    </MobileHeader>
  )
}
export default MainMenu
