import * as React from 'react'
import styled from 'styled-components/macro'
import AlertItem from './AlertItem'
import {Icon} from 'semantic-ui-react'
import {useNavigate} from 'react-router-dom'

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  max-height: ${p => (p.showing === 'true' ? '230px' : 0)};
  overflow: hidden;
  top: auto;
  bottom: 48px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  white-space: nowrap;
  width: 350px;
  transition: max-height 0.4s ease;
  border-radius: 20px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
`

const Header = styled.div`
  background-color: var(--asc-vermilion);
  color: #fff;
  font-weight: 500;
  cursor: default;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
  div {
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      margin-right: 15px;
    }
    :hover {
      text-decoration: underline;
    }
  }
`
const List = styled.div`
  overflow: auto;
  max-height: 100%;
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

const AlertList = ({
  alerts,
  showingAlerts,
  setShowingAlerts,
  setAssetFilter,
  assetFilter,
  alertEvents,
}) => {
  const navigate = useNavigate()
  return (
    <Container showing={showingAlerts}>
      <Header>
        <div onClick={() => navigate('/alertevents')}>
          <span>Alerts</span>
          Last 24 Hours
        </div>
        <StyledIcon
          name="chevron circle down"
          onClick={() => setShowingAlerts('false')}
        />
      </Header>
      <List>
        {alerts &&
          alerts.map(alert => {
            const alertSeverity = alertEvents?.find(
              event => event.Alert_Type === alert.type,
            )?.User_Alert_Defs_Severity_LevelName
            return (
              <AlertItem
                key={alert.type}
                label={alert.type}
                item={alert}
                assetFilter={assetFilter}
                setAssetFilter={setAssetFilter}
                alertSeverity={alertSeverity}
              />
            )
          })}
      </List>
    </Container>
  )
}
export default AlertList
