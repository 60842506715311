import * as React from 'react'
import Autocomplete from 'common/components/Autocomplete'
import useAssets from 'common/components/hooks/useAssetsForDropdowns'
import InputErrorMessage from 'common/components/InputErrorMessage'
import styled from 'styled-components/macro'
import {Icon} from 'semantic-ui-react'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: ${({maxWidth}) => (maxWidth ? maxWidth : '480px')}; */
  position: relative;
`
const StyledIcon = styled(Icon)`
  position: absolute;
  right: 7px;
  bottom: 15px;
  font-size: 12px !important;
  cursor: pointer;
`

const StyledIconNoClick = styled(StyledIcon)`
  pointer-events: none;
`

const StyledAutocomplete = styled(Autocomplete)`
  &&& div > .autocomplete-icon {
    color: red;
  }
`
const AssetsDropdown = ({
  selectAsset,
  selectedID,
  excludeIDs,
  errors,
  maxWidth,
  disabled,
  label,
  required,
  defaultOpen = false,
  clearable = false,
  className = '',
  useDriverList = false,
}) => {
  const {data: assets, status} = useAssets({useDriverList})
  const [assetsList, setAssetsList] = React.useState([])

  React.useEffect(() => {
    const newAssets = []
    if (assets && assets.length > 0) {
      assets.forEach(a => {
        if (!excludeIDs || !excludeIDs.includes(a.idAsset)) {
          const newAsset = {
            id: a.idAsset,
            name: a.Asset_Label,
            idDevice_Type: a.idDevice_Type,
          }
          newAssets.push(newAsset)
        }
      })
      setAssetsList(newAssets)
    }
  }, [assets, excludeIDs])

  const assetChanged = item => {
    selectAsset(item?.id, item?.name, item?.idDevice_Type)
  }

  return (
    <Container
      className={className}
      data-cy="assets-dropdown"
      maxWidth={maxWidth}
    >
      <StyledAutocomplete
        list={assetsList}
        label={label}
        required={required}
        onChange={assetChanged}
        selectedID={selectedID}
        placeholder="Select an asset"
        loading={String(status === 'loading' || !assets)}
        disabled={disabled}
        name="idAsset"
        defaultOpen={defaultOpen}
        showIcon={!selectedID}
      />
      {selectedID && clearable && (
        <>
          <StyledIcon
            data-cy="autocomplete-icon-clear"
            className="close icon"
            onClick={() =>
              assetChanged({id: null, name: null, idDevice_Type: null})
            }
          />
        </>
      )}
      {!clearable && selectedID && (
        <>
          <StyledIconNoClick
            data-cy="autocomplete-icon"
            className="caret down icon"
          />
        </>
      )}
      {errors?.message || errors?.idAsset ? (
        <InputErrorMessage
          message={errors?.message || errors?.idAsset?.message}
        />
      ) : (
        <></>
      )}
    </Container>
  )
}
export default React.memo(AssetsDropdown)
