import {LatitudeSchema, LongitudeSchema} from 'common/constants'
import {LocationInputType} from 'routingAndDispatch/common/AddressCoordinatesToggle'

const checkErrors = location => {
  const newErrors = {}
  if (!location?.Landmark_Name) {
    newErrors.Landmark_Name = {message: 'Location Name is required.'}
  }
  return Object.keys(newErrors).length === 0 ? null : newErrors
}

const resetAddress = {
  Landmark_Address_Line1: '',
  Landmark_Address_Line2: '',
  Landmark_City: '',
  Landmark_Region: '',
  Landmark_Postal_Code: '',
  Landmark_Country: '',
}

export const reducer = (state, action) => {
  const validateLatitude = value => {
    try {
      LatitudeSchema.parse({latitude: Number(value)})
      return true
    } catch (error) {
      return false
    }
  }
  const validateLongitude = value => {
    try {
      LongitudeSchema.parse({longitude: Number(value)})
      return true
    } catch (error) {
      return false
    }
  }

  switch (action.type) {
    case 'submitting':
      return {
        ...state,
        submitting: action.data,
      }
    case 'setErrors':
      return {
        ...state,
        errors: action.data,
        submitting: false,
      }
    case 'error':
      return {
        ...state,
        error: action.data,
      }
    case `setShape`:
      return {
        ...state,
        formattedAddress: getFormattedAddressFromLocation({
          ...state.location,
          ...action.data,
        }),
        location: {...state.location, ...action.data},
      }

    case 'loadLocation': {
      if (action.data?.Latitude && action.data?.Longitude) {
        return {
          ...state,
          location: {...state.location, ...action.data.location},
          formattedAddress: action.data.formattedAddress,
          marker: {
            center: {
              lat: () => action.data.location.Latitude,
              lng: () => action.data.location.Longitude,
            },
          },
          isDirty: false,
        }
      } else {
        return {
          ...state,
          formattedAddress: action.data.formattedAddress,
          location: {...state.location, ...action.data.location},
          isDirty: false,
        }
      }
    }
    case 'setState':
      return {
        ...state,
        ...action.data,
        isDirty: true,
      }
    case 'setCleanState':
      return {
        ...state,
        ...action.data,
      }
    case 'landmarkDrawingReturn': {
      const {formattedAddress, ...rest} = action.data
      return {
        ...state,
        formattedAddress,
        location: {
          ...state.location,
          ...rest.newAddress,
        },
        isDirty: true,
      }
    }
    case 'updateAddress': {
      const {marker, address, location} = action.data
      if (location?.lat === 0 && location?.lng === 0) {
        return {
          ...state,
          formattedAddress: '',
          marker: null,
          location: {...state.location, ...resetAddress},
        }
      }
      return {
        ...state,
        location: {
          ...state.location,
          ...address,
          Location_Text:
            'POINT (' + address.Longitude + ' ' + address.Latitude + ')',
        },
        formattedAddress: action.data.formattedAddress,
        marker: marker,
        isDirty: true,
      }
    }
    case 'updateLocation': {
      var errors
      if (state.errors && state.errors !== {}) {
        errors = checkErrors({...state.location, ...action.data})
      }
      const radius = state.location.Landmark_Radius || DEFAULT_CIRCLE_RADIUS
      return {
        ...state,
        errors,
        location: {...state.location, ...action.data, Landmark_Radius: radius},
        isDirty: true,
      }
    }
    case 'dismissCoordinatesModal': {
      return {
        ...state,
        errors: {...errors},
        tempLatitude: null,
        tempLongitude: null,
        tempAddress: null,
        showCoordinatesModal: false,
      }
    }
    case 'setLatitude': {
      let errors = {...state.errors}
      validateLatitude(Number(action.data))
        ? (errors = {
            ...errors,
            latitude: {message: ''},
            formattedAddress: {message: ''},
          })
        : (errors = {
            ...errors,
            latitude: {
              message: 'Latitude must be between -90 and 90',
            },
            formattedAddress: {message: ''},
          })

      return {
        ...state,
        errors: {...errors},
        tempLatitude: Number(action.data),
      }
    }
    case 'setLongitude': {
      let errors = {...state.errors}

      validateLongitude(Number(action.data))
        ? (errors = {
            ...errors,
            longitude: {message: ''},
            formattedAddress: {message: ''},
          })
        : (errors = {
            ...errors,
            longitude: {
              message: 'Longitude must be between -180 and 180',
            },
            formattedAddress: {message: ''},
          })

      return {
        ...state,
        errors: {...errors},
        tempLongitude: Number(action.data),
      }
    }
    case 'setAddressToState': {
      return {
        ...state,
        formattedAddress: state.tempAddress?.formattedAddress || '',
        location: {
          ...state.location,
          Landmark_Address_Line1: state.tempAddress.address,
          Landmark_Address_Line2: '',
          Landmark_City: state.tempAddress.city,
          Landmark_Region: state.tempAddress.addressState,
          Landmark_Postal_Code: state.tempAddress.zipCode,
          Latitude: state.tempLatitude,
          Longitude: state.tempLongitude,
          Location_Text:
            'POINT (' + state.tempLongitude + ' ' + state.tempLatitude + ')',
        },
        tempAddress: null,
        tempLatitude: null,
        tempLongitude: null,
        showCoordinatesModal: false,
      }
    }
    case 'setTempAddress': {
      return {
        ...state,
        tempAddress: action.data,
      }
    }
    default:
      console.error('invalid reducer action', action)
      return {...state}
  }
}
export const DEFAULT_CIRCLE_RADIUS = 62

export const initialState = {
  location: {
    Landmark_Name: '',
    Landmark_Label: '',
    Landmark_Style: '',
    idLandmark_Style: null,
    IsActive: true,
    IsShared: false,
    Landmark_UUID: '',
    Landmark_Address_Line1: '',
    Landmark_Address_Line2: '',
    Landmark_City: '',
    Landmark_Region: '',
    Landmark_Postal_Code: '',
    Landmark_Country: '',
    Shared_idCustomer: null,
    Shared_idCustomerType: null,
    Latitude: null,
    Longitude: null,
    Landmark_Radius: DEFAULT_CIRCLE_RADIUS,
    Location_Text: '',
    Landmark_Type: 'Circle',
    Landmark_Category: 1,
    ContactName: '',
    ContactEmail: '',
    ContactPhoneNumber: '',
  },
  locationLookupType: LocationInputType.Address,
  tempLatitude: null,
  tempLongitude: null,
  tempAddress: null,
  formattedAddress: '',
  isDirty: false,
  isDrawing: false,
  submitting: false,
  showCoordinatesModal: false,
}

export const getFormattedAddressFromLocation = location => {
  const addressString = `${
    location.Landmark_Address_Line1
      ? location.Landmark_Address_Line1 + ', '
      : ''
  } ${location.Landmark_City}, ${location.Landmark_Region} ${
    location.Landmark_Postal_Code
  }`

  const coordinatesString = `${location.Latitude}, ${location.Longitude}`
  return location.Landmark_City ? addressString : coordinatesString
}
