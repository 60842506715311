import {createGlobalStyle} from 'styled-components/macro'

export const DONUT_COLORS = {
  overdue: 'var(--asc-vermilion)',
  due: 'var(--asc-gold)',
  notdue: 'var(--asc-lime)',
}

export const ALERT_EVENT_COLORS = {
  Critical: 'var(--asc-vermilion)',
  Warning: 'var(--asc-gold)',
  Information: 'var(--asc-moss)',
}

export const ascendColors = {
  primary: '#3D491F',
  secondary: '#B9CF33',
  /*Ascend Colors*/
  darkolive: '#3D491F',
  lime: '#B9CF33',
  moss: '#778737',
  stone: '#E4DED2',
  subduedmoss: '#70785E',
  keylime: '#DCE799',
  /*Secondary*/
  successgreen: '#4DC591',
  selectedgreen: '#F0F6E1',
  sage: '#A1AD74',
  gold: '#FEC143',
  wheat: '#FFE4AE',
  vermilion: '#D24341',
  brick: '#993B35',
  tan: '#D3BF90',
  brown: '#5F3B0C',
  lavender: '#FBECEC',
  steelblue: '#80B4C5',
  babyblue: '#E6F2F8',
  floralwhite: '#FFF9EC',
  ginfizz: '#F6E9CC',
  /*Reserved For Asset Indicators*/
  flamingo: '#EE0869',
  rust: '#DC8534',
  blue: '#0000F6',
  denim: '#4DACDF',
  purple: '#540C67',
}

const GlobalStyles = createGlobalStyle`
  body {
    /* Colors: */
    
    /*Primary*/
    --primary: ${ascendColors.primary};
    --secondary:${ascendColors.secondary};
    /*Ascend Colors*/
    --asc-darkolive:${ascendColors.darkolive};
    --asc-lime:${ascendColors.lime};
    --asc-moss:${ascendColors.moss};
    --asc-stone:${ascendColors.stone};
    --asc-subduedmoss:${ascendColors.subduedmoss};
    --asc-keylime:${ascendColors.keylime};
    /*Secondary*/
    --asc-successgreen:${ascendColors.successgreen};
    --asc-selectedgreen:${ascendColors.selectedgreen};
    --asc-sage:${ascendColors.sage};
    --asc-gold:${ascendColors.gold};
    --asc-wheat:${ascendColors.wheat};
    --asc-vermilion:${ascendColors.vermilion};
    --asc-brick:${ascendColors.brick};
    --asc-tan:${ascendColors.tan};
    --asc-brown:${ascendColors.brown};
    --asc-lavender:${ascendColors.lavender};
    --asc-steelblue:${ascendColors.steelblue};
    --asc-babyblue:${ascendColors.babyblue};
    --asc-floralwhite:${ascendColors.floralwhite};
    --asc-ginfizz:${ascendColors.ginfizz};

    /*Table*/
    --asc-hoverrow: #F0F6E1;
    
    /*Charts*/
    --asc-chart-blue: #037ABB;
    --asc-chart-green: #9DA474;
    --asc-chart-darkgreen: #5E6B30;
    --asc-chart-lightorange: #E2BF7E;
    --asc-chart-lightblue: #A6CEE3;
    --asc-chart-gray: #849397;
    --asc-chart-red: #C36A56;
    --asc-chart-brown: #937D54;
    --asc-chart-steel: #817A6A;

    /*Neutral*/
    --asc-black: #000000;
    --asc-jet: #333333;
    --asc-sonicsilver: #767676;
    --asc-concrete: #CCCCCC;
    --asc-graphite: #949494;
    --asc-coolgray: #D2D2D7;
    --asc-cultured: #F5F5F7;
    --asc-platinum: #EAEAEC;
    --asc-dimgray: #696969;
    --asc-lightgray: #E2E2E2;
    --asc-mediumgray: #D4D4D4;
    
    /*Reserved For Asset Indicators*/
    --asc-flamingo:${ascendColors.flamingo};
    --asc-rust:${ascendColors.rust};
    --asc-blue:${ascendColors.blue};
    --asc-denim:${ascendColors.denim};
    --asc-purple:${ascendColors.purple};

    /*Legacy Colors*/
    --orange: #FF9802; /*Used in: AlertsByAssetChart.js*/
    --amber: #FFC008; /*Used in: AlertsByAssetChart.js, FuelUsageByAssetChart.js, FuelUsageByDayChart.js, SafetyEventsByDayChart.js*/
    --yellow: #FFEA3B; /*Used in: BatteryMeter.js*/
    --green: #4CAF50; /*Used in: EquipmentUtilizaionByAssetChart.js, EquimentUtilizationByDayChart.js, PlanSummary.js, BatteryMeter.js */
    --blue: #2196F3; /*Used in: rangeSliderStyles.js, UtilizationSlider.js, GutButton.js, EngineRuntimeByAssetChart.js, EngineRuntimeByDayChart.js, StyledComponent.js, OpeningTabFilter.js, SearchContainer.js*/
    --indigo: #3F51B5; /*Used in: eventCardStyle.js, AlertsByTypeChart.js, DistanceTraveledByAssetChart.js, DistanceTraveledByDayChart.js, SafetyEventsByDayChart.js*/
    --red: #F44335; /*Used in: AlertsByTypeChart.js , SafetyEventsByAssetChart.js, SafetyEventsByDayChart.js, BatteryMeter.js*/
    --grey-050: #FAFAFA; /*Used in: dynamicGroupStyles.js, eventCardStyles.js */
    --grey-100: #F5F5F5; /*Used in: QuickReportModal.js, TimePicker.js, DriverAssetDisabled.js, Controls.js, EventCards.js, eventCardStyles.js, Header.js, HistoryTrail.js, SummaryCard.js, ApiUsersTables.tsx, SubCustomersTables.tsx, SubscriptionsTable.tsx, DataTab.js, LocationList.js*/
    --grey-200: #EEEEEE; /*Used in: mainMenuStyles.js, rangeSliderStyles.js, dynamicGroupStyles.js, Hierarchy.js, Header.js, InfoWindows.js, OpeningTabFilters.js*/
    --grey-300: #E0E0E0; /*Used in: AlertDetail.js, AlertTypeSelect.js, AssetDetail.js, CameraEventDetail.js, Input.tsx, InputWithLabel.tsx, Select.tsx, StyledForm.tsx, TextAreaWithLabel.jsx, TimePicker.js, filterStyles.js,StyledComponents.js, groupDetailStyles.js, dynamicGroupStyles.js, Hierarchy.js, EventCardAlert.js, eventCardStyles.js, GridItem.js, bulkLocationImportHelpers.js, locationDetailStyles.js, inspectionDetailHelper.js, MaintenanceLogDetail.js, PlanDetailStyles.js, StyledComponents.js, MaintenanceTaskDetail.js, UserDetail.js, zoneDetailStyles.js*/
    --grey-400: #BDBDBD; /*Used in: Autocomplete.js, DateRangePicker.tsx, rangeSliderStyles.js*/
    --grey-500: #9E9E9E; /*Used in: filterStyles.js, UtilizationSlider.js, dynamicGroupStyles.js, GridChart.js, DeviceSwapModal.tsx*/
    --grey-600: #757575; /*Used in: eventCardStyles.js, DeviceSwapModal.tsx, AssetTab.js, DataTab.js, IconBadge.js, OpeningTabFilter.js*/
    --grey-700: #616161; /*Used in: UtilizationSlider.js, GutButton.js, drawTimeLine.js, GridItem.js, RecentAsset.js, ForgotPassword.js, Login.js*/
    --grey-800: #424242; /*Used in: dynamicGroupStyles.js*/
    --grey-900: #212121; /*Used in  GridChart.js, DeviceSwapModal.js, PublicForgotPassword.js, PublicLogin.js, PublicResetPassword.js*/
    --error-border: #9f3a38; /*Used in: InputErrorMEssage.tsx, InfoWindow.js*/
    --error-text: #9f3a38; /*Used in: InputWithLabel.tsx, InfoWindow.js, Tab.js, InspectionTypeSelect.js, UserDetailSecurityProfile.js*/
    --history-trail-stop: #fe0908; /*Used in: eventCardHelpers.js, eventCardHelpers.test.js, eventCardStyles.js*/
    --history-trail-motion: rgb(93,225,84); /*Used in: eventCardHelpers.js, eventCardHelpers.test.js*/
    --history-trail-sensors: #0000F5; /*Used in: eventCardHelpers.js, eventCardHelpers.test.js*/
    --history-trail-camera: #66097E; /*Used in: eventCardHelpers.js, eventCardHelpers.test.js*/
    --history-trail-driver-safety: #FFFF00; /*Used in: eventCardHelpers.js, eventCardHelpers.test.js*/
    --history-trail-active-card: #3F51B515; /*Used in: eventCardStyles.js*/
    --table-borders: rgba(0,0,0,0.1); /*Used in: BarChart.tsx, LineChartTemplate.js, StyledComponents.js, QuickReportModal.js, TextAreaWithLabel.tsx, GlobalFilter.js, tableStyles.js, LatLngInput.js, ApiUsersTable.tsx, SubCustomersTable.tsx, SubscriptionsTable.tsx, AlertsTab.js, InfoWindow.js, SensorsTab.js, HierarchyLine.js, LocationList.js, OpeningTabFilters.js, StylesComponents.js*/
    --border-color: #767676; /*Used in: QuickReportModal.js, DriverAssetDisabled.js, DriverAssetList.js, AlertItem.js, styledFormComponents.ts, GroupCard.js, IconBadge.js, LocationCard.js*/
    --overdue-color: DONUT_COLORS.overdue; /*Used in: MaintenanceStatus.js*/
    --due-color: DONUT_COLORS.due; /*Used in: MaintenanceStatus.js*/
    --notdue-color: DONUT_COLORS.notdue; /*Used in: MaintenanceStatus.js*/
    --critical-color: ALERT_EVENT_COLORS.Critical; /*Used in: AlertEventPieChart.js*/
    --warning-color: ALERT_EVENT_COLORS.Warning; /*Used in: AlertEventPieChart.js*/
    --information-color: ALERT_EVENT_COLORS.Information; /*Used in: AlertEventPieChart.js*/
    --chart-background-color: white; /*Used in: AlertsByAssetChart.js, AlertsByDayChart.js, AlertsBySeverityChart.js, AlertsByTypeChart.js, AssetActivityByDayChart.js, DistanceTraveledByAssetChart.js, DistanceTraveledByDayChart.js, EngineRuntimeByAssetChart.js, EngineRuntimeByDayChart.js, EquipmentMakeChart.js, EquipmentTypeChart.js, EquipmentUtilizationByAssetChart.js, EquipmentUtilizationByDayChart.js, FuelUsageByAssetChart.js, FuelUsageByDayChart.js, SafetyEventsByAssetChart.js, SafetyEventsByDayChart.js, SafetyEventsByTypeChart.js */
    --chart-gridline-color: #CCCCCC; /*Used in: AlertsByAssetChart.js, AlertsByDayChart.js, AlertsBySeverityChart.js, AlertsByTypeChart.js, AssetActivityByDayChart.js, DistanceTraveledByAssetChart.js, DistanceTraveledByDayChart.js, EngineRuntimeByAssetChart.js, EngineRuntimeByDayChart.js, EquipmentMakeChart.js, EquipmentTypeChart.js, EquipmentUtilizationByAssetChart.js, EquipmentUtilizationByDayChart.js, FuelUsageByAssetChart.js, FuelUsageByDayChart.js, SafetyEventsByAssetChart.js, SafetyEventsByDayChart.js, SafetyEventsByTypeChart.js */
    --chart-tick-color: #CCCCCC; /*Used in: AlertsByAssetChart.js, AlertsByDayChart.js, AlertsBySeverityChart.js, AlertsByTypeChart.js, AssetActivityByDayChart.js, DistanceTraveledByAssetChart.js, DistanceTraveledByDayChart.js, EngineRuntimeByAssetChart.js, EngineRuntimeByDayChart.js, EquipmentMakeChart.js, EquipmentTypeChart.js, EquipmentUtilizationByAssetChart.js, EquipmentUtilizationByDayChart.js, FuelUsageByAssetChart.js, FuelUsageByDayChart.js, SafetyEventsByAssetChart.js, SafetyEventsByDayChart.js, SafetyEventsByTypeChart.js */
    --chart-axis-color: #424242; /*Used in: AlertsByAssetChart.js, AlertsByDayChart.js, AlertsBySeverityChart.js, AlertsByTypeChart.js, AssetActivityByDayChart.js, DistanceTraveledByAssetChart.js, DistanceTraveledByDayChart.js, EngineRuntimeByAssetChart.js, EngineRuntimeByDayChart.js, EquipmentMakeChart.js, EquipmentTypeChart.js, EquipmentUtilizationByAssetChart.js, EquipmentUtilizationByDayChart.js, FuelUsageByAssetChart.js, FuelUsageByDayChart.js, SafetyEventsByAssetChart.js, SafetyEventsByDayChart.js, SafetyEventsByTypeChart.js */
    --chart-text-color: #424242; /*Used in: AlertsByAssetChart.js, AlertsByDayChart.js, AlertsBySeverityChart.js, AlertsByTypeChart.js, AssetActivityByDayChart.js, DistanceTraveledByAssetChart.js, DistanceTraveledByDayChart.js, EngineRuntimeByAssetChart.js, EngineRuntimeByDayChart.js, EquipmentMakeChart.js, EquipmentTypeChart.js, EquipmentUtilizationByAssetChart.js, EquipmentUtilizationByDayChart.js, FuelUsageByAssetChart.js, FuelUsageByDayChart.js, SafetyEventsByAssetChart.js, SafetyEventsByDayChart.js, SafetyEventsByTypeChart.js */
    --chart-text-color-lighter: #616161; /*Used in: AlertsByAssetChart.js, AlertsByDayChart.js, AlertsBySeverityChart.js, AlertsByTypeChart.js, AssetActivityByDayChart.js, DistanceTraveledByAssetChart.js, DistanceTraveledByDayChart.js, EngineRuntimeByAssetChart.js, EngineRuntimeByDayChart.js, EquipmentMakeChart.js, EquipmentTypeChart.js, EquipmentUtilizationByAssetChart.js, EquipmentUtilizationByDayChart.js, FuelUsageByAssetChart.js, FuelUsageByDayChart.js, SafetyEventsByAssetChart.js, SafetyEventsByDayChart.js, SafetyEventsByTypeChart.js */
    --chart-alert-critical-color: #F44335; /*Used in: SafetyEventsByTypeChart.js*/
    --chart-alert-warning-color: #FFC008; /*Used in:SafetyEventsByTypeChart.js*/
    --chart-alert-info-color:#B9CF33; /*Used in: SafetyEventsByTypeChart.js*/
    --chart-asset-active-color: #B9CF33; /*Used in: AssetActivityByDayChart.js*/
    --chart-asset-inactive-color: #F5F5F7; /*Used in: AssetActivityByDayChart.js*/
    --sany-button: #d9534f;
    --sany-border-color:#d43f3a;
    --halliburton-button: rgba(204,3,0,0.9);
    --sany-border-color:transparent;
    --eams-button: #337ab7;
    --eams-border-color:transparent;
    --amat-button: #337ab7;
    --amat-border-color:transparent;
    --trackmobile-button: #005daa;
    --trackmobile-border-color:transparent;
    }
`

export default GlobalStyles
