import Axios from 'axios'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {showToast} from 'common/components/Toastr'

const postLocation = async location => {
  const {data} = await Axios.post(`/landmarks`, location)
  return data
}

function usePostLocation() {
  const cache = useQueryClient()
  return useMutation(postLocation, {
    onSuccess: () => {
      showToast(`Successfully added the location.`, 'success')
    },
    // Optimistically update the cache value on mutate, but store
    // the old value and return it so that it's accessible in case of
    // an error
    // onMutate: location => {},
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
      cache.setQueryData(['locations'], previousValue)
    },
    // After success or failure, refetch the invitees query
    onSettled: () => {
      cache.invalidateQueries(['locations'])
    },
  })
}

export default usePostLocation
