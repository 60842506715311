import * as React from 'react'
import Button from 'common/components/Button'
import styled from 'styled-components/macro'
import {CaretDown, CaretUp} from 'phosphor-react'
import SpeedDropDownCard from './SpeedDropDownCard'
const Wrapper = styled.div`
  position: relative;
  width: auto;
  display: flex;
`
const StyledButton = styled(Button)`
  margin-left: 5px;
  background: transparent;
  color: var(--primary);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: center;

  svg {
    margin-right: 5px;
  }
  :hover {
    background: transparent;
    color: var(--secondary);
  }
`
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 8px 10px;
  min-width: 104px;
  background: ${props =>
    props.active ? 'var(--asc-selectedgreen)' : 'transparent'};
  cursor: pointer;
  border: ${props =>
    props.active ? '1px solid var(--primary)' : '1px solid transparent'};
  :hover {
    background: var(--asc-selectedgreen);
    border: 1px solid var(--primary);
    span {
      color: var(--primary);
      svg {
        color: var(--primary);
      }
    }
  }
`
const Text = styled.span`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary);
  align-items: center;
  span {
    margin-right: 5px;
  }
  svg {
    color: var(--primary);
    margin-right: 0px;
  }
`
const IconHolder = styled.div`
  display: flex;
  width: 12px;
  height: 12px;
  align-items: center;
  justify-content: center;
`
const SpeedDropDown = ({direction, options, onChange, speed}) => {
  const [open, setOpen] = React.useState(false)
  const drop = React.useRef(null)

  return (
    <Wrapper className="dropdown" ref={drop} onClick={() => setOpen(!open)}>
      <StyledButton active={open}>
        <Container active={open}>
          <Text active={open}>
            <span>
              {`${options.find(element => element.value === speed).key}`}
            </span>
            <IconHolder>
              {open ? (
                <CaretUp
                  size={14}
                  weight="fill"
                  onClick={() => {
                    setOpen(false)
                  }}
                />
              ) : (
                <CaretDown
                  size={14}
                  weight="fill"
                  onClick={() => {
                    setOpen(true)
                  }}
                />
              )}
            </IconHolder>
          </Text>
        </Container>
      </StyledButton>
      {open && (
        <SpeedDropDownCard
          direction={direction}
          options={options}
          setOpen={setOpen}
          onChange={onChange}
          closeDropdown={() => setOpen(false)}
          speed={speed}
        />
      )}
    </Wrapper>
  )
}

export default SpeedDropDown
