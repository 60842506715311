import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getOpening2 = async () => {
  const {data} = await Axios.get('/opening/2')
  return data
}

export default function useOpening2() {
  const user = useStore(state => state.user)
  return useQuery(['opening2'], getOpening2, {
    enabled: Boolean(user?.token),
  });
}
