import {Filters, FilterFromAsset} from './gridTypes'

export const applyGlobalFilter = ({
  value,
  filters,
}: {
  value: string
  filters: Filters
}) => {
  const newFilters = {...filters}
  newFilters.globalFilter = value || ''
  return newFilters
}

export const applyColumnFilter = ({
  name,
  value,
  filters,
}: {
  name: FilterFromAsset
  value: string
  filters: Filters
}) => {
  let filterFound = false
  let newFilters = {...filters}
  if (value === '') {
    newFilters.columnFilters = []
    filters?.columnFilters.forEach(filter => {
      if (filter.name !== name) {
        newFilters.columnFilters.push({name, value})
      }
    })
  } else {
    newFilters?.columnFilters.forEach(filter => {
      if (filter.name === name) {
        filter.value = value
        filterFound = true
      }
    })
    if (!filterFound) {
      const newFilter = {
        name,
        value,
      }
      newFilters.columnFilters.push(newFilter)
    }
  }
  return newFilters
}
