import * as React from 'react'
import styled from 'styled-components/macro'
import {useParams} from 'react-router-dom'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import GridTable from 'grid/GridTable'
import Map from 'map/Map'
import GetDirections from 'directions/GetDirections'
import useGridSchema from 'grid/hooks/useGridSchema'
import {useMapStore} from 'map/useMapStore'
import Spinner from 'common/components/Spinner'
import {
  // setHeight,
  buildColumns,
} from './gridMapContainerHelpers'
import {applyDistancesToAssets} from 'mapControl/findNearestAssetsHelpers'
import {useStore} from 'common/useStore'
import {AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE} from 'common/constants'
import useQueryParams from 'common/components/useQueryParams'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas:
    'topsection'
    'bottomsection';
  grid-template-rows: 0 1fr;

  #bottomsection {
    grid-area: bottomsection;
  }
`

const TopSection = styled.div`
  grid-area: topsection;
  overflow: hidden;
  z-index: 10;
  background: var(--asc-cultured);
  height: ${props => (props.gridOpen ? `100%` : '0')};
`
const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: bottomsection;
  height: 100%;
  margin-top: 0;
`

const AssetImageDiv = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AssetImage = styled.img`
  max-height: 24px;
  max-width: 50px;
`

const AssetImageCell = row => {
  if (row.value) {
    return (
      <AssetImageDiv>
        <AssetImage
          src={`${AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE}/${row.value}`}
        />
      </AssetImageDiv>
    )
  }
  return null
}

const initialState = {
  columns: [],
  shownAssets: [],
}

const reducer = (state, action) => {
  let newAssets = []
  switch (action.type) {
    case 'setColumns':
      return {...state, columns: action.data}
    case 'setAssets':
      newAssets = action.data.filteredAssets.filter(
        x => x.idAsset === action.data.gridFilteredAssets.idAsset,
      )
      return {...state, shownAssets: newAssets}
    default:
      break
  }
}

const GridMapContainer = ({
  filteredAssets,
  activeMarker,
  mapRef,
  setAndFocusActiveMarker,
  focusMap,
  clearMap,
  filterAssetsFromList,
  assetFilter,
  toggleFollow,
  toggleStreetView,
  clearAssetFilter,
  setLocationsFilter,
  setZonesFilter,
  gridFullscreen,
  setGridFullscreen,
  openingDispatch,
}) => {
  const {data: schema, status: gridStatus} = useGridSchema()

  const params = useParams()
  const showingNearestAssetDistances = useMapStore(
    state => state.showingNearestAssetDistances,
  )
  const nearByDistances = useMapStore(state => state.nearByDistances)
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const userConfig = useStore(state => state.userConfig)
  const gridOpen = useStore(state => state.gridOpen)
  React.useEffect(() => {
    if (userConfig) {
      const gridColumns = buildColumns({
        userConfig,
        schema,
        AssetImageCell,
        showingNearestAssetDistances,
      })
      if (gridColumns?.length && gridOpen) {
        dispatch({type: 'setColumns', data: gridColumns})
      }
    }
  }, [schema, userConfig, showingNearestAssetDistances, gridOpen])

  React.useEffect(() => {
    const page = document.getElementById('grid-map-page')
    if (gridOpen) {
      if (gridFullscreen) {
        page.style.gridTemplateRows = `1fr 0`
      } else {
        page.style.gridTemplateRows = `calc(50vh - 83px) 1fr`
      }
    } else {
      page.style.gridTemplateRows = `0 1fr`
    }
  }, [gridFullscreen, gridOpen])

  let query = useQueryParams()

  React.useEffect(() => {
    if (
      showingNearestAssetDistances &&
      filteredAssets &&
      filteredAssets[0]?.FindNearestAssetDistance === undefined &&
      nearByDistances.length > 0
    ) {
      const assetsWithDistances = applyDistancesToAssets({
        filteredAssets,
        nearByDistances,
      })

      assetsWithDistances &&
        openingDispatch({
          type: 'updateAssets',
          data: {assets: assetsWithDistances},
        })
    }
  }, [
    filteredAssets,
    nearByDistances,
    openingDispatch,
    showingNearestAssetDistances,
  ])

  return (
    <Container id="grid-map-page" data-cy="grid">
      <TopSection
        id="topsection"
        gridOpen={gridOpen}
        gridFullscreen={gridFullscreen}
      >
        {gridOpen && (
          <>
            <GridTable
              activeId={activeMarker?.idAsset || 0}
              data={filteredAssets || []}
              columns={state.columns}
              showingNearest={showingNearestAssetDistances}
              setActiveMarker={setAndFocusActiveMarker}
              // assets={assets}
              setGridFullscreen={setGridFullscreen}
              gridFullscreen={gridFullscreen}
              filterAssetsFromList={filterAssetsFromList}
            ></GridTable>
            {gridStatus === 'loading' && <Spinner type="partial" />}
          </>
        )}
      </TopSection>
      <BottomSection id="bottomsection" gridOpen={gridOpen}>
        <Map
          focusMap={focusMap}
          mapRef={mapRef}
          setAndFocusActiveMarker={setAndFocusActiveMarker}
          clearMap={clearMap}
          setAssetFilter={filterAssetsFromList}
          assetFilter={assetFilter}
          toggleFollow={toggleFollow}
          toggleStreetView={toggleStreetView}
          clearAssetFilter={clearAssetFilter}
          setLocationsFilter={setLocationsFilter}
          setZonesFilter={setZonesFilter}
          filteredAssets={filteredAssets}
        />
        {params?.id === 'directions' && (
          <GetDirections
            map={mapRef?.current?.map}
            address={query.get('address')}
          />
        )}
      </BottomSection>
    </Container>
  )
}
export default React.memo(GridMapContainer)
