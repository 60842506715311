/* eslint-disable max-lines */
import create from 'zustand'
import {
  StopType,
  Segment,
  OrderType,
} from 'routingAndDispatch/types/typesModule'
import {
  addMinutes,
  addSeconds,
  differenceInMinutes,
  startOfDay,
  areIntervalsOverlapping,
  formatISO,
  isEqual,
  differenceInSeconds,
} from 'date-fns'
import {sortArrayOfObjectsByDateValue} from 'common/helpers'
import {RouteChange} from './hooks/usePutTweakSegments'
import {cloneDeep} from 'lodash'
import {matchSorter} from 'match-sorter'
// import {googleDistanceResponseMultStops} from 'utils/responses/route-adjust'

// import axios from 'axios'
const initialStore = {
  originalSegments: null, //null initially to know if no segments found for a date
  segments: [],
  searchStopIds: [],
  routeChanges: [],
  dateSelected: startOfDay(new Date()),
  newlyUnassignedOrders: [],
  idSegmentLoading: null,
}

export type DraggableStop = StopType & {
  idSegment?: string | null
  ServiceStartDateTime: Date
  ServiceEndDateTime: Date
}
export type DraggableOrder = OrderType & {
  stops: DraggableStop[]
  duration: number
  idSegment?: string | null
  ServiceStartDateTime: Date
  ServiceEndDateTime: Date
}
export interface SegmentWithDraggableStop extends Segment {
  stops: DraggableStop[]
  idPlan?: number
  planName?: string
  planStatus?: string
  planStartDateTime?: Date
  planEndDateTime?: Date
  index?: number
}

type State = {
  originalSegments: SegmentWithDraggableStop[] | null
  segments: SegmentWithDraggableStop[]
  searchStopIds: string[]
  routeChanges: RouteChange[]
  idSegmentLoading: string | null
  setSegments: (segments: SegmentWithDraggableStop[]) => void
  stopMoved: (stops: DraggableStop[]) => void
  stopUnassigned: (stops: DraggableStop[]) => void
  dateSelected: Date
  setDateSelected: (newDate: Date) => void
  setIdSegmentLoading: (idSegment: string) => void
  getSearchedSegments: (
    segments: SegmentWithDraggableStop[],
    searchString: string,
  ) => void
  reset: () => void
  emptyStore: () => void
  newlyUnassignedOrders: OrderType[]
}

const directionsServicePromise = (stops: DraggableStop[]) => {
  // await new Promise(resolve => setTimeout(resolve, 500))
  const distanceMatrix = new google.maps.DistanceMatrixService()
  const origins = []
  const destinations = []

  for (let index = 0; index < stops.length - 1; index++) {
    const thisStop = stops[index]
    const nextStop = stops[index + 1]
    origins.push(
      new google.maps.LatLng(thisStop.location.lat, thisStop.location.lng),
    )
    destinations.push(
      new google.maps.LatLng(nextStop.location.lat, nextStop.location.lng),
    )
  }
  return distanceMatrix.getDistanceMatrix({
    origins,
    destinations,
    travelMode: window.google.maps.TravelMode.DRIVING,
    unitSystem: window.google.maps.UnitSystem.METRIC,
  })

  // return googleDistanceResponseMultStops
}

export type Element = {
  distance: {
    text: string
    value: number
  }
  duration: {
    text: string
    value: number
  }
  status: string
}

const getDirections = async (
  stops: DraggableStop[],
): Promise<Element[] | null> => {
  try {
    const response = await directionsServicePromise(stops)
    if (response) {
      //each row in the response corresponds to an origin
      //each element is that origin to each destination
      const trips: Element[] = []
      response.rows.forEach((row, index) => {
        //only interested in one response for each element i.e. origin 1 to destination 1 etc
        trips.push(row.elements[index])
      })
      return trips
    }
  } catch (error) {
    console.error('error getting directions')
  }
  return null
}

type GetDirectionsType = (inp: DraggableStop[]) => Promise<Element[] | null>

//exported for tests only
const recalculateTravelTimes = async ({
  stops,
  segments,
  getDirections,
  originalSegmentID,
}: {
  stops: DraggableStop[]
  segments: SegmentWithDraggableStop[]
  getDirections: GetDirectionsType
  originalSegmentID?: string
}) => {
  const oldSegment = cloneDeep(
    segments.find(seg => seg.idSegment === stops[0].idSegment),
  )
  const newSegments = cloneDeep(segments)
  const newSegment = cloneDeep(oldSegment)
  if (oldSegment && newSegment) {
    const stopIndex = newSegment.stops.findIndex(
      s => s.idStop === stops[0].idStop,
    )
    //greater than 0 because you can't drag the first stop "home"
    if (stopIndex > 0 && newSegment.stops.length > stopIndex) {
      const stopBefore = newSegment.stops[stopIndex - 1]
      const stopAfter = newSegment.stops[stopIndex + stops.length]
      const directionsParameters: DraggableStop[] = [
        stopBefore,
        ...stops,
        stopAfter,
      ]
      //the last stop was moved from another segment, need to recalculate destination ETA
      if (originalSegmentID) {
        const originalSegment = segments.find(
          s => s.idSegment === originalSegmentID,
        )
        if (originalSegment) {
          const lastStop =
            originalSegment.stops[originalSegment.stops.length - 2]
          const destination =
            originalSegment.stops[originalSegment.stops.length - 1]
          directionsParameters.push(lastStop, destination)
        }
      }

      useRouteEditingStore.setState({idSegmentLoading: oldSegment.idSegment})
      const directionsResponses = await getDirections(directionsParameters)
      useRouteEditingStore.setState({idSegmentLoading: null})
      const duration1 = directionsResponses
        ? directionsResponses[0].duration.value
        : 0
      if (stopIndex === 1 && duration1) {
        //stop was moved to first - adjust time leaving home
        const newLeaveHomeTime = addSeconds(
          stops[0].ServiceStartDateTime,
          -duration1,
        )
        stopBefore.ServiceStartDateTime = newLeaveHomeTime
        stopBefore.ServiceEndDateTime = newLeaveHomeTime
      }

      //adjustment for moved stop
      if (stopIndex !== 1 && duration1 && stopBefore.ServiceEndDateTime) {
        //if duration plus end of previous stop is less than start of this stop move it out
        const earliestStartTime = addSeconds(
          stopBefore.ServiceEndDateTime,
          duration1,
        )
        if (earliestStartTime > stops[0].ServiceStartDateTime) {
          const stopAddedMinutes = differenceInMinutes(
            earliestStartTime,
            stops[0].ServiceStartDateTime,
          )

          stops.forEach((stop, index) => {
            const thisStop = newSegment.stops[stopIndex + index]
            thisStop.ServiceStartDateTime = addMinutes(
              stop.ServiceStartDateTime,
              stopAddedMinutes,
            )
            thisStop.ServiceEndDateTime = addMinutes(
              stop.ServiceEndDateTime,
              stopAddedMinutes,
            )
          })
          // console.log(newSegment.stops)
        }
      }

      // console.log(newSegment.stops.length, stopIndex, stops.length)

      if (newSegment.stops.length - 1 >= stopIndex + stops.length - 1) {
        //all stops after this potentially need to be adjusted
        const commuteAfter = directionsResponses
          ? directionsResponses[stops.length]
          : {duration: {value: 0}}

        // console.log(newSegment.stops)

        for (
          let index = stopIndex + stops.length;
          index < newSegment.stops.length;
          index++
        ) {
          const previousStop = newSegment.stops[index - 1]
          const thisStop = newSegment.stops[index]
          //use google response if first stop after otherwise use original commute
          // console.log('commuteCalc', {
          //   index,
          //   prevEnd: segment.stops[index - 1].ServiceEndDateTime,
          //   thisStart: segment.stops[index].ServiceStartDateTime,
          //   first: index === stopIndex + stops.length,
          //   commuteAfterValue: commuteAfter?.value,
          // })

          const commute =
            index === stopIndex + stops.length && commuteAfter.duration.value
              ? commuteAfter.duration.value
              : differenceInSeconds(
                  //getting from segment because newSegment has already been adjusted
                  oldSegment.stops[index].ServiceStartDateTime,
                  oldSegment.stops[index - 1].ServiceEndDateTime,
                )
          const earliestStartTime = addSeconds(
            previousStop.ServiceEndDateTime,
            commute,
          )

          // console.log('stopToAdjust', {
          //   index,
          //   previousEnd: previousStop.ServiceEndDateTime,
          //   startTime: thisStop.ServiceStartDateTime,
          //   endTime: thisStop.ServiceEndDateTime,
          //   duration: thisStop.serviceDuration,
          //   earliestStartTime,
          //   commute: commute / 60,
          // })
          if (
            earliestStartTime > thisStop.ServiceStartDateTime ||
            thisStop.stopType === 'destination'
          ) {
            thisStop.ServiceStartDateTime = earliestStartTime
            thisStop.ServiceEndDateTime = addMinutes(
              earliestStartTime,
              thisStop.serviceDuration || 0,
            )
          }
        }
      }
      //need to recalculate commute home earlier if last stop was moved earlier
      if (originalSegmentID) {
        const homeCommuteMinutes = directionsResponses
          ? directionsResponses[directionsResponses.length - 1].duration.value /
            60
          : 0
        const originalSegment = segments.find(
          s => s.idSegment === originalSegmentID,
        )
        if (originalSegment) {
          const newSegmentAdjusted = adjustDestinationTime({
            segment: originalSegment,
            homeCommuteMinutes,
          })
          const segmentIndex = newSegments.findIndex(
            ns => ns.idSegment === originalSegmentID,
          )
          newSegments[segmentIndex] = newSegmentAdjusted
        }
      }
    }
  }
  // console.log(newSegment.stops)
  const foundSegmentIndex = segments.findIndex(
    s => s.idSegment === stops[0].idSegment,
  )
  if (foundSegmentIndex > -1 && newSegment) {
    newSegments[foundSegmentIndex] = newSegment
  }
  return newSegments
}

//exported for tests only
export const reorderStops = (
  foundSegment: SegmentWithDraggableStop,
  stops: DraggableStop[],
) => {
  const newSegment = {...foundSegment}
  let newStops: StopType[] = []
  if (newSegment?.stops && stops) {
    let sortedStopsWithoutChanged = newSegment.stops.filter(
      s => s.idOrder !== stops[0].idOrder,
    )
    sortedStopsWithoutChanged = sortArrayOfObjectsByDateValue(
      sortedStopsWithoutChanged,
      'ServiceStartDateTime',
    )
    //check for overlaps first
    let overlap = false
    sortedStopsWithoutChanged.forEach(s => {
      const stopsOnThisOrder = s.idOrder
        ? sortedStopsWithoutChanged.filter(
            sorted => sorted.idOrder === s.idOrder,
          )
        : [s]
      if (
        !overlap &&
        s.stopType !== 'origin' &&
        areIntervalsOverlapping(
          {
            start: stops[0].ServiceStartDateTime,
            end: stops[stops.length - 1]?.ServiceEndDateTime,
          },
          {
            start: stopsOnThisOrder[0].ServiceStartDateTime,
            end: stopsOnThisOrder[stopsOnThisOrder.length - 1]
              ?.ServiceEndDateTime,
          },
        )
      ) {
        overlap = true
        newStops.push(stops[0])
        if (stops.length > 1) {
          newStops.push(stops[1])
        }
      }
      newStops.push(s)
    })
    if (!overlap) {
      const origin = sortedStopsWithoutChanged[0]
      const destination =
        sortedStopsWithoutChanged[sortedStopsWithoutChanged.length - 1]
      if (
        stops[0].ServiceStartDateTime.getTime() <
        origin.ServiceStartDateTime.getTime()
      ) {
        //stop was before first stop - move to after start
        newStops.splice(1, 0, stops[0])
        if (stops.length > 1) {
          newStops.splice(2, 0, stops[1])
        }
      } else if (
        stops[0].ServiceStartDateTime.getTime() >
        destination.ServiceStartDateTime.getTime()
      ) {
        //stop was after last stop - move to before end
        newStops.splice(sortedStopsWithoutChanged.length - 1, 0, stops[0])
        if (stops.length > 1) {
          newStops.splice(newStops.length - 1, 0, stops[1])
        }
      } else {
        newStops = sortArrayOfObjectsByDateValue(
          [...sortedStopsWithoutChanged, ...stops],
          'ServiceStartDateTime',
        )
      }
    }
  }
  newSegment.stops = newStops as DraggableStop[]
  return newSegment
}

//exported for tests only
export const adjustDestinationTime = ({
  segment,
  homeCommuteMinutes,
}: {
  segment: SegmentWithDraggableStop
  homeCommuteMinutes: number
}) => {
  const newSegment = cloneDeep(segment)
  const stops = newSegment.stops
  if (stops?.length) {
    const newArrivalAtDestination = addMinutes(
      stops[stops.length - 2].ServiceEndDateTime,
      homeCommuteMinutes,
    )
    newSegment.stops[stops.length - 1].ServiceStartDateTime =
      newArrivalAtDestination
    newSegment.stops[stops.length - 1].ServiceEndDateTime =
      newArrivalAtDestination
  }
  return newSegment
}

export const getNewSegments = async ({
  stops,
  segments,
  remove,
  getDirections,
}: {
  stops: DraggableStop[]
  segments: SegmentWithDraggableStop[]
  remove?: boolean
  getDirections: GetDirectionsType
}) => {
  let newSegments: SegmentWithDraggableStop[] = cloneDeep(segments)

  const foundSegmentIndex = newSegments.findIndex(
    s => s.idSegment === stops[0].idSegment,
  )
  let foundSegment = cloneDeep(newSegments[foundSegmentIndex])
  let originalSegmentID = ''

  if (foundSegment) {
    const thisStopIndex = foundSegment.stops.findIndex(
      s => s.idStop === stops[0].idStop,
    )
    //stop
    if (thisStopIndex > -1) {
      if (remove) {
        //stop unassigned
        foundSegment.stops.splice(thisStopIndex, 1)

        //unassign any other orders with the same idOrder
        const otherStopsOnOrder = foundSegment.stops.filter(
          s => s.idOrder === stops[0].idOrder,
        )
        otherStopsOnOrder.forEach(stop => {
          const stopIndexInSegment = foundSegment.stops.findIndex(
            s => s.idStop === stop.idStop,
          )
          foundSegment.stops.splice(stopIndexInSegment, 1)
        })
      } else {
        //splice in all stops
        stops.forEach((stop, index) => {
          foundSegment.stops.splice(thisStopIndex + index, 0, stop)
        })
      }
    } else {
      //stop has changed segments find original stop and remove
      let changedStops: DraggableStop[] | undefined = undefined

      newSegments.forEach(segment => {
        if (!changedStops) {
          let originalSegmentStop = segment.stops.find(
            s => s.idStop === stops[0].idStop,
          )

          if (originalSegmentStop) {
            changedStops = stops
            //this is the segment that the stop was on, remove it
            const originalSegment = newSegments.find(
              s => s.idSegment === segment.idSegment,
            )

            if (originalSegment?.idSegment) {
              //need to adjust destination if last stop moved to new segment
              const stopIndex = originalSegment.stops.findIndex(
                st => st.idStop === stops[stops.length - 1].idStop,
              )
              if (stopIndex === originalSegment.stops.length - 2) {
                originalSegmentID = originalSegment.idSegment
              }
              //keep stop's original idSegment
              const updatedStops = originalSegment.stops.filter(
                s => s.idOrder !== stops[0].idOrder,
              )
              originalSegment.stops = [...updatedStops]
            }
          }
        }
      })
      //stop not found - this one came from unassigned
      if (!changedStops) {
        changedStops = stops
      }
      //add stop to new segment
      if (changedStops) {
        foundSegment.stops = [...foundSegment.stops, ...changedStops]
      }
    }
    newSegments[foundSegmentIndex] = foundSegment
    if (!remove) {
      //reorder stops on segment and move out any overlapped stops
      foundSegment = reorderStops(foundSegment, stops)
      newSegments[foundSegmentIndex] = foundSegment
      newSegments =
        (await recalculateTravelTimes({
          stops: stops,
          segments: newSegments,
          getDirections,
          originalSegmentID,
        })) || []
    } else {
      //if last stop was unassigned, adjust destination time
      if (
        thisStopIndex > -1 &&
        thisStopIndex === foundSegment.stops.length - 1
      ) {
        const directionsParameters: DraggableStop[] = [
          foundSegment.stops[foundSegment.stops.length - 2],
          foundSegment.stops[foundSegment.stops.length - 1],
        ]
        const directionsResponses = await getDirections(directionsParameters)
        const homeCommuteMinutes = directionsResponses
          ? directionsResponses[0].duration.value / 60
          : 0
        const newSegmentAdjusted = adjustDestinationTime({
          segment: foundSegment,
          homeCommuteMinutes,
        })
        newSegments[foundSegmentIndex] = newSegmentAdjusted
      }
    }
  }

  return {newSegments}
}

export const getRouteChanges = (
  newSegments: SegmentWithDraggableStop[],
  originalSegments: SegmentWithDraggableStop[],
) => {
  const routeChanges: RouteChange[] = []
  originalSegments.forEach(originalSegment => {
    //check the existing stops
    originalSegment.stops.forEach(originalStop => {
      const newSegment = newSegments.find(
        seg => seg.idSegment === originalSegment.idSegment,
      )
      if (newSegment) {
        const stopOnNewSegment = newSegment.stops.find(
          s => s.idStop === originalStop.idStop,
        )
        //stop is still in a segment
        if (
          stopOnNewSegment &&
          !isEqual(
            originalStop.ServiceStartDateTime,
            stopOnNewSegment.ServiceStartDateTime,
          )
        ) {
          const change: RouteChange = {
            idSegment: originalSegment.idSegment || null,
            newIdSegment: newSegment.idSegment,
            idOrder: originalStop.idOrder || '',
            idStop: originalStop.idStop || '',
            scheduledEarliestDate: formatISO(
              stopOnNewSegment.ServiceStartDateTime,
            ).substring(0, 19),
            scheduledLatestDate: formatISO(
              stopOnNewSegment.ServiceEndDateTime,
            ).substring(0, 19),
          }
          routeChanges.push(change)
        } else {
          //couldn't find the stop, check other segments if it has the stop, if not it's unassigned
          const foundNewSegment = newSegments.find(segment =>
            segment.stops.find(stop => stop.idStop === originalStop.idStop),
          )
          if (foundNewSegment) {
            const foundStop = foundNewSegment.stops.find(
              stop => stop.idStop === originalStop.idStop,
            )
            if (
              foundStop &&
              !isEqual(
                originalStop.ServiceStartDateTime,
                foundStop.ServiceStartDateTime,
              )
            ) {
              const change: RouteChange = {
                idSegment: originalSegment.idSegment || null,
                newIdSegment: foundNewSegment.idSegment,
                idOrder: originalStop.idOrder || '',
                idStop: originalStop.idStop || '',
                scheduledEarliestDate: formatISO(
                  foundStop.ServiceStartDateTime,
                ).substring(0, 19),
                scheduledLatestDate: formatISO(
                  foundStop.ServiceEndDateTime,
                ).substring(0, 19),
              }
              routeChanges.push(change)
            }
          } else {
            const change: RouteChange = {
              idSegment: originalSegment.idSegment || null,
              newIdSegment: null,
              idOrder: originalStop.idOrder || '',
              idStop: originalStop.idStop || '',
              scheduledEarliestDate: '',
              scheduledLatestDate: '',
            }
            routeChanges.push(change)
          }
        }
      }
    })
  })
  //check if new stops added
  newSegments.forEach(newSegment => {
    const stopsNotInOriginal = newSegment.stops.filter(
      stop =>
        !originalSegments.find(segment =>
          segment.stops.some(s => s.idStop === stop.idStop),
        ),
    )
    if (stopsNotInOriginal.length > 0) {
      stopsNotInOriginal.forEach(stop => {
        const change: RouteChange = {
          idSegment: null,
          newIdSegment: newSegment.idSegment,
          idOrder: stop.idOrder || '',
          idStop: stop.idStop || '',
          scheduledEarliestDate: formatISO(stop.ServiceStartDateTime).substring(
            0,
            19,
          ),
          scheduledLatestDate: formatISO(stop.ServiceEndDateTime).substring(
            0,
            19,
          ),
        }
        routeChanges.push(change)
      })
    }
  })

  return routeChanges
}

const STOP_SEARCH_KEYS = [
  'companyName',
  'contact',
  'orderName',
  'address',
  'city',
  'state',
  'zipCode',
  'orderServiceType',
]

const searchedSegments = (
  data: SegmentWithDraggableStop[],
  searchString: string,
) => {
  const stopIds: string[] = []
  if (searchString === '') {
    return []
  }
  data &&
    data.forEach(segment => {
      const foundStops = matchSorter(
        segment.stops,
        searchString.toLowerCase(),
        {
          threshold: matchSorter.rankings.CONTAINS,
          keys: STOP_SEARCH_KEYS,
        },
      )

      foundStops.forEach(stop => stop.idStop && stopIds.push(stop.idStop))
    })
  return stopIds
}

const convertStopToOrder = (stops: DraggableStop[]): OrderType => {
  const unassignedStops = cloneDeep(stops)
  delete unassignedStops[0].idSegment

  const newUnassignedStops = unassignedStops.map(stop => {
    stop.scheduledEarliestDate = formatISO(stop.ServiceStartDateTime).substring(
      0,
      19,
    )
    stop.scheduledLatestDate = formatISO(stop.ServiceEndDateTime).substring(
      0,
      19,
    )
    return stop
  })
  const newOrder: OrderType = {
    idOrder: stops[0].idOrder || '',
    title: stops[0].orderName || '',
    scheduledDate: 'Unassigned',
    stops: [...newUnassignedStops],
    createdAt: '',
    totalStops: String(stops.length),
    description: stops[0].orderDescription,
  }
  return newOrder
}

export const useRouteEditingStore = create<State>((set, get) => ({
  ...initialStore,
  stopMoved: async (stops: DraggableStop[]) => {
    const originalSegments = get().originalSegments
    const currentSegments = get().segments
    const newlyUnassignedOrders = get().newlyUnassignedOrders
    const {newSegments} = await getNewSegments({
      stops,
      segments: currentSegments,
      getDirections,
    })
    if (originalSegments) {
      const changes = getRouteChanges(newSegments, originalSegments)
      const updatedNewlyUnassignedOrders = newlyUnassignedOrders.filter(
        ord => ord.stops[0].idOrder !== stops[0].idOrder,
      )
      set({
        segments: newSegments,
        routeChanges: changes,
        newlyUnassignedOrders: updatedNewlyUnassignedOrders,
      })
    }
  },
  stopUnassigned: async (stops: DraggableStop[]) => {
    const originalSegments = get().originalSegments
    const currentSegments = get().segments
    let newlyUnassignedOrders = get().newlyUnassignedOrders
    const {newSegments} = await getNewSegments({
      stops,
      segments: currentSegments,
      remove: true,
      getDirections,
    })
    if (originalSegments) {
      const changes = getRouteChanges(newSegments, originalSegments)
      //if scheduledEarliestDate is on stop it started as unassigned and doesn't need to be added
      if (!stops[0].scheduledEarliestDate) {
        const stopAsOrder = convertStopToOrder(stops)
        newlyUnassignedOrders = [...newlyUnassignedOrders, stopAsOrder]
      }
      set({
        segments: newSegments,
        routeChanges: changes,
        newlyUnassignedOrders,
      })
    }
  },
  setSegments: (segments: Segment[]) => {
    const newSegments: SegmentWithDraggableStop[] = segments.map(segment => {
      const newSegment: SegmentWithDraggableStop =
        segment as SegmentWithDraggableStop
      newSegment.stops.map(stop => {
        const newStop = stop
        // stop.idSegment = segment.idSegment
        return newStop as DraggableStop
      })
      return newSegment as SegmentWithDraggableStop
    })
    set({
      segments: newSegments,
      originalSegments: newSegments,
      newlyUnassignedOrders: [],
    })
  },
  getSearchedSegments: (
    segments: SegmentWithDraggableStop[],
    searchString: string,
  ) => {
    const newStopIds: string[] = searchedSegments(segments, searchString)

    set({searchStopIds: newStopIds})
  },
  setDateSelected: (newDate: Date) => {
    set({dateSelected: newDate})
  },
  setIdSegmentLoading: (idSegmentLoading: string) => {
    set({idSegmentLoading})
  },
  reset: () => {
    const originalSegments = get().originalSegments
    const dateSelected = get().dateSelected

    set({
      ...initialStore,
      segments: originalSegments || [],
      originalSegments,
      dateSelected,
    })
  },
  emptyStore: () => {
    const dateSelected = get().dateSelected
    //doing this so things trigger to refresh as it's a "new" date
    const newDate = new Date(dateSelected)
    set({...initialStore, dateSelected: newDate})
  },
}))
