import {sortArrayOfObjectsByStringValue} from 'common/helpers'
import {isBefore, parseISO, subDays} from 'date-fns'
import {cloneDeep} from 'lodash'

export const initialGutAssetState = {
  assetCount: 0,

  inactive: {
    count: 0,
    color: 'green',
    assets: [],
  },
  running: {
    count: 0,
    color: 'green',
    assets: [],
  },
  moving: {
    count: 0,
    color: 'green',
    assets: [],
  },
  stopped: {
    count: 0,
    color: 'green',
    assets: [],
  },
  due: {
    count: 0,
    color: 'green',
    assets: [],
  },
  overdue: {
    count: 0,
    color: 'green',
    assets: [],
  },
}

export const calcGutAssetValues = assets => {
  // ALERTS - One per asset/alert type (assets can be repeated if different alert types)
  // INACTIVE - Last motion time more than 24 hours ago (can also be running or in motion if we don't have points)
  // RUNNING - Engine is running (can also be in motion)
  // MOVING - Asset In_Motion
  // STOPPED - Asset not In_Motion (can also be running or inactive?)
  // DUE - Assets with plans due (asset can be repeated in overdue)
  // OVERDUE - Assets with plans overdue (asset can be repeated in due)
  if (assets?.length) {
    const newCounts = cloneDeep(initialGutAssetState)
    newCounts.assetCount = assets.length
    assets.forEach(asset => {
      if (asset?.Device?.In_Motion) {
        newCounts.moving.count++
        newCounts.moving.assets.push(asset.idAsset)
      } else {
        newCounts.stopped.count++
        newCounts.stopped.assets.push(asset.idAsset)
        newCounts.stopped.color = 'red'
      }
      if (asset?.Device?.Is_Running) {
        newCounts.running.count++
        newCounts.running.assets.push(asset.idAsset)
        newCounts.running.color = 'yellow'
      }

      const twentyFourHoursAgo = subDays(new Date(), 1)
      const lastMotionTime = asset?.Device?.Last_Motion_Time
      if (lastMotionTime) {
        const lastMotionTimeDateObject = parseISO(lastMotionTime)
        const olderThan24Hours = isBefore(
          lastMotionTimeDateObject,
          twentyFourHoursAgo,
        )
        if (olderThan24Hours) {
          newCounts.inactive.count++
          newCounts.inactive.assets.push(asset.idAsset)
        }
        // } else {
        // newCounts.inactive.count++
        // newCounts.inactive.assets.push(asset)
      }

      if (asset.MaintDueCount) {
        newCounts.due.count++
        newCounts.due.assets.push(asset.idAsset)
        newCounts.due.color = 'yellow'
      }
      if (asset.MaintOverdueCount) {
        newCounts.overdue.count++
        newCounts.overdue.assets.push(asset.idAsset)
        newCounts.overdue.color = 'red'
      }
    })

    if (newCounts.inactive.count > assets.length * 0.1) {
      newCounts.inactive.color = 'red'
    }
    return newCounts
  } else {
    return initialGutAssetState
  }
}

export const calcGutAlertValues = alerts => {
  // ALERTS - One per asset/alert type (assets can be repeated if different alert types)

  const alertsByType = []
  let alertCount = 0
  if (alerts) {
    alerts.forEach(alert => {
      const alertIndex = alertsByType.findIndex(
        a => a.type === alert.Alert_Type,
      )
      if (alertIndex > -1) {
        if (!alertsByType[alertIndex].assets.includes(alert.idAsset)) {
          alertsByType[alertIndex].count++
          alertsByType[alertIndex].assets.push(alert.idAsset)
          alertCount++
        }
      } else {
        alertsByType.push({
          type: alert.Alert_Type,
          count: 1,
          assets: [alert.idAsset],
        })
        alertCount++
      }
    })
  }

  const sortedAlertsByType = sortArrayOfObjectsByStringValue(
    alertsByType,
    'type',
  )
  const newAlerts = {
    count: alertCount,
    color: alertCount > 0 ? 'red' : 'green',
    types: sortedAlertsByType,
  }
  return newAlerts
}
