import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import {ArrowLeft, Plus} from 'phosphor-react'
import {StyledButton} from 'common/tables/TableButtons'
import {Loader} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import {Row} from 'common/components'
import Badge from 'common/components/Badge'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #ffffff;
  border-bottom: solid 1px var(--asc-coolgray);
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`
const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
`

const BackButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary);
  svg {
    margin-right: 5px;
  }
  :hover {
    color: var(--secondary);
  }
`
const TitleBadgeGroup = styled(Row)`
  align-items: center;
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  align-items: center;
  width: fit-content;
  gap: 5px;
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

interface Props {
  title: string
  totalItems: number
  selectedItems: number //pass 0 if not selecting in table
  isUpdating?: boolean
  showBackButton?: boolean
  backUrl?: string
  showAddButton?: boolean
  addUrl?: string
  addButtonText?: string
  showSelectAll?: boolean
  toggleSelectAll?: () => void
  addAction?: () => void
  //used to pass group add button like in order list
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customAddButton?: any
  secondCustomAddButton?: JSX.Element
}

const ResizableTableHeader: React.FC<Props> = ({
  title,
  totalItems,
  isUpdating,
  showAddButton = true,
  addButtonText = '',
  showBackButton = true,
  backUrl = '',
  selectedItems,
  customAddButton,
  secondCustomAddButton,
  addAction,
}) => {
  const navigate = useNavigate()

  return (
    <Header>
      <HeaderRow>
        <Row>
          {showBackButton && (
            <BackButton
              data-cy={'back-button'}
              onClick={() =>
                backUrl !== '' ? navigate(backUrl) : navigate(-1)
              }
            >
              <ArrowLeft size={25} />
            </BackButton>
          )}
          <TitleBadgeGroup>
            <Title>{title}</Title>
            <Badge data-cy="badge">
              {selectedItems >= 1 && selectedItems < totalItems
                ? `${selectedItems} of ${totalItems}`
                : totalItems}
            </Badge>
            {isUpdating && (
              <LoaderContainer>
                <Loader active inline size="tiny" />
                Retrieving List
              </LoaderContainer>
            )}
          </TitleBadgeGroup>
        </Row>
        <ButtonRow>
          {secondCustomAddButton && secondCustomAddButton}
          {showAddButton &&
            addAction &&
            (customAddButton ? (
              <div onClick={addAction}>{customAddButton}</div>
            ) : (
              <StyledButton onClick={addAction}>
                <Plus />
                {addButtonText}
              </StyledButton>
            ))}
        </ButtonRow>
      </HeaderRow>
    </Header>
  )
}
export default ResizableTableHeader
