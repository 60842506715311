import React from 'react'
import {createRoot} from 'react-dom/client'
// import * as Sentry from '@sentry/react'
// import {BrowserTracing} from '@sentry/tracing'
import App from './App'
import 'rc-tooltip/assets/bootstrap.css'
//import reportWebVitals from './reportWebVitals'
// {
//   process.env.NODE_ENV !== 'development' &&
//     Sentry.init({
//       dsn: 'https://84bbadd4d7b44dcf877c2d216620cead@o4504600068554752.ingest.sentry.io/4504684705415168',
//       integrations: [new BrowserTracing()],
//       tracesSampleRate: 1.0,
//     })
// }

const container = document.getElementById('root')

const root = createRoot(container)

root.render(
  // <React.StrictMode>
  <App />,
  //</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.info)
