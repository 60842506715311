import * as React from 'react'
import {
  ActionTypes,
  State,
  initialState,
  reducer,
} from './addStopFromDetailHelpers'
import {
  Checkbox,
  InputDropdownOptional,
  InputErrorMessage,
  InputWithLabel,
  TextAreaWithLabel,
  Tooltip,
} from 'common/components'
import useCustomerServiceTypes from 'routingAndDispatch/hooks/useCustomerServiceTypes'
import {StyledRow as ButtonRow} from 'routingAndDispatch/Orders/orderStyles'
import DurationInput from 'routingAndDispatch/common/DurationInput'
import {Info} from 'phosphor-react'
import {Button} from 'semantic-ui-react'
import ShiftTimePicker from 'reports/sharedComponents/ShiftTimePicker'
import {ShiftTimePickerType} from 'routingAndDispatch/Setup/routeSettings/routeSettingsHelper'
import {
  ContactRightColumn,
  Content,
  CoordinateRow,
  DurationTitleRow,
  CheckboxRow,
  RelativeContainer,
  RequiredAsterisk,
  Section,
  StyledColumn,
  StyledGroupButton,
  StyledSearchLocationInput,
} from './addStopFromDetailStyles'
import LocationsSearchPanel from 'routingAndDispatch/common/LocationsSearchPanel/LocationsSearchPanel'
import {LandmarkType} from 'locations/hooks/useLocations'
import usePostStopToSegment, {
  PostOrderToSegmentType,
} from 'routingAndDispatch/Orders/hooks/usePostStopToSegment'
import {
  add,
  format,
  isWithinInterval,
  setHours,
  setMilliseconds,
  setMinutes,
} from 'date-fns'
import useGetRouteDefaultSettings from 'routingAndDispatch/hooks/useGetRouteDefaultSettings'
import {useStore} from 'common/useStore'
import {checkAddressOrCoordinates} from 'routingAndDispatch/Stops/stopHelper'
import Modal from 'common/Modal'
import usePostLocation from 'locations/hooks/usePostLocation'
import usePutLocation from 'locations/hooks/usePutLocation'
import {SemanticInputType} from 'common/components/InputDropdownOptional'

type AddStopFromDetailModalProps = {
  showStopModal: boolean
  toggleShowStopModal: () => void
  idSegment: string
  idPlan: string
  idRouteDispatchDriver: string
  planStartDate: Date
  planEndDate: Date
  setSubmitting: (submitting: boolean) => void
}
const AddStopFromDetailModal: React.FC<AddStopFromDetailModalProps> = ({
  showStopModal,
  toggleShowStopModal,
  idSegment,
  idPlan,
  idRouteDispatchDriver,
  planStartDate,
  planEndDate,
  setSubmitting,
}) => {
  const userConfig = useStore(state => state.userConfig)
  const [state, dispatch]: [
    state: State,
    dispatch: React.Dispatch<ActionTypes>,
  ] = React.useReducer(reducer, initialState)
  const {data: serviceTypeData} = useCustomerServiceTypes()
  const {data: settingsData, status: settingsStatus} =
    useGetRouteDefaultSettings()
  const {mutateAsync: postStopToSegment} = usePostStopToSegment()
  const {mutateAsync: putLocation} = usePutLocation()
  const {mutateAsync: postLocation} = usePostLocation()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let {name, value} = event.currentTarget
    if (state.errors[name]?.message) {
      dispatch({
        type: 'resetErrors',
        data: {
          [name]: true,
        },
      })
    }
    dispatch({
      type: 'setState',
      data: {
        [name]: value,
        isDirty: true,
        locationIsDirty: name !== 'title' ? true : state.locationIsDirty,
      },
    })
  }

  const handleValueChange = ({
    name,
    value,
  }: {
    name: string
    value?: SemanticInputType
  }) => {
    if (state.errors[name]?.message) {
      dispatch({
        type: 'resetErrors',
        data: {
          [name]: true,
        },
      })
    }

    dispatch({type: 'setState', data: {[name]: value, isDirty: true}})
  }

  const handleDurationChange = (
    dispatch: React.Dispatch<ActionTypes>,
    type: string,
    value: number,
  ) => {
    dispatch({
      type: 'setDurationState',
      data: {type: type, value: value},
    })
  }

  const handleUpdateTime = (value: ShiftTimePickerType) => {
    const {startDate} = state.dateRange
    const hours = value.ampm === 'PM' ? value.hour + 12 : value.hour
    const newStartDate = setHours(
      setMinutes(setMilliseconds(startDate || planStartDate, 0), value.minute),
      hours,
    )
    const newEndDate = add(newStartDate, {
      minutes: state.estimatedDuration?.mins || 0,
      hours: state.estimatedDuration?.hrs || 0,
    })
    const dateInterval = {start: planStartDate, end: planEndDate}

    const outsideTimeWindow =
      !isWithinInterval(newStartDate, dateInterval) &&
      !isWithinInterval(newEndDate, dateInterval)

    const errors = {...state.errors}
    if (outsideTimeWindow) {
      errors.serviceWindow = {message: 'Outside of the current plan times'}
    } else if (errors.serviceWindow) {
      delete errors.serviceWindow
    }

    dispatch({
      type: 'setState',
      data: {
        startTime: value,
        dateRange: {startDate: newStartDate, endDate: newEndDate},
        errors,
      },
    })
  }

  const handleLocationChange = (location: LandmarkType | null) => {
    if (location) {
      const formattedAddress = `${location.Landmark_Address_Line1} ${
        location.Landmark_City ? location.Landmark_City + ',' : ''
      } ${location.Landmark_Region}`
      if (state.errors['formattedAddress']?.message) {
        dispatch({
          type: 'resetErrors',
          data: {
            formattedAddress: true,
          },
        })
      }
      dispatch({
        type: 'setState',
        data: {
          selectedLocation: location,
          locationName: location.Landmark_Name,
          formattedAddress,
          contactName: location.ContactName || state.contactName,
          email: location.ContactEmail || state.email,
          phoneNumber: location.CustomerPhoneNumber || state.phoneNumber,
          address: formattedAddress,
          city: location.Landmark_City || state.city,
          state: location.Landmark_Region,
        },
      })
    } else {
      dispatch({
        type: 'setState',
        data: {
          selectedLocation: location,
          contactName: '',
          locationName: '',
          email: '',
          phoneNumber: '',
          address: '',
          formattedAddress: '',
          location: undefined,
          city: '',
          state: '',
          zipCode: '',
        },
      })
    }
  }

  const handleCoordinateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (state.errors['latitude']?.message && event.target.name === 'latitude') {
      dispatch({
        type: 'resetErrors',
        data: {
          latitude: true,
        },
      })
    }
    if (
      state.errors['longitude']?.message &&
      event.target.name === 'longitude'
    ) {
      dispatch({
        type: 'resetErrors',
        data: {
          longitude: true,
        },
      })
    }
    dispatch({
      type: 'setState',
      data: {
        location: {
          ...state.location,
          [event.target.name === 'latitude' ? 'lat' : 'lng']: Number(
            event.target.value,
          ),
        },
      },
    })
  }

  const checkFormValidation = () => {
    const validAddressCoordinates = checkAddressOrCoordinates({
      formattedAddress: state.formattedAddress,
      showAddressOrCoordinates: state.currentAddressEntryType,
      location: state.location,
    })

    if (!validAddressCoordinates || !state.title) {
      dispatch({
        type: 'setErrors',
        data: {
          formattedAddress: state?.formattedAddress,
          serviceWindow: state.startTime,
          latitude: state?.location?.lat,
          longitude: state?.location?.lng,
          idSegment: idSegment,
          title: state.title,
          startTime: state.dateRange?.startDate,
        },
      })
    } else {
      onSave()
    }
  }

  const onChangeAddressEntryType = (
    entryType: 'location' | 'address' | 'coordinates',
  ) => {
    dispatch({
      type: 'setState',
      data: {
        currentAddressEntryType: entryType,
        selectedLocation: null,
        locationIsDirty: false,
        saveLocation: false,
        contactName: '',
        locationName: '',
        email: '',
        phoneNumber: '',
        address: '',
        formattedAddress: '',
        location: undefined,
        city: '',
        state: '',
        zipCode: '',
      },
    })
  }
  const onSave = async () => {
    setSubmitting(true)
    const startDateTime = state.dateRange?.startDate

    const endDateTime =
      startDateTime &&
      add(startDateTime, {
        minutes: state.estimatedDuration?.mins || 0,
        hours: state.estimatedDuration?.hrs || 0,
      })
    const today = new Date()
    const postObject: PostOrderToSegmentType = {
      idOrder: '',
      title: state.title,
      stops: [
        {
          idStop: '',
          scheduledEarliestDate: startDateTime
            ? format(startDateTime, "yyyy-MM-dd'T'HH:mm:ss")
            : undefined,
          scheduledLatestDate: endDateTime
            ? format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss")
            : undefined,
          description: state.description,
          location: state.location,
          companyName: state.locationName,
          address: state.formattedAddress,
          city: state.city,
          state: state.state,
          zipCode: state.zipCode,
          phone: state.phoneNumber,
          contact: state.contactName,
          email: state.email,
          serviceDuration: state.serviceDuration,
          UpdateReason: 'add',
          stopType: 'Service',
        },
      ],
      createdAt: today.toString(),
      totalStops: '1',
      orderStatus: '',
      idSegment,
      idPlan,
      idRouteDispatchDriver,
      description: state.description,
      serviceType: state.serviceType,
    }
    toggleShowStopModal()
    await postStopToSegment(postObject)

    dispatch({type: 'setState', data: initialState})
    if (state.saveLocation && userConfig) {
      const locationPayload = {
        ...state.address,
        ...state.selectedLocation,
        ContactName: state.contactName,
        ContactPhoneNumber: state.phoneNumber,
        ContactEmail: state.email,
        Landmark_Name: state.locationName,
        Landmark_Label: state.locationName,
        Latitude: state.location?.lat || null,
        Longitude: state.location?.lng || null,
        idUser: userConfig.idUser,
        isActive: true,
      }
      if (state.selectedLocation) {
        await putLocation({...locationPayload})
      } else {
        await postLocation({...locationPayload})
      }
    }
    setSubmitting(false)
  }

  React.useEffect(() => {
    if (serviceTypeData) {
      const serviceTypeNames = serviceTypeData.map(
        serviceType => serviceType.Name,
      )
      const options = serviceTypeNames.map(
        (serviceType: string, index: number) => {
          return {
            key: index,
            value: serviceType,
            text: serviceType,
          }
        },
      )
      dispatch({type: 'setState', data: {availableServiceTypes: options}})
    }
  }, [serviceTypeData])

  React.useEffect(() => {
    if (settingsStatus === 'success' && settingsData && showStopModal) {
      const {ServiceDuration} = settingsData
      const hrs = Math.floor(ServiceDuration / 60)
      const mins = ServiceDuration % 60
      const startDate = new Date(planStartDate)

      const endDate = add(startDate, {
        minutes: mins,
        hours: hrs,
      })
      const startTime: ShiftTimePickerType = {
        ampm: 'AM',
        hour: 0,
        minute: 0,
      }
      if (
        userConfig &&
        userConfig.Time_Format === '24HR' &&
        planStartDate.getHours() < 12
      ) {
        startTime.ampm = 'AM'
      } else if (
        userConfig &&
        userConfig.Time_Format === '24HR' &&
        planStartDate.getHours() > 12
      ) {
        startTime.ampm = 'PM'
      }

      startTime.hour = planStartDate.getHours()
      startTime.minute = planStartDate.getMinutes()
      dispatch({
        type: 'setState',
        data: {
          serviceDuration: ServiceDuration,
          estimatedDuration: {hrs, mins},
          startTime: startTime,
          dateRange: {
            startDate: startDate,
            endDate: endDate,
          },
        },
      })
    }
  }, [
    dispatch,
    planStartDate,
    settingsData,
    settingsStatus,
    showStopModal,
    userConfig,
  ])

  return (
    <Modal
      showDialog={showStopModal}
      title={'Add Stop'}
      onDismiss={() => {
        toggleShowStopModal()
        dispatch({type: 'setState', data: initialState})
      }}
      width="720px"
      renderFooter={true}
      onSave={checkFormValidation}
    >
      <Content>
        <Section>
          <StyledColumn flexGrow="2">
            <InputWithLabel
              name={'title'}
              label={'Order Name'}
              value={state.title}
              onChange={handleInputChange}
              required={true}
              errors={state.errors}
            />
            <RelativeContainer>
              <ShiftTimePicker
                label="Start Time"
                required={true}
                showLabel={true}
                setTime={handleUpdateTime}
                time={state.startTime}
              />

              {state.errors['serviceWindow']?.message && (
                <InputErrorMessage
                  message={state.errors['serviceWindow']?.message}
                />
              )}
            </RelativeContainer>

            <>
              <DurationTitleRow>
                <div>
                  Est. Duration <RequiredAsterisk />
                </div>
                <Tooltip
                  tooltip={
                    <ul>
                      <li>
                        This is the minimum amount of time drivers will need to
                        complete this stop.
                      </li>
                      <li>
                        {`The default time can be adjusted in Route Optimization > Setup > Route Default Settings.`}
                      </li>
                    </ul>
                  }
                  position={'top center'}
                  trigger={
                    <Info color="var(--asc-moss)" weight="fill" size={16} />
                  }
                />
              </DurationTitleRow>

              <DurationInput
                onHourChange={value =>
                  handleDurationChange(dispatch, 'hours', value)
                }
                onMinuteChange={value =>
                  handleDurationChange(dispatch, 'mins', value)
                }
                value={state.estimatedDuration}
              />
            </>
          </StyledColumn>
          <StyledColumn flexGrow="1">
            <InputDropdownOptional
              placeholder="Select Service Type"
              options={state.availableServiceTypes}
              name={'serviceType'}
              label={'Service Type'}
              clearable={true}
              required={false}
              onChange={handleValueChange}
              value={state.serviceType}
              errors={{}}
            />
            <TextAreaWithLabel
              name={'description'}
              label={'Description'}
              rows={6}
              defaultValue={state.description}
              setValue={(name, value) => {
                return handleValueChange({name, value})
              }}
            />
          </StyledColumn>
        </Section>
        <Section background="var(--asc-cultured)">
          <StyledColumn width="100%">
            <ButtonRow>
              <StyledGroupButton>
                <Button
                  primary={state.currentAddressEntryType === 'location'}
                  onClick={() => onChangeAddressEntryType('location')}
                  type="button"
                >
                  LOCATION
                </Button>
                <Button
                  primary={state.currentAddressEntryType === 'address'}
                  onClick={() => onChangeAddressEntryType('address')}
                  type="button"
                >
                  ADDRESS
                </Button>
                <Button
                  primary={state.currentAddressEntryType === 'coordinates'}
                  onClick={() => onChangeAddressEntryType('coordinates')}
                  type="button"
                >
                  COORDINATES
                </Button>
              </StyledGroupButton>
            </ButtonRow>
            {state.currentAddressEntryType === 'location' && (
              <RelativeContainer>
                <LocationsSearchPanel
                  id={'locations-search-panel'}
                  label="Choose Location"
                  handleLocationChange={handleLocationChange}
                  value={state.selectedLocation?.Landmark_Name}
                />
                {state.errors['formattedAddress']?.message && (
                  <InputErrorMessage
                    message={state.errors['formattedAddress']?.message}
                  />
                )}
              </RelativeContainer>
            )}

            {state.currentAddressEntryType === 'address' && (
              <StyledSearchLocationInput
                dispatch={dispatch}
                formattedAddress={state.formattedAddress}
                width={100}
                flexdirection={'column'}
                alignitems={'start'}
                errors={state.errors}
              />
            )}
            {state.currentAddressEntryType === 'coordinates' && (
              <CoordinateRow>
                <InputWithLabel
                  name="latitude"
                  label="Latitude"
                  disabled={false}
                  errors={state.errors}
                  required={true}
                  value={state?.location?.lat || ''}
                  onChange={handleCoordinateChange}
                  onBlur={undefined}
                  valueIsValid={false}
                />
                <InputWithLabel
                  name="longitude"
                  label="Longitude"
                  disabled={false}
                  errors={state.errors}
                  required={true}
                  value={state?.location?.lng || ''}
                  onChange={handleCoordinateChange}
                  onBlur={undefined}
                  valueIsValid={false}
                />
              </CoordinateRow>
            )}
          </StyledColumn>
        </Section>
        <Section>
          <StyledColumn flexGrow="2">
            <InputWithLabel
              name={'contactName'}
              label={'Contact Name'}
              value={state.contactName}
              onChange={handleInputChange}
            />
            <InputWithLabel
              name={'email'}
              label={'Email Address'}
              value={state.email}
              onChange={handleInputChange}
            />
          </StyledColumn>
          <ContactRightColumn flexGrow="1">
            {state.currentAddressEntryType !== 'location' && (
              <InputWithLabel
                name={'locationName'}
                label={'Location (Customer) Name'}
                value={state.locationName}
                onChange={handleInputChange}
                required={state.saveLocation}
              />
            )}

            <InputWithLabel
              name={'phoneNumber'}
              label={'Phone Number'}
              value={state.phoneNumber}
              onChange={handleInputChange}
            />

            <CheckboxRow>
              <Checkbox
                selected={state.saveLocation}
                dataCy={'save-location-check'}
                onChange={() =>
                  dispatch({
                    type: 'setState',
                    data: {saveLocation: !state.saveLocation},
                  })
                }
                disabled={
                  state.currentAddressEntryType === 'location' &&
                  ((state.selectedLocation && !state.locationIsDirty) ||
                    !state.selectedLocation)
                    ? true
                    : false
                }
                label={`${
                  state.currentAddressEntryType === 'location'
                    ? 'Update'
                    : 'Save'
                } Location`}
              />
            </CheckboxRow>
          </ContactRightColumn>
        </Section>
      </Content>
    </Modal>
  )
}
export default AddStopFromDetailModal
