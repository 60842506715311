import * as React from 'react'
import styled from 'styled-components/macro'
import {ButtonDropDown} from 'common/components'
import {
  MagnifyingGlass,
  DownloadSimple,
  Rows,
  X,
  ArrowsOutSimple,
  ArrowsInSimple,
} from 'phosphor-react'
import Tooltip from 'common/components/Tooltip'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const IconHolder = styled.span`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  flex-direction: column;
  cursor: pointer;
  background-color: ${props =>
    props.active ? 'var(--primary)' : 'transparent'};
  svg {
    color: ${props => (props.active ? 'var(--secondary)' : 'var(--primary)')};
    fill: ${props => (props.active ? 'var(--secondary)' : 'var(--primary)')};
  }
  button:hover {
    background: transparent;
  }
  button {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 25px;
    width: 30px;
    height: 30px;
    background: none !important;
    padding: 0;
    svg {
      fill: var(--primary);
    }
  }
  &&:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`
const IconContainer = styled.div`
  display: flex;
  padding: 7px 0 7px 0;
  background: transparent;
  width: 100%;
  align-self: center;
  text-align: right;
  justify-content: flex-end;
`

const GridTableButtons = ({
  exportData,
  showAssetList,
  closeGrid,
  toggleGlobalFilter,
  showGlobalFilter,
  setGridFullscreen,
  gridFullscreen,
}) => {
  // const [buttonDropdownIsActive, setButtonDropdownIsActive] =
  //   React.useState(false)
  const options = [
    {key: 0, text: 'Export All XLSX', value: 0},
    {key: 1, text: 'Export Filtered XLSX', value: 1},
    {key: 2, text: 'Export All CSV', value: 2},
    {key: 3, text: 'Export Filtered CSV', value: 3},
    {key: 4, text: 'Export All PDF', value: 4},
    {key: 5, text: 'Export Filtered PDF', value: 5},
  ]
  const dropdownClicked = value => {
    const type =
      value === 0 || value === 1
        ? 'xlsx'
        : value === 2 || value === 3
        ? 'csv'
        : 'pdf'
    const allRecords = value === 0 || value === 2 || value === 4
    exportData(type, allRecords)
  }
  return (
    <Container>
      <IconContainer>
        <Tooltip
          tooltip={'Search All Records'}
          position={'top center'}
          trigger={
            <IconHolder
              onClick={toggleGlobalFilter}
              active={showGlobalFilter}
              data-cy="grid-global-filter-icon"
            >
              <MagnifyingGlass size={19} />
            </IconHolder>
          }
        />
        <Tooltip
          tooltip={'Export'}
          position={'top center'}
          trigger={
            <IconHolder>
              <ButtonDropDown
                text={<DownloadSimple size={19} />}
                direction="right"
                options={options}
                onChange={dropdownClicked}
              />
            </IconHolder>
          }
        />
        <Tooltip
          tooltip={'Open Asset List'}
          position={'top center'}
          trigger={
            <IconHolder onClick={showAssetList}>
              <Rows size={19} color={`var(--primary)`} />
            </IconHolder>
          }
        />
        <Tooltip
          tooltip={gridFullscreen ? 'Shrink Grid' : 'Expand Grid'}
          position={'top center'}
          trigger={
            <IconHolder onClick={() => setGridFullscreen(!gridFullscreen)}>
              {gridFullscreen ? (
                <ArrowsInSimple size={19} color={`var(--primary)`} />
              ) : (
                <ArrowsOutSimple size={19} color={`var(--primary)`} />
              )}
            </IconHolder>
          }
        />
        <Tooltip
          tooltip={'Close Grid'}
          position={'top center'}
          trigger={
            <IconHolder
              onClick={() => {
                setGridFullscreen(false)
                closeGrid()
              }}
            >
              <X size={19} color={'var(--primary)'} />
            </IconHolder>
          }
        />
      </IconContainer>
    </Container>
  )
}
export default GridTableButtons
