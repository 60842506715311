import {State, Action, CheckboxType} from './multiSelectTypes'

export const returnHeader = (checkboxes: CheckboxType[]) => {
  const selectedCheckboxes = checkboxes.filter(cb => cb.checked)
  let headerText = ''
  if (selectedCheckboxes.length === 0) {
    headerText = 'None selected'
  } else if (selectedCheckboxes.length > 10) {
    headerText = 'More than 10 items selected'
  } else {
    headerText = selectedCheckboxes
      .map(cb => cb.label)
      .join('&&')
      .substr(0, 100)
    if (headerText.length === 100) {
      headerText += '...'
    }
  }
  return headerText
}

const calculateFilteredCheckboxes = ({
  checkboxes,
  searchValue,
  showingSelectedOnly,
}: {
  checkboxes: CheckboxType[]
  searchValue: string
  showingSelectedOnly: boolean
}) => {
  let filteredCheckboxes = [...checkboxes]

  if (searchValue) {
    filteredCheckboxes = checkboxes.filter(cb =>
      cb.label.toLowerCase().includes(searchValue),
    )
  }

  if (showingSelectedOnly) {
    filteredCheckboxes = filteredCheckboxes.filter(cb => cb.checked)
  }

  return filteredCheckboxes
}

// const calculateVisibleColumns = ({
//   filteredCheckboxes,
//   currentPageNumber,
//   pageSize,
// }: {
//   filteredCheckboxes: CheckboxType[]
//   currentPageNumber: number
//   pageSize: number
// }) => {
//   return filteredCheckboxes.slice(
//     (currentPageNumber - 1) * pageSize,
//     currentPageNumber * pageSize,
//   )
// }

export const reducer = (state: State, action: Action): State => {
  let headerText = ''
  const checkboxes: CheckboxType[] = []
  let filteredCheckboxes: CheckboxType[] = []
  // let visibleCheckboxes: CheckboxType[] = []
  let allSelected = true
  const idsSelected: (string | number)[] = []
  switch (action.type) {
    case 'checkOne':
      // if (state.checkboxes.length === 0) allSelected = false
      state.checkboxes.forEach(cb => {
        const newCheckbox = {...cb}
        if (newCheckbox.id === action.id) {
          newCheckbox.checked = !newCheckbox.checked
        }
        if (newCheckbox.checked) {
          idsSelected.push(newCheckbox.id)
        }
        checkboxes.push(newCheckbox)
      })
      state.filteredCheckboxes.forEach(cb => {
        const newCheckbox = {...cb}
        if (newCheckbox.id === action.id)
          newCheckbox.checked = !newCheckbox.checked
        if (newCheckbox.checked === false) allSelected = false
        filteredCheckboxes.push(newCheckbox)
      })
      // state.visibleCheckboxes.forEach(cb => {
      //   const newCheckbox = {...cb}
      //   if (newCheckbox.id === action.id) newCheckbox.checked = !newCheckbox.checked
      //   visibleCheckboxes.push(newCheckbox)
      // })

      headerText = returnHeader(checkboxes)
      return {
        ...state,
        checkboxes,
        filteredCheckboxes,
        // visibleCheckboxes,
        headerText,
        // idsSelected,
        allSelected,
      }
    case 'setCheckboxes':
      // visibleCheckboxes = calculateVisibleColumns({
      //   filteredCheckboxes: action.checkboxes,
      //   currentPageNumber: 1,
      // })
      headerText = returnHeader(action.checkboxes)
      return {
        ...state,
        checkboxes: [...action.checkboxes],
        // visibleCheckboxes,
        filteredCheckboxes: [...action.checkboxes],
        allSelected: false,
        // idsSelected: [...action.selectedIDsInList],
        // totalPageCount: action.checkboxes.length/action.pageSize,
        headerText,
      }
    case 'toggleShowWindow':
      return {...state, showWindow: state.showWindow === 'yes' ? 'no' : 'yes'}
    // case 'pageChange':
    //   visibleCheckboxes = calculateVisibleColumns({
    //     filteredCheckboxes: state.filteredCheckboxes,
    //     currentPageNumber: action.activePage,
    //     pageSize: action.pageSize,
    //   })
    //   return {...state, currentPageNumber: action.activePage, visibleCheckboxes}
    case 'searchChange':
      filteredCheckboxes = calculateFilteredCheckboxes({
        checkboxes: state.checkboxes,
        searchValue: action.search,
        showingSelectedOnly: state.showingSelectedOnly,
      })

      // visibleCheckboxes = calculateVisibleColumns({
      //   filteredCheckboxes,
      //   currentPageNumber: 1,
      //   pageSize: action.pageSize,
      // })
      return {
        ...state,
        searchValue: action.search,
        // visibleCheckboxes,
        filteredCheckboxes,
        // currentPageNumber: 1,
        // totalPageCount: filteredCheckboxes.length/action.pageSize
      }
    case 'showSelected':
      filteredCheckboxes = calculateFilteredCheckboxes({
        checkboxes: state.checkboxes,
        searchValue: state.searchValue,
        showingSelectedOnly: action.active,
      })
      // visibleCheckboxes = calculateVisibleColumns({
      //   filteredCheckboxes,
      //   currentPageNumber: 1,
      //   pageSize: action.pageSize,
      // })
      return {
        ...state,
        // visibleCheckboxes,
        filteredCheckboxes,
        // currentPageNumber: 1,
        showingSelectedOnly: action.active,
        // totalPageCount: filteredCheckboxes.length/action.pageSize
      }
    case 'selectAll':
      return {
        ...state,
        allSelected: action.active,
      }
    case 'updateSelectedIDs':
      state.filteredCheckboxes.forEach(item => {
        const foundSelected = action.selectedIDs?.indexOf(item.id) > -1
        if (foundSelected) idsSelected.push(item.id)
        filteredCheckboxes.push({
          id: item.id,
          label: item.label,
          checked: foundSelected,
        })
      })
      state.checkboxes.forEach(item => {
        const foundSelected = action.selectedIDs?.indexOf(item.id) > -1
        if (foundSelected) idsSelected.push(item.id)
        checkboxes.push({
          id: item.id,
          label: item.label,
          checked: foundSelected,
        })
      })
      // visibleCheckboxes = calculateVisibleColumns({
      //   filteredCheckboxes,
      //   currentPageNumber: 1,
      //   pageSize: action.pageSize,
      // })
      headerText = returnHeader(checkboxes)
      return {
        ...state,
        checkboxes: [...checkboxes],
        // visibleCheckboxes,
        filteredCheckboxes: [...filteredCheckboxes],
        // idsSelected: [...action.selectedIDs || []],
        // totalPageCount: checkboxes.length/action.pageSize,
        headerText,
      }
    default: {
      return state
    }
  }
}
