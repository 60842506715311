import * as React from 'react'
import {ArrowDown, ArrowUp} from 'phosphor-react'
// https://tanstack.com/table/v8/docs/examples/react/filters
import {ColumnDef, FilterFn, Header, flexRender} from '@tanstack/react-table'

import {
  SortedArrow,
  HeaderContainer,
  ResizableHeader,
} from './resizableTableStyles'

//TODO figure out how to type this without errors
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fuzzyFilter: FilterFn<any> = (
  row,
  columnId,
  value,
  // addMeta,
) => {
  // Rank the item
  // const itemRank = rankItem(row.getValue(columnId), value)

  // // Store the itemRank info
  // addMeta({
  //   itemRank,
  // })
  const testValue = String(row.getValue(columnId)).toLowerCase()
  // Return if the item should be filtered in/out
  return testValue.includes(value.toLowerCase())
}

export const TableHeader = <T,>({header}: {header: Header<T, unknown>}) => (
  <HeaderContainer
    key={header.id}
    data-column-name={header.id} // useEffect depends on that !
    align={header.column.columnDef.meta?.headerAlign || 'left'}
    colSpan={header.colSpan}
    style={{width: header.getSize()}}
    hidden={header.getSize() === 0}
  >
    {header.isPlaceholder ? null : (
      <ResizableHeader
        onClick={header.column.getToggleSortingHandler()}
        canSort={header.column.getCanSort()}
        headerAlign={header.column.columnDef.meta?.headerAlign}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
        {{
          asc: (
            <SortedArrow>
              <ArrowUp weight={'bold'} />
            </SortedArrow>
          ),
          desc: (
            <SortedArrow>
              <ArrowDown weight={'bold'} />
            </SortedArrow>
          ),
        }[header.column.getIsSorted() as string] ?? null}
      </ResizableHeader>
    )}
    {header.column.getCanResize() && (
      <div
        onMouseDown={header.getResizeHandler()}
        onTouchStart={header.getResizeHandler()}
        className={`resizer ${
          header.column.getIsResizing() ? 'isResizing' : ''
        }`}
      ></div>
    )}
  </HeaderContainer>
)

type calcColWidthType<S, T> = {
  columns: ColumnDef<S, T>[]
  hiddenColumns: string[]
  containerWidth?: number
}
// eslint-disable-next-line import/no-unused-modules
export const calcColWidth = <S, T>({
  columns,
  hiddenColumns,
  containerWidth,
}: calcColWidthType<S, T>) => {
  //calculate width to fill space
  const cellPadding = 12
  if (columns.length > 8 || !containerWidth) return columns // if 8 columns or more just use 150
  let showingColumns: ColumnDef<S, T>[] = []
  if (hiddenColumns.length) {
    columns.forEach(col => {
      if (hiddenColumns.findIndex(hc => hc === col.id) === -1) {
        showingColumns.push(col)
      }
    })
  } else {
    showingColumns = [...columns]
  }
  const availableWidth =
    containerWidth - cellPadding - cellPadding * showingColumns.length
  showingColumns.length * 2 //space for borders and padding
  const widthSpecified = showingColumns.reduce(
    (sum, {size = 0}) => sum + size,
    0,
  )
  const colsWithoutWidth = showingColumns.filter(c => !c.size).length
  let spreadWidth = (availableWidth - widthSpecified) / colsWithoutWidth
  if (spreadWidth < 100) spreadWidth = 100
  const newColumns = columns.map(col => {
    if (hiddenColumns.findIndex(hc => hc === col.id) === -1) {
      return col.size ? col : {...col, size: spreadWidth}
    } else {
      return {...col, size: 0}
    }
  })
  return newColumns
}
