import * as React from 'react'
import styled from 'styled-components/macro'
import {Message} from 'semantic-ui-react'
import {
  convertToUsersDateFormat,
  convertToUsersTimeFormat,
} from 'common/helpersDateTime'
import {QUERIES} from 'common/constants'
import {useStore} from 'common/useStore'

interface IProps {
  Created_On: string
  Created_By_UserName: string
  Updated_On: string
  Updated_By_UserName: string
  hidden: boolean
}

const Container = styled(Message)`
  &&& {
    margin: 0;
    background-color: transparent;
    box-shadow: 0 0 0 0 transparent !important;
    display: none;
    @media ${QUERIES.laptopAndUp} {
      display: flex;
    }
  }
`

const StyledMessage = styled(Message.Content)`
  &&& {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    background: transparent;
    &div {
      display: flex;
      flex-direction: column;
    }
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 5px solid
    ${(props: {type: string}) =>
      props.type === 'created' ? 'var(--asc-moss)' : 'var(--asc-tan)'};
  div {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    font-size: 13px;
    span {
      display: flex;
      flex-direction: row;
      margin-left: 20px;
    }
  }
`

const SystemInformation = ({
  Created_On,
  Created_By_UserName = '',
  Updated_On,
  Updated_By_UserName = '',
}: IProps) => {
  const userConfig = useStore(state => state.userConfig)

  return (
    <Container>
      <StyledMessage>
        <InfoContainer type={'created'}>
          <div>
            <strong>Created by &nbsp;</strong>
            <span>{Created_By_UserName}</span>
          </div>
          <div>
            <strong>Created on &nbsp;</strong>
            <span>
              {Created_On && userConfig
                ? `${convertToUsersDateFormat(
                    Created_On,
                    userConfig,
                  )} ${convertToUsersTimeFormat(Created_On, userConfig)}`
                : ''}
            </span>
          </div>
        </InfoContainer>
        <InfoContainer type={'updated'}>
          <div>
            <strong>Last Modified by &nbsp;</strong>
            <span>{Updated_By_UserName}</span>
          </div>
          <div>
            <strong>Last Modified on &nbsp;</strong>
            <span>
              {Updated_On && userConfig
                ? `${convertToUsersDateFormat(
                    Updated_On,
                    userConfig,
                  )} ${convertToUsersTimeFormat(Updated_On, userConfig)}`
                : ''}
            </span>
          </div>
        </InfoContainer>
      </StyledMessage>
    </Container>
  )
}

export default SystemInformation
