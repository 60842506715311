import styled from 'styled-components/macro'
import {Button} from 'common/components'
import {Button as SemanticButton} from 'semantic-ui-react'
import {QUERIES} from 'common/constants'

export const Styles = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 70px); */
  .table {
    border-spacing: 0;
    cursor: ${p => (p.rowClick ? 'pointer' : 'inherit')};
    border: none;
    .thead {
      ${
        '' /* These styles are required for a scrollable body to align with the header properly */
      }
      white-space: nowrap;
    }
    .body {
      .tr {
        box-shadow: none;
      }
    }
    .tr,
    th {
      :last-child {
        .td {
          border-bottom: 1px solid var(--table-borders);
        }
      }
    }
    .subheader {
      margin: 0px !important;
    }
    .tr .subheader {
      box-shadow: none !important;
    }
    .tr {
      background: none;
      border-radius: 0;
      margin: 0;
    }

    .th {
      background-color: rgb(240, 240, 240);
      padding-left: 0;
      .td {
        vertical-align: bottom;
        height: 100%;
      }
    }

    .tr {
      :hover {
        .td {
          background-color: var(--asc-hoverrow) !important;
        }
      }
    }
    .active-item .td {
      background-color: var(--asc-coolgray) !important;
    }
    .subheader {
      display: flex;
    }

    .tr .td {
      padding: 12px 6px;
    }

    .th,
    .td {
      margin: 0;

      overflow: visible;
      :last-child {
        border-right: 0;
      }
    }

    [data-sticky-td] {
      position: relative !important; //no sticky columns on mobile
    }
    &.sticky {
      overflow: auto;
      .header,
      .footer {
        position: sticky;
        z-index: 5;
        width: fit-content;
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
        background: transparent;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
        padding-bottom: 0;
      }

      @media ${QUERIES.tabletAndUp} {
        [data-sticky-td] {
          position: sticky !important;
          border-right: 1px solid rgb(240, 240, 240);
        }

        .clickableRow {
          cursor: pointer;
          :hover {
            background-color: #ccc !important;
          }
        }
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
  .directDistance {
    color: #fff !important;
    background-color: var(--asc-moss) !important;
    font-weight: 700;
    justify-content: right;
    align-items: center;
    padding-right: 15px;
    border-bottom: 1px solid var(--asc-coolgray);
  }
  a {
    color: var(--primary);
    text-decoration: none;
    font-weight: 700;
    &:hover {
      color: var(--asc-moss) !important;
      text-decoration: underline;
    }
  }
`

export const Table = styled.div`
  overflow-x: scroll;
  /* width: ${p =>
    p.width ? p.width : `calc(100vw - ${p.leftMenuWidth + 32}px)`}; */
`

export const Body = styled.div`
  position: relative;
  height: calc(100vh - ${p => (p.showingPagination ? '260px' : '210px')});
  @media ${QUERIES.tabletAndUp} {
    height: ${p => {
      let headerHeight = 0
      if (p.showingPagination) {
        if (p.hasPieChart) {
          headerHeight = 0
        } else {
          headerHeight = 75
        }
      } else {
        if (p.hasPieChart) {
          headerHeight = 10
        } else {
          headerHeight = 100
        }
      }
      return p.height ? p.height : `calc(100vh - ${270 - headerHeight}px)`
    }};
  }
`

export const Header = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: ${({align}) => (align ? align : 'left')};
  align-items: center;
  padding: 3px;
  border-bottom: 1px var(--table-borders) solid;
  background: var(--primary);
  color: white;
  span {
    width: 75%;
    font-weight: bold;
    margin-right: 5px;
  }
`

export const TableHeadersAll = styled.div`
  /* overflow-y: scroll;
  overflow-x: scroll; */
  /* position: sticky;
  top: 0; */
  .groupingHeader {
    background-color: rgb(251, 251, 251) !important;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
`

export const TableHeaders = styled.div`
  text-align: center;
  font-weight: bold;
  .td {
    text-align: left;
  }
  .td > span > div {
    justify-content: flex-start;
  }
`

export const TableSubHeaders = styled.div`
  input {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--table-borders);
    background: #fff;
    /* padding: 2px; */
    font-size: inherit;
    border-radius: 3px;
    font-weight: 400;
    outline-width: 0;
  }
`

export const PaginationFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 0;
  background-color: #eee;
  padding: 5px;
`

// export const PaginationCenter = styled.div`
//   display: flex;
// `

export const PaginationButtons = styled.button`
  /* width: 80%; */
  /* @media (min-width: 991px) {
    min-width: 150px;
  } */
  cursor: pointer;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 3px 10px;
  border: 1px solid #bbb;
  border-radius: 3px;
  :hover,
  :focus {
    background-color: rgba(0, 0, 0, 0.35);
    outline: none;
  }
`

export const PaginationPageCounts = styled.div`
  display: flex;
  align-items: center;
  input {
    border: 1px solid var(--table-borders);
    background: #fff;
    line-height: 1.21428571em;
    padding: 0.67857143em 0.5em;
    margin: 0 5px !important;
    font-size: inherit;
    border-radius: 3px;
    font-weight: 400;
    outline-width: 0;
    width: 50px !important;
  }
`
export const PaginationPageDropDown = styled.div`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: flex;
  }
  align-items: center;
  select {
    border: 1px solid var(--table-borders);
    background: #fff;
    padding: 2px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: 400;
    outline-width: 0;
    line-height: 1.15;
    height: 100%;
  }
`

export const NoRecordsRow = styled.div`
  text-align: center;
  padding: 6px;
`

export const TableTitle = styled.div`
  width: 100%;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledMenu = styled.div`
   {
    background: var(--asc-cultured);
    padding: 5px;
    width: 100%;
    margin-bottom: 0;
    flex: 1;
    padding-right: 0;
    height: 80px;
    max-height: 80px;
    flex-wrap: wrap;
    display: none;
    @media ${QUERIES.tabletAndUp} {
      flex-wrap: nowrap;
      padding-right: 20px;
      display: flex;
    }
    h4 {
      font-size: 1.4rem;
      margin-bottom: 0;
      padding-left: 10px;
    }

    justify-content: space-between;
    align-items: center;
  }
`
export const MobileMenu = styled(StyledMenu)`
  justify-content: ${({newButton, hiddenNewButton}) =>
    newButton && !hiddenNewButton ? 'space-between' : 'center'};
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  @media ${QUERIES.tabletAndUp} {
    display: none;
    visibility: hidden;
  }
`
export const TitleButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({isGrid}) => (isGrid ? 'flex-end' : 'flex-start')};
  flex-grow: 1;
  > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    h4 {
      margin-right: 10px;
      white-space: nowrap;
    }
  }
`

export const TitleAndBadge = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`

export const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  button {
    min-width: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 0 5px;
    max-height: 35px;
  }
  span {
    padding: 0 2px;
  }
`

export const SortedArrow = styled.div`
  &&& {
    width: 14px !important;
    height: 14px !important;
    margin-left: 3px;
    svg {
      width: 14px !important;
      height: 14px !important;
    }
  }
`
export const ItemBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: center;
  background-color: transparent;
`

export const ItemBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 40px;
  min-height: 1em;
  max-height: 30px;
  padding: 0.37em;
  line-height: 1em;
  justify-content: center;
  border-radius: 500rem;
  background-color: var(--secondary);
  box-shadow: 1px 1px 1px var(--asc-coolgray);
  color: black;
  font-weight: bold;
  white-space: nowrap;
`
export const TitleButton = styled(Button)`
  &&& {
    text-transform: capitalize;
    background: var(--secondary);
    color: var(--primary);
  }
`

export const StyledTableRow = styled.div`
  .td {
    background-color: ${p =>
      p.activeRow
        ? 'var(--asc-coolgray)'
        : p.index % 2 === 0
        ? 'var(--asc-cultured)'
        : 'white'};
    display: flex !important;
    border-bottom: 1px solid var(--table-borders);
    align-items: flex-start;
    padding: 12px;
  }
`
export const IconHolder = styled.div`
  margin: 0px 5px;
  cursor: pointer;
  width: 100%;
`

export const StyledGroupButton = styled(SemanticButton.Group)`
  && {
    align-items: center;
    height: 40px;
    svg {
      min-width: 20px;
      min-height: 20px;
    }
  }
`
export const StyledButton = styled(Button)`
  align-items: center;
  background: ${({primary}) =>
    primary ? 'var(--primary)' : 'white'} !important;
  color: ${({primary}) =>
    primary ? 'var(--secondary)' : 'var(--primary)'} !important ;
  border: 1px solid var(--primary) !important;
  border-radius: ${({first}) =>
    first ? '5px 0 0 5px' : '0 5px 5px 0'} !important;
  border-right: ${({first}) => (first ? 'none' : 'default')} !important;
  padding: 1px !important;
  display: flex;
  flex-direction: row;
`

export const TableButtonsPlaceHolder = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
  @media ${QUERIES.tabletAndUp} {
    margin-right: 10px;
  }
`
