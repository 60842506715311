import {differenceInSeconds, parseISO} from 'date-fns'

export function createGeoJson(events) {
  var convertedJSON = {}
  var feature = {} //individual feature
  convertedJSON.type = 'FeatureCollection'
  convertedJSON.features = []
  const eventsWithStopTime = calculateStopTimes(events)
  eventsWithStopTime?.forEach((thisEvent, index) => {
    const coordinates = [thisEvent.Longitude, thisEvent.Latitude]
    if (thisEvent.Longitude && thisEvent.Latitude && coordinates !== [0, 0]) {
      feature = {}
      feature.type = 'Feature'
      feature.geometry = {}
      feature.geometry.type = 'Point'
      feature.properties = {}
      feature.properties.id = index + 1
      feature.properties.idEvent_Code = thisEvent.idEvent_Code
      feature.properties.idRaw_Data = thisEvent.idRaw_Data
      feature.properties.Event_Type = thisEvent.Event_Type
      feature.properties.Event_Timestamp = thisEvent.Event_Timestamp
      feature.properties.Event_Timestamp_Local = thisEvent.Event_Timestamp_Local
      feature.properties.Nearby_Address = thisEvent.Nearby_Address
      feature.properties.Odometer =
        thisEvent.Engine_Odometer || thisEvent.GPS_Odometer
      feature.properties.Last_Heading = thisEvent.Last_Heading
      feature.properties.Last_Heading_Cardinal = thisEvent.Last_Heading_Cardinal
      feature.properties.Last_Speed = thisEvent.Last_Speed
      feature.properties.In_Motion = thisEvent.In_Motion
      feature.properties.stopLength = thisEvent.stopLength || 0
      feature.properties.Alerts = thisEvent.Alerts
      feature.properties.SensorEvent = thisEvent.SensorEvent
      feature.properties.CameraMediaEventIds = thisEvent.CameraMediaEventIds
      feature.properties.CurrentDriver = thisEvent.CurrentDriver
      feature.geometry.coordinates = [thisEvent.Longitude, thisEvent.Latitude]
      feature.properties.validCoordinates = thisEvent.validCoordinates
      feature.properties.visible = thisEvent.visible
      feature.properties.color = thisEvent.color
      feature.properties.title = thisEvent.title
      feature.properties.icon = thisEvent.icon

      convertedJSON.features.push(feature)
    }
  })
  return convertedJSON
}

export function secondsToHMSS(input) {
  var hours = Math.floor(input / 60 / 60)
  var minutes = Math.floor(input / 60) % 60
  var seconds = input % 60
  return (
    hours +
    ':' +
    (minutes < 10 ? '0' : '') +
    minutes +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds
  )
}

function calculateStopTimes(events) {
  var inMotion = false
  var eventCount = events?.length

  events?.forEach(point => {
    var pointDateTime = parseISO(point.Event_Timestamp + 'Z')
    var stopLength, nextMotion
    if ((!point.In_Motion || !point.Engine_Running) && inMotion) {
      //this point is not in motion and we were in motion before then record stop
      inMotion = false
      stopLength = 0
      if (!point.stopLength) {
        //check that stop time wasn't already calculated (subsequent filters)
        // if (index === eventCount - 1) {
        //   //this is the last entry - calculate stop time to now
        //   stopLength = Math.floor(
        //     differenceInSeconds(new Date(), pointDateTime),
        //   )
        //   // stopLength = Math.floor(moment.utc().diff(moment.utc(timeux)) / 1000);
        // } else {
        //now find the next entry where we are in motion
        const futureEvents = events.filter(o => {
          return o.idRaw_Data > point.idRaw_Data
        })
        nextMotion = futureEvents.find(e => e.In_Motion)
        if (nextMotion) {
          const nextMotionDate = parseISO(nextMotion.Event_Timestamp + 'Z')
          stopLength = Math.floor(
            differenceInSeconds(nextMotionDate, pointDateTime),
          )
          // stopLength = moment(nextMotion.Event_Timestamp).diff(moment(timeux)) / 1000;
        } else {
          if (differenceInSeconds < 86400) {
            stopLength = Math.floor(
              differenceInSeconds(new Date(), pointDateTime),
            )
          }
        }
        // }
      }
      if (stopLength > 0) {
        stopLength = Math.round(stopLength) //round off decimals - some camera events were giving fractions
        if (nextMotion) {
          //put stop time on last stop event not first one
          var nextMotionIndex = events.findIndex(event => {
            return event.idRaw_Data === nextMotion.idRaw_Data
          })
          events[nextMotionIndex - 1].stopLength = secondsToHMSS(stopLength)
        } else {
          //last event
          events[eventCount - 1].stopLength = secondsToHMSS(stopLength)
        }
      }
    } else {
      if (point.In_Motion && !inMotion) {
        //we are now in motion and weren't before
        inMotion = true
      }
    }
  })
  return events
}
