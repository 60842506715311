import create from 'zustand'
import {initialGutAssetState} from 'gut/gutHelpers'

const removeDirections = directions => {
  directions.routes?.map(route => {
    if (route !== null) {
      route.setMap(null)
      route.setPanel(null)
      route = null
    }
    return route
  })
  directions.directionsServiceArray = []
  directions.markers?.map(marker => marker.setMap(null))
  directions.markers = []
}

const initialStore = {
  markers: [],
  clusters: [],
  recentAssets: [],
  locationMarkers: [],
  zoneMarkers: [],
  directions: {routes: [], markers: [], directionsServiceArray: []},
  totalActiveAssets: 0,
  activeMarker: undefined,
  activeUnassignedMarker: undefined,
  showBoxSearch: false,
  boxSearchMarker: null,
  boxSearchRectangle: null,
  boxSearchDrawingManager: null,
  addOrdersButton: null,
  gutAssetData: initialGutAssetState,

  gutAlertData: {
    count: 0,
    color: 'green',
    types: [],
  },
  showingNearestAssetDistances: false,
  nearByDistances: [],
  gridSort: null,
  showClusters: true,
  showDropdown: false,
  clickedLocation: null,
  showAssetMarkers: true,
}

export const useMapStore = create((set, get) => ({
  ...initialStore,
  removeDirections: () => {
    const directions = get().directions
    removeDirections(directions)
    set({directions: {routes: [], markers: [], directionsServiceArray: []}})
  },
  setDirections: directions => {
    set({directions})
  },
  setActiveMarker: marker => {
    set({activeUnassignedMarker: undefined})
    set({activeMarker: marker})
  },
  setActiveUnassignedMarker: marker => {
    set({activeMarker: undefined})
    set({activeUnassignedMarker: marker})
  },
  setTotalActiveAssets: count => set({totalActiveAssets: count}),
  emptyStore: () => set(state => ({...state, ...initialStore})),
}))
