import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getUnassignedDevices = async () => {
  const {data} = await Axios.get('/devices/unassigned')

  return data
}

export default function useUnassignedDevices() {
  const user = useStore(state => state.user)

  return useQuery(['unassignedDevices'], getUnassignedDevices, {
    enabled: Boolean(user?.token),
  });
}
