import React, {Fragment} from 'react'
import {HEADING_HEIGHT} from 'common/constants'
import {
  useTable,
  useSortBy,
  // useFilters,
  // useGlobalFilter,
  // usePagination,
  // useResizeColumns,
  useBlockLayout,
} from 'react-table'
import {useVirtual} from 'react-virtual'
import {useSticky} from 'react-table-sticky'
import {useExportData} from 'react-table-plugins'
import {useStore} from 'common/useStore'
import {
  getExportFileBlob,
  compareIgnoreCase,
} from '../common/tables/tableWithHooksHelpers'
import GridGlobalFilter from './GridGlobalFilter'
import ColumnFilter from './GridColumnFilter'
import AddNewGroupButton from 'grid/AddNewGroup'
import GridTableButtons from './GridTableButtons'
// import GridTablePagination from './GridTablePagination'
import {
  Styles,
  TableHeaders,
  TableHeadersAll,
  TableSubHeaders,
  NoRecordsRow,
  StyledMenu,
  Table,
  TitleButtons,
  Header,
} from '../common/tables/tableStyles'
// import {DefaultColumnFilter} from '../common/tables/Filters'
import {Spinner} from 'common/components'
import styled from 'styled-components/macro'
import {nFormatter} from 'common/helpersNumber'
import {ArrowUp, ArrowDown} from 'phosphor-react'
import AddAssetButton from 'common/components/AddAssetButton'

const StyledTable = styled(Table)`
  border: none !important;
  .asset {
    border-right: 1px solid black;
  }
  .th {
    border-right: none !important;
    box-shadow: none;
    :first-child {
      border-right: 1px solid var(--grey-200) !important;
    }
  }
  .td {
    :first-child {
      border-right: 1px solid var(--grey-200) !important;
    }
    border-right: none !important;
  }
  /* .resizer {
    display: none !important;
  } */
  height: ${p =>
    p.gridFullscreen
      ? `calc(100vh - ${66 + HEADING_HEIGHT}px)`
      : `calc(50vh - ${106 + HEADING_HEIGHT}px)`};
`
const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  width: 100%;
  align-self: center;
  text-align: right;
  justify-content: flex-start;
  margin-right: 10px;
`

const HeaderBadge = styled.div`
  min-width: 2em;
  min-height: 1em;
  padding: 0.37em;
  line-height: 1em;
  text-align: center;
  border-radius: 500rem;
  background-color: var(--secondary);
  box-shadow: 1px 1px 1px var(--asc-coolgray);
  color: black;
  font-weight: bold;
  align-self: center;
`

const HeaderLabel = styled.div`
  display: flex;
  margin-left: 15px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 24px;
`

const StyledHeader = styled(Header)`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 3px;
  box-shadow: none;
  background: var(--primary);
  color: white;
  padding: 10px 0px;
  white-space: nowrap;
  span {
    margin-left: 10px;
  }
`
const SortedArrow = styled.div`
  margin-left: 3px;
`

const StyledTableRow = styled.div`
  height: 44px;
  cursor: pointer;

  .td {
    background-color: ${p =>
      p.activeRow
        ? 'var(--asc-coolgray)'
        : p.index % 2 === 0
        ? 'var(--asc-cultured)'
        : 'white'};
    display: flex !important;
  }
`

const GridTable = ({
  columns,
  data = [],
  activeId,
  showingNearest,
  setActiveMarker,
  setGridFullscreen,
  gridFullscreen,
  filterAssetsFromList,
}) => {
  const setGridOpen = useStore(state => state.setGridOpen)
  const setAssetListOpen = useStore(state => state.setAssetListOpen)
  const [showGlobalFilter, setShowGlobalFilter] = React.useState(false)

  const parentRef = React.useRef()
  // const defaultColumn = React.useMemo(
  //   () => ({
  //     Filter: DefaultColumnFilter,
  //     // minWidth: 50, // minWidth is only used as a limit for resizing
  //     // width: 150, // width is used for both the flex-basis and flex-grow
  //     // maxWidth: 1000, // maxWidth is only used as a limit for resizing
  //   }),
  //   [],
  // )

  const memoizedData = React.useMemo(() => {
    return data
  }, [data])

  const exportFile = React.useCallback(props => {
    const newProps = {
      ...props,
      fileName: props.fileName === 'all-data' ? 'Grid' : `Grid-filtered`,
    }
    return getExportFileBlob(newProps)
  }, [])

  const sortTypes = React.useMemo(
    () => ({
      alphanumeric: (row1, row2, columnName) => {
        return compareIgnoreCase(
          row1.values[columnName],
          row2.values[columnName],
        )
      },
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // page,
    prepareRow,
    rows,
    state: {hiddenColumns},
    exportData,
    toggleSortBy,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data: memoizedData,
      // defaultColumn,
      getExportFileBlob: exportFile,
      sortTypes: sortTypes,
      initialState: {
        pageSize: 50,
        hiddenColumns: ['FindNearestAssetDistance'],
      },
      autoResetSortBy: false,
      autoResetPage: false,
    },
    // useResizeColumns,
    useBlockLayout,
    // useGlobalFilter,
    useSortBy,
    // usePagination,
    useExportData,
    useSticky,
  )

  const {virtualItems, totalSize} = useVirtual({
    size: rows?.length || 0,
    parentRef,
    estimateSize: React.useCallback(() => 44, []),
    overscan: 3,
  })

  React.useEffect(() => {
    if (showingNearest && hiddenColumns.length) {
      setHiddenColumns([])
    } else if (!showingNearest && hiddenColumns?.length === 0) {
      setHiddenColumns(['FindNearestAssetDistance'])
    }
  }, [hiddenColumns, setHiddenColumns, showingNearest, toggleSortBy])

  React.useEffect(() => {
    if (!hiddenColumns.length) {
      toggleSortBy('FindNearestAssetDistance', false, false)
    }
  }, [hiddenColumns.length, toggleSortBy])

  const closeGrid = () => {
    filterAssetsFromList({
      label: 'clearGridFilters',
    })

    setGridOpen(false)
  }

  const showAssetList = () => {
    closeGrid()
    setAssetListOpen(true)
  }

  const toggleGlobalFilter = () => {
    setShowGlobalFilter(!showGlobalFilter)
  }

  const scrollRef = React.useRef()

  const RenderRow = React.useCallback(
    (virtualRow, item) => {
      const {index, start} = virtualRow
      // const cellProps = (props, {cell}) => getStyles(props, cell.column.align)
      const rowClick = ({original}) => {
        const changeActiveMarkerFromClick = () => {
          const newActiveMarker = {
            idDevice: original.idDevice,
            idAsset: original.idAsset,
            label: original.Asset_Label,
            lat: original.Device.Latitude,
            lng: original.Device.Longitude,
            following: true,
            heading: original.Device.Last_Heading,
          }
          setActiveMarker(newActiveMarker)
        }
        if (original.idAsset) {
          changeActiveMarkerFromClick()
        }
      }
      prepareRow(item)
      return (
        <StyledTableRow
          data-cy="grid-table-row"
          className="tr"
          key={virtualRow.index}
          onClick={rowClick ? () => rowClick(item) : null}
          {...item.getRowProps()}
          index={index}
          // className="tr clickableRow"
          activeRow={activeId && item?.original.idAsset === activeId}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            transform: `translateY(${start}px)`,
          }}
        >
          {item.cells.map(cell => {
            return (
              <div
                {...cell.getCellProps()}
                className={`td ${
                  cell.column.Header === 'Direct Distance'
                    ? 'directDistance'
                    : ''
                }`}
              >
                {cell.column.Header === 'Direct Distance' &&
                cell.value === 999999999999
                  ? ''
                  : cell.render('Cell')}
              </div>
            )
          })}
        </StyledTableRow>
      )
    },
    [activeId, prepareRow, setActiveMarker],
  )

  return (
    <Styles>
      <StyledMenu isGrid={true}>
        <TitleButtons>
          <LabelContainer>
            <HeaderLabel>Assets</HeaderLabel>
            <HeaderBadge data-cy="grid-counter">
              {rows.length !== data.length
                ? `${nFormatter(rows.length, 0)} of `
                : ''}
              {nFormatter(data.length, 0)}
            </HeaderBadge>
          </LabelContainer>
          <AddNewGroupButton allData={data} tableRows={rows} />
        </TitleButtons>
        <TitleButtons isGrid={true}>
          {showGlobalFilter ? (
            <GridGlobalFilter
              totalAssetCount={data.length || 0}
              filterAssetsFromList={filterAssetsFromList}
              columns={columns}
            />
          ) : (
            <div />
          )}
          <GridTableButtons
            exportData={exportData}
            newButton={null}
            hiddenNewButton={true}
            hiddenExportButton={false}
            showAssetList={showAssetList}
            closeGrid={closeGrid}
            toggleGlobalFilter={toggleGlobalFilter}
            showGlobalFilter={showGlobalFilter}
            setGridFullscreen={setGridFullscreen}
            gridFullscreen={gridFullscreen}
          />
        </TitleButtons>
      </StyledMenu>
      <StyledTable
        {...getTableProps()}
        className="table sticky"
        data-cy="grid-table"
        gridFullscreen={gridFullscreen}
        ref={parentRef}
      >
        <TableHeadersAll className="header">
          {headerGroups.map((headerGroup, index) => (
            <Fragment key={`hgtr-${index}`}>
              <div
                ref={scrollRef}
                {...headerGroup.getHeaderGroupProps({
                  // style: { paddingRight: '15px' },
                })}
                data-cy="grid-header-rows"
              >
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting.
                  <TableHeaders
                    // key={`hgth-${colIndex}`}

                    {...column.getHeaderProps()}
                    className={`th ${
                      index !== headerGroups.length - 1 &&
                      column?.Header?.name !== undefined
                        ? 'groupingHeader'
                        : ''
                    } ${
                      column?.Header === 'Direct Distance'
                        ? 'directDistance'
                        : ''
                    }`}
                  >
                    <span
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <StyledHeader
                        sorted={
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'desc'
                              : 'asc'
                            : ''
                        }
                        className={`${
                          column.Header === 'Asset'
                            ? column.Header.toLowerCase()
                            : ''
                        }`}
                      >
                        <span>{column.render('Header')}</span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortedArrow>
                              <ArrowDown weight={'bold'} />
                            </SortedArrow>
                          ) : (
                            <SortedArrow>
                              <ArrowUp weight={'bold'} />
                            </SortedArrow>
                          )
                        ) : (
                          <div />
                        )}
                      </StyledHeader>
                    </span>
                    {/* Use column.getResizerProps to hook up the events correctly */}
                    {/* {column.canResize && index === headerGroups.length - 1 && (
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${
                          column.isResizing ? 'isResizing' : ''
                        }`}
                      />
                    )} */}
                  </TableHeaders>
                ))}
              </div>

              {showGlobalFilter ? (
                <div className="tr subheader">
                  {headerGroup.headers.map((column, colIndex) => (
                    <TableSubHeaders
                      className="th"
                      key={`shth-${colIndex}`}
                      {...column.getHeaderProps()}
                    >
                      {column.disableFilters === false ? (
                        <ColumnFilter
                          column={column}
                          filterAssetsFromList={filterAssetsFromList}
                          columns={columns}
                          name={column.id}
                        />
                      ) : (
                        <></>
                      )}
                    </TableSubHeaders>
                  ))}
                </div>
              ) : (
                ''
              )}
            </Fragment>
          ))}
        </TableHeadersAll>
        <div className="body" {...getTableBodyProps()}>
          {status === 'loading' ? (
            <Spinner type={'partial'} />
          ) : rows.length > 0 ? (
            <div
              className="ListInner"
              style={{
                height: `${totalSize}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {virtualItems.map(row => {
                const item = rows[row.index]
                return RenderRow(row, item)
              })}
            </div>
          ) : (
            <div>
              <NoRecordsRow colSpan={100} data-cy="noRecords">
                {data.length === 0 ? <AddAssetButton /> : 'No Records Found'}
              </NoRecordsRow>
            </div>
          )}
        </div>
      </StyledTable>
      {/* <pre>
        <code>{JSON.stringify({state}, null, 2)}</code>
      </pre> */}
    </Styles>
  )
}

export default React.memo(GridTable)
