import {numberWithCommas} from 'common/helpersNumber'
import * as React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  border: none;
  box-shadow: none;
  background: var(--asc-platinum);
  padding: 0;
  border-radius: 500rem;
  text-align: center;
  overflow: hidden;
`

interface ProgressBarProps {
  color: string
  value: number
  className: string
  fontColor?: 'white' | 'black'
}

const Bar = styled.div<ProgressBarProps>`
  line-height: 1;
  position: relative;
  width: ${p => (p.value === Infinity ? 100 : p.value || 0)}%;
  min-width: 1em;
  background: var(--asc-sonicsilver);
  border-radius: 50px 0 0 50px;
  transition: width 0.1s ease, background-color 0.1s ease;
  background-color: ${p => p.color || 'green'} !important;
  height: 1.75em;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
`

const ProgressValue = styled.div`
  position: absolute;
  display: block;
  white-space: nowrap;
  width: 100%;
  font-size: calc(12 / 16 * 1rem);
  color: ${({fontColor}: {fontColor: 'white' | 'black'}) =>
    fontColor === 'black' ? 'black' : 'rgba(255,255,255,.7)'};
  text-shadow: none;
  font-weight: 700;
  top: 2px;
  span {
    font-size: calc(8 / 16 * 1rem);
  }
`

const ProgressBar = ({
  value,
  color,
  fontColor = 'white',
  className,
}: ProgressBarProps) => {
  const formattedValue = value
    ? value === Infinity
      ? '100+'
      : numberWithCommas(Math.round(value), 0)
    : 0
  return (
    <Container className={className}>
      <Bar
        className={className + ' bar'}
        color={color}
        value={value > 100 ? 100 : value}
      />
      <ProgressValue data-cy={'progress-bar'} fontColor={fontColor}>
        {formattedValue} %
      </ProgressValue>
    </Container>
  )
}
export default ProgressBar
