import * as React from 'react'
import {Dropdown} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import Swal from 'sweetalert2'
import {useStore} from 'common/useStore'
import {VERSION, APP_FLAVOR} from 'common/constants'
import {
  Question,
  Book,
  ChatCircleText,
  WarningCircle,
  CaretUp,
  CaretDown,
} from 'phosphor-react'
import ContactSupport from './ContactSupport'
import FeedBack from 'feedback/Feedback'

const StyledDropdown = styled(Dropdown)`
  &&& {
    font-family: 'Open Sans';
    div.menu {
      top: 30px;
      left: -112px;
      border: 1px solid var(--asc-graphite);
      padding: 5px;
      border-radius: 6px;
    }

    .selected.item {
      background: white;
      color: rgba(61, 73, 31, 0.66);
      &:hover {
        font-weight: 700;
        color: var(--primary);
      }
    }
    div.item {
      padding: 10px;
      font-weight: 700;
      color: rgba(61, 73, 31, 0.66);
      background-color: white;
      &:hover {
        background-color: var(--secondary);
        font-weight: 700;
        color: var(--primary);
      }
    }
    i.dropdown {
      display: none;
    }
  }
`

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin: 0 5px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  span {
    color: var(--primary);
    font-weight: bold;
    font-size: 13px;
    padding-left: 5px;
  }
  .item {
    svg {
      display: inline;
      margin-right: 5px;
    }
  }
`

const StyledDropdownItem = styled(Dropdown.Item)`
  &&& {
    display: flex !important;
  }
`

const HelpMenuDropdown = () => {
  const [openModal, setOpenModal] = React.useState(null)
  const [menuOpen, setMenuOpen] = React.useState(false)
  const closeModal = () => setOpenModal(null)
  const userConfig = useStore.getState().userConfig
  return (
    <React.Fragment>
      <Content>
        <StyledDropdown
          floating
          text={``}
          direction="right"
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
          icon={
            <Trigger>
              <Question size={23} weight={'fill'} color={'var(--primary)'} />
              <span>Help</span>
              {menuOpen ? (
                <CaretUp size={12} weight="fill" color={'var(--primary)'} />
              ) : (
                <CaretDown size={12} weight="fill" color={'var(--primary)'} />
              )}
            </Trigger>
          }
          closeOnBlur={true}
        >
          <Dropdown.Menu>
            {/* <Dropdown.Item
              icon={
                <Book
                  size={15}
                  weight={'fill'}
                  color={'var(--primary)'}
                />
              }
              text="Help Center"
              onClick={() => {
                window.open('https://help.temeda.com/', '_blank')
                setMenuOpen(false)
              }}
            /> */}
            <StyledDropdownItem
              icon={
                <Question
                  size={17}
                  weight={'regular'}
                  color={'var(--primary)'}
                />
              }
              text="Contact Support"
              onClick={() => {
                setOpenModal('contactSupport')
                setMenuOpen(false)
              }}
            />
            {APP_FLAVOR !== 'ascend' && (
              <StyledDropdownItem
                icon={
                  <Book size={17} weight={'fill'} color={'var(--primary)'} />
                }
                text="Help Center"
                onClick={() => {
                  window.open('https://help.temeda.com/', 'help')
                }}
              />
            )}
            <StyledDropdownItem
              icon={
                <ChatCircleText
                  size={17}
                  weight={'fill'}
                  color={'var(--primary)'}
                />
              }
              text="Send Feedback"
              onClick={() => {
                setOpenModal('feedback')
                setMenuOpen(false)
              }}
            />

            {/* <StyledDropdownItem
              icon={
                <Megaphone
                  size={15}
                  weight={'fill'}
                  color={'var(--primary)'}
                  mirrored={true}
                />
              }
              text="Whats New"
              onClick={() => {
                window.open('https://about.temeda.com/new_features/', '_blank')
                setMenuOpen(false)
              }}
            /> */}
            <StyledDropdownItem
              icon={
                <WarningCircle
                  size={17}
                  weight={'fill'}
                  color={'var(--primary)'}
                />
              }
              text="About"
              onClick={() => {
                Swal.fire({
                  title:
                    APP_FLAVOR === 'ascend'
                      ? '<strong>ASCEND</strong>'
                      : '<strong>TEMEDA</strong>',
                  icon: 'success',
                  html: `<div style="color:#fff; font-size: 8px; margin-top: -10px; ">
                  subby, dr dan, sir chrlz, shorty, the informant, MCA, zuluHotel, & the mad scientist!</div>
                 <div>
                 <div>Version ${VERSION}</div>
                 <div>${userConfig.WebServer}`,
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK',
                  confirmButtonColor: 'var(--asc-moss)',
                })
                // setShowDialog(true)
                setMenuOpen(false)
              }}
            />
          </Dropdown.Menu>
        </StyledDropdown>
      </Content>
      {openModal === 'contactSupport' && (
        <ContactSupport onDismiss={closeModal} />
      )}
      {openModal === 'feedback' && <FeedBack onDismiss={closeModal} />}
    </React.Fragment>
  )
}
export default HelpMenuDropdown
