import * as React from 'react'
import styled from 'styled-components/macro'
import {Button, Icon} from 'semantic-ui-react'
import {SystemInformation} from 'common/components'
import {QUERIES} from 'common/constants'

import {useLocation, useNavigate} from 'react-router-dom'
import {ArrowLeft, WarningCircle} from 'phosphor-react'

const ButtonContainer = styled.div`
  display: flex;
  padding: 15px 0;
  gap: 16px;
  margin-right: 15px;
  button {
    white-space: nowrap;
  }
`

const Controls = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media ${QUERIES.tabletAndUp} {
    flex-direction: row;
  }
  justify-content: space-between;
  gap: 16px;
  align-items: flex-end;
`

type ErrorProps = {errorcolor?: string; errorClickable?: boolean}
const ErrorWrapper = styled.div<ErrorProps>`
  background-color: ${props =>
    props.errorcolor === 'red'
      ? 'var(--asc-lavender)'
      : 'var(--asc-floralwhite)'};
  border: solid 1px
    ${props =>
      props.errorcolor === 'red'
        ? 'var(--asc-vermilion)'
        : 'var(--asc-ginfizz)'};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  max-width: 330px;
  align-items: center;
  cursor: ${props => (props.errorClickable ? 'pointer' : 'default')};
`
const ErrorIcon = styled(WarningCircle)<ErrorProps>`
  margin: 0 7px;
  & line {
    stroke: ${props => (props.errorcolor === 'red' ? 'white' : 'black')};
  }
  & circle {
    stroke: ${props =>
      props.errorcolor === 'red' ? 'var(--asc-vermilion)' : 'black'};
    fill: ${props =>
      props.errorcolor === 'red' ? 'var(--asc-vermilion)' : 'var(--asc-gold)'};
  }

  & circle:last-child {
    stroke: ${props => (props.errorcolor === 'red' ? 'white' : 'black')};
    fill: ${props => (props.errorcolor === 'red' ? 'white' : 'black')};
  }
`

const ErrorText = styled.div`
  font-size: 14px;
  margin-right: 5px;
`

interface StyledProps {
  $noButtonFocus?: boolean
}
const SaveButton = styled(Button)<StyledProps>`
  &&& {
    background: var(--secondary);
    color: var(--primary);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    height: 50px;
    svg {
      color: var(--primary);
    }
    :hover {
      svg {
        color: var(--secondary);
      }
      background: var(--primary);
      color: var(--secondary);
    }
    :active {
      background: var(--secondary) !important;
      color: var(--primary) !important;
    }
    :focus {
      background: var(--secondary) !important;
      color: var(--primary) !important;
      :hover {
        background: ${props =>
          props.$noButtonFocus
            ? 'var(--primary) !important;'
            : 'var(--secondary) !important;'};
        color: ${props =>
          props.$noButtonFocus
            ? 'var(--secondary) !important;'
            : 'var(--primary) !important;'};
      }
    }
  }
`
const BackButton = styled.button`
  height: 50px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  color: var(--primary);
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  border: 1px solid var(--primary);
  border-radius: 5px;
  padding: 0 20px;
  gap: 12px;
  cursor: pointer;
  svg {
    min-width: 18px;
    min-height: 18px;
  }
  :hover {
    background-color: var(--primary);
    color: #ffffff;
    svg {
      color: #ffffff;
    }
  }
`
const CancelButton = styled(Button)`
  &&& {
    background: transparent;
    color: var(--primary);
    :hover {
      background-color: var(--asc-platinum);
    }
  }
`

type DataType = {
  Created_On: string
  Created_By_UserName: string
  Updated_On: string
  Updated_By_UserName: string
  [key: string]: unknown
}
interface Props {
  data?: DataType
  submitting?: boolean
  submitDisabled?: boolean
  handleSubmit: () => void
  listRoute?: string
  errorMessage?: string
  errorColor?: string //red, orange
  cancelFunction?: () => void
  className?: string
  children?: React.ReactNode
  submitText?: string
  submitIcon?: React.ReactNode
  showSystemInfo?: boolean
  noButtonFocus?: boolean
  handleBack?: () => void
  backButtonText?: string
  errorClickable?: boolean
  handleErrorClicked?: () => void
}

const AdminDetailFooter: React.FC<Props> = ({
  data,
  submitting = false,
  handleSubmit,
  listRoute = '',
  errorMessage = '',
  cancelFunction,
  className = '',
  submitText = 'SAVE',
  showSystemInfo = true,
  submitIcon = null,
  noButtonFocus = false,
  handleBack = undefined,
  backButtonText = 'BACK',
  submitDisabled = false,
  errorColor = 'orange',
  errorClickable = false,
  handleErrorClicked,
}) => {
  const navigate = useNavigate()
  const {state: locationState} = useLocation()
  const onCancel = () => {
    if (cancelFunction) {
      cancelFunction()
    } else {
      navigate(listRoute, {state: {filters: locationState?.filters || ''}})
    }
  }
  return (
    <>
      <Controls className={className} data-cy={'admin-footer'}>
        {showSystemInfo && data ? (
          <SystemInformation
            Created_On={data?.Created_On}
            Created_By_UserName={data?.Created_By_UserName}
            Updated_On={data?.Updated_On}
            Updated_By_UserName={data?.Updated_By_UserName}
            hidden={false}
          />
        ) : (
          <div />
        )}
        <ButtonContainer>
          {errorMessage && (
            <ErrorWrapper
              errorcolor={errorColor}
              errorClickable={errorClickable}
              onClick={
                errorClickable
                  ? () => {
                      if (handleErrorClicked) handleErrorClicked()
                    }
                  : undefined
              }
            >
              <ErrorIcon size={23} weight={'regular'} errorcolor={errorColor} />
              <ErrorText id="error-message">NOTE: {errorMessage}</ErrorText>
            </ErrorWrapper>
          )}
          <CancelButton name="cancel" type="button" onClick={onCancel}>
            <Icon name="remove" />
            CANCEL
          </CancelButton>
          {handleBack ? (
            <BackButton onClick={handleBack}>
              <ArrowLeft weight="bold" /> {backButtonText}
            </BackButton>
          ) : null}
          <SaveButton
            $noButtonFocus={noButtonFocus}
            primary
            name="submit"
            type="button"
            loading={submitting}
            onClick={handleSubmit}
            disabled={submitting || submitDisabled}
          >
            {submitText || 'SAVE'}
            {submitIcon ? submitIcon : null}
          </SaveButton>
        </ButtonContainer>
      </Controls>
    </>
  )
}
export default AdminDetailFooter
