import styled from 'styled-components/macro'

export const SliderContainer = styled.div`
  position: relative;
  width: 95%;
  min-width: 300px;
  max-width: 600px;
  /* padding-bottom: 10px; */
`

export const Slider = styled.div`
  position: relative;
  z-index: 1;
  height: 6px;
  margin: 3px 3px 5px 3px;
`

export const Track = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 3px;
  background-color: var(--asc-tan);
`

export const Range = styled.div.attrs(p => ({
  style: {
    left: `${p.start / 10}%`,
    right: `${(1000 - p.end) / 10}%`,
  },
}))`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: var(--asc-brown);
`

export const Thumb = styled.div`
  position: absolute;
  z-index: 3;
  width: 15px;
  height: 15px;

  transition: box-shadow 0.3s ease-in-out;
`

export const LeftThumb = styled(Thumb).attrs(p => ({
  style: {
    left: `${p.start / 10}%`,
  },
}))`
  transform: translate(-4px, -4px) rotate(90deg);

  svg {
    z-index: 4;
    fill: var(--asc-tan);
    path {
      opacity: 1;
    }
  }
`

export const RightThumb = styled(Thumb).attrs(p => ({
  style: {
    right: `${(1000 - p.end) / 10}%`,
  },
}))`
  transform: translate(4px, -4px) rotate(90deg);
  svg {
    z-index: 4;
    fill: var(--asc-tan);
    path {
      opacity: 1;
    }
  }
`
export const Bubble = styled.div.attrs(p => ({
  style: {
    left: `${p.position}%`,
    display: `${p.position !== 500 ? 'block' : 'none'}`,
  },
}))`
  background: black;
  white-space: nowrap;
  color: white;
  padding: 4px 8px;
  position: absolute;
  border-radius: 3px;
  transform: translateX(-50%);
  top: -35px;
  :after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    bottom: -8px;
    margin-left: -48%;
    margin-top: -2px;
  }
`

export const Container = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  input[type='range'] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    width: 100%;
    opacity: 0;
    :hover {
      cursor: pointer;
    }
  }
  input[type='range']::-webkit-slider-thumb {
    pointer-events: all;
    width: 20px;
    height: 20px;
    border-radius: 0;
    border: 0 none;
    background-color: red;
    -webkit-appearance: none;
  }
`

export const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--asc-brown);
  div {
    span {
      font-weight: bold;
    }
  }
`
