import React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  position: ${p => (p.type === 'full' ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p =>
    p.type === 'full' ? 'rgba(68, 74, 79, 0.3)' : '#fff'};
  z-index: ${p => (p.type === 'full' ? '999' : 'auto')};
  .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 10px auto;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--asc-moss);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`

const Spinner = ({type = 'full'}) => {
  return (
    <Container type={type} data-cy="spinner">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </Container>
  )
}

export default Spinner
