import {cloneDeep} from 'lodash'
import {useStore} from 'common/useStore'
import {getPointsBounds} from 'map/mapHelpers'
import {Column, Filters} from 'grid/gridTypes'
import {Asset, Device} from 'common/types/opening1Response'
import {useGridFilterStore} from 'grid/useGridFilterStore'
import {useMapStore} from 'map/useMapStore'

export const initialState = {
  activeMarker: null,
  filteredAssets: null,
  assetFilter: undefined,
  gridFiltersAssetIDs: undefined,
  tabSelected: 'assets',
  gridCollapse: false,
  searchValue: '',
  hierarchyCustomers: undefined,
  selectedLocations: [],
  selectedZones: [],
  selectedProjects: [],
  unassignedDevices: [],
  locationsOnMap: [],
  zonesOnMap: [],
  showFilter: false,
  gridFullscreen: false,
}

type Marker = {
  idAsset: number
}

type Filter = {
  label: string
  filteredAssetIDs: number[]
}

type State = {
  activeMarker: null | Marker
  filteredAssets: null | Asset[]
  assetFilter: undefined | Filter
  gridFiltersAssetIDs: undefined | number[]
  tabSelected:
    | 'assets'
    | 'groups'
    | 'projects'
    | 'locations'
    | 'zones'
    | 'hierarchy'
  gridCollapse: boolean
  searchValue: string
  hierarchyCustomers: unknown[]
  filteredHierarchyCustomers: unknown[]
  selectedGroups: number[]
  selectedProjects: number[]
  selectedLocations: number[]
  selectedZones: number[]
  unassignedDevices: unknown[]
  locationsOnMap: number[]
  zonesOnMap: number[]
  showFilter: boolean
  showSearch: boolean
  gridFullscreen: boolean
}

// type ActionData = State & {
//   assets?: Asset[]
//   groupIDs?: number[]
//   label?: string
//   filteredAssetIDs?: number[]
//   projectIDs?: number[]
//   filteredAssets?: Asset[]
//   tab?: string
// }
//TODO - Finish typing this
type Action =
  | {
      type: 'clearAssetFilter'
      data: {assets: Asset[]; hierarchyCustomers?: hierarchyCustomerType[]}
    }
  | {type: 'clearGridFilters'; data: {assets: Asset[]}}
  | {type: 'setHierarchy'; data: {CustomerList: CustomerList[]}}
  // | {type: 'filterHierarchy', data: {assets: Asset[]}}
  | {type: 'setGridCollapse'; data: boolean}
  | {
      type: 'searchChanged'
      data: {filteredAssets: Asset[]; searchValue: string}
    }
  | {
      type: 'setAssetFilter'
      data: {
        label: string
        filteredAssetIDs: number[]
        gridFiltersAssetIDs?: number[]
        filteredAssets: Asset[]
        groupIDs: number[]
        projectIDs: number[]
        selectedLocations: number[]
      }
    }
  | {
      type: 'setGridFilter'
      data: {gridFiltersAssetIDs: number[]; filteredAssets: Asset[]}
    }
  | {type: 'updateAssets'; data: {assets: Asset[]}}
  | {
      type: 'clearMap'
      data: {assets: Asset[]; hierarchyCustomers: hierarchyCustomerType[]}
    }
  | {type: 'setTabSelected'; data: {tab: string}}
  | {type: 'setSelectedLocations'; data: {assets: Asset[]}}
  | {type: 'setLocationsOnMap'; data: {assets: Asset[]}}
  | {type: 'setLocations'; data: {selectedLocations: number[]}}
  | {type: 'setFiltersOpen'; data: {assets: Asset[]}}
  | {type: 'setSearchOpen'; data: {assets: Asset[]}}
  | {type: 'setGridFullscreen'; data: boolean}
  | {type: 'setZones'; data: {selectedZones: number[]}}
  | {type: 'setSelectedZones'; data: {assets: Asset[]}}
  | {type: 'setZonesOnMap'; data: {assets: Asset[]}}
  | {type: 'setUnassignedDevices'; data: {assets: Asset[]}}
  | {type: 'setDefaultGroupApplied'}
// | 'clearAssetFilter'
//   | 'clearGridFilters'
//   | 'setHierarchy'
//   | 'filterHierarchy'
//   | 'setGridCollapse'
//   | 'searchChanged'
//   | 'setAssetFilter'
//   | 'setGridFilter'
//   | 'updateAssets'
//   | 'clearMap'
//   | 'setTabSelected'
//   | 'setSelectedLocations'
//   | 'setLocationsOnMap'
//   | 'setLocations'
//   | 'setFiltersOpen'
//   | 'setSearchOpen'
//   | 'setGridFullscreen'
//   | 'setZones'
//   | 'setSelectedZones'
//   | 'setZonesOnMap'
//   | 'setUnassignedDevices'
//   | 'setDefaultGroupApplied'
// data: ActionData
// }

export const reducer = (state: State, action: Action) => {
  let newFilteredAssets: Asset[] = []
  switch (action.type) {
    // case 'setActiveMarker':
    //   return {...state, activeMarker: action.data}
    case 'clearGridFilters':
      if (state.assetFilter?.label) {
        newFilteredAssets = action.data.assets.filter(
          (asset: Asset) =>
            state.assetFilter &&
            state.assetFilter.filteredAssetIDs.includes(asset.idAsset),
        )
      } else {
        newFilteredAssets = action.data.assets
      }
      useGridFilterStore.getState().clearAllFilters()
      return {
        ...state,
        filteredAssets: newFilteredAssets,
        gridFiltersAssetIDs: undefined,
      }
    case 'clearAssetFilter':
      return {
        ...state,
        assetFilter: undefined,
        filteredAssets: action.data.assets,
        activeMarker: null,
        searchValue: '',
        selectedGroups: [],
        selectedProjects: [],
        selectedLocations: [],
        hierarchyCustomers:
          action.data.hierarchyCustomers || state.hierarchyCustomers,
        hierarchySearchValue: '',
      }
    case 'setAssetFilter':
      return {
        ...state,
        assetFilter: {
          label: action.data.label,
          filteredAssetIDs: action.data.filteredAssetIDs,
        },
        gridFiltersAssetIDs: action.data.gridFiltersAssetIDs,
        filteredAssets: action.data.filteredAssets,
        activeMarker: null,
        searchValue: state.tabSelected === 'assets' ? '' : state.searchValue,
        selectedGroups: action.data.groupIDs,
        selectedProjects: action.data.projectIDs,
        selectedLocations: action.data.selectedLocations,
      }
    case 'setGridFilter':
      return {
        ...state,
        gridFiltersAssetIDs: action.data.gridFiltersAssetIDs,
        filteredAssets: action.data.filteredAssets,
      }
    case 'setHierarchy':
      return {
        ...state,
        hierarchyCustomers: action.data,
        hierarchySearchValue: '',
      }
    case 'setSelectedLocations':
      return {
        ...state,
        selectedLocations: action.data,
      }
    case 'setSelectedZones':
      return {
        ...state,
        selectedZones: action.data,
      }
    case 'setUnassignedDevices':
      return {
        ...state,
        unassignedDevices: action.data,
      }
    case 'setGridCollapse':
      return {...state, gridCollapse: action.data}
    case 'setLocationsOnMap':
      return {
        ...state,
        locationsOnMap: action.data,
      }
    case 'setZonesOnMap':
      return {
        ...state,
        zonesOnMap: action.data,
      }
    case 'setFiltersOpen':
      return {
        ...state,
        showFilter: action.data,
      }
    case 'setSearchOpen':
      return {
        ...state,
        showSearch: action.data,
      }
    case 'updateAssets':
      //updated assets from websocket or initially from API call
      if (state.filteredAssets && action.data) {
        state.filteredAssets.forEach((asset: {idAsset: number}) => {
          const foundAsset = action.data?.assets?.find(
            (fa: {idAsset: number}) => fa.idAsset === asset.idAsset,
          )
          if (foundAsset) {
            newFilteredAssets.push(foundAsset)
          }
        })
      } else {
        newFilteredAssets = action.data.assets
      }
      return {...state, filteredAssets: newFilteredAssets}
    case 'searchChanged':
      useMapStore
        .getState()
        .setTotalActiveAssets(action.data.filteredAssets.length)
      if (action.data.filteredAssets) {
        return {
          ...state,
          searchValue: action.data.searchValue,
          filteredAssets: action.data.filteredAssets,
        }
      } else {
        return {
          ...state,
          searchValue: action.data.searchValue,
        }
      }
    case 'clearMap':
      useGridFilterStore.getState().clearAllFilters()
      useMapStore.getState().setTotalActiveAssets(action.data.assets.length)
      return {
        ...state,
        activeMarker: null,
        filteredAssets: action.data.assets,
        searchValue: '',
        assetFilter: undefined,
        selectedGroups: [],
        selectedLocations: [],
        selectedZones: [],
        selectedProjects: [],
        hierarchyCustomers:
          action.data.hierarchyCustomers || state.hierarchyCustomers,
        hierarchySearchValue: '',
      }
    case 'setLocations':
      return {
        ...state,
        selectedLocations: action.data.selectedLocations,
      }
    case 'setZones':
      return {
        ...state,
        selectedZones: action.data.selectedZones,
      }
    case 'setTabSelected':
      return {
        ...state,
        searchValue: '',
        tabSelected: action.data.tab,
      }
    case 'setGridFullscreen':
      return {...state, gridFullscreen: action.data}
    case 'setDefaultGroupApplied':
      return {...state, defaultGroupApplied: true}
    default:
      console.error('unrecognized reducer type', action)
      return state
  }
}

type CustomerList = {
  visible: boolean
  expanded: boolean
  checked: boolean
  unassignedChecked: boolean
  CustomerList: CustomerList[]
}

type hierarchyCustomerType = {
  CustomerList: CustomerList[]
  checked: boolean
}

export const clearAllHierarchy = (
  hierarchyCustomers: hierarchyCustomerType,
) => {
  if (hierarchyCustomers?.CustomerList) {
    const newHierarchyCustomers = cloneDeep(hierarchyCustomers)
    newHierarchyCustomers.checked = false
    newHierarchyCustomers?.CustomerList.map(l2 => {
      l2.visible = true
      l2.expanded = false
      l2.checked = false
      if (l2.CustomerList) {
        l2.CustomerList.map(l3 => {
          l3.visible = true
          l3.expanded = false
          l3.checked = false
          l3.unassignedChecked = false
          if (l3.CustomerList) {
            l3.CustomerList.map(l4 => {
              l4.visible = true
              l4.expanded = false
              l4.checked = false
              l4.unassignedChecked = false
              if (l4.CustomerList) {
                l4.CustomerList.map(l5 => {
                  l5.visible = true
                  l5.expanded = false
                  l5.checked = false
                  l5.unassignedChecked = false
                  return l5
                })
              }
              return l4
            })
          }
          return l3
        })
      }
      return l2
    })
    return newHierarchyCustomers
  } else {
    return undefined
  }
}

type focusMapProps = {
  lat: number
  lng: number
  map: any
  isMobile?: boolean
}

export const focusMap = ({lat, lng, map, isMobile}: focusMapProps) => {
  if (map) {
    const gridOpen = useStore.getState().gridOpen
    const location = {
      lat,
      lng,
    }
    const div = map.getDiv() as HTMLElement
    if (map.getZoom() < 17) {
      map.setZoom(17)
    }
    map.panTo(location)
    if (isMobile) {
      map.panBy(50, -div.offsetHeight / 50)
    } else if (gridOpen) {
      map.panBy(0, -div.offsetHeight / 3)
    } else {
      map.panBy(0, -div.offsetHeight / 7)
    }
  }
}
type SetAssetFilterProps = {
  filteredAssetIDs: number[]
  label: string
  groupIDs?: number[]
  projectIDs?: number[]
  assetFilter: Filter
  gridFilters?: {
    filters: Filters
    columns: Column[]
  }
  selectedLocations?: number[]
  selectedZones?: number[]
  setAndFocusActiveMarker?: (id: number | null) => void
  dispatch: (e: Action) => void
  assetsRef: {
    current: Asset[]
  }
  mapRef: {
    current: {map: {fitBounds: (newBounds: google.maps.LatLngBounds) => void}}
  }
}

export interface FilterAssetsFromListProps {
  filteredAssetIDs?: number[]
  label?: string
  groupIDs?: number[]
  projectIDs?: number[]
  selectedLocations?: number[]
  selectedZones?: number[]
  gridFilters?: {
    filters: Filters
    columns: Column[]
  }
}

type AssetFilterDataProps = {
  filteredAssetIDs: number[]
  label: string
  filteredAssets: Asset[]
  gridFiltersAssetIDs?: number[]
  groupIDs: number[]
  projectIDs: number[]
  selectedLocations: number[]
  selectedZones: number[]
}

interface GetGridFilterProps {
  gridFilters: {
    filters: Filters
    columns: Column[]
  }
  assets: Asset[]
  assetFilter: Filter
}

type AssetKeysType = keyof Asset

type OptionalAssetFields = {
  [K in keyof Asset]?: Asset[K]
}
// type OptionalAssetKeysType = keyof OptionalAssetFieldsWithID

//exported for test only
export const getGridFilteredAssets = ({
  gridFilters,
  assetFilter,
  assets,
}: GetGridFilterProps) => {
  //apply filters to all assets then compare with new filtered assets to
  //see which ones intersect
  let gridFiltersAssetIDs: number[] = []
  let returnedIDs: number[] = []
  let preGridFilteredAssetIDs =
    assetFilter?.filteredAssetIDs || assets.map(a => a.idAsset)
  if (
    gridFilters?.filters?.columnFilters?.length ||
    gridFilters?.filters?.globalFilter
  ) {
    if (gridFilters?.filters?.columnFilters?.length) {
      assets?.forEach(asset => {
        let passing = true
        gridFilters?.filters?.columnFilters.forEach(filter => {
          if (passing) {
            let value = ''
            if (
              String(filter.name).startsWith('Device>') ||
              String(filter.name).startsWith('Device.')
            ) {
              const truncatedFilterName = String(filter.name).substring(7)
              value = String(
                asset.Device[truncatedFilterName as keyof Device] || '',
              ).toLowerCase()
            } else {
              value = String(asset[filter.name] || '').toLowerCase()
            }
            if (
              value === '' ||
              (value && !value.includes(filter.value.toLowerCase()))
            ) {
              passing = false
            }
          }
        })
        if (passing) gridFiltersAssetIDs.push(asset.idAsset)
      })
    }
    if (gridFilters?.filters?.globalFilter) {
      let availableAssets = [...assets]
      if (gridFilters?.filters?.columnFilters?.length) {
        //global filter and column filters applied - start with column assets and clear filtered IDs
        availableAssets = assets.filter(a =>
          gridFiltersAssetIDs.includes(a.idAsset),
        )
        gridFiltersAssetIDs = []
      }

      const assetsWithSearchableFields = availableAssets.map(asset => {
        const returnedAsset: OptionalAssetFields = {
          idAsset: asset.idAsset,
        }
        let key: AssetKeysType
        for (key in asset) {
          if (key === 'Device') {
            let deviceKey: keyof Device
            for (deviceKey in asset[key]) {
              const foundColumn = gridFilters.columns.find(
                (column: Column) => column.accessor === `Device.${deviceKey}`,
              )
              if (foundColumn) {
                //@ts-expect-error Can't figure out how to type optional asset fields
                returnedAsset[deviceKey as keyof Device] =
                  asset.Device[deviceKey]
              }
            }
          } else {
            const foundColumn = gridFilters.columns.find(
              (column: Column) => column.accessor === key,
            )
            if (foundColumn && key) {
              //@ts-expect-error Can't figure out how to type optional asset fields
              returnedAsset[key] = asset[key] || ''
            }
          }
        }

        return returnedAsset
      })
      assetsWithSearchableFields.forEach(asset => {
        let match = false
        let key: keyof Asset
        for (key in asset) {
          if (!match && asset.idAsset) {
            // const foundKey = gridFilters.columns.find(
            //   column => column.accessor === key,
            // )
            if (
              // foundKey &&
              String(asset[key] || '')
                .toLowerCase()
                .includes(gridFilters?.filters?.globalFilter.toLowerCase()) &&
              !gridFiltersAssetIDs.includes(asset.idAsset)
            ) {
              gridFiltersAssetIDs.push(asset.idAsset)
              match = true
            }
          }
        }
      })
    }
    //now compare with filtered assets from other filters
    returnedIDs = gridFiltersAssetIDs.filter(id =>
      preGridFilteredAssetIDs.includes(id),
    )
  } else {
    if (assetFilter?.filteredAssetIDs) {
      returnedIDs = assetFilter.filteredAssetIDs
    } else {
      returnedIDs = assets.map(a => a.idAsset)
    }
  }

  return returnedIDs
}

export const setAssetFilter = ({
  filteredAssetIDs,
  assetFilter,
  label,
  groupIDs,
  projectIDs,
  gridFilters,
  selectedLocations,
  selectedZones,
  setAndFocusActiveMarker,
  dispatch,
  assetsRef,
  mapRef,
}: SetAssetFilterProps) => {
  const setTotalActiveAssets = useMapStore.getState().setTotalActiveAssets
  let newFilteredAssets
  if (setAndFocusActiveMarker) {
    setAndFocusActiveMarker(null)
  }
  if (!label && !gridFilters) {
    newFilteredAssets = assetsRef.current
    dispatch({
      type: 'clearAssetFilter',
      data: {assets: assetsRef.current},
    })
  } else if (label === 'clearGridFilters') {
    if (assetFilter?.filteredAssetIDs) {
      newFilteredAssets = assetsRef.current.filter(a =>
        assetFilter?.filteredAssetIDs.includes(a.idAsset),
      )
    } else {
      newFilteredAssets = assetsRef.current
    }
    dispatch({
      type: 'clearGridFilters',
      data: {assets: assetsRef.current},
    })
  } else {
    if (
      gridFilters?.filters?.globalFilter ||
      gridFilters?.filters?.columnFilters?.length
    ) {
      const data: {filteredAssets: Asset[]; gridFiltersAssetIDs: number[]} = {
        filteredAssets: [],
        gridFiltersAssetIDs: [],
      }
      data.gridFiltersAssetIDs = getGridFilteredAssets({
        gridFilters,
        assetFilter,
        assets: assetsRef.current,
      })
      newFilteredAssets = assetsRef.current?.filter(asset =>
        data.gridFiltersAssetIDs.includes(asset.idAsset),
      )
      data.filteredAssets = newFilteredAssets
      dispatch({
        type: 'setGridFilter',
        data,
      })
    } else {
      if (gridFilters?.filters && assetFilter?.filteredAssetIDs) {
        //filters were cleared - apply latest asset filter
        filteredAssetIDs = assetFilter.filteredAssetIDs
      }
      useGridFilterStore.getState().clearAllFilters()

      const newFilteredAssetIDs = filteredAssetIDs
        ? [...filteredAssetIDs]
        : assetsRef.current.map(a => a.idAsset)
      newFilteredAssets = assetsRef.current?.filter(asset =>
        newFilteredAssetIDs.includes(asset.idAsset),
      )
      const data: AssetFilterDataProps = {
        filteredAssetIDs,
        label,
        filteredAssets: newFilteredAssets,
        gridFiltersAssetIDs: undefined,
        groupIDs: groupIDs || [],
        projectIDs: projectIDs || [],
        selectedLocations: [],
        selectedZones: [],
      }
      if (selectedLocations) {
        data.selectedLocations = selectedLocations
      }
      if (selectedZones) {
        data.selectedZones = selectedZones
      }
      dispatch({
        type: 'setAssetFilter',
        data,
      })
    }
  }

  setTotalActiveAssets(newFilteredAssets.length)
  const newBounds = getPointsBounds(newFilteredAssets, window.google.maps)
  if (newBounds && label !== 'Box Search') {
    mapRef?.current?.map.fitBounds(newBounds)
  }
}
