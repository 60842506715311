import {
  changeAddressObjectToSTAGEFormat,
  createAddressObject,
} from 'common/components/LandmarkDrawingManager/landmarkDrawingPolygonListeners'
import React, {useEffect, useRef} from 'react'
import styled from 'styled-components/macro'
import {Form, Input} from 'semantic-ui-react'
import InputErrorMessage from './InputErrorMessage'

const AutoCompleteInput = styled(Form.Field)`
  display: flex;
  flex-direction: ${props => props.flexdirection};
  align-items: ${props => props.alignitems};
  width: ${props => props.widthpercent}%;
  label {
    font-weight: bold;
    span {
      color: var(--asc-vermilion);
      margin: 0px 3px;
    }
  }
  div {
    width: 100%;
  }
  input {
    padding: 5px;
    width: 100%;
  }
  #input-error-message {
    position: relative !important;
    bottom: -1px;
    width: fit-content;
  }
`

function SearchLocationInput({
  dispatch,
  formattedAddress,
  width,
  flexdirection,
  alignitems,
  className = '',
  errors,
  showTitle = true,
}) {
  const autoCompleteInputRef = useRef(null)

  const [addressIsSelected, setIsSelected] = React.useState(false)
  const [inputChanged, setInputChanged] = React.useState(false)

  useEffect(() => {
    //on init - create google autocomplete and set reference
    const autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteInputRef.current,
      {types: ['geocode', 'establishment']},
    )
    autoComplete.setFields([
      'address_components',
      'formatted_address',
      'geometry',
    ])
    autoComplete.addListener('place_changed', () => {
      const addressObject = autoComplete.getPlace()
      if (!addressObject) return
      setIsSelected(true)
      setInputChanged(false)

      const formattedAddressObject = createAddressObject(addressObject)
      const tempAddress = changeAddressObjectToSTAGEFormat(
        formattedAddressObject,
      )
      const address = {
        ...tempAddress,
        Latitude: addressObject?.geometry?.location?.lat(),
        Longitude: addressObject?.geometry?.location?.lng(),
      }
      dispatch({
        type: 'updateAddress',
        data: {
          address,
          formattedAddress: addressObject?.formatted_address,
          marker: {center: addressObject.geometry?.location},
        },
      })
    })
  }, [dispatch])

  const handleBlur = () => {
    if (addressIsSelected && !inputChanged) return
    if (!formattedAddress || !addressIsSelected || inputChanged) {
      setIsSelected(false)
      setInputChanged(false)
      return dispatch({
        type: 'updateAddress',
        data: {
          address: '',
          city: '',
          state: '',
          zipCode: '',
          location: {lat: 0, lng: 0},
          formattedAddress: '',
          marker: null,
        },
      })
    }
  }

  return (
    <AutoCompleteInput
      widthpercent={width}
      flexdirection={flexdirection}
      alignitems={alignitems}
      className={className}
    >
      {showTitle && (
        <label htmlFor="location-search">
          Address<span>*</span>
        </label>
      )}
      <Input
        data-cy="location-search"
        aria-label="location-input"
        onChange={event => {
          setInputChanged(true)
          dispatch({
            type: 'setState',
            data: {formattedAddress: event.target.value},
          })
        }}
        placeholder=""
        value={formattedAddress || ''}
      >
        <input
          onBlur={handleBlur}
          ref={autoCompleteInputRef}
          data-cy="location-search-input"
        />
      </Input>
      {errors && errors['formattedAddress']?.message ? (
        <InputErrorMessage message={errors['formattedAddress']?.message} />
      ) : null}
    </AutoCompleteInput>
  )
}

export default SearchLocationInput
