import {setToken} from 'common/setToken'
import Swal from 'sweetalert2'
import {isEmail} from 'common/helpers'

type LoginType = {
  email: string
  password: string
  impersonatingEmail?: string
  isSSOToken?: boolean
  remember?: boolean
  acceptedEULA?: boolean | null
  ignoreEULA?: boolean
}

//SSO also imports this
export const login = async ({
  email,
  password,
  impersonatingEmail,
  // isSSOToken = false,
  remember,
  acceptedEULA,
  ignoreEULA,
}: LoginType) => {
  try {
    let token = ''
    // if (isSSOToken) {
    //   token = window.btoa(password)
    // } else {
    if (impersonatingEmail) {
      token = window.btoa(`${email}:${password}:${impersonatingEmail}`)
    } else {
      token = window.btoa(`${email}:${password}`)
    }
    // }
    const data = await setToken({
      token,
      impersonating: Boolean(impersonatingEmail),
      remember,
      acceptedEULA,
      ignoreEULA,
    })
    return {token, data}
  } catch (error) {
    console.error(error)
    return {token: null, data: null}
  }
}

type OnSubmitProps = {
  email: string
  password: string
  remember: boolean
  acceptedEULA: boolean | null
  emptyStore: () => void
  ignoreEULA?: boolean
}

export const submitLogin = async ({
  email,
  password,
  remember,
  acceptedEULA,
  emptyStore,
  ignoreEULA = false,
}: OnSubmitProps) => {
  const {errors} = validateForm({email, password, acceptedEULA, ignoreEULA})

  if (Object.keys(errors).length === 0) {
    emptyStore()

    const {token, data} = await login({
      email,
      password,
      remember,
      acceptedEULA,
      ignoreEULA,
    })
    if (token) {
      //settimeout to avoid update on unmounted component error
      // setTimeout(() => {
      //go to management if super user or dealer
      if (data?.Role_Id === 0 || data?.Role_Id === 3) {
        window.location.href = '/management'
      } else {
        const requestedUrl = sessionStorage.getItem('requestedUrl')
        if (requestedUrl) {
          sessionStorage.removeItem('requestedUrl')
          window.location.href = requestedUrl
        } else {
          window.location.href = '/map'
        }
      }
      // }, 0)
    } else {
      Swal.fire({
        confirmButtonColor: 'var(--secondary)',
        title: 'Login failed.',
        text: 'Please verify your Username and Password.',
        icon: 'error',
      })
    }
  }
  return errors
}

type ErrorsType = {
  email?: {message: string}
  password?: {message: string}
  eula?: {message: string}
}

export const validateForm = ({
  email,
  password,
  acceptedEULA,
  ignoreEULA,
}: {
  email: string
  password: string
  acceptedEULA: boolean | null
  ignoreEULA?: boolean
}) => {
  const validEmail = isEmail(email)
  let errors: ErrorsType = {}
  if (!email) {
    errors.email = {
      message: 'Email is required',
    }
  } else if (!validEmail) {
    errors.email = {
      message: 'Email is invalid',
    }
  }
  if (!password) {
    errors.password = {
      message: 'Password is required',
    }
  }
  if (!acceptedEULA && !ignoreEULA) {
    errors.eula = {
      message: 'Must accept Privacy Policy and EULA',
    }
  }
  return {
    errors,
    email,
    password,
  }
}
