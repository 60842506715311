import Axios from 'axios'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import Swal from 'sweetalert2'
import {showToast} from 'common/components/Toastr'

const putLocation = async location => {
  const {data} = await Axios.put(`/landmarks/${location.idLandmark}`, location)
  return data
}

function usePutLocation() {
  const cache = useQueryClient()
  return useMutation(putLocation, {
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
      Swal.fire(
        'Error',
        'There was an error saving this location. Please check your network and contact customer support if this continues.',
        'error',
      )
    },
    onSuccess: () => {
      showToast(`Successfully updated the location.`, 'success')
    },
    onSettled: location => {
      cache.invalidateQueries(['locations'])
      cache.invalidateQueries([`locationDetail-${location?.idLandmark}`])
    },
  })
}

export default usePutLocation
