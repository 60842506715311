import * as React from 'react'
import styled from 'styled-components/macro'
import {
  TEMEDA_URI,
  UPDATE_INTERVAL_MILLISECONDS,
  ANIMATION_DURATION_MILLISECONDS,
} from 'common/constants'
import {format, formatDistanceToNow, isEqual} from 'date-fns'
// import {splitAddress} from 'common/helpers'
import Marker from 'map/Marker'
import {createPoints, lookupColor} from 'map/mapHelpers'
import IconBadge from './IconBadge'
import {useStore} from 'common/useStore'
import {Broadcast, MapPinLine, User} from 'phosphor-react'
import AssetUpdatedNotification from 'commandConsole/common/AssetUpdatedNotification'

const Container = styled.div`
  border: ${props =>
    props.focused ? '3px solid var(--primary)' : '3px solid #fff'};
  min-height: 130px;
  padding: 10px 15px;
  background-color: white;
  border-radius: 0 20px 20px 20px;
  margin: 5px 10px;
  cursor: pointer;
  box-shadow: 0px 4px 12px 0px var(--asc-coolgray);
  &:hover {
    box-shadow: 0px 4px 12px 0px var(--asc-sonicsilver);
  }
`

const ImageContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-right: 10px;
`
const AssetImage = styled.img`
  max-height: 65px;
  max-width: 100px;
  height: auto;
  width: auto;
  padding: 5px;
  display: flex;
`

const DistanceTime = styled.div`
  font-size: 0.9rem;
  white-space: nowrap;
  width: 100%;
  font-weight: 500;
  color: var(--asc-brown);
`
const VerticalBarSeparator = styled.span`
  margin-right: 3%;
  margin-left: 3%;
`
const DateTime = styled.span`
  text-align: left;
`
const TimeAgo = styled.span`
  text-align: right;
`

const AssetLabel = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
  max-width: 285px;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 0;
  padding-right: 2px;
  text-overflow: ellipsis;
`

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  margin-top: 5px;
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  /* height: 75px; */
`

const BackgroundFade = styled.div`
  box-shadow: -19px 0px 21px 10px white;
  position: absolute;
  right: -10px;
  top: 0;
  background-color: white;
  padding-right: 10px;
`
const AlertMaintIcons = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 5px;
  gap: 15px;
`

const LastEvent = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: bold;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 88%;
  }
  svg {
    margin-right: 5px;
    display: inline;
    width: 12%;
  }
`
const CurrentDriver = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
  margin-top: 3px;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 88%;
  }
  svg {
    margin-right: 5px;
    display: inline;
    width: 12%;
  }
`

const DriverAndAddress = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 60%;
`

const Address = styled.div`
  /* margin-top: 5px; */
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 3px;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 88%;
  }
  svg {
    margin-right: 5px;
    display: inline;
    width: 12%;
  }
`

const MarkerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const ColorCorner = styled.div`
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid ${({iconColor}) => iconColor};
  transform: rotate(135deg);
  position: absolute;
  top: ${({focused}) => (focused ? '6px' : '3px')};
  left: ${({focused}) => (focused ? '4px' : '1px')};
`

const AssetCard = ({
  index,
  activeMarker,
  assetDetail,
  changeActiveMarkerFromClick,
}) => {
  const userConfig = useStore(state => state.userConfig)

  const [timeFromNow, setTimeFromNow] = React.useState('')
  const [justUpdated, setJustUpdated] = React.useState(false)

  const Asset_Label = assetDetail?.Asset_Label
  const Device_Last_Event = assetDetail?.Device?.Last_Event
  const Icon_Path = assetDetail?.Asset_Icon_Full_Path
  const Current_Driver = assetDetail?.CurrentDriver
  // const {addressLine2} = splitAddress(assetDetail?.Device.Last_Nearby_Address)
  const Alert_Count = assetDetail?.Alert_Count || 0
  const Maint_Overdue_Count = assetDetail?.MaintOverdueCount || 0
  const Maint_Due_Count = assetDetail?.MaintDueCount || 0
  const Device = assetDetail?.Device
  const iconColor = lookupColor(assetDetail?.Icon_Color)
  let cityStateCountry =
    (Device?.Last_Nearby_Locality ? Device?.Last_Nearby_Locality + ', ' : '') +
    (Device?.Last_Nearby_Region || '') +
    ' ' +
    (Device?.Last_Nearby_isoCountryCode || '')
  if (cityStateCountry.trim() === '') {
    cityStateCountry = 'No location'
  }
  let localDateTime = Device.Last_Location_DateTime_Local
    ? new Date(Device.Last_Location_DateTime_Local)
    : null

  const previousUpdate = React.useRef(null)

  React.useEffect(() => {
    previousUpdate.current = null
    setJustUpdated(false)
  }, [assetDetail.idAsset])

  React.useEffect(() => {
    let isMounted = true
    const renderTimeData = deviceTime => {
      if (deviceTime) {
        try {
          const timeWithoutTimeZone = deviceTime.split('+')[0]
          const updatedTime = new Date(
            timeWithoutTimeZone.endsWith('Z')
              ? timeWithoutTimeZone
              : timeWithoutTimeZone + 'Z',
          )

          if (!previousUpdate.current) {
            previousUpdate.current = updatedTime
          }
          if (!isEqual(previousUpdate.current, updatedTime)) {
            setJustUpdated(true)
            previousUpdate.current = updatedTime
            setTimeout(
              () => setJustUpdated(false),
              ANIMATION_DURATION_MILLISECONDS,
            )
          }
          setTimeFromNow(formatDistanceToNow(updatedTime))
        } catch (error) {
          console.error('invalid time', error)
        }
      } else setTimeFromNow('')
    }
    if (isMounted) renderTimeData(Device.Last_Location_Time)
    const updateInterval = setInterval(() => {
      if (isMounted) renderTimeData(Device.Last_Location_Time)
    }, UPDATE_INTERVAL_MILLISECONDS)
    return () => {
      isMounted = false
      clearInterval(updateInterval)
    }
  }, [Device.Last_Location_Time])

  const timeFormat = userConfig?.Time_Format === '12HR' ? 'h:mm a' : 'H:mm a'

  const point =
    assetDetail && !Icon_Path ? createPoints([assetDetail])[0] : null
  return (
    <Container
      key={index}
      focused={activeMarker?.idAsset === assetDetail.idAsset}
      data-id={assetDetail.idAsset}
      onClick={changeActiveMarkerFromClick}
    >
      <ColorCorner
        iconColor={iconColor}
        focused={activeMarker?.idAsset === assetDetail.idAsset}
      />
      <HeaderRow>
        <div>
          <AssetLabel>{Asset_Label}</AssetLabel>
        </div>
        {justUpdated && (
          <BackgroundFade>
            <AssetUpdatedNotification />
          </BackgroundFade>
        )}
      </HeaderRow>
      <HeaderRow>
        <DistanceTime>
          <DateTime>
            {localDateTime &&
              format(localDateTime, `${userConfig.Date_Format} ${timeFormat}`)}
          </DateTime>
          <VerticalBarSeparator>{timeFromNow && ' | '}</VerticalBarSeparator>
          <TimeAgo>{timeFromNow && timeFromNow + ' ago'}</TimeAgo>
        </DistanceTime>
      </HeaderRow>
      <BottomRow>
        <ImageContainer>
          {Icon_Path ? (
            <AssetImage src={TEMEDA_URI + Icon_Path} />
          ) : (
            assetDetail && (
              <MarkerContainer>
                <Marker point={point} type="card" />
              </MarkerContainer>
            )
          )}
        </ImageContainer>
        <DriverAndAddress>
          {Device_Last_Event && (
            <LastEvent>
              <Broadcast weight={'fill'} />
              <span>{Device_Last_Event}</span>
            </LastEvent>
          )}
          {Current_Driver && (
            <CurrentDriver>
              <User size={15} />
              <span>{Current_Driver}</span>
            </CurrentDriver>
          )}
          {Device?.Last_Landmark_Name ? (
            <Address>
              <MapPinLine size={15} />
              <span>
                {Device?.Last_Landmark_Name
                  ? Device?.Last_Landmark_Name
                  : cityStateCountry}
              </span>
            </Address>
          ) : (
            cityStateCountry && (
              <Address>
                <MapPinLine size={15} />
                <span>{cityStateCountry}</span>
              </Address>
            )
          )}

          <AlertMaintIcons>
            {Alert_Count > 0 && (
              <IconBadge
                icon={'alert'}
                count={Alert_Count}
                idAsset={assetDetail.idAsset}
              />
            )}
            {(Maint_Overdue_Count > 0 || Maint_Due_Count > 0) && (
              <IconBadge
                icon={'wrench'}
                count={Maint_Overdue_Count + Maint_Due_Count}
                idAsset={assetDetail.idAsset}
              />
            )}
          </AlertMaintIcons>
        </DriverAndAddress>
      </BottomRow>
    </Container>
  )
}
export default AssetCard
