import * as React from 'react'
import {useStore} from 'common/useStore'
import styled from 'styled-components/macro'
import {useMapStore} from 'map/useMapStore'

const AssetListControlDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  user-select: none; /*prevents highlighting the dropdown items*/
  background: var(--primary);
  padding: 10px 5px;
  border-radius: 20px;
  margin: 10px;
  justify-content: center;
  box-shadow: 2px 4px 8px 1px rgba(0, 0, 0, 0.35);
  cursor: ${props => (props.assetListOpen ? 'default' : 'pointer')};
  /*:hover {
    box-shadow: 0px 4px 5px 0px var(--asc-graphite);
  }*/
`

const ItemBadge = styled.div`
  max-width: 60%;
  min-height: 1em;
  padding: 0.37em;
  line-height: 1em;
  text-align: center;
  border-radius: 500rem;
  background-color: var(--secondary);
  color: var(--primary);
  font-weight: bold;
  align-self: center;
`

const ItemLabel = styled.div`
  display: flex;
  font-size: 13px;
  margin-right: 5px;
  color: var(--secondary);
  font-weight: 600;
`

const AssetListControl = () => {
  const setAssetListOpen = useStore(state => state.setAssetListOpen)
  const setGridOpen = useStore(state => state.setGridOpen)
  const assetListOpen = useStore(state => state.assetListOpen)
  const assetsCount = useMapStore(state => state.gutAssetData.assetCount)
  const totalActiveAssets = useMapStore(state => state.totalActiveAssets)

  const onClick = () => {
    setAssetListOpen(true)
    setGridOpen(false)
    useMapStore.setState({showAssetMarkers: true})
  }

  return (
    <React.Fragment>
      <AssetListControlDiv onClick={onClick} assetListOpen={assetListOpen}>
        <ItemLabel>ASSETS</ItemLabel>
        <ItemBadge>
          {totalActiveAssets > 0 && totalActiveAssets !== assetsCount
            ? `${new Intl.NumberFormat().format(
                totalActiveAssets,
              )} of ${new Intl.NumberFormat().format(assetsCount)}`
            : new Intl.NumberFormat().format(assetsCount)}
        </ItemBadge>
      </AssetListControlDiv>
    </React.Fragment>
  )
}
export default AssetListControl
