import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div<{
  direction: string
  marginTop: string
}>`
  position: absolute;
  ${props => props.direction}: 0;
  top: 20px;
  background-color: white;
  z-index: 99;
  border: 1px solid lightgray;
  border-radius: 3px;
  margin-bottom: 0;
  margin-top: ${props => props.marginTop}px;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  background-color: white;
`

const ListItem = styled.li`
  white-space: nowrap;
  padding: 10px;
  list-style: none;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  padding-left: ${(p: {paddingLR: string | undefined}) =>
    p.paddingLR ? p.paddingLR : '10px'};
  padding-right: ${p => (p.paddingLR ? p.paddingLR : '10px')};
  :hover {
    background-color: #e7e7e7;
    color: rgba(0, 0, 0, 0.9);
  }
`

type Item = {
  key: number
  text: string
  dataCy?: string
}
type Data = Item[]

type Props = {
  options: Data
  setOpen: (toggle: boolean) => void
  direction: 'left' | 'top' | 'right' | 'bottom'
  onChange: (value: number) => void
  marginTop: string
  paddingLR?: string
  isEnd?: boolean
}

const DropDownCard = ({
  options = [],
  setOpen,
  direction = 'left',
  onChange,
  marginTop,
  paddingLR,
}: Props) => {
  const onClick = (item: number) => {
    onChange(item)
    setOpen(false)
  }
  return (
    <Wrapper direction={direction} marginTop={marginTop} id="dropdown-card">
      <List>
        {options.map((item, i) => (
          <ListItem
            data-cy={item.dataCy}
            key={i}
            onClick={() => onClick(item.key)}
            paddingLR={paddingLR}
          >
            {item.text}
          </ListItem>
        ))}
      </List>
    </Wrapper>
  )
}

export default DropDownCard
