import * as React from 'react'
import styled from 'styled-components/macro'
import {
  CaretDown,
  MagnifyingGlass,
  ToggleLeft,
  ToggleRight,
} from 'phosphor-react'
import useOnClickOutside from '../hooks/useOnClickOutside'
import {CheckboxContainer} from '../Checkbox'
import {useVirtual} from 'react-virtual'
import {reducer} from './multiSelectHelpers'
import {Props, CheckboxType, State} from './multiSelectTypes'
import usePrevious from 'common/usePrevious'
import {get} from 'lodash'
import {QUERIES} from 'common/constants'

type StyleProps = {
  open?: boolean
  checked?: boolean
  selectedColor?: string
}

const Container = styled.div`
  position: relative;
  cursor: pointer;
  margin-bottom: 16px;
`
const Input = styled.input<StyleProps>`
  border: 1px solid var(--asc-coolgray);
  height: 41px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid var(--asc-coolgray);
  border-radius: ${({open}) => (open ? '5px 5px 0px 0px' : '5px')};
  outline: ${({open}) => (open ? '1px solid var(--asc-keylime)' : 'none')};
  margin-right: 2px;
  caret-color: transparent;
`
const InputCaret = styled(CaretDown)`
  width: 24px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 10px;
  display: ${({open}) => (open ? 'hidden' : 'block')};
  transform: ${({open}: {open: boolean}) =>
    open ? 'scaleY(-1)' : 'scaleY(1)'};
  display: ${({open}: {open: boolean}) => (open ? 'none' : 'block')};

  cursor: pointer;
`
const SearchIcon = styled(MagnifyingGlass)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  left: 57px;
  cursor: pointer;
`
const Controls = styled.div`
  height: 60px;
  background-color: var(--asc-cultured);
  position: absolute;
  /* outline: 1px solid red; */
  border-bottom: 1px solid var(--asc-coolgray);
  border-left: 1px solid var(--asc-graphite);
  border-right: 1px solid var(--asc-graphite);
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`
const StyledCheckbox = styled(CheckboxContainer)`
  input[type='checkbox'] {
    margin-right: 20px;
    border-color: var(--asc-sonicsilver);
    background-color: #ffffff;
  }
`
const OptionList = styled.div`
  position: absolute;
  height: 263px;
  width: 100%;
  top: 100px;
  background-color: #ffffff;
  border-top: 1px solid var(--asc-coolgray);
  border-bottom: 1px solid var(--asc-graphite);
  border-left: 1px solid var(--asc-graphite);
  border-right: 1px solid var(--asc-graphite);
  border-radius: 0px 0px 20px 20px;
  overflow-x: hidden;
  z-index: 10;
`
const Option = styled.div<StyleProps>`
  height: 46px;
  border-bottom: 1px solid #d2d2d7;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  width: 100%;
  padding: 15px;
  background-color: ${({checked}) =>
    checked ? 'var(--asc-selectedgreen)' : '#ffffff'};
`
const SearchBar = styled.input`
  height: 30px;
  background: #ffffff;
  border: 1px solid var(--asc-coolgray);
  border-radius: 5px;
  padding: 8px 8px 8px 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-right: 8px;
  display: flex;
  flex: 1 0;
  width: 0;
  :focus {
    outline: 1px solid var(--asc-keylime);
    border: 1px solid var(--asc-moss);
  }
`
const SearchControl = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`
const ShowSelectedToggle = styled.div`
  display: none;
  @media ${QUERIES.laptopAndUp} {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
    span {
      font-size: 12px;
      white-space: nowrap;
    }
  }
`
const SelectedListFocused = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  position: absolute;
  top: 7px;
  right: 2px;
  width: 98%;
  padding: 0 5px;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-bottom: 3px;
`
const SelectedListBlurred = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  position: absolute;
  top: 7px;
  right: 2px;
  width: 98%;
  padding: 0 5px;
  overflow-x: hidden;
  overflow-y: hidden;
`

const selectedColors = {
  gold: {
    background: 'var(--asc-gold)',
    border: 'none',
  },
}

const SelectedItem = styled.div`
  background: ${({open, selectedColor}: StyleProps) =>
    selectedColor === 'gold'
      ? selectedColors.gold.background
      : open
      ? 'var(--asc-keylime)'
      : 'var(--asc-platinum)'};
  /* border: 1px solid var(--asc-keylime); */
  border-color: ${({selectedColor}: StyleProps) =>
    selectedColor === 'gold'
      ? selectedColors.gold.background
      : 'var(--asc-graphite)'};
  border-radius: 20px;
  padding: 3px 10px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* min-width: 45px; */
  margin-bottom: ${({open}) => (open ? '5px' : '0')};
`
const Gradient = styled.div`
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  width: 61px;
  height: 30px;
  position: absolute;
  right: -5px;
`
const Dropdown = styled.div`
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);
`
const EmptyText = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
`

const initialState: State = {
  checkboxes: [],
  filteredCheckboxes: [],
  idsSelected: [],
  headerText: '',
  showWindow: 'no',
  searchValue: '',
  showingSelectedOnly: false,
  allSelected: false,
  selectedColor: '',
}

const MultiSelect = ({
  list,
  selectedIDs,
  status = 'success',
  idField,
  labelField,
  customLabel,
  setChecked,
  // pageSize = 50,
  disabled = false,
  typeLabel = '',
  showSelectedToggle = true,
  selectedColor,
  className = ',',
}: Props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [open, setOpen] = React.useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  }
  const previous = usePrevious({
    idsSelected: state.idsSelected,
    list,
    selectedIDs,
  })

  const clickedOutside = () => {
    if (open) {
      setOpen(false)
    }
  }

  const containerRef = React.useRef<HTMLDivElement>(null)
  useOnClickOutside(containerRef, clickedOutside)

  const parentRef = React.useRef<HTMLDivElement>(null)
  const {virtualItems, totalSize} = useVirtual({
    size: state.filteredCheckboxes?.length || 0,
    parentRef,
    estimateSize: React.useCallback(() => 46, []),
    overscan: 3,
  })

  //initial load of list into checkboxes
  React.useEffect(() => {
    if (list && status === 'success' && previous?.list !== list) {
      const selectedIDsInList: (number | string)[] = []
      const newCheckboxes: CheckboxType[] = []
      list.forEach(item => {
        const foundSelected = selectedIDs?.indexOf(item[idField]) > -1
        if (foundSelected) selectedIDsInList.push(item[idField])
        let label = ''
        if (typeof labelField === 'object') {
          if (customLabel) {
            label = customLabel
            labelField.forEach(field => {
              let value = item[field]
              if (field.includes('.')) {
                value = get(item, field)
              }
              label = label.replace(field, (value || '').toString())
            })
          } else {
            labelField.forEach(field => (label += `${item[field]} `))
          }
        } else {
          label = item[labelField]?.toString()
        }
        newCheckboxes.push({
          id: item[idField],
          label,
          checked: foundSelected,
        })
      })
      // Sort by label
      newCheckboxes.sort((a, b) => (a.label > b.label ? 1 : -1))
      dispatch({
        type: 'setCheckboxes',
        checkboxes: newCheckboxes,
        // pageSize,
        selectedIDsInList,
      })
    }
  }, [
    list,
    idField,
    labelField,
    // pageSize,
    selectedIDs,
    status,
    previous?.list,
    customLabel,
  ])

  React.useEffect(() => {
    dispatch({
      type: 'updateSelectedIDs',
      idField,
      selectedIDs,
    })
  }, [idField, selectedIDs])

  const checkOption = (dataid: string | number) => {
    let newSelectedIDs: (number | string)[] = []
    if (dataid && selectedIDs.includes(dataid)) {
      //we are deselecting
      newSelectedIDs = selectedIDs.filter(item => item !== dataid)
    } else {
      //we are selecting
      newSelectedIDs = selectedIDs?.length ? [...selectedIDs, dataid] : [dataid]
    }
    setChecked(newSelectedIDs)
    dispatch({type: 'checkOne', id: dataid})
  }

  const toggleSelectAll = () => {
    const newSelectedIDs: (string | number)[] = []
    const filteredIDs: (string | number)[] = []
    state.filteredCheckboxes.forEach(cb => {
      filteredIDs.push(cb.id)
    })
    state.checkboxes.forEach(cb => {
      if (filteredIDs.indexOf(cb.id) > -1) {
        if (!state.allSelected) {
          newSelectedIDs.push(cb.id)
        }
      } else {
        if (cb.checked) {
          newSelectedIDs.push(cb.id)
        }
      }
    })
    setChecked(newSelectedIDs)
    dispatch({type: 'selectAll', active: !state.allSelected})
  }

  const searchChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    dispatch({type: 'searchChange', search: value.toLowerCase()})
  }

  const toggleShowSelected = () => {
    dispatch({
      type: 'showSelected',
      active: !state.showingSelectedOnly,
    })
  }

  return (
    <Container ref={containerRef} className={className}>
      <Input
        data-lpignore={true}
        onFocus={toggleOpen}
        open={open}
        data-cy="multiselect"
      />
      {open ? (
        <SelectedListFocused onClick={toggleOpen}>
          {state.headerText
            ?.split(/&&/)
            .slice(0, 10)
            .map((text: string, index: number) => (
              <SelectedItem
                data-cy={'selected-item'}
                key={index}
                open={open}
                selectedColor={String(selectedColor)}
              >
                {text}
              </SelectedItem>
            ))}
          {!open ? <Gradient /> : null}
        </SelectedListFocused>
      ) : (
        <SelectedListBlurred onClick={toggleOpen}>
          {state.headerText
            ?.split(/&&/)
            .slice(0, 10)
            .map((text: string, index: number) => (
              <SelectedItem
                data-cy={'selected-item'}
                key={index}
                open={open}
                selectedColor={String(selectedColor)}
              >
                {text}
              </SelectedItem>
            ))}
          <Gradient />
          <InputCaret
            open={open}
            onClick={toggleOpen}
            data-cy="multiselect-input-caret"
          />
        </SelectedListBlurred>
      )}
      {open && (
        <Dropdown>
          <Controls>
            <SearchControl>
              <StyledCheckbox color="var(--primary)">
                <input
                  onChange={toggleSelectAll}
                  disabled={disabled}
                  type="checkbox"
                  checked={state.allSelected}
                  data-cy="multiselect-select-all"
                />
              </StyledCheckbox>
              <SearchBar
                placeholder={`Search ${typeLabel}...`}
                onChange={searchChange}
                value={state.searchValue}
              />
              <SearchIcon weight="light" />
            </SearchControl>
            {showSelectedToggle && (
              <ShowSelectedToggle onClick={toggleShowSelected}>
                {!state.showingSelectedOnly ? (
                  <ToggleLeft />
                ) : (
                  <ToggleRight
                    weight="fill"
                    style={{color: 'var(--asc-moss)'}}
                  />
                )}
                <span>Display Selected Only</span>
              </ShowSelectedToggle>
            )}
          </Controls>
          <OptionList ref={parentRef}>
            <div
              className="ListInner"
              style={{
                minHeight: `${totalSize}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {virtualItems.length ? (
                virtualItems.map(virtualItem => {
                  const item = state.filteredCheckboxes[virtualItem.index]
                  return (
                    <Option
                      key={item.id}
                      checked={item.checked}
                      onClick={() => checkOption(item.id)}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        transform: `translateY(${virtualItem.start}px)`,
                      }}
                    >
                      <StyledCheckbox color="var(--primary)">
                        <input
                          data-id={item.id}
                          checked={item.checked}
                          onChange={e => e.stopPropagation()}
                          id={`mscb${item.id}`}
                          data-cy={`mscb${item.id}`}
                          disabled={disabled}
                          type="checkbox"
                        />
                        {item.label}
                      </StyledCheckbox>
                    </Option>
                  )
                })
              ) : (
                <EmptyText>No Results Found</EmptyText>
              )}
            </div>
          </OptionList>
        </Dropdown>
      )}
    </Container>
  )
}
export default MultiSelect
