import {useMapStore} from 'map/useMapStore'
import * as React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  background-color: ${({selected}) => (selected ? 'var(--primary)' : 'white')};
  border: ${({label}) =>
    label === 'ALERTS'
      ? '1px solid var(--asc-vermilion)'
      : '1px solid var(--asc-sonicsilver)'};
  border-radius: 20px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin-right: 5px;
  span {
    color: ${({selected}) =>
      selected ? 'var(--secondary)' : 'var(--primary)'};
  }
  svg {
    color: ${({label, selected}) =>
      label === 'ALERTS'
        ? 'var(--asc-vermilion)'
        : selected
        ? 'var(--secondary)'
        : 'var(--asc-sonicsilver)'};
  }
  :hover {
    span {
      color: var(--primary);
    }
    border: ${({label}) =>
      label === 'ALERTS'
        ? '1px solid var(--asc-vermilion)'
        : '1px solid var(--secondary)'};
    svg {
      color: ${({label}) =>
        label === 'ALERTS' ? 'var(--asc-vermilion)' : 'var(--primary)'};
    }
    cursor: pointer;
    background-color: ${({label}) =>
      label === 'ALERTS' ? 'var(--asc-lavender)' : 'var(--secondary)'};
    div {
      background-color: ${({label}) =>
        label === 'ALERTS' ? 'var(--asc-vermilion)' : 'var(--primary)'};
      color: ${({label}) =>
        label === 'ALERTS' ? 'white' : 'var(--secondary)'};
    }
  }
  span {
    text-transform: uppercase;
    font-weight: 700;
  }
`
const Count = styled.div`
  padding: 3px 5px;
  border-radius: 10px;
  background-color: ${({label, selected}) =>
    label === 'ALERTS'
      ? 'var(--asc-vermilion)'
      : selected
      ? 'var(--secondary)'
      : 'var(--asc-sonicsilver)'};
  color: ${({label, selected}) =>
    label === 'ALERTS' ? 'white' : selected ? 'var(--primary)' : 'white'};
  font-weight: 700;
  font-size: 12px;
  line-height: 13px;
`

const GutButton = ({
  label,
  item,
  setAssetFilter,
  assetFilter,
  toggleShowingAlerts,
  showingAlerts = false,
  Icon,
  className,
}) => {
  const onClick = () => {
    useMapStore.setState({showAssetMarkers: true})
    if (label === assetFilter?.label) {
      setAssetFilter({label: null, filteredAssetIDs: []})
    } else {
      if (label !== 'ALERTS') {
        setAssetFilter({label, filteredAssetIDs: item.assets})
      } else {
        toggleShowingAlerts()
      }
    }
  }

  return (
    <Container
      onClick={onClick}
      label={label}
      selected={assetFilter?.label === label}
      showingAlerts={showingAlerts}
      className={className}
    >
      {Icon ? <Icon weight="fill" size={22} /> : <span>{label}</span>}
      <Count label={label} selected={assetFilter?.label === label}>
        {item.count}
      </Count>
    </Container>
  )
}
export default GutButton
