import create from 'zustand'
import {
  parseISO,
  differenceInSeconds,
  differenceInDays,
  startOfToday,
  endOfToday,
} from 'date-fns'
import {
  getIcon,
  plotMarkers,
  getType,
  setVisible,
} from 'historyTrail/plotMarkers'
import {drawTimeLine} from 'historyTrail/drawTimeLine'
import {utcToZonedTime} from 'date-fns-tz'
import {changeTimeZone} from 'common/helpersDateTime'
import {useStore} from 'common/useStore'

export const getStartingBounds = (points, maps) => {
  var newBounds = new maps.LatLngBounds()
  points?.forEach(point => {
    const coordinates: [number, number] = [point.Longitude, point.Latitude]
    if (point.Longitude && point.Latitude && coordinates !== [0, 0]) {
      newBounds.extend(new maps.LatLng(point.Latitude, point.Longitude))
    }
  })
  return newBounds
}
const pointsFilter = (points, map, range, eventFilter) => {
  const filteredPoints = []
  points.forEach(point => {
    let visible = false
    const userConfig = useStore.getState().userConfig

    const pointDate = utcToZonedTime(
      parseISO(point.Event_Timestamp + 'Z'),
      userConfig.TZ_Moment_Name,
    )

    const inDateRange =
      pointDate >= range.startDate && pointDate <= range.endDate

    let inEventFilter = true

    if (eventFilter === 'Motion' && !point.In_Motion) {
      inEventFilter = false
    } else if (
      eventFilter === 'Alerts' &&
      (!point.Alerts || point.Alerts.length === 0)
    ) {
      inEventFilter = false
    } else if (eventFilter === 'Stop' && point.In_Motion) {
      inEventFilter = false
    } else if (
      eventFilter === 'Camera' &&
      (!point.CameraMediaEventIds || point.CameraMediaEventIds.length === 0)
    ) {
      inEventFilter = false
    } else if (
      eventFilter === 'DriverSafety' &&
      point.idEvent_Code !== 26 &&
      point.idEvent_Code !== 27 &&
      point.idEvent_Code !== 28
    ) {
      inEventFilter = false
    }

    if (inEventFilter && inDateRange) {
      filteredPoints.push(point)
      visible = true
    }
    setVisible({
      map: map,
      featureID: point.idRaw_Data,
      visible,
      animate: false,
      range,
    })
  })

  return filteredPoints
}

export const initialLoad = ({data, mapRef}) => {
  const setPoints = useHistoryTrailStore.getState().setPoints
  if (data?.length) {
    const startingBounds = getStartingBounds(data, window.google.maps)
    mapRef.current.map.fitBounds(startingBounds)
  }

  const points = data.map(point => {
    const type = getType(point)
    const newPoint = {...point, ...type}
    const icon = getIcon(window.google.maps, newPoint)
    newPoint.icon = icon
    return newPoint
  })
  const flagMarkers = plotMarkers(
    points,
    mapRef.current.map,
    window.google.maps,
  )

  setPoints(points)

  return flagMarkers
}

export const engineTimesCalculator = (
  points,
  svgRef,
  userConfig,
  dateRange,
) => {
  const setActiveID = useHistoryTrailStore.getState().setActiveID
  let totalAlerts = 0
  const engineTimes = []
  const alerts = []
  let minDistance = 0
  let maxDistance = 0
  let minHours = 0
  let maxHours = 0
  let engineStatus = 'off'

  const localDateRange = {...dateRange}

  const now = changeTimeZone(new Date(), userConfig.TZ_Moment_Name)

  if (localDateRange.endDate > now) {
    localDateRange.endDate = now
  }

  points &&
    points?.length > 0 &&
    points?.forEach((point, index) => {
      const pointDate = changeTimeZone(
        parseISO(point.Event_Timestamp + 'Z'),
        userConfig.TZ_Moment_Name,
      )
      // if (
      //   pointDate >= localDateRange.startDate &&
      //   pointDate <= localDateRange.endDate
      // ) {
      if (point.GPS_Odometer) {
        if (minDistance === 0) {
          minDistance = point.GPS_Odometer
          maxDistance = point.GPS_Odometer
        }
        if (point.GPS_Odometer < minDistance) {
          minDistance = point.GPS_Odometer
        }
        if (point.GPS_Odometer > maxDistance) {
          maxDistance = point.GPS_Odometer
        }
      }
      if (point.Total_Engine_Hours) {
        if (minHours === 0) {
          minHours = point.Total_Engine_Hours
          maxHours = point.Total_Engine_Hours
        }
        if (point.Total_Engine_Hours < minHours) {
          minHours = point.Total_Engine_Hours
        }
        if (point.Total_Engine_Hours > maxHours) {
          maxHours = point.Total_Engine_Hours
        }
      }
      if (point.Alerts && point.Alerts.length > 0) {
        totalAlerts = totalAlerts + point.Alerts.length
        alerts.push(...point.Alerts)
      }

      //phone only will not have ignition events, stop if last point is completed route
      const lastPointIsCompletedRoute =
        index === points.length - 1 && point.idEvent_Code === 3004

      const newEngineStatus =
        point.Engine_Running && !lastPointIsCompletedRoute
          ? point.In_Motion
            ? 'moving'
            : 'idling'
          : 'off'

      if (newEngineStatus !== engineStatus) {
        //close open item if found
        const openIndex = engineTimes.findIndex(t => t.open)
        if (openIndex > -1) {
          const seconds = differenceInSeconds(
            pointDate,
            engineTimes[openIndex].startTime,
          )
          engineTimes[openIndex].endTime = pointDate
          engineTimes[openIndex].seconds = seconds
          engineTimes[openIndex].open = false
        }

        engineTimes.push({
          startTime: pointDate,
          open: true,
          status: newEngineStatus,
          idRaw_Data: point.idRaw_Data,
        })
        engineStatus = newEngineStatus
      }
      // }
    })
  if (engineTimes.length && engineTimes[engineTimes.length - 1].open) {
    // let endTime = new Date()
    let openIndex = engineTimes.length - 1
    // if (range.endDate < endTime) {
    //   endTime = range.endDate
    // }
    const seconds = differenceInSeconds(
      localDateRange.endDate,
      engineTimes[openIndex].startTime,
    )
    engineTimes[openIndex].endTime = localDateRange.endDate
    engineTimes[openIndex].seconds = seconds
  }
  // if (engineTimes?.length) {
  if (
    engineTimes?.length > 1 &&
    engineTimes[engineTimes.length - 1].status === 'off'
  ) {
    //remove last entry if off
    engineTimes.pop()
  }
  if (svgRef) {
    drawTimeLine({
      startDate: localDateRange.startDate,
      endDate: localDateRange.endDate,
      data: engineTimes,
      alerts: alerts,
      svgRef,
      setActiveID,
    })
  }
  const hours = Math.floor((maxHours - minHours) / 3600)
  let minutes = Math.floor(((maxHours - minHours) % 3600) / 60)
  if (minutes < 10) minutes = '0' + minutes
  const distanceConverter = userConfig?.Distance_UOM === 'km' ? 1000 : 1609.344
  // }
  return {
    distance: (maxDistance - minDistance) / distanceConverter,
    hours: hours + ':' + minutes,
    alerts: totalAlerts,
    engineTimes,
  }
}

const initialStore = {
  zoom: 2,
  bounds: null,
  eventFilter: 'All',
  dateRange: {
    startDate: startOfToday(),
    endDate: endOfToday(),
  },
  selectionDateRange: {
    startDate: startOfToday(),
    endDate: endOfToday(),
  },
  points: [],
  eventFilterPoints: [],
  dateRangePoints: [],
  flags: [],
  landmarkMarkers: [],
  showingLocations: true,
  showTimeLine: true,
  cameraID: false,
  assetInfo: {},
  resetCounter: 0,
  showingEventCards: window.innerWidth < 768 ? false : true,
  showingStreetView: false,
  pausePlayingCounter: 0,
  showAlertsSection: false,
  totalCount: 0,
}

export const useHistoryTrailStore = create((set, get) => ({
  ...initialStore,
  setPoints: points => {
    const daysInRange = differenceInDays(
      get().dateRange.endDate,
      get().dateRange.startDate,
    )
    set({points})
    set({totalCount: points?.length})
    set({eventFilterPoints: points})
    set({dateRangePoints: points})
    set({activeInfoWindowID: null})
    set({
      activeID: points?.length ? points[points.length - 1].idRaw_Data : null,
    })
    set({showTimeLine: daysInRange < 1 && points?.length ? true : false})
  },
  setActiveID: activeID => {
    set({activeID})
    set({activeInfoWindowID: null})
  },
  setAssetInfo: assetInfo => {
    set({
      cameraID:
        assetInfo?.Device?.idDevice_Type === 11 ||
        assetInfo?.Device?.idDevice_Type === 35
          ? assetInfo.idAsset
          : null,
    })
    set({assetInfo: assetInfo})
  },
  setActiveInfoWindowID: (id, showAlertsSection) => {
    set({activeInfoWindowID: id})
    set({activeID: id})
    set({showAlertsSection})
  },
  setMarkerClicked: id => {
    set({activeInfoWindowID: id})
    set({activeID: id})
    set({pausePlayingCounter: get().pausePlayingCounter + 1})
    set({showAlertsSection: false})
  },
  closeInfoWindow: () => {
    set({activeInfoWindowID: null})
    set({activeID: null})
  },
  setLandmarkMarkers: landmarkMarkers => {
    set({landmarkMarkers})
  },
  toggleLocations: () => {
    set({showingLocations: !get().showingLocations})
  },
  setFlags: flags => {
    set({flags: flags})
  },
  setAssetChange: cameraID => {
    set({cameraID})
    set({activeInfoWindowID: null})
    set({activeID: null})
    set({resetCounter: get().resetCounter + 1})
    set({pausePlayingCounter: get().pausePlayingCounter + 1})
    set({eventFilter: 'All'})
  },
  dateRangeChanged: dateRange => {
    if (get().landmarkMarkers) {
      get().landmarkMarkers.forEach(marker => marker.setMap(null))
    }
    if (get().flags) {
      get().flags.forEach(marker => marker.setMap(null))
    }
    set({dateRange})
    set({selectionDateRange: dateRange})
    set({activeInfoWindowID: null})
    set({
      activeID: dateRange.length
        ? dateRange[dateRange.length - 1].idRaw_Data
        : null,
    })
    set({eventFilter: 'All'})
  },
  dateSliderChanged: (map, range) => {
    const filteredPoints = pointsFilter(
      get().points,
      map,
      range,
      get().eventFilter,
    )
    set({dateRangePoints: filteredPoints})
    set({eventFilterPoints: filteredPoints})
    set({selectionDateRange: range})
    set({totalCount: filteredPoints.length})
    set({activeInfoWindowID: null})
    set({activeID: null})
  },
  setSelectionDateRange: selectionDateRange => {
    set({selectionDateRange: selectionDateRange})
  },
  setEventFilter: (map, filter) => {
    const filteredPoints = pointsFilter(
      get().points,
      map,
      get().selectionDateRange,
      filter,
    )
    set({eventFilterPoints: filteredPoints})
    set({eventFilter: filter})
    set({activeInfoWindowID: null})
    set({activeID: null})
  },
  toggleShowEventCards: () => {
    set({showingEventCards: !get().showingEventCards})
  },
  setStreetView: show => {
    set({showingStreetView: show || false})
  },
  setShowAlertsSection: show => {
    set({showAlertsSection: show || false})
  },
  emptyStore: () => set(state => ({...state, ...initialStore})),
}))
