import * as React from 'react'
import styled from 'styled-components/macro'
import {
  formatDate,
  getExportFileBlob,
} from 'common/tables/tableWithHooksHelpers'
import Button from 'common/components/Button'
import {DownloadSimple} from 'phosphor-react'

const Container = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
  flex-direction: row;
  height: 100%;
  padding: 5px;
  width: 25%;
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    border-radius: 2px;
    background-color: transparent;
    padding: 10px;
    color: var(--primary);
    font-weight: bold;
    font-size: 13px;
    :hover {
      background-color: transparent;
      color: var(--secondary);
    }
    svg {
      min-width: 16px;
    }
  }
`

const ExportIconLabel = styled.span`
  margin-left: 5px;
`

const assetColumns = [
  {
    Header: 'Name',
    accessor: 'Asset_Label',
    formatting: 'string',
    excelWidth: 30,
  },
  {
    Header: 'Event Type',
    accessor: 'Event_Type',
    formatting: 'string',
    excelWidth: 30,
  },
  {
    Header: 'Date/Time',
    accessor: 'Event_Timestamp_Local',
    formatting: 'date',
    Cell: formatDate,
    excelWidth: 50,
  },
  {
    Header: 'Location/Address',
    accessor: 'Nearby_Address',
    formatting: 'string',
    excelWidth: 40,
  },
  {
    Header: 'Driver',
    accessor: 'CurrentDriver',
    formatting: 'string',
    excelWidth: 30,
  },
  {
    Header: 'Direction',
    accessor: 'Last_Heading_Cardinal',
    formatting: 'string',
    excelWidth: 10,
  },
  {
    Header: 'Speed',
    accessor: 'Last_Speed',
    formatting: 'number',
    excelWidth: 10,
  },
]

const getPickedData = (data, assetInfo) => {
  const newData = []
  data?.forEach(d => {
    const {
      Event_Type,
      Event_Timestamp_Local,
      Nearby_Address,
      CurrentDriver,
      Last_Heading_Cardinal,
      Last_Speed,
    } = d
    newData.push([
      assetInfo.Asset_Label,
      Event_Type,
      Event_Timestamp_Local,
      Nearby_Address,
      CurrentDriver,
      Last_Heading_Cardinal,
      Last_Speed,
    ])
  })
  return newData
}

/*to use:
columns send like usual;[{header, accessor, id}]...[{header:var1, accessor:var1, id:var1},{header:var2, accessor:var2, id:var2}]
data will be a corresponding array of only the value, no object/property naming
[[object1Var1Value,object1Var2Value],[object2Var1Value,object2Var2Value]]
*/
const ExportButton = ({
  data,
  assetInfo,
  customColumns = [],
  fileName = 'History Trail',
}) => {
  const exportData = () => {
    const convertedData = assetInfo ? getPickedData(data, assetInfo) : data
    const columns = customColumns.length > 0 ? customColumns : assetColumns
    getExportFileBlob({
      columns: columns,
      data: convertedData,
      fileType: 'xlsx',
      fileName: fileName,
    })
  }

  return (
    <Container>
      <Button onClick={exportData}>
        <DownloadSimple size={16} />
        <ExportIconLabel>EXPORT</ExportIconLabel>
      </Button>
    </Container>
  )
}
export default ExportButton
