import Axios from 'axios'
import {useMutation} from '@tanstack/react-query'

const postTrip = async (tripShareValues: any) => {
  const {data} = await Axios.post(`/TripShare`, tripShareValues)
  return data
}

export function useTripShareModal() {
  return useMutation(postTrip, {})
}
