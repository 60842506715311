import {
  differenceInMinutes,
  format,
  addMinutes,
  startOfToday,
  addSeconds,
} from 'date-fns'

export const createLabels = ({
  start = startOfToday(),
  end = new Date(),
  dateFormat,
}) => {
  return {
    startDateLabel: format(start, dateFormat),
    endDateLabel: format(end, dateFormat),
    start,
    end,
  }
}

export const bubbleLabels = ({
  start,
  end,
  selectionStart,
  selectionEnd,
  dateFormat,
}) => {
  const totalMinutes = differenceInMinutes(end, start)
  const interval = totalMinutes / 1000
  const newStartDate = addMinutes(start, selectionStart * interval)
  let newEndDate = addMinutes(start, selectionEnd * interval)
  newEndDate = addSeconds(newEndDate, 59)
  const startDateLabel = format(newStartDate, dateFormat)
  const endDateLabel = format(newEndDate, dateFormat)
  return {
    startDateLabel,
    endDateLabel,
    newStartDate,
    newEndDate,
  }
}

export const getSliderValue = ({
  start,
  end,
  selectionStartDate,
  selectionEndDate,
  dateFormat,
}) => {
  const totalMinutes = differenceInMinutes(end, start)
  const interval = totalMinutes / 1000

  const startDifference = differenceInMinutes(selectionStartDate, start)
  const endDifference = differenceInMinutes(selectionEndDate, start)
  const newStartValue = Math.round(startDifference / interval)
  const newEndValue = Math.round(endDifference / interval)

  const startDateLabel = format(selectionStartDate, dateFormat)
  const endDateLabel = format(selectionEndDate, dateFormat)

  return {
    startDateLabel,
    endDateLabel,
    newStartValue,
    newEndValue,
  }
}
