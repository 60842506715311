import {UploadSimple} from 'phosphor-react'
import * as React from 'react'
import {SemanticCOLORS, SemanticICONS} from 'semantic-ui-react'
import styled from 'styled-components/macro'
import Swal from 'sweetalert2'
import AlertModal from './AlertModal'
import StyledButton from './StyledButton'

const Container = styled.div`
  display: flex;
`
const ButtonText = styled.span`
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--primary);
`
interface IProps {
  onChange: (image: {fileURL: string; file: File}) => void
  buttonText: string
  dataCy: string
  icon: SemanticICONS
  color?: SemanticCOLORS
  className?: string
}
const HiddenInput = styled.input`
  display: none;
`
const ImageUpload = ({onChange, dataCy}: IProps) => {
  const [showDialog, setShowDialog] = React.useState(false)
  const [dialogText, setDialogText] = React.useState('')
  const inputEl = React.useRef<HTMLInputElement>(null)
  const onUploadImageClick = () => {
    if (inputEl && inputEl.current) {
      inputEl.current.value = ''
      inputEl.current.click()
    }
  }
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      const file = e.currentTarget.files[0]
      // if (file.size > 3000000) {
      //   setDialogText('File too large. File size is limited to 3MB.')
      //   //setShowDialog(true)
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'File too large',
      //     text: 'File size is limited to 3MB',
      //     confirmButtonColor: 'var(--primary)',
      //   })
      //   return
      // }
      const validImageTypes = [
        'image/gif',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/bmp',
        'image/svg+xml',
      ]
      if (!validImageTypes.includes(file.type)) {
        setDialogText(`Incorrect file type. 
        Valid extensions include: .gif, .jpeg, .png, .bmp and .svg. Please try again with a valid image file.`)
        //setShowDialog(true)
        Swal.fire({
          icon: 'error',
          title: 'Incorrect file type',
          text: 'File type is not supported. Valid extensions include: .gif, .jpeg, .png, .bmp and .svg. Please try again with a valid image file',
          confirmButtonColor: 'var(--primary)',
        })
        return
      }
      const reader = new FileReader()
      let baseString
      reader.onloadend = async function () {
        baseString = reader.result
        if (baseString && typeof baseString === 'string') {
          const resizedImage = await resizeBase64Image(baseString)
          const data = {
            fileURL: resizedImage,
            file: file,
          }
          onChange(data)
          if (e && e.currentTarget) {
            e.currentTarget.value = '' //clear file input so same file could be uploaded
          }
        }
      }
      reader.readAsDataURL(file)
    }
  }

  // const resizeImg = async (file: string): Promise<string> => {
  //   const img = document.createElement('img')
  //   img.src = file
  //   await new Promise(resolve => (img.onload = resolve))
  //   const canvas = document.createElement('canvas')
  //   let ctx = canvas.getContext('2d')
  //   if (ctx) ctx.drawImage(img, 0, 0)
  //   const width = img.naturalWidth
  //   const height = img.naturalHeight
  //   canvas.width = width
  //   canvas.height = height
  //   ctx = canvas.getContext('2d')
  //   if (ctx) ctx.drawImage(img, 0, 0, width, height)
  //   return canvas.toDataURL("image/jpeg", 0.5)
  // }

  const resizeBase64Image = async (file: string): Promise<string> => {
    const maxSizeInMB = 3
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024
    const img = new Image()
    img.src = file
    await new Promise(resolve => (img.onload = resolve))
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const width = img.width
    const height = img.height
    const aspectRatio = width / height
    const newWidth = Math.sqrt(maxSizeInBytes * aspectRatio)
    const newHeight = Math.sqrt(maxSizeInBytes / aspectRatio)
    canvas.width = newWidth
    canvas.height = newHeight
    if (ctx) ctx.drawImage(img, 0, 0, newWidth, newHeight)
    let quality = 0.8
    return canvas.toDataURL('image/jpeg', quality)
  }

  return (
    <Container>
      <HiddenInput
        ref={inputEl}
        onChange={handleImageUpload}
        type="file"
        data-cy={dataCy}
      />
      <StyledButton onClick={onUploadImageClick}>
        <UploadSimple size={16} />
        <ButtonText>Upload Image</ButtonText>
      </StyledButton>

      <AlertModal
        showDialog={showDialog}
        onSave={() => {
          setShowDialog(false)
        }}
        type={'error'}
        title={'Unable to upload to this image.'}
        text={dialogText}
        showDenyButton={false}
        disabled={undefined}
        onDismiss={undefined}
      />
    </Container>
  )
}

export default ImageUpload
