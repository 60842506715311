import ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'

export const saveAsExcel = async (columns, data, fileName) => {
  const workbook = new ExcelJS.Workbook()
  workbook.created = new Date()
  workbook.modified = new Date()
  const worksheet = workbook.addWorksheet()

  const headers = columns.map(c => {
    return {
      //TODO - fix this typescript weirdness
      name: c.exportHeader || c.exportValue || c.Header || c.header,
      filterButton: true,
    }
  })

  worksheet.addTable({
    name: 'ExportTable',
    ref: 'A1',
    headerRow: true,
    totalsRow: false,
    style: {
      theme: 'TableStyleMedium2',
      showRowStripes: true,
    },
    columns: headers,
    rows: data,
  })

  const columnsToDelete = []

  worksheet.columns.forEach((column, index) => {
    const originalColumn = columns[index]
    if (originalColumn.noExport) {
      columnsToDelete.push(index)
    }
    if (originalColumn?.excelWidth) {
      //excelwidth was provided use that
      column.width = originalColumn.excelWidth
    } else {
      //autofit width based on character count
      var dataMax = 0
      column.eachCell({includeEmpty: true}, function (cell) {
        var columnLength = cell.value?.length
        if (columnLength > dataMax) {
          dataMax = columnLength
        }
      })
      column.width = dataMax < 15 ? 15 : Math.min(dataMax, 100)
    }

    if (originalColumn?.formatting === 'number') {
      const format = '#,##0.' + '0'.repeat(originalColumn?.decimals || 0)
      column.numFmt = format
    }

    if (originalColumn?.formatting === 'currency') {
      column.numFmt = '$#,##0.00'
    }
  })

  const buf = await workbook.xlsx.writeBuffer()

  saveAs(new Blob([buf]), `${fileName || 'Export'}.xlsx`)
}
