import RenderCell from './RenderCell'

// export const setHeight = ({event, dragbarHeight}) => {
//   // if (isDragging) {
//   let page = document.getElementById('page')
//   const topSection = document.getElementById('topsection')
//   const bottomSection = document.getElementById('bottomsection')
//   const topRect = topSection.getBoundingClientRect()
//   const bottomRect = bottomSection.getBoundingClientRect()
//   const totalHeight = topRect.height + dragbarHeight + bottomRect.height
//   const topHeight = event.clientY - topRect.top
//   const bottomHeight = totalHeight - topHeight - dragbarHeight

//   let rows = [topHeight, dragbarHeight, bottomHeight]

//   let newColDefn = rows.map(c => c.toString() + 'px').join(' ')

//   page.style.gridTemplateRows = newColDefn

//   event.preventDefault()
//   return topHeight
//   // }
// }

export const thresholdclass = function (field, value, row) {
  let tclass = ''
  let threshholdFactor = 1
  switch (field.threshold.ThresholdType) {
    case 0:
      //Check for battery voltage to calculate factor
      if (field.deviceFieldName === 'Last_Battery_Voltage' && value) {
        const asset = row?.row?.original
        if (asset?.BatteryTypeVoltage) {
          threshholdFactor = parseInt(asset.BatteryTypeVoltage) / 12
        }
      }
      if (
        value >=
          Number(field.threshold.ValueGreenLowerLimit) * threshholdFactor &&
        value <= Number(field.threshold.ValueGreenUpperLimit) * threshholdFactor
      ) {
        tclass = ''
      } else if (
        value >=
          Number(field.threshold.ValueYellowLowerLimit) * threshholdFactor &&
        value <=
          Number(field.threshold.ValueYellowUpperLimit) * threshholdFactor
      ) {
        tclass = 'yellow'
      } else if (value && typeof value === 'number') {
        // make sure there is a value before marking it red
        tclass = 'red'
      }
      break
    case 1:
      if (value === field.threshold.ValueGreenLowerLimit) {
        tclass = ''
      } else if (value && typeof value === 'number') {
        // make sure there is a value before marking it red
        tclass = 'red'
      }
      break
    default:
      return false
  }
  return tclass
}

export const buildColumns = ({userConfig, schema, AssetImageCell}) => {
  if (!schema) return []
  const newColumns = []
  //change lowercase m to upper case M
  const timeFormat = userConfig?.Time_Format === '12HR' ? 'h:mm a' : 'HH:mm a'
  const userDateTimeFormat = userConfig
    ? userConfig?.Date_Format.replace('mm', 'MM') + ' ' + timeFormat
    : 'MM/dd/yyyy h:mm a'
  schema.map(s => {
    if (s.deviceFieldName === 'FindNearestAssetDistance') {
      s.fieldVisibility = 1
    }
    return s
  })
  const filteredFieldVisibiltySchema = schema?.filter(
    item => item.fieldVisibility,
  )
  if (filteredFieldVisibiltySchema) {
    filteredFieldVisibiltySchema.forEach(function (field) {
      const format = ''
      const filtertype = ''
      let cellrndr
      const columnObj = {}

      columnObj.Header = field.columnheader
      columnObj.accessor = field.FieldMapping?.replace('>', '.')
      columnObj.width = field.width
      columnObj.formatting = field.type
      columnObj.disableFilters = field.columnfilterable
      columnObj.decimals = field.precision
      columnObj.threshold = field.threshold
      if (field.FieldMapping === 'Asset_Icon_Full_Path') {
        columnObj.disableFilters = true
        columnObj.disableExport = true
        columnObj.Cell = AssetImageCell
      } else if (field.FieldMapping === 'FindNearestAssetDistance') {
        columnObj.disableExport = true
      } else {
        columnObj.id = field.FieldMapping
        columnObj.Cell = row => RenderCell(row, field, userDateTimeFormat)
        columnObj.disableFilters = false
      }
      if (filtertype) {
        columnObj.filtertype = filtertype
      }
      if (field.pinned === 1) {
        columnObj.pinned = true
      }
      if (field.align) {
        columnObj.align = field.align
      }
      columnObj.cellsalign = field.cellsalign
      if (format !== '') {
        columnObj.cellsformat = format
      }
      if (field.cellrenderer) {
        columnObj.cellsrenderer = cellrndr
      }

      if (columnObj.formatting === 'date') {
        columnObj.getCellExportValue = row => {
          const returnValue = row.values[columnObj.id]
            ? new Date(row.values[columnObj.id])
            : ''
          return returnValue
        }
      } else {
        columnObj.getCellExportValue = row => {
          return row.values[columnObj.id] || ''
        }
      }
      newColumns.push(columnObj)
    })
    newColumns[0].sticky = 'left'
    // newColumns[0].id = 'name'
  }

  return newColumns
}
