import React, {PropsWithChildren} from 'react'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  SortingState,
  Row,
  RowData,
  ColumnDef,
  ColumnFiltersState,
} from '@tanstack/react-table'
import {useVirtual} from 'react-virtual'
import {
  Body,
  Header,
  NoRecordsRow,
  StyledTableRow,
  ContainerCell,
  Cell,
  Styles,
  RightButtons,
  ScrollableTable,
  SelectionActionButton,
} from './resizableTableStyles'
import {TableHeader, calcColWidth, fuzzyFilter} from './resizableTableHelpers'
import {Range} from 'react-date-range'
import {Spinner, DateRangePicker} from 'common/components'
import GlobalFilter from '../GlobalFilter'
import TableExportButton from '../TableExportButton'
import {saveAsExcel} from '../excelExport'
import {format} from 'date-fns'
import {useStore} from 'common/useStore'
import {saveAsCSV} from '../csvExport'
import {saveAsPDF} from '../pdfExport'
import SelectAllButton from './SelectAllButton'

type TableProps<TDataType, TSelectionIdType> = {
  title?: string
  data: TDataType[]
  columns: ColumnDef<TDataType, TSelectionIdType>[]
  status: 'loading' | 'success' | 'error' | 'idle'
  height?: string
  dateRange?: {
    testId: string
    range: Range
    showTime: boolean
    setRange: (range: Range) => void
  }
  showSelectAll?: boolean
  initialFilters?: {globalFilter: string}
  updateToMatchGlobalFilter?: (value: string) => void //used to update external state to reapply previous values when navigating away from table
  rowClick?: (row: TDataType) => void
  showHeader?: boolean
  rowSelection?: {
    idPropertyName: string
    setSelectedIDs: (ids: TSelectionIdType[]) => void
    selectedIDs: TSelectionIdType[]
    selectableIDs?: TSelectionIdType[]
  }
  selectionActionButtons?: SelectionActionButton[]
  disableVirtualization?: boolean
  initSort?: SortingState
}

//named function instead of arrow function because of TS errors
/**
 * For TS warnings:
 * You can add the data type and the type of the IDs (number | string) like this:
 * <ResizableTable<OrderType, string> {props}    />
 */
function ResizableTable<TDataType, TSelectionIdType>({
  title = '',
  data,
  columns,
  status,
  dateRange,
  height,
  showSelectAll = false,
  selectionActionButtons,
  children,
  initialFilters,
  updateToMatchGlobalFilter,
  rowClick,
  showHeader = true,
  rowSelection,
  disableVirtualization = false,
  initSort = [],
}: PropsWithChildren<TableProps<TDataType, TSelectionIdType>>) {
  // const rerender = React.useReducer(() => ({}), {})[1]
  const userConfig = useStore(state => state.userConfig)

  const [sorting, setSorting] = React.useState<SortingState>(initSort)
  const [allRows, setAllRows] = React.useState<unknown[]>([])
  const [globalFilter, setGlobalFilter] = React.useState(
    initialFilters?.globalFilter || '',
  )
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  )
  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const memoizedData = React.useMemo(() => {
    return data
  }, [data])

  const memoizedColumns = React.useMemo(() => {
    const newColumns = [...columns]
    const columnsWithSize = calcColWidth<TDataType, TSelectionIdType>({
      columns: newColumns,
      hiddenColumns: [],
      containerWidth: tableContainerRef.current?.offsetWidth,
    })
    return columnsWithSize
    //Need to keep offsetWidth in the dependencies so it can watch for changes in width and adjust but eslint thinks it is not needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, tableContainerRef.current?.offsetWidth])

  //hiding columns is no longer supported with show:value
  /*To hide column on table for exporting
      {
      header: 'idAsset',
      id: 'idAsset',
      accessorKey: 'idAsset',
      cell: '',
      maxSize: 0,
      enableResizing: false,
      meta: {
        disableExport: true,
      },
    },
  */

  const table = useReactTable({
    data: memoizedData || [],
    columns: memoizedColumns || [],
    enableColumnResizing: true,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: fuzzyFilter,
    columnResizeMode: 'onChange',
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // enablePinning: true,
    // enableColumnPinning: true,
    state: {
      sorting,
      globalFilter,
      columnFilters,
    },
    initialState: {
      globalFilter: initialFilters?.globalFilter || '',
    },
  })

  const {rows: filteredRows} = table.getRowModel()
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: filteredRows.length,
    overscan: 10,
  })
  const {virtualItems: virtualRows, totalSize} = rowVirtualizer

  const paddingTop =
    !disableVirtualization && virtualRows.length > 0
      ? virtualRows?.[0]?.start || 0
      : 0
  const paddingBottom =
    !disableVirtualization && virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0

  const setFilter = (value: string) => {
    if (!allRows.length) {
      const rows = table.getRowModel().rows
      setAllRows(rows || [])
    }
    setGlobalFilter(value)
    updateToMatchGlobalFilter && updateToMatchGlobalFilter(value)
  }

  const getArrayOfDataForExport = (
    rows: Row<RowData>[],
    columns: ColumnDef<TDataType, TSelectionIdType>[],
  ) => {
    const aoa: Array<Array<RowData>> = []
    rows.forEach(thisRow => {
      const allCells = thisRow.getVisibleCells()
      const row: RowData[] = []
      allCells.forEach((cell, index) => {
        if (!columns[index]?.meta?.disableExport) {
          let value = cell.getValue() || ''
          if (value instanceof Date) {
            value = format(value, userConfig?.Date_Format || 'MM/dd/yyyy')
          }
          row.push(value)
        }
      })
      aoa.push(row)
    })
    return aoa
  }

  const exportData = (type: 'xlsx' | 'csv' | 'pdf', allRecords: boolean) => {
    const rows =
      allRecords && allRows.length ? allRows : table.getFilteredRowModel().rows

    const convertedRows = getArrayOfDataForExport(
      rows as Row<RowData>[],
      columns,
    )
    const exportedColumns = columns
      .map(c => {
        if (c.meta?.exportHeader) {
          c.header = c.meta.exportHeader
        }
        return c
      })
      .filter(c => !c.meta?.disableExport)
    if (type === 'xlsx') {
      saveAsExcel(exportedColumns, convertedRows, title)
    } else if (type === 'csv') {
      saveAsCSV(exportedColumns, convertedRows, title)
    } else {
      saveAsPDF(exportedColumns, convertedRows, title)
    }
  }

  const toggleSelect = (
    selectedId: TSelectionIdType | undefined,
    selectAllClicked: boolean,
  ) => {
    if (!rowSelection) return

    const allSelectableIds = rowSelection.selectableIDs

    if (!selectAllClicked) {
      //deselect individual row if already selected
      if (
        selectedId !== undefined &&
        rowSelection.selectedIDs.includes(selectedId)
      ) {
        rowSelection.setSelectedIDs(
          rowSelection.selectedIDs.filter(id => id !== selectedId),
        )

        //select individual row if not selected
      } else {
        selectedId !== undefined
          ? rowSelection.setSelectedIDs([
              ...rowSelection.selectedIDs,
              selectedId,
            ])
          : rowSelection.setSelectedIDs([...rowSelection.selectedIDs])
      }
    }

    if (filteredRows.length > 0) {
      const totalFilteredIds: TSelectionIdType[] = filteredRows
        .map(
          row =>
            row.original[
              rowSelection.idPropertyName as keyof TDataType
            ] as TSelectionIdType,
        )
        .filter(testId =>
          allSelectableIds ? allSelectableIds.find(id => id === testId) : true,
        )

      const selectedHiddenIds = rowSelection.selectedIDs.filter(
        selectedId =>
          !totalFilteredIds.find(filteredId => filteredId === selectedId),
      )
      const selectedFilteredIds = totalFilteredIds.filter(filteredId =>
        rowSelection.selectedIDs.find(selectedId => selectedId === filteredId),
      )
      //TODO clean this up
      const areAllFilteredIdsSelected =
        selectedFilteredIds.length === totalFilteredIds.length
      const selectedFilteredLocations = areAllFilteredIdsSelected
        ? []
        : totalFilteredIds

      rowSelection.setSelectedIDs([
        ...selectedFilteredLocations,
        ...selectedHiddenIds,
      ])
    }
  }

  const areAllFilteredSelected = () => {
    if (!rowSelection?.idPropertyName || rowSelection?.selectedIDs.length === 0)
      return false
    const firstUnselectedId = filteredRows?.find(row => {
      //The TDataType is optional so it throws a TS error without having any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rowID = (row.original as TDataType | any)[
        rowSelection?.idPropertyName
      ]
      if (!rowID) return false
      return !rowSelection.selectedIDs?.includes(rowID)
    })
    return !firstUnselectedId
  }

  const tableRows = disableVirtualization ? filteredRows : virtualRows

  return (
    <>
      <Styles>
        {showHeader && (
          <Header>
            {showSelectAll && selectionActionButtons && (
              <SelectAllButton
                data={rowSelection?.selectableIDs || []}
                toggleSelectAll={toggleSelect}
                selectedItemsTotal={rowSelection?.selectedIDs?.length}
                selectionActionButtons={selectionActionButtons}
                disabled={rowSelection?.selectedIDs.length === 0}
                showChecked={areAllFilteredSelected()}
              />
            )}
            <GlobalFilter
              totalCount={filteredRows.length}
              globalFilter={globalFilter}
              setGlobalFilter={setFilter}
            />
            {React.Children.map(
              children,
              child =>
                child &&
                React.cloneElement(<>{child}</>, {tableRows: filteredRows}),
            )}
            <RightButtons>
              {dateRange && (
                <DateRangePicker {...dateRange} direction="right" />
              )}
              {data?.length > 0 && (
                <TableExportButton
                  hiddenGlobalFilter={false}
                  exportData={exportData}
                />
              )}
            </RightButtons>
          </Header>
        )}
        <ScrollableTable ref={tableContainerRef} height={height}>
          <table>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <TableHeader<TDataType> header={header} key={header.id} />
                  ))}
                </tr>
              ))}
            </thead>
            <Body>
              {status === 'loading' ? (
                <tr>
                  <td colSpan={100}>
                    <Spinner type={'partial'} />
                  </td>
                </tr>
              ) : filteredRows.length > 0 ? (
                <>
                  {paddingTop > 0 && (
                    <tr onClick={() => {}}>
                      <td style={{height: `${paddingTop}px`}} />
                    </tr>
                  )}
                  {tableRows.map(virtualRow => {
                    const row = filteredRows[virtualRow.index] as Row<TDataType>
                    const isRowSelected = rowSelection?.selectedIDs.includes(
                      row.original[
                        rowSelection.idPropertyName as keyof TDataType
                      ] as TSelectionIdType,
                    )
                    const rowClickFn = rowClick
                      ? () => rowClick(row.original)
                      : () => {}

                    return (
                      <StyledTableRow
                        data-cy={'tableRow'}
                        key={row.id}
                        onClick={rowClickFn}
                        clickable={Boolean(rowClick)}
                      >
                        {row.getVisibleCells().map(cell => {
                          return (
                            <ContainerCell
                              activeRow={isRowSelected}
                              index={virtualRow.index}
                              key={cell.id}
                              size={cell.column.getSize()}
                            >
                              <Cell
                                disableOverflow={!cell.column.getCanResize()}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Cell>
                            </ContainerCell>
                          )
                        })}
                      </StyledTableRow>
                    )
                  })}
                  {paddingBottom > 0 && (
                    <tr>
                      <td style={{height: `${paddingBottom}px`}} />
                    </tr>
                  )}
                </>
              ) : (
                <tr>
                  <NoRecordsRow colSpan={100} data-cy="noRecords">
                    No records found
                  </NoRecordsRow>
                </tr>
              )}
            </Body>
          </table>
        </ScrollableTable>
      </Styles>
    </>
  )
}

export default ResizableTable
