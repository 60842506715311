import * as React from 'react'
import styled from 'styled-components/macro'
import axios from 'axios'
// import useCameraAssetList from './hooks/useCameraAssetList'
import Speedometer from './Speedometer'
import {Select, Spinner} from 'common/components'
import {Button} from 'semantic-ui-react'
import {
  getCameraChannels,
  getAvailableCameras,
  liveLookInitialState,
  getCameraSnapshot,
  liveLookReducer,
  getLiveVideo,
  timeout,
} from './liveLookHelper'
import {getUserDistanceUOMPerHour} from 'users/userDetailHelpers'
import MapFootage from 'cameraEvents/MapFootage'
import AlertModal from './AlertModal'
import Swal from 'sweetalert2'
import ReactPlayer from 'react-player'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`
const AvailableChannelOptions = styled.div`
  max-height: 60px;
  padding: 10px 0;
  margin: 0 5px;
`

const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  min-height: 360px;
`
const GoogleMapContent = styled.div`
  display: flex;
  flex: 1;
  transition: 0.3s;
  padding: 16px 16px;
  flex-direction: row;
  gap: 16px;
`
const ImageContent = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`
const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  object-fit: contain;
  img {
    margin: auto;
    display: flex;
    flex: 1;
    position: relative;
    max-height: calc(100vh - 200px);
    object-fit: contain;
  }
`
const CommandButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const CommandButtons = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  max-height: 110px;
`
const MapContent = styled.div`
  display: flex;
  width: 50%;
  height: 450px;
  margin: auto;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 5px;
  flex-direction: column;
`

const LiveLook = ({idAsset}) => {
  // const {data, status} = useCameraAssetList()
  const [state, dispatch] = React.useReducer(
    liveLookReducer,
    liveLookInitialState,
  )

  const [showDialog, setShowDialog] = React.useState(false)
  const [playerType, setPlayerType] = React.useState()
  //use to see when something has changed
  const playingCode = React.useRef(null)
  const startTime = React.useRef(null)

  const [mapRendered, setMapRendered] = React.useState(false)

  //use to see when something has changed
  const abortControllerRef = React.useRef(new AbortController())
  React.useEffect(() => {
    const controller = abortControllerRef.current
    return () => {
      controller.abort()
      dispatch({
        type: 'setState',
        data: {
          playing: false,
          intervalDelay: null,
          playerType: null,
        },
      })
      playingCode.current = null
    }
  }, [])

  const setNewUrl = React.useCallback(async () => {
    dispatch({
      type: 'setState',
      data: {
        loading: true,
        url: '',
      },
    })
    const url = await getLiveVideo(state.selectedCamera, state.channel)
    if (!url) {
      return Swal.fire(
        'Error',
        'There was an error retrieving the video. Please try again',
        'error',
      )
    }

    const coordinates = {
      lat: url.Latitude,
      lng: url.Longitude,
    }
    dispatch({
      type: 'setState',
      data: {
        loading: false,
        url: url.URL,
        cameraData: {coordinates: coordinates, Speed: url.Speed},
      },
    })
  }, [state.channel, state.selectedCamera])

  const handlePlay = () => {
    dispatch({
      type: 'setState',
      data: {
        playing: true,
        intervalDelay: state.playing ? null : 200,
      },
    })
    startTime.current = null
    playingCode.current = null
  }

  const handlePause = () => {
    dispatch({
      type: 'setState',
      data: {
        playing: false,
        intervalDelay: state.playing ? null : 200,
      },
    })
    startTime.current = null
    playingCode.current = null
  }

  React.useEffect(() => {
    //read from url and set
    const getCameraData = async () => {
      const numberID = Number(idAsset)
      const {data} = await axios.get('/camera/assetlist/online')
      if (data) {
        const smartWitnessCameras = data.filter(cam => cam.idDevice_Type === 11)
        const cameraChannel = getCameraChannels(smartWitnessCameras, numberID)
        const cameraList = getAvailableCameras(smartWitnessCameras)
        const selectedCamera = cameraList?.find(cam => cam.value === numberID)
        if (selectedCamera) {
          dispatch({
            type: 'setState',
            data: {
              selectedCamera: selectedCamera
                ? selectedCamera.value
                : cameraList[0].value,
              availableChannels: cameraChannel,
              channel: cameraChannel[0].value,
              cameraList: cameraList,
              loading: false,
            },
          })
        }
      } else {
        Swal.fire(
          'Error',
          'There was an error retrieving the camera list. Please try again',
          'error',
        )
      }
    }
    getCameraData()
  }, [idAsset])

  React.useEffect(() => {
    if (state.channel && state.selectedCamera && playerType === 'video') {
      setNewUrl()
    }
  }, [state.selectedCamera, state.channel, setNewUrl, playerType])

  React.useEffect(() => {
    const startSnapshotFetch = async () => {
      if (playingCode.current) {
        const snapshot = await getCameraSnapshot(
          state.selectedCamera,
          state.channel,
          abortControllerRef,
        )
        if (!snapshot) {
          dispatch({
            type: 'setState',
            data: {
              intervalDelay: null,
              error: true,
              playing: false,
              loading: false,
            },
          })
          Swal.fire(
            'Error requesting image',
            'There was an error connecting to this camera. Please try again later.',
            'error',
          )
        } else {
          //timer to shut off live look
          if (new Date() - startTime.current > 5 * 60 * 1000) {
            dispatch({
              type: 'setState',
              data: {
                intervalDelay: null,
                playing: false,
              },
            })
            playingCode.current = null
            startTime.current = null
          } else {
            if (snapshot) {
              const cameraData = JSON.parse(snapshot)
              cameraData.coordinates = {
                lat: cameraData.Latitude,
                lng: cameraData.Longitude,
              }

              dispatch({
                type: 'setState',
                data: {cameraData, loading: false, playing: true, error: false},
              })

              if (state.intervalDelay) {
                await timeout(state.intervalDelay)
                //ensure that only current playback works
                if (
                  playingCode.current ===
                  `${state.selectedCamera}-${state.channel}-${state.intervalDelay}`
                ) {
                  startSnapshotFetch()
                }
              }
            }
          }
        }
      }
    }
    if (
      state.channel &&
      state.selectedCamera &&
      state.intervalDelay &&
      playerType === 'images'
    ) {
      dispatch({
        type: 'setState',
        data: {loading: true},
      })
      if (playingCode.current) {
        abortControllerRef.current.abort()
      }
      playingCode.current = `${state.selectedCamera}-${state.channel}-${state.intervalDelay}`
      startTime.current = new Date()
      startSnapshotFetch()
    }
  }, [state.channel, state.selectedCamera, state.intervalDelay, playerType])

  const cameraChange = async e => {
    const numberID = Number(e.currentTarget?.value)
    abortControllerRef.current.abort()
    if (numberID) {
      const {data} = await axios.get('/camera/assetlist/online')
      if (data) {
        const smartWitnessCameras = data.filter(cam => cam.idDevice_Type === 11)
        const cameraChannel = getCameraChannels(smartWitnessCameras, numberID)
        const cameraList = getAvailableCameras(smartWitnessCameras)
        const selectedCamera = cameraList?.find(cam => cam.value === numberID)

        dispatch({
          type: 'setState',
          data: {
            selectedCamera: selectedCamera
              ? selectedCamera.value
              : cameraList[0].value,
            availableChannels: cameraChannel,
            channel: cameraChannel?.length && cameraChannel[0]?.value,
            cameraList: cameraList,
            loading: true,
            playing: false,
          },
        })
      } else {
        Swal.fire(
          'Error',
          'There was an error retrieving the camera list. Please try again',
          'error',
        )
      }
    }
  }

  return (
    <Container>
      <GoogleMapContent>
        <ImageContent>
          <>
            {!playerType && (
              <CommandButtons>
                <h3>Please Select Live Look Type</h3>
                <CommandButtonWrapper>
                  <Button onClick={() => setPlayerType('video')}>Video</Button>
                  <Button onClick={() => setPlayerType('images')}>
                    Images
                  </Button>
                </CommandButtonWrapper>
              </CommandButtons>
            )}
            {playerType === 'video' ? (
              <div>
                {state.loading ? (
                  <LoadingContent>
                    <strong>Loading...</strong>
                    <strong>(Please allow up to 15 seconds)</strong>
                    <Spinner type="partial" />
                  </LoadingContent>
                ) : state.error ? (
                  <LoadingContent>
                    <strong>{`There was an error connecting to this camera.`}</strong>
                  </LoadingContent>
                ) : (
                  <ImageWrapper>
                    <ReactPlayer
                      url={state.url}
                      width="100%"
                      controls={true}
                      loop={false}
                      playing={state.playing}
                      onPlay={handlePlay}
                      onPause={handlePause}
                      onEnded={handlePause}
                    />
                  </ImageWrapper>
                  /*<img src={state.cameraData.URL}></img> */
                )}
              </div>
            ) : (
              <>
                {playerType === 'images' && (
                  <div>
                    {state.loading ? (
                      <LoadingContent>
                        <strong>Loading...</strong>
                        <strong>(Please allow up to 15 seconds)</strong>
                        <Spinner type="partial" />
                      </LoadingContent>
                    ) : state.error ? (
                      <LoadingContent>
                        <strong>{`There was an error connecting to this camera.`}</strong>
                      </LoadingContent>
                    ) : (
                      <ImageWrapper>
                        <img src={state.cameraData.URL}></img>
                      </ImageWrapper>
                    )}
                  </div>
                )}
              </>
            )}

            {playerType && (
              <CommandButtons>
                <CommandButtonWrapper>
                  <AvailableChannelOptions>
                    <Select
                      value={state.selectedCamera}
                      onChange={cameraChange}
                    >
                      {state.cameraList?.map(opt => (
                        <option key={opt.index} value={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                    </Select>
                  </AvailableChannelOptions>
                  <AvailableChannelOptions>
                    <Select
                      value={state.channel}
                      onChange={e => {
                        dispatch({
                          type: 'setState',
                          data: {channel: e.currentTarget.value},
                        })
                      }}
                    >
                      {state.availableChannels?.map(opt => (
                        <option key={opt.index} value={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                    </Select>
                  </AvailableChannelOptions>
                </CommandButtonWrapper>
                <CommandButtonWrapper>
                  <AvailableChannelOptions>
                    <Button
                      content={state.playing ? 'Pause' : 'Play'}
                      icon={state.playing ? 'pause' : 'play'}
                      labelPosition="left"
                      onClick={state.playing ? handlePause : handlePlay}
                    />
                  </AvailableChannelOptions>
                  {playerType === 'video' && (
                    <AvailableChannelOptions>
                      <CommandButtonWrapper>
                        <Button
                          content={'Refresh Video'}
                          icon={'refresh'}
                          labelPosition="left"
                          onClick={() => setNewUrl()}
                        />
                      </CommandButtonWrapper>
                    </AvailableChannelOptions>
                  )}
                </CommandButtonWrapper>
              </CommandButtons>
            )}
            <AlertModal
              showDialog={showDialog}
              onSave={() => {
                setShowDialog(false)
              }}
              type={'error'}
              title={'Unable to connect to this camera.'}
              text={'Please check your connection and try again.'}
              showDenyButton={false}
            />
          </>
        </ImageContent>
        <MapContent>
          <MapFootage
            coordinates={state.cameraData?.coordinates}
            setMapRendered={setMapRendered}
            mapRendered={mapRendered}
          />
          {mapRendered && (
            <Speedometer
              speedLabel={getUserDistanceUOMPerHour()}
              speedValue={state?.cameraData?.Speed}
            />
          )}
        </MapContent>
      </GoogleMapContent>
    </Container>
  )
}
export default LiveLook
