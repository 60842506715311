import * as React from 'react'
import MapControl from '../mapControl/MapControl'
import SearchMap from 'mapControl/SearchMap'
import RecentAssets from './RecentAssets'
import {useMapStore} from 'map/useMapStore'
import BoxSearchDialog from './BoxSearchDialog'
// import {clearDrawingManager} from './boxSearchHelper'

const MapControlDisplay = ({
  map,
  activeMarker,
  setActiveMarker,
  assetsRef,
  setAssetFilter,
  clearAssetFilter,
  setLocationsFilter,
  clearMap,
  openGrid,
  queryClient,
  opening1,
  allowSearch = true,
  allowOptions = true,
  allowRecent = true,
}) => {
  const [showSearch, setShowSearch] = React.useState(false)
  const [addLocationMarker, setAddLocationMarker] = React.useState(null)
  const [showRecentAssets, setShowRecentAssets] = React.useState(false)
  const [recentAssetsLoaded, setRecentAssetsLoaded] = React.useState(false)
  const removeDirections = useMapStore(state => state.removeDirections)
  const showBoxSearch = useMapStore(state => state.showBoxSearch)

  const toggleRecentAssets = () => {
    setRecentAssetsLoaded(true)
    setShowRecentAssets(!showRecentAssets)
  }

  const toggleSearch = () => {
    removeDirections()
    setShowSearch(!showSearch)
  }

  const clearMapAndMarker = () => {
    clearMap()
    setShowSearch(false)
    addLocationMarker?.setMap(null)
    setAddLocationMarker(null)
  }

  return (
    <div data-cy="map-control-display">
      <MapControl
        map={map}
        toggleMapSearch={toggleSearch}
        clearMap={clearMapAndMarker}
        toggleRecentAssets={toggleRecentAssets}
        assetsRef={assetsRef}
        setAssetFilter={setAssetFilter}
        setLocationsFilter={setLocationsFilter}
        clearAssetFilter={clearAssetFilter}
        showRecentAssets={showRecentAssets}
        showSearch={showSearch}
        allowSearch={allowSearch}
        allowOptions={allowOptions}
        allowRecent={allowRecent}
      />
      {recentAssetsLoaded && (
        <RecentAssets
          visible={showRecentAssets}
          setActiveMarker={setActiveMarker}
          toggleRecentAssets={toggleRecentAssets}
          assetsRef={assetsRef}
        />
      )}
      {showSearch && (
        <SearchMap
          map={map}
          visible={showSearch && !activeMarker}
          marker={addLocationMarker}
          setMarker={setAddLocationMarker}
          openGrid={openGrid}
          assetsRef={assetsRef}
          cache={queryClient}
          opening1={opening1}
          // setCoordinates={setCoordinates}
        />
      )}
      {showBoxSearch && (
        <BoxSearchDialog
          map={map}
          assetsRef={assetsRef}
          setAssetFilter={setAssetFilter}
          clearAssetFilter={clearAssetFilter}
        />
      )}
    </div>
  )
}
export default React.memo(MapControlDisplay)
