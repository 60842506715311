import * as React from 'react'
import styled from 'styled-components/macro'
import UserToolbarDropdown from 'profileAdmin/UserToolbarDropdown'
import UserProfile from '../userProfile/UserProfile'
import ChangePassword from 'changePassword/ChangePassword'
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
`
const ProfileSettings = () => {
  const [openModal, setOpenModal] = React.useState(null)
  const closeModal = () => setOpenModal(null)
  return (
    <Container>
      <UserToolbarDropdown setOpenModal={setOpenModal} />
      {openModal === 'userProfile' && (
        <UserProfile
          onDismiss={closeModal}
          shouldShow={openModal === 'userProfile'}
        />
      )}
      {openModal === 'changePassword' && (
        <ChangePassword onDismiss={closeModal} />
      )}
    </Container>
  )
}
export default ProfileSettings
