import * as React from 'react'
import styled from 'styled-components/macro'
import {XCircle, MagnifyingGlass} from 'phosphor-react'

const Container = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  background-color: transparent;
  flex-direction: row;
  max-width: 400px;
`

const InputAndFilter = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
`

const SearchBarContainer = styled.div`
  display: flex;
  width: 100%;
`
const SearchIcon = styled(MagnifyingGlass)`
  position: absolute;
  top: 10px;
  left: 10px;
`
const SearchBar = styled.input`
  flex-grow: 1;
  height: 40px;
  border: 1px solid var(--asc-coolgray);
  border-radius: 5px;
  padding: 13px 13px 13px 35px;
  outline: none;
  ::placeholder {
    color: var(--asc-sonicsilver);
    opacity: 1;
    font-weight: 400;
  }

  :hover {
    border: 1px solid rgba(34, 36, 38, 0.35); /*matches semantic ui dropdown hover*/
  }
`

const FilterPill = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--asc-keylime);
  position: absolute;
  top: 10px;
  right: 4px;
  color: var(--black);
  border-radius: 20px;
  padding: 2px 5px 2px 7px;
  font-size: 12px;
  line-height: 12px;
  gap: 5px;
`
const StyledXCircle = styled(XCircle)`
  cursor: pointer;
`

const FilterPillTitle = styled.span`
  font-weight: 700;
`

const FilterLabel = styled.span`
  max-width: 100px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
`

const ClearButton = styled.button`
  color: var(--primary);
  font-weight: 700;
  font-size: 13px;
  padding: 0 10px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-left: 2px;
  :hover {
    text-decoration: underline;
  }
  :focus {
    outline: 1px solid var(--asc-cultured);
    text-decoration: underline;
  }
  :after {
    content: 'Clear';
  }
`

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const SearchContainer = ({
  handleSearch,
  searchValue,
  clearFunction,
  assetFilter = undefined,
  className = '',
  placeholder = true,
  dataCy = '',
  searchType = '',
  onClick = () => {},
}) => {
  const [capTab, setCapTab] = React.useState('records')
  React.useEffect(() => {
    setCapTab(capitalizeFirstLetter(searchType || 'records'))
  }, [searchType])

  return (
    <Container className={className}>
      <SearchBarContainer>
        <InputAndFilter>
          <SearchIcon weight="thin" size={20} />
          <SearchBar
            data-cy={dataCy}
            placeholder={placeholder ? `Search ${capTab}...` : ''}
            onChange={handleSearch}
            value={searchValue || ''}
            clearFunction={clearFunction}
            searchValue={searchValue}
            autoComplete="off"
            onClick={onClick}
          ></SearchBar>
          {assetFilter && (
            <FilterPill>
              <FilterPillTitle>Filter:</FilterPillTitle>
              <FilterLabel> {assetFilter.label}</FilterLabel>
              <StyledXCircle
                size={16}
                weight={'fill'}
                color={'var(--black)'}
                onClick={clearFunction}
                data-cy={'clear-filter'}
              />
            </FilterPill>
          )}
        </InputAndFilter>
        {searchValue?.length ? (
          <ClearButton data-cy={'clear-button'} onClick={clearFunction} />
        ) : null}
      </SearchBarContainer>
    </Container>
  )
}
export default SearchContainer
