import {createGeoJson} from './createGeoJson'
import {useHistoryTrailStore} from './hooks/useHistoryTrailStore'
export function setVisible({
  map,
  featureID,
  visible,
  animate,
  animateTime = 1000,
  range,
}) {
  // console.log('setVisible')
  const dateRange = useHistoryTrailStore.getState().dateRange

  //toggling flag visibility
  const startFlag = document.querySelector(
    `img[src='${process.env.PUBLIC_URL}/images/history/hist-flag-start.svg']`,
  )
  const endFlag = document.querySelector(
    `img[src='${process.env.PUBLIC_URL}/images/history/hist-flag-end.svg']`,
  )
  if (dateRange.startDate && dateRange.endDate && range) {
    //if not at very start, hide start flag
    if (
      dateRange.startDate.toString() !== range.startDate.toString() &&
      startFlag
    ) {
      startFlag.setAttribute(
        'src',
        'https://maps.gstatic.com/mapfiles/transparent.png',
      )
      startFlag.setAttribute('id', 'start-flag')
    } else if (dateRange.startDate.toString() === range.startDate.toString()) {
      const hiddenStartFlag = document.getElementById('start-flag')
      if (hiddenStartFlag) {
        hiddenStartFlag.setAttribute(
          'src',
          `${process.env.PUBLIC_URL}/images/history/hist-flag-start.svg`,
        )
      }
    }
    //if not at very end, hide end flag
    if (dateRange.endDate.toString() !== range.endDate.toString() && endFlag) {
      endFlag.setAttribute(
        'src',
        'https://maps.gstatic.com/mapfiles/transparent.png',
      )
      endFlag.setAttribute('id', 'end-flag')
    } else if (dateRange.endDate.toString() === range.endDate.toString()) {
      const hiddenEndFlag = document.getElementById('end-flag')
      if (hiddenEndFlag) {
        hiddenEndFlag.setAttribute(
          'src',
          `${process.env.PUBLIC_URL}/images/history/hist-flag-end.svg`,
        )
      }
    }
  }
  var feature = map.data.getFeatureById(featureID)
  if (!feature || !feature.getProperty('icon')) {
    // console.error(`no icon when running history trail idAsset ${assetId}`)
  } else {
    if (!feature.getProperty('validCoordinates')) {
      //don't plot invalid coordinates (0,0)
      visible = false
    }

    var featureIconProp = feature.getProperty('icon')
    var copiedIcon = {...featureIconProp}

    if (animate) {
      copiedIcon.url = copiedIcon.urlAnimate
      copiedIcon.scaledSize = copiedIcon.animateSize // scaled size
      copiedIcon.size = copiedIcon.animateSize // scaled size
      copiedIcon.origin = new window.google.maps.Point(0, 0)
      copiedIcon.anchor = new window.google.maps.Point(20, 20)

      map.data.overrideStyle(feature, {
        visible: visible,
        icon: copiedIcon,
        zIndex: 10000,
      })
      setTimeout(() => {
        map.data.overrideStyle(feature, {icon: featureIconProp, zIndex: 999})
      }, animateTime)
    } else {
      copiedIcon.size = copiedIcon.scaledSize
      map.data.overrideStyle(feature, {
        visible: visible,
        icon: copiedIcon,
        zIndex: 999,
      })
    }
  }
}

function plotFlag(point, type, map, maps) {
  //type is 'start' or 'end'
  var image = {
    url: `${process.env.PUBLIC_URL}/images/history/hist-flag-${type}.svg`,
    scaledSize: new maps.Size(35, 35),
    origin: new maps.Point(0, 0),
    anchor: new maps.Point(1, 50),
    title: type,
    zIndex: 1000,
  }

  const flagMarker = new maps.Marker({
    animation: maps.Animation.DROP,
    position: point,
    map: map,
    icon: image,
    title: type,
    label: '',
  })

  return flagMarker
}

export const plotMarkers = (data, map, maps) => {
  // console.log('plotMarkers')
  const setMarkerClicked = useHistoryTrailStore.getState().setMarkerClicked
  const geoJSON = createGeoJson(data)
  const filteredEvents = geoJSON.features
  const markers = []

  map.data.setMap(null)
  if (!filteredEvents || filteredEvents.length === 0) {
    return null
  }
  // var marker = null;
  const startPoint = new maps.LatLng(
    filteredEvents[0].geometry.coordinates[1],
    filteredEvents[0].geometry.coordinates[0],
  )
  markers.push(plotFlag(startPoint, 'start', map, maps))
  const endPoint = new maps.LatLng(
    filteredEvents[filteredEvents.length - 1].geometry.coordinates[1],
    filteredEvents[filteredEvents.length - 1].geometry.coordinates[0],
  )
  markers.push(plotFlag(endPoint, 'end', map, maps))

  map.data = new maps.Data({map: map})
  map.data.addGeoJson(geoJSON, {
    idPropertyName: 'idRaw_Data',
  })
  map.data.setStyle(feature => {
    const type = feature.getGeometry().getType()
    if (type === 'Polygon') {
      return {
        strokeColor: '#666666',
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: '#F6A930',
        fillOpacity: 0.6,
      }
    } else {
      return {
        icon: feature.getProperty('icon'),
      }
    }
  })

  map.data.setMap(map)

  // for (let i in data) {
  //   setVisible(map, data[i].idRaw_Data, true, 500)
  //   await sleep(200)
  // }

  map.data.addListener('click', function (e) {
    const featureID = e.feature.getProperty('idRaw_Data')
    setMarkerClicked(featureID)
  })
  return markers
}

export const getType = point => {
  var driverSafetyEventCodes = [26, 27, 28]
  const returnType = {}
  var validCoordinates = true
  var visible = true
  if (point.Longitude === 0 && point.Latitude === 0) {
    point.validCoordinates = true
    point.visible = true
  }
  returnType.validCoordinates = validCoordinates
  returnType.visible = visible

  //set color property that will be used by set style

  // var alertObject = point.Alerts
  var eventCode = point.idEvent_Code
  if (point.CameraMediaEventIds) {
    returnType.title = 'Camera'
  } else if (point.SensorEvent) {
    returnType.title = 'Sensors'
  } else if (
    driverSafetyEventCodes.find(code => code === eventCode) !== undefined
  ) {
    returnType.title = 'Driver Safety'
  } else if (point.In_Motion) {
    returnType.title = 'Motion'
  } else {
    returnType.title = 'Stop' // Green Arrow
  }
  return returnType
}

export const getIcon = function (maps, point) {
  // console.log('getIcon')
  var driverSafetyEventCodes = [26, 27, 28]
  //all this to set the icon
  if (!point.validCoordinates) {
    var invalidPoint = {
      //hide marker if invalid coordinates
      url: `${process.env.PUBLIC_URL}/images/history/invalid.png`,
      urlAnimate: `${process.env.PUBLIC_URL}/images/history/invalid.png`,
    }
    return invalidPoint
  }

  var icon
  const title = point.title
  const titleNoSpaces = title ? title.replace(' ', '') : ''

  if (!title || !titleNoSpaces) return {}

  /**
   * use png instead of svg path , so that the images dont have to render on to of the map
   * To improve the performance
   */

  if (
    point.In_Motion ||
    // point.idEvent_Code === 11 ||
    point.idEvent_Code === 26 ||
    point.idEvent_Code === 27 ||
    point.idEvent_Code === 28
  ) {
    const lastHeading =
      point.Last_Heading || point.Last_Heading === 0
        ? Math.round(point.Last_Heading / 10) * 10
        : null

    const urlBase = `${process.env.PUBLIC_URL}/images/history/`
    let filledArrow
    let urlAnimate
    let url
    let size = new maps.Size(22, 22)
    if (lastHeading === null) {
      size = new maps.Size(14, 14)
      urlAnimate = `${urlBase}motion-no-heading-animatedot.png`
      if (point?.Alerts) {
        url = `${urlBase}motion-no-heading-vermiliondot.png`
      } else if (point?.SensorEvent) {
        url = `${urlBase}motion-no-heading-denimdot.png`
      } else if (
        driverSafetyEventCodes.find(code => code === point.idEvent_Code)
      ) {
        url = `${urlBase}motion-no-heading-golddot.png`
      } else if (point.CameraMediaEventIds) {
        url = `${urlBase}motion-no-heading-purpledot.png`
      } else {
        url = `${urlBase}motion-no-heading-greendot.png`
      }
    } else {
      urlAnimate = `${urlBase}arrow_${lastHeading}deg_Animate.png`
      if (point?.Alerts) {
        url = `${urlBase}arrow_${lastHeading}deg_Alerts.png`
      } else if (point?.SensorEvent) {
        url = `${urlBase}arrow_${lastHeading}deg_Sensors.png`
      } else if (
        driverSafetyEventCodes.find(code => code === point.idEvent_Code)
      ) {
        url = `${urlBase}arrow_${lastHeading}deg_DriverSafety.png`
      } else if (point.CameraMediaEventIds) {
        url = `${urlBase}arrow_${lastHeading}deg_Camera.png`
      } else {
        url = `${urlBase}arrow_${lastHeading}deg_${titleNoSpaces}.png`
      }
    }
    filledArrow = {
      url: url,
      urlAnimate: urlAnimate,
      scaledSize: size,
      origin: new maps.Point(0, 0),
      anchor: new maps.Point(2, 12),
      animateSize: new maps.Size(45, 45),
    }

    icon = filledArrow
  } else {
    let stopSign
    if (point?.Alerts) {
      stopSign = {
        url: `${process.env.PUBLIC_URL}/images/history/stop_Alerts.png`,
        urlAnimate: `${process.env.PUBLIC_URL}/images/history/stop_Animate.png`,
        scaledSize: new maps.Size(14, 14),
        origin: new maps.Point(0, 0),
        anchor: new maps.Point(2, 12),
        animateSize: new maps.Size(45, 45),
      }
    } else if (point?.SensorEvent) {
      stopSign = {
        url: `${process.env.PUBLIC_URL}/images/history/stop_Sensors.png`,
        urlAnimate: `${process.env.PUBLIC_URL}/images/history/stop_Animate.png`,
        scaledSize: new maps.Size(14, 14),
        origin: new maps.Point(0, 0),
        anchor: new maps.Point(2, 12),
        animateSize: new maps.Size(45, 45),
      }
    } else if (
      driverSafetyEventCodes.find(code => code === point.idEvent_Code)
    ) {
      stopSign = {
        url: `${process.env.PUBLIC_URL}/images/history/stop_DriverSafety.png`,
        urlAnimate: `${process.env.PUBLIC_URL}/images/history/stop_Animate.png`,
        scaledSize: new maps.Size(14, 14),
        origin: new maps.Point(0, 0),
        anchor: new maps.Point(2, 12),
        animateSize: new maps.Size(45, 45),
      }
    } else if (point.CameraMediaEventIds) {
      stopSign = {
        url: `${process.env.PUBLIC_URL}/images/history/stop_Camera.png`,
        urlAnimate: `${process.env.PUBLIC_URL}/images/history/stop_Animate.png`,
        scaledSize: new maps.Size(14, 14),
        origin: new maps.Point(0, 0),
        anchor: new maps.Point(2, 12),
        animateSize: new maps.Size(45, 45),
      }
    } else {
      stopSign = {
        url: `${process.env.PUBLIC_URL}/images/history/stop_${titleNoSpaces}.png`,
        urlAnimate: `${process.env.PUBLIC_URL}/images/history/stop_Animate.png`,
        scaledSize: new maps.Size(14, 14),
        origin: new maps.Point(0, 0),
        anchor: new maps.Point(2, 12),
        animateSize: new maps.Size(45, 45),
      }
    }
    icon = stopSign
  }

  return icon
}
