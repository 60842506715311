import * as React from 'react'
import styled from 'styled-components/macro'
import FlyoutItem from './FlyoutItem'
import {LEFT_MENU_PADDING, LEFT_MENU_WIDTH_EXPANDED} from './constants'

type Item = {
  label: string
  navigateTo?: string
  onClick?: () => void
}

type Props = {
  items: Item[]
  visible: boolean
  menuTopPosition: number
  setFlyoutHovered: (hovered: boolean) => void
}

type ContainerProps = {
  visible: boolean
  top: number
}

const Container = styled.div`
  display: ${({visible}: ContainerProps) => (visible ? 'flex' : 'none')};
  position: fixed;
  flex-direction: column;
  left: ${LEFT_MENU_WIDTH_EXPANDED - LEFT_MENU_PADDING}px;
  top: ${({top}: ContainerProps) => top}px;
  width: 196px;
  background-color: #ffffff;
  border: solid 1px var(--asc-coolgray);
  border-radius: 6px;
  overflow: hidden;
`

const FlyoutMenuContainer = ({
  items,
  visible,
  menuTopPosition,
  setFlyoutHovered,
}: Props) => {
  const [isHovered, setIsHovered] = React.useState(false)
  React.useEffect(() => {
    setFlyoutHovered(isHovered)
  }, [isHovered, setFlyoutHovered])

  return (
    <Container
      visible={visible}
      top={menuTopPosition}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {items.map((item, index) => (
        <FlyoutItem
          key={typeof item.label === 'string' ? item.label : index}
          navigateTo={item.navigateTo}
          label={item.label}
          onClick={item.onClick}
        />
      ))}
    </Container>
  )
}
export default FlyoutMenuContainer
