import create from 'zustand'
import {Device} from 'common/types/opening1Response'
import {AlertEvents} from 'common/types/alertTypes'

const initialStore = {
  socketListening: false,
  pendingDeviceUpdates: [],
  pendingMapUpdates: [],
  pendingAlertUpdates: [],
}

export type State = {
  socketListening: boolean
  pendingDeviceUpdates: Device[]
  pendingMapUpdates: Device[]
  pendingAlertUpdates: AlertEvents[]
}

export const useWebsocketStore = create<State>(set => ({
  ...initialStore,
  setPendingDeviceUpdates: (pendingDeviceUpdates: Device[]) =>
    set(() => ({pendingDeviceUpdates})),
  setPendingMapUpdates: (pendingMapUpdates: Device[]) =>
    set(() => ({pendingMapUpdates})),
  setPendingAlertUpdates: (pendingAlertUpdates: AlertEvents[]) =>
    set(() => ({pendingAlertUpdates})),
  emptyStore: () => set(state => ({...state, ...initialStore})),
}))
