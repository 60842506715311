import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getAssets = async useDriverList => {
  if (useDriverList) {
    const {data} = await Axios.get('assets?a=getassetsfordriverassignment')
    return data
  } else {
    const {data} = await Axios.get('/assets?a=getassetsforhtmlforms')
    return data
  }
}

export default function useAssets({useDriverList} = {useDriverList: false}) {
  const user = useStore(state => state.user)
  return useQuery(['assetsForDropdown'], () => getAssets(useDriverList), {
    enabled: Boolean(user?.token),
    refetchOnWindowFocus: false,
  });
}
