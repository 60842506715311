import * as React from 'react'
import styled from 'styled-components/macro'
import {DONUT_COLORS} from 'common/GlobalStyles'
import {ResponsivePie} from '@nivo/pie'

const PieChartContent = styled.div`
  display: flex;
  align-items: center;
  height: 90px;
  width: 265px;
  margin-bottom: 7px;
  svg {
    width: 240px;
  }
`

const theme = {
  fontSize: 14,
}

const TablePieChart = ({chartData, onClick}) => {
  return (
    <PieChartContent>
      <ResponsivePie
        data={chartData}
        enableArcLinkLabels={false}
        margin={{top: 10, right: 150, bottom: 5, left: 10}}
        innerRadius={0.4}
        padAngle={0.1}
        isInteractive
        onClick={onClick}
        colors={[DONUT_COLORS.overdue, DONUT_COLORS.due, DONUT_COLORS.notdue]}
        borderWidth={0}
        borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor="#333333"
        radialLabelsLinkColor={{from: 'color'}}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor="#fff"
        animate={true}
        arcLinkLabelsColor={{
          from: 'color',
        }}
        arcLabelsThickness={3}
        arcLabelsTextColor={'#fff'}
        theme={theme}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            onClick: onClick,
            justify: false,
            translateX: 105,
            translateY: 0,
            itemsSpacing: 15,
            itemWidth: 105,
            itemHeight: 8,
            itemTextColor: '#333',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
    </PieChartContent>
  )
}
export default TablePieChart
