import Swal from 'sweetalert2'
import axios from 'axios'
import {showToast} from './components/Toastr'
import {QueryClient} from '@tanstack/react-query'

type Props = {
  deleteURL: string
  id: number | string
  idField: string
  cache: QueryClient
  reactQueryKey: string
  subKey?: string
  title?: string
}

export const deleteDialog = async ({
  deleteURL,
  id,
  idField,
  cache,
  reactQueryKey,
  subKey = '',
  title = '',
}: Props) => {
  try {
    const response = await axios.delete(deleteURL)
    if (!response) {
      throw new Error()
    } else {
      showToast(`Successfully deleted${title && ' ' + title}.`, 'success')
      if (cache) {
        //disable auto reset on table so deleting keeps current page when data changes
        // setSkipPageReset(true)
        cache.cancelQueries([reactQueryKey])
        cache.invalidateQueries([reactQueryKey])
        cache.setQueryData<Array<unknown>>([reactQueryKey], (old: any) => {
          const newItems: any[] = []
          if (old) {
            if (subKey && old[subKey]) {
              if (old[subKey] && old[subKey].length) {
                old[subKey].forEach((a: any) => {
                  if (a[idField] !== id) {
                    newItems.push(a)
                  }
                })
              }
            } else {
              old.forEach((a: any) => {
                if (a[idField] !== id) {
                  newItems.push(a)
                }
              })
            }
          } else {
            return []
          }
          if (subKey) {
            return {
              ...old,
              [subKey]: newItems,
            }
          } else {
            return newItems
          }
        })
        // setTimeout(() => {
        //   //reenable auto reset on table so filter works correctly
        //   setSkipPageReset(false)
        // }, 1000)
      }
    }
  } catch (error) {
    console.error(error)
    Swal.fire({
      icon: 'error',
      title: `Delete failed, please try again and contact support if you continue to have problems.`,
      text: `An unexpected error occurred.`,
    })
    return null
  }
}
