import create from 'zustand'

export interface TravelTimeObj {
  distance: number
  duration: string
}

type TravelTimeKey = string

export type TravelTimes = Record<TravelTimeKey, TravelTimeObj>

interface State {
  travelTimes: TravelTimes
  setTravelTimes: (newTravelTime: TravelTimes) => void
}

const initialStore = {
  travelTimes: {},
}

// eslint-disable-next-line import/no-unused-modules
export const useRouteDetailStore = create<State>(
  // devtools(set => ({
  set => ({
    ...initialStore,
    setTravelTimes: (newTravelTime: TravelTimes) => {
      return set(state => {
        const newTravelTimes = {...state.travelTimes, ...newTravelTime}
        return {
          ...state,
          travelTimes: newTravelTimes,
        }
      })
    },
    travelTimes: initialStore.travelTimes,
  }),
  // })),
)
