import React, {useState} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Error from 'common/Error'
import {
  User,
  Selected,
  RowType,
  Device,
  HotSwapDevice,
  DeviceType,
  Customer,
  Dealer,
} from '../types/types'
import {useStore} from 'common/useStore'

const NavTabBottomContainer = React.lazy(
  () => import('./NavTabBottomContainer'),
)

const DevicesBottomContainer = React.lazy(
  () => import('./TemedaAdmin/Devices/DevicesBottomContainer'),
)

const CustomersBottomContainer = React.lazy(
  () => import('./TemedaAdmin/Customers/CustomersBottomContainer'),
)

const UsersBottomContainer = React.lazy(
  () => import('./TemedaAdmin/Users/UsersBottomContainer'),
)

const ApiUsersBottomContainer = React.lazy(
  () => import('./TemedaAdmin/ApiUsers/ApiUsersBottomContainer'),
)

const DeviceHistoryTable = React.lazy(() => import('./DeviceHistoryTable'))

const UsersTable = React.lazy(() => import('./UsersTable'))
const CustomersTable = React.lazy(() => import('./CustomersTable'))
const DealersTable = React.lazy(() => import('./DealersTable'))
const DealerDevicesTable = React.lazy(() => import('./DealerDevicesTable'))

//const DeviceHistoryTable = lazy(() => import('./DeviceHistoryTable'))

const Icon = styled(FontAwesomeIcon)`
  font-size: calc(14 / 14 * 1rem);
  color: #333;
  margin: -0.1em 0.5em 0 0;
`

const StyledButton = styled.button`
  padding: 15px !important;
  cursor: pointer;
  border-radius: 3px;
  margin: 0 10px !important;
  background-color: #e0e1e2;
  border: none;
  &:hover {
    color: white;
    background-color: #43494e;
  }
  :focus {
    outline: none;
  }
`

const MainContainer = styled.div`
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  > div {
    flex: 1;
  }
`

const FULL_HEIGHT = 'calc(100vh - 213px)'
const HALF_HEIGHT = 'calc(50vh - 140px)'

type Props = {
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
    loading: boolean
    selected: Selected
    allUsers: User[]
    filteredUsers: User[]
    devices: Device[]
    hotSwapDevices: HotSwapDevice[]
    deviceTypes: DeviceType[]
    customers: Customer[]
    dealers: Dealer[]
    activeSubTab: string
    logoFile: string
    newLogoFile: string
    newLogoFileURL: string
    idBranding: number
    creds: {
      auth: string
      userName: string
      userCompany: string
      dateFormat: string
      timezoneName: string
    }
  }
  customerClicked: (row: RowType) => void
  dealerClicked: (row: RowType) => void
  clearSelected: () => void
}

const ManagementRoutes = (props: Props) => {
  const {filteredUsers, allUsers} = props.data

  const [_showUnlinkedApiUsers, setShowUnlinkedApiUsers] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userConfig = useStore((state: any) => state.userConfig)

  const UnassociatedUsersButton = () => (
    <StyledButton onClick={() => setShowUnlinkedApiUsers(true)}>
      <Icon icon="info-circle"></Icon> Unlinked Users
    </StyledButton>
  )

  return (
    <MainContainer>
      <Routes>
        <Route errorElement={<Error />}>
          <Route
            path={`users`}
            element={
              <UsersTable
                users={allUsers}
                selected={props.data.selected}
                height={FULL_HEIGHT}
                title="All Users"
              />
            }
          />

          <Route
            path={`customers/*`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />
                <NavTabBottomContainer
                  selected={props.data.selected}
                  users={filteredUsers}
                />
              </>
            }
          />

          <Route
            path={`dealers/*`}
            element={
              <>
                <DealersTable
                  selected={props.data.selected}
                  dealerClicked={props.dealerClicked}
                  height={HALF_HEIGHT}
                />
                <NavTabBottomContainer
                  selected={props.data.selected}
                  users={filteredUsers}
                />
              </>
            }
          />

          <Route path={`dealer-devices`} element={<DealerDevicesTable />} />

          <Route
            path={`support/notreporting`}
            element={<DeviceHistoryTable />}
          />

          <Route
            path={`support/devices`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />
                <DevicesBottomContainer
                  selected={props.data.selected}
                  timezoneName={userConfig?.TZ_Moment_Name}
                />
              </>
            }
          />

          <Route
            path={`support/customeradmin`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />

                <CustomersBottomContainer
                  selected={props.data.selected}
                  clearSelected={props.clearSelected}
                />
              </>
            }
          />

          <Route
            path={`support/useradmin`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />

                <UsersBottomContainer
                  users={filteredUsers}
                  selected={props.data.selected}
                />
              </>
            }
          />

          <Route
            path={`support/apiusers`}
            element={
              <>
                <CustomersTable
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  height={HALF_HEIGHT}
                />
                <UnassociatedUsersButton />

                <ApiUsersBottomContainer
                  selected={props.data.selected}
                  customerClicked={props.customerClicked}
                  isUnlinkedApiUsersDisplayed={_showUnlinkedApiUsers}
                  onHideUnlinkedApiUsers={() => setShowUnlinkedApiUsers(false)}
                />
              </>
            }
          />

          <Route
            path={`support`}
            element={<Navigate to={`/management/support/notreporting`} />}
          />
          <Route path={``} element={<Navigate to={`users`} />} />
        </Route>
      </Routes>
    </MainContainer>
  )
}

export default ManagementRoutes
