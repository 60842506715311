import * as React from 'react'
import CommandConsole from 'images/CommandConsole.svg'
import {
  MapPin,
  SquaresFour,
  FileText,
  Warning,
  Wrench,
  Gear,
  Faders,
  ClipboardText,
  Headset,
} from 'phosphor-react'
import {cloneDeep} from 'lodash'
import {ORoleType, useStore} from 'common/useStore'
import {startSocket} from './webSockets/conversationsSockets'

const initialMenu = [
  {
    name: 'map',
    text: 'Map',
    link: '/map',
    icon: <MapPin color={'var(--primary)'} weight={'fill'} size={16} />,
  },
  {
    name: 'dashboards',
    icon: <SquaresFour color={'var(--primary)'} weight={'fill'} size={16} />,
    text: 'Dashboards',
    open: false,
    subMenu: [
      {
        text: 'Fleet Overview',
        link: '/dashboards/fleet-overview',
        restrictedAdminText: 'restricted',
        name: 'fleet overview',
      },
      {
        text: 'Maintenance',
        link: '/dashboards/maintenance',
        restrictedAdminText: 'restricted',
        name: 'maintenance',
      },
      {
        text: 'User Activity',
        link: '/dashboards/user-activity',
        restrictedAdminText: 'restricted',
        name: 'user activity',
      },
      {
        text: 'Driver Summary',
        link: '/dashboards/driver-summary',
        restrictedAdminText: 'restricted',
        name: 'driver summary',
      },
      {
        text: 'Driver Safety',
        link: '/dashboards/driver-safety',
        restrictedAdminText: 'restricted',
        name: 'driver safety',
      },
      {
        text: 'Asset Dashboard',
        link: '/asset-dashboard',
        newWindow: true,
        name: 'asset dahsboard',
      },
      {
        text: 'Weekly Snapshot',
        link: '/dashboards/weekly-snapshot',
        restrictedAdminText: 'restricted',
        name: 'weekly snapshot',
      },
      // {
      //   text: 'Annual Summary',
      //   link: '/dashboards/annual-summary',
      // },
    ],
  },
  {
    name: 'reports',
    icon: <FileText color={'var(--primary)'} weight={'fill'} size={16} />,
    text: 'Reports',
    open: false,
    subMenu: [
      {
        text: 'Run a report',
        link: '/reports/run',
        name: 'run a report',
      },
      // {
      //   text: 'Schedule a report',
      //   link: '/reports/schedule',
      // },
      {
        text: 'View Schedules',
        link: '/reports/saved',
        name: 'view schedules',
      },
    ],
  },
  {
    name: 'alerts',
    icon: <Warning color={'var(--primary)'} weight={'fill'} size={16} />,
    text: 'Alerts',
    open: false,
    subMenu: [
      {
        text: 'Manage Alerts/Rules',
        name: 'manage alerts/rules',
        restrictedAdminText: 'manage-alerts',
        link: '/alerts',
      },
      {
        text: 'View Alert Events',
        link: '/alertevents',
        name: 'alert events',
      },
    ],
  },
  {
    name: 'maintenance',
    icon: <Wrench color={'var(--primary)'} weight={'fill'} size={16} />,
    text: 'Maintenance',
    open: false,
    subMenu: [
      {
        text: 'Status',
        link: '/maintenance/status',
        name: 'status',
      },
      {
        text: 'Schedules',
        link: '/maintenance/schedules',
        name: 'schedules',
      },
      {
        text: 'Plans',
        link: '/maintenance/plans',
        name: 'plans',
      },
      {
        text: 'Tasks',
        link: '/maintenance/tasks',
        name: 'tasks',
      },
      {
        text: 'Logs',
        link: '/maintenance/logs',
        name: 'logs',
      },
      {
        text: 'Inspections',
        link: '/maintenance/inspections',
        name: 'inspections',
      },
      {
        text: 'Inspection Types',
        link: '/maintenance/inspection-types',
        name: 'inspection types',
      },
      {
        text: 'DTCs',
        link: '/maintenance/dtc',
        name: 'dtc',
      },
      {
        text: 'Help',
        link: '/maintenance/help',
        name: 'help',
      },
    ],
  },
  {
    name: 'admin',
    icon: <Gear color={'var(--primary)'} weight={'fill'} size={16} />,
    text: 'Admin',
    open: false,
    subMenu: [
      {
        text: 'Assets',
        link: '/assets',
        restrictedAdminText: 'assets',
        name: 'assets',
      },
      {
        text: 'Camera Events',
        link: '/cameraevents',
        restrictedAdminText: 'cameras',
        name: 'cameras',
      },
      {
        text: 'Drivers',
        link: '/drivers',
        restrictedAdminText: 'drivers',
        name: 'drivers',
      },
      {
        text: 'Fuel Cards',
        link: '/fuel-cards/list',
        restrictedAdminText: 'fuelCards',
        name: 'fuel cards',
      },
      {
        text: 'Groups',
        link: '/groups',
        restrictedAdminText: 'groups',
        name: 'groups',
      },
      {
        text: 'Locations',
        link: '/locations',
        restrictedAdminText: 'locations',
        name: 'locations',
      },
      {
        text: 'Projects',
        link: '/projects',
        restrictedAdminText: 'projects',
        name: 'projects',
      },
      {
        text: 'Sensors',
        link: '/sensors',
        restrictedAdminText: 'sensors',
        name: 'sensors',
      },
      {
        text: 'Unassigned Devices',
        link: '/devices',
        restrictedAdminText: 'unassignedDevices',
        name: 'unassigned devices',
      },
      {
        text: 'Users',
        link: '/users',
        restrictedAdminText: 'usersAreNotAllowedToRestrictedAdmins',
        name: 'users',
      },
      {
        text: 'Zones',
        link: '/zones',
        restrictedAdminText: 'zones',
        name: 'zones',
      },
    ],
  },
  {
    name: 'account',
    icon: <Faders color={'var(--primary)'} weight={'bold'} size={16} />,
    text: 'Account',
    open: false,
    subMenu: [
      {
        text: 'Hierarchy',
        link: '/hierarchy',
        name: 'hierarchy',
      },
      {
        text: 'Contacts',
        link: '/contacts',
        restrictedAdminText: 'account-notifications',
        name: 'contacts',
      },
      {
        text: 'Integrations',
        link: '/integrations',
        restrictedAdminText: 'account-integrations',
        name: 'integrations',
      },
      {
        text: 'Customize Views',
        link: '/customizeviews',
        name: 'customize views',
      },
    ],
  },
]
const eldMenu = {
  name: 'eld',
  icon: <ClipboardText color={'var(--primary)'} weight={'fill'} size={16} />,
  text: 'ELD',
  link: '',
  newWindow: true,
  external: true,
}

const routingAndDispatchMenu = {
  name: 'routing',
  icon: <Headset color={'var(--primary)'} weight={'fill'} size={16} />,
  text: 'Route Optimization',
  open: false,
  subMenu: [
    {
      text: 'Setup',
      link: '/routing/setup',
      name: 'setup',
    },
    {
      text: 'Orders',
      link: '/routing/orders',
      name: 'orders',
    },
    {
      text: 'Routes',
      link: '/routing/routes',
      name: 'routes',
    },
    {
      text: 'Messages',
      link: '/routing/messages',
      name: 'messages',
    },
  ],
}

const commandConsoleMenu = {
  name: 'commandConsole',
  icon: <img src={CommandConsole} height={18} width={18} />,
  text: 'Command Console',
  open: false,
  subMenu: [
    {
      text: 'Pump KPI Dashboard',
      link: '/command-console/pumps',
      newWindow: true,
      name: 'pumps',
    },
    {
      text: 'Jobs & Crews',
      link: '/command-console/yomcom',
      newWindow: true,
      name: 'yomcom',
    },
  ],
}

export const getMenuItems = (userConfig, dispatch, cache) => {
  let newMenuItems = cloneDeep(initialMenu)
  if (
    userConfig?.UserRole?.idUserRole !== 1 &&
    userConfig?.UserRole?.idUserRole !== 11
  ) {
    //remove admin-only menu items
    newMenuItems = newMenuItems.filter(
      item =>
        item.name !== 'admin' &&
        item.name !== 'account' &&
        item.name !== 'dashboards',
    )

    //remove manage alerts submenu
    const alertsIndex = newMenuItems.findIndex(item => item.name === 'alerts')
    newMenuItems[alertsIndex] = {
      ...newMenuItems[alertsIndex],
      subMenu: newMenuItems[alertsIndex].subMenu.filter(subMenu => {
        return subMenu.name !== 'manage alerts/rules'
      }),
    }
  }

  //Reset in case changing customers
  useStore.setState({
    isROCustomer: false,
  })

  userConfig?.CustomerConfigurations?.forEach(config => {
    if (
      config.ConfigGroup === 'dashboardmenu' &&
      config.ConfigContext === 'halliburton' &&
      initialMenu[0].name !== 'commandConsole'
    ) {
      newMenuItems = [commandConsoleMenu, ...newMenuItems]
    } else if (
      config.ConfigGroup === 'custommenu'
      // newMenuItems[newMenuItems.length - 1]?.name !== 'eld'
    ) {
      const menus = JSON.parse(config.ConfigSettings) || null
      if (
        menus &&
        menus.maintitle === 'TotalELD' &&
        userConfig.UserRole.idUserRole === ORoleType.Administrator
      ) {
        eldMenu.link = menus.mainlink
        newMenuItems = [...newMenuItems, eldMenu]
      }
      if (
        menus &&
        menus.maintitle === 'Tranztec' &&
        (userConfig.UserRole.idUserRole === ORoleType.Administrator ||
          (userConfig.UserRole.idUserRole ===
            ORoleType.RestrictedAdministrator &&
            userConfig?.RestrictedAdminAccess?.includes(
              'routing-and-dispatch',
            )))
      ) {
        //This is an RO customer
        useStore.setState({
          isROCustomer: true,
        })
        if (
          !newMenuItems.find(item => item.name === routingAndDispatchMenu.name)
        ) {
          newMenuItems
            .find(item => item.name === 'dashboards')
            .subMenu.splice(5, 0, {
              text: 'Route Optimization',
              link: '/dashboards/route-dispatch',
              name: 'route optimization dashboard',
            })
          newMenuItems.splice(1, 0, routingAndDispatchMenu)
          startSocket(cache)
        }
      }
    } else if (config.ConfigGroup === 'disabledfeatures') {
      const disabledFeatures = config.ConfigSettings.split(',')
      disabledFeatures.forEach(feature => {
        newMenuItems.map(item => {
          if (
            item.name === feature ||
            (item.name === 'dashboard' && feature === 'dashboards')
          ) {
            item.disabled = true
          }
          return item
        })
      })
    }
  })
  if (userConfig?.UserRole?.idUserRole === ORoleType.RestrictedAdministrator) {
    const splitAdminOptions = userConfig.RestrictedAdminAccess
      ? userConfig.RestrictedAdminAccess.split(',')
      : []
    const newAdminOptions = []
    const adminIndex = newMenuItems.findIndex(i => i.name === 'admin')
    if (adminIndex > -1) {
      newMenuItems[adminIndex].subMenu.forEach(item => {
        if (
          splitAdminOptions.find(
            opt => opt.toLowerCase() === item.restrictedAdminText.toLowerCase(),
          )
        ) {
          newAdminOptions.push(item)
        }
      })
      if (newAdminOptions.length) {
        newMenuItems[adminIndex].subMenu = newAdminOptions
      } else {
        newMenuItems.splice(adminIndex, 1)
      }
    }
    const accountIndex = newMenuItems.findIndex(i => i.name === 'account')
    const newAccountOptions = []
    if (accountIndex > -1) {
      newMenuItems[accountIndex].subMenu.forEach(item => {
        if (
          item.name === 'hierarchy' ||
          item.name === 'customize views' ||
          splitAdminOptions.find(
            opt => opt === item.restrictedAdminText.toLowerCase(),
          )
        ) {
          newAccountOptions.push(item)
        }
      })
      if (newAccountOptions.length) {
        newMenuItems[accountIndex].subMenu = newAccountOptions
      } else {
        newMenuItems.splice(accountIndex, 1)
      }
    }

    const dashboardIndex = newMenuItems.findIndex(i => i.name === 'dashboards')
    const newDashboardOptions = []
    if (dashboardIndex > -1) {
      newMenuItems[dashboardIndex].subMenu.forEach(item => {
        if (
          !item.restrictedAdminText ||
          splitAdminOptions.find(
            opt => opt === item.restrictedAdminText?.toLowerCase() || '',
          )
        ) {
          newDashboardOptions.push(item)
        }
      })
      if (newDashboardOptions.length) {
        newMenuItems[dashboardIndex].subMenu = newDashboardOptions
      } else {
        newMenuItems.splice(dashboardIndex, 1)
      }
    }
  }
  return newMenuItems
}

export const setAndOpenMenuItem = (name, dispatch, menuItems) => {
  dispatch({type: 'setState', data: {activeTab: name}})
  const newMenuItems = menuItems.map(item => {
    if (item.name === name) {
      if (item.subMenu) {
        item.open = true
      }
      item.isActive = true
    } else {
      item.isActive = false
    }
    return item
  })
  dispatch({type: 'setState', data: {menuItems: newMenuItems}})
}

export const initialState = {
  menuItems: initialMenu,
  collapse: false,
  activeTab: '',
  logoSource: '',
  showNav: false,
  subMenuOpen: {
    isOpen: false,
    name: '',
  },
  menuSet: false,
  showConversationToast: false,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setState': {
      return {
        ...state,
        ...action.data,
      }
    }
    default:
      break
  }
}
