import * as React from 'react'
import styled from 'styled-components/macro'
import {Link} from 'react-router-dom'
// import Button from 'common/components/Button'
// import ButtonDropDown from 'common/components/DropDownButton/ButtonDropDown'
// import DateRangePicker from 'common/components/DateRangePicker'
import {Button, DateRangePicker} from 'common/components'

import {Plus} from 'phosphor-react'
import {QUERIES} from 'common/constants'
import TableExportButton from './TableExportButton'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  @media ${QUERIES.tabletAndUp} {
    margin-right: 10px;
  }
`

const DateRangeButton = styled.div`
  display: block;
`

export const StyledButton = styled(Button)`
  text-transform: uppercase;
  background: var(--secondary);
  color: var(--primary);
  font-weight: bold;
  font-size: 13px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @media ${QUERIES.tabletAndUp} {
    padding: 10px 15px;
  }
  :hover {
    color: var(--secondary);
    background: var(--primary);
  }
  svg {
    min-width: 16px;
    min-height: 16px;
  }
`

const MobilePlus = styled(Plus)`
  @media ${QUERIES.tabletAndUp} {
    display: none;
  }
`

const ButtonText = styled.span`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: block;
  }
`

const TableButtons = ({
  exportData,
  newButton,
  hiddenNewButton,
  hiddenExportButton,
  dateRange,
  hiddenGlobalFilter = false,
  // isMobile,
}) => {
  return (
    <Container>
      {dateRange && (
        <DateRangeButton>
          <DateRangePicker {...dateRange} direction="right" />
        </DateRangeButton>
      )}

      {!hiddenExportButton && (
        <TableExportButton
          hiddenGlobalFilter={hiddenGlobalFilter}
          exportData={exportData}
        />
      )}
      {!hiddenNewButton && newButton.url && (
        <Link
          to={newButton.url}
          state={{prevPath: location.pathname, ...newButton.state}}
        >
          <StyledButton>
            <MobilePlus weight="bold" size={16} />
            <ButtonText>{newButton.text}</ButtonText>
          </StyledButton>
        </Link>
      )}
      {!hiddenNewButton && newButton.fn && (
        <StyledButton onClick={newButton.fn}>{newButton.text}</StyledButton>
      )}
    </Container>
  )
}
export default TableButtons
