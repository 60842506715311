//location default limit size for a Location in square miles
export const maxSquareMiles = 25
export const defaultRadius = 137
var componentForm = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'short_name',
  administrative_area_level_1: 'short_name',
  country: 'short_name',
  postal_code: 'short_name',
}
export function addPolygonListener(shape, maps, map, dispatch) {
  //check changes before handling listener
  polygonChanged(shape, maps, map, dispatch)
  // maps.event.addListener(shape, 'mouseup', function () {
  //listen to each point of the polygon
  const path = shape.getPath()
  maps.event.addListener(path, 'set_at', () => {
    polygonChanged(shape, maps, map, dispatch)
  })
  maps.event.addListener(path, 'insert_at', () => {
    polygonChanged(shape, maps, map, dispatch)
  })
  maps.event.addListener(path, 'remove_at', () => {
    polygonChanged(shape, maps, map, dispatch)
  })
  maps.event.addListener(path, 'drag', () => {
    polygonChanged(shape, maps, map, dispatch)
  })
  // })
}
// for limiting circle size by passing mile radius value to the function
export function convertToAreaCircle(radius) {
  return Number(Math.PI * radius * radius).toFixed(2)
}
// for limiting polygon size by passing mile radius value to the function
export function convertToAreaPolygon(shape, maps) {
  return Number(
    (
      maps.geometry?.spherical.computeArea(shape.getPath()) * 0.000000386102159
    ).toFixed(2),
  )
}
export const alertOversizeLandmark = (area, dispatch) => {
  const isZone = location.pathname.match(/zone/gi)
  if (area > maxSquareMiles && !isZone) {
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Oversize location',
    //   text:
    //     'A Location cannot be greater than ' +
    //     maxSquareMiles +
    //     ' square miles. Your polygon is currently ' +
    //     area +
    //     ' square miles.',
    // })
    dispatch({
      type: 'setShowErrorDialog',
      data: {show: true, currentPolygon: area},
    })
    dispatch({
      type: 'error',
      data: true,
    })
    return true
  } else {
    dispatch({
      type: 'error',
      data: false,
    })
    return false
  }
}
/**
 *
 * @param {polygon object} shape
 * @param {* googlemaps api} maps
 * @param {* current map} map
 * @param {* reducer to dispath data back to the object} dispatch
 */
function polygonChanged(shape, maps, map, dispatch) {
  const landmarkPolygon = {bounds: [], submitBounds: []}
  const newpolygonArray = shape.getPath().getArray()
  //var area = (google.maps.geometry.spherical.computeArea(shape.getPath()) / 2589988.11); //0.000 000 386 102 159
  var area = convertToAreaPolygon(shape, maps)
  const error = alertOversizeLandmark(area, dispatch)
  newpolygonArray.forEach(function (e) {
    landmarkPolygon.bounds.push({
      lng: e.lng(),
      lat: e.lat(),
    })
    landmarkPolygon.submitBounds.push(e.lng() + ' ' + e.lat())
  })
  getPolygonLocation(landmarkPolygon, maps, map, dispatch, error)
}
/**
 *
 * @param {polygon object to submit} landmarkPolygon
 * @param {* googlemaps api} maps
 * @param {* current map} map
 * @param {* reducer to dispath data back to the object} dispatch
 */
function getPolygonLocation(landmarkPolygon, maps, map, dispatch, error) {
  landmarkPolygon.boundsExtend = []
  landmarkPolygon.bounds.forEach(function (item) {
    landmarkPolygon.boundsExtend.push(
      new maps.LatLng(parseFloat(item['lat']), parseFloat(item['lng'])),
    )
  })
  //find center of the polygon to find the address
  var bounds = new maps.LatLngBounds()
  landmarkPolygon.boundsExtend.forEach(function (points) {
    bounds.extend(points)
  })
  landmarkPolygon.center = bounds?.getCenter()
  landmarkPolygon.center && map.panTo(landmarkPolygon.center)

  const latlngsubmit =
    landmarkPolygon.bounds[0]?.lng + ' ' + landmarkPolygon.bounds[0]?.lat
  const Location_Text =
    'POLYGON ((' +
    landmarkPolygon.submitBounds.join(', ') +
    ', ' +
    latlngsubmit +
    '))'

  dispatch({
    type: 'setShape',
    data: {
      Location_Text: Location_Text,
      LandmarkCenter: landmarkPolygon.center,
      Landmark_Type: 'Polygon',
      error,
    },
  })
}

export const changeAddressObjectToSTAGEFormat = address => {
  // const
  let streetAddress
  //check if the location object consist of address number and street name put both to the input
  //otherwise just give the street number for the location address line 1
  if (address.street_number && address.route) {
    //get the full address
    streetAddress = address.street_number + ' ' + address.route
  } else if (address.street_number && !address.route) {
    //only street number
    streetAddress = address.street_number
  } else if (!address.street_number && address.route) {
    //only route address
    streetAddress = address.route
  }
  //apply all the address fields
  const Landmark_Address_Line1 = streetAddress ? streetAddress : ''
  const Landmark_Region = address.administrative_area_level_1
    ? address.administrative_area_level_1
    : null
  const Landmark_City = address.locality ? address.locality : null
  const Landmark_Country = address.country ? address.country : 'US'
  const Landmark_Postal_Code = address.postal_code ? address.postal_code : ''
  return {
    Landmark_Address_Line1,
    Landmark_Region,
    Landmark_City,
    Landmark_Country,
    Landmark_Postal_Code,
  }
}
/**
 * parsing latln to adress
 * @param {lat long of the polygon or circle} latlng
 * @param {* googlemaps api} maps
 */
export function parseAddress(latlng, maps, callBack) {
  if (maps.Geocoder) {
    var geocoder = new maps.Geocoder()
    geocoder.geocode(
      {
        location: latlng,
      },
      function (results, status) {
        if (status === maps.GeocoderStatus.OK) {
          if (results[0]) {
            const addressObject = createAddressObject(results[0])
            const formattedAddressObject =
              changeAddressObjectToSTAGEFormat(addressObject)
            return callBack(formattedAddressObject)
          }
        } else {
          return callBack({Landmark_Address_Line1: ''})
        }
      },
    )
  }
  return callBack({Landmark_Address_Line1: ''})
}

export const asyncParseAddress = async (latlng, maps, callBack) => {
  if (!latlng) return
  let returnObject = {Landmark_Address_Line1: ''}
  if (maps.Geocoder) {
    var geocoder = new maps.Geocoder()

    await geocoder.geocode(
      {
        location: latlng,
      },
      function (results, status) {
        if (status === maps.GeocoderStatus.OK) {
          if (results[0]) {
            const addressObject = createAddressObject(results[0])
            const formattedAddressObject =
              changeAddressObjectToSTAGEFormat(addressObject)
            returnObject = formattedAddressObject
            return callBack(formattedAddressObject)
          }
        }
      },
    )
  }
  return callBack(returnObject)
}
/*
  automatically fill the address in location detail  and saved object location information  for the location modal.
  */

export const createAddressObject = place => {
  var obj = {}

  if (place.address_components) {
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (componentForm[addressType]) {
        //collect all the address object from reverse geocode
        var val = place.address_components[i][componentForm[addressType]]
        obj[addressType] = val
      }
    }
  }
  //assign the full address to the location object
  return obj
}
