import styled from 'styled-components/macro'

export const StyledLabel = styled.label<{noMargin?: string}>`
  font-family: 'Open Sans';
  margin-bottom: ${p => (p.noMargin ? '0px' : '5px')};
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  display: flex;
`
export const Page = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`
export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  position: relative;
`
export const VideoCameraLink = styled.div`
  display: flex;
  flex-direction: row;
  background: transparent;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  svg {
    cursor: pointer;
    :hover {
      fill: var(--secondary) !important;
    }
  }
`
//do not add to this code, please extend this component to add custom styling
export const EllipsisText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.15;
`

//do not add to this code, please extend this component to add custom styling
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
//do not add to this code, please extend this component to add custom styling
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoPill = styled.div<{
  infoPillBackgroundColor: string
  svgMinWidth?: string
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 21px;

  background: ${props => props.infoPillBackgroundColor || ''};
  padding-right: 10px;
  height: 22px;
  min-width: ${props => props.svgMinWidth || '0px'};
  width: auto;

  svg {
    height: 12px !important;
    min-width: 12px !important;
  }
`

export const InfoText = styled.div`
  color: var(--asc-black);
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`

export const InfoPillContainer = styled.div`
  display: flex;
  width: fit-content;
`

export const IconContainer = styled.div`
  display: flex;
  margin-left: 5px;
`
