import create from 'zustand'
import {ColumnFilterType} from './gridTypes'

type State = {
  globalFilter: string
  setGlobalFilter: (globalFilter: string) => void
  columnFilters: ColumnFilterType[]
  setColumnFilter: ({name, value}: {name: string; value: string}) => void
  clearAllFilters: () => void
}

const initialStore = {
  globalFilter: '',
  columnFilters: [],
}

const updateColumnFilters = ({
  columnFilters,
  name,
  value,
}: {
  columnFilters: ColumnFilterType[]
  name: string
  value: string
}) => {
  const newColumnFilters: ColumnFilterType[] = columnFilters.map(c => {
    if (c.name === name) c.value = value
    return c
  })
  return newColumnFilters
}

export const useGridFilterStore = create<State>((set, get) => ({
  globalFilter: initialStore.globalFilter,
  setGlobalFilter: globalFilter => set({globalFilter}),
  columnFilters: initialStore.columnFilters,
  // getColumnFilter: ({name}: {name: string}) => get().columnFilters[name],
  setColumnFilter: ({name, value}) => {
    const columnFilters = get().columnFilters
    const newColumnFilters = updateColumnFilters({columnFilters, name, value})
    set({columnFilters: newColumnFilters})
  },
  clearAllFilters: () =>
    set(state => {
      return {...state, globalFilter: '', columnFilters: []}
    }),
}))
