import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

export interface LandmarkType {
  idLandmark: number
  Landmark_Name: string
  Landmark_Label?: string
  Landmark_Category: number
  Landmark_House_Nbr?: string
  Landmark_Address_Line1?: string
  Landmark_City?: string
  Landmark_Region?: string
  Landmark_Country?: string
  Landmark_Type: string
  Landmark_Style?: string
  IsActive: boolean
  Landmark_UUID?: string
  ContactName?: string
  CustomerPhoneNumber?: string
  ContactEmail?: string
  Latitude?: string
  Longitude?: string
}

const getLocations = async () => {
  const {data}: {data: LandmarkType[]} = await Axios.get(
    `/landmarks/?a=getlandmarksforhtmlformsbycategoryincludenotactive&category=1`,
    // `/landmarks/?a=getlandmarksforhtmlformsbycategoryincludenotactive&category=1&filter=${filter}`,
  )
  data.sort((a, b) => {
    const x = a.Landmark_Name.toUpperCase()
    const y = b.Landmark_Name.toUpperCase()
    return x < y ? -1 : x > y ? 1 : 0
  })
  return data
}

const useLocations = () => {
  const user = useStore(state => state.user)
  return useQuery(['locations'], () => getLocations(), {
    enabled: Boolean(user?.token),
  })
}

export default useLocations
