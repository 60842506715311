import {Option} from 'common/components/InputDropdownOptional'
import {setHours, setMilliseconds, setMinutes} from 'date-fns'
import {LandmarkType} from 'locations/hooks/useLocations'
import {Range} from 'react-date-range'
import {ShiftTimePickerType} from 'routingAndDispatch/Setup/routeSettings/routeSettingsHelper'

type ErrorType = {type?: string; message: string}

export type State = {
  isDirty: boolean
  title: string
  serviceType: string
  serviceDuration: number
  estimatedDuration: {hrs: number; mins: number}
  description: string
  availableServiceTypes: Option[]
  dateRange: Range
  startTime: undefined | ShiftTimePickerType
  contactName: string
  locationName: string
  email: string
  phoneNumber: string
  currentAddressEntryType: 'location' | 'address' | 'coordinates'
  saveLocation: boolean
  errors: Record<string, ErrorType>
  formattedAddress: string
  address: any //need to type address object from google
  location: {lat: number; lng: number} | undefined
  city: string
  state: string
  zipCode: string
  selectedLocation: LandmarkType | null
  locationIsDirty: boolean
}

const tomorrow = new Date()
const startDate = setHours(setMinutes(setMilliseconds(tomorrow, 0), 0), 8)
const endDate = setHours(setMinutes(setMilliseconds(tomorrow, 0), 0), 17)

export const initialState: State = {
  isDirty: false,
  title: '',
  serviceType: '',
  description: '',
  availableServiceTypes: [],
  serviceDuration: 15,
  estimatedDuration: {hrs: 0, mins: 30},
  startTime: {
    ampm: 'AM',
    hour: 8,
    minute: 0,
  },
  errors: {},
  currentAddressEntryType: 'location',
  dateRange: {
    startDate,
    endDate,
  },
  contactName: '',
  locationName: '',
  email: '',
  phoneNumber: '',
  address: '',
  formattedAddress: '',
  location: undefined,
  city: '',
  state: '',
  zipCode: '',
  selectedLocation: null,
  saveLocation: false,
  locationIsDirty: false,
}

export type ActionTypes = {
  type:
    | 'setState'
    | 'setDurationState'
    | 'resetErrors'
    | 'updateAddress'
    | 'setErrors'
  data: Record<string, any>
}

export const reducer = (state: State, action: ActionTypes) => {
  switch (action.type) {
    case 'setState':
      return {...state, ...action.data}
    case 'updateAddress': {
      const address = action.data.address
      const newLocation = {
        lat: address.Latitude,
        lng: address.Longitude,
      }
      const updatedErrors = state.errors
      const formattedAddress = `${address.Landmark_Address_Line1} ${
        address.Landmark_City ? address.Landmark_City + ',' : ''
      } ${address.Landmark_Region}`
      if (updatedErrors['formattedAddress']?.message)
        updatedErrors['formattedAddress'].message = ''
      return {
        ...state,
        ...action.data,
        selectedLocation: null,
        location: newLocation,
        errors: updatedErrors,
        address,
        formattedAddress,
      }
    }
    case 'setDurationState': {
      const {type, value} = action.data || {type: 'hours', value: 0}

      const newDurationState =
        type === 'hours'
          ? {hrs: value, mins: state.estimatedDuration.mins}
          : {hrs: state.estimatedDuration.hrs, mins: value}

      const estimatedDurationInMinutes =
        type === 'hours'
          ? value * 60 + state.estimatedDuration.mins
          : state.estimatedDuration.hrs * 60 + value

      return {
        ...state,
        estimatedDuration: newDurationState,
        serviceDuration: estimatedDurationInMinutes,
        isDirty: true,
      }
    }
    case 'setErrors': {
      const errors = {
        formattedAddress: {message: ''},
        serviceWindow: {message: ''},
        latitude: {message: ''},
        longitude: {message: ''},
        title: {message: ''},
      }
      //Empty required fields
      if (
        action.data.formattedAddress === '' &&
        (state.currentAddressEntryType === 'address' ||
          state.currentAddressEntryType === 'location')
      ) {
        errors['formattedAddress'].message = 'Missing Address'
      }
      if (action.data.idSegment && !action.data.title) {
        errors['title'].message = 'Missing Title'
      }
      if (state.currentAddressEntryType === 'coordinates') {
        if (!action.data.latitude || action.data.latitude === 0) {
          errors['latitude'].message = `Missing Latitude`
        }
        if (!action.data.longitude || action.data.longitude === 0) {
          errors['longitude'].message = `Missing Longitude`
        }
        if (action.data.latitude < -85 || action.data.latitude > 85) {
          errors['latitude'].message = `Invalid Latitude`
        }
        if (action.data.longitude < -180 || action.data.longitude > 180) {
          errors['longitude'].message = `Invalid Longitude`
        }
      }

      if (!action.data.serviceWindow) {
        errors['serviceWindow'].message = 'Invalid Start Time'
      }

      return {
        ...state,
        errors,
      }
    }
    case 'resetErrors': {
      const errors = state.errors
      //Empty required fields
      if (action.data.formattedAddress) {
        errors['formattedAddress'].message = ''
      }
      if (action.data.scheduledDate) {
        errors['scheduledDate'].message = ''
      }
      if (action.data.contactName) {
        errors['contactName'].message = ''
      }
      if (action.data.phone) {
        errors['phone'].message = ''
      }
      if (action.data.email) {
        errors['email'].message = ''
      }
      if (action.data.serviceWindow) {
        errors['serviceWindow'].message = ''
      }
      if (action.data.latitude) {
        errors['latitude'].message = ''
      }
      if (action.data.longitude) {
        errors['longitude'].message = ''
      }
      if (action.data.title) {
        errors['title'].message = ''
      }
      return {
        ...state,
        errors,
      }
    }
    default:
      return state
  }
}
