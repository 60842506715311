import {useStore} from 'common/useStore'
import {cloneDeep, sortBy} from 'lodash'
import {useMapStore} from 'map/useMapStore'
const setDirections = useMapStore.getState().setDirections
const directions = useMapStore.getState().directions
const removeDirections = useMapStore.getState().removeDirections
export const applyDistancesToAssets = ({filteredAssets, nearByDistances}) => {
  const newAssets = cloneDeep(filteredAssets)
  newAssets.map(asset => {
    const foundIndex = nearByDistances.findIndex(
      d => d.idAsset === asset.idAsset,
    )
    if (foundIndex > -1) {
      asset.FindNearestAssetDistance = nearByDistances[foundIndex].distance
    }
    return asset
  })
  return newAssets
}
const convertMetersToUsersDistance = function (numbers, des, userConfig) {
  let conversionPercent = 0.000621371192 //default to miles
  if (userConfig.Distance_UOM && userConfig.Distance_UOM === 'km') {
    conversionPercent = 0.001
  }
  if (des) {
    return parseFloat((numbers * conversionPercent).toFixed(des))
  } else {
    return Math.round(numbers * conversionPercent)
  }
}
const getAssetDistances = (latlng, bounds, assets) => {
  const distances = []
  let destlatlong = null
  const userConfig = useStore.getState().userConfig
  let distance
  if (assets?.length) {
    bounds.extend(latlng)
    assets.forEach(function (asset) {
      distance = null
      if (
        asset &&
        asset.Device &&
        asset.Device.Latitude &&
        asset.Device.Longitude &&
        !asset.Primary_idAsset
      ) {
        destlatlong = new window.google.maps.LatLng(
          asset.Device.Latitude,
          asset.Device.Longitude,
        )
        distance = window.google.maps.geometry.spherical.computeDistanceBetween(
          destlatlong,
          latlng,
        )
      }
      if (distance !== null && distance !== undefined && !isNaN(distance)) {
        distances.push({
          idAsset: asset.idAsset,
          distance: convertMetersToUsersDistance(distance, 2, userConfig),
          directions: {
            origin: latlng,
            destination: destlatlong,
          },
        })
      } else {
        distances.push({
          idAsset: asset.idAsset,
          distance: 999999999999,
        })
      }
    })
  }
  const sortedArr = sortBy(distances, 'distance')
  return sortedArr
}
const showDestinationMarker = (
  directionResult,
  index,
  bounds,
  markersTemp,
  map,
) => {
  bounds.extend(directionResult.routes[0].legs[0].start_location)
  const m = new window.google.maps.Marker({
    position: directionResult.routes[0].legs[0].start_location,
    label: (index + 1).toString(),
    title:
      'Driving distance: ' + directionResult.routes[0].legs[0].distance.text,
    map: map,
  })
  window.google.maps.event.addListener(m, 'click', function (event) {
    map.setZoom(17)
    map.panTo(event.latLng)
  })
  markersTemp.push(m)
  return markersTemp
}
const displayRoutes = (routes, bounds, map) => {
  //only redraw if routes have changed
  // let markersTemp = []

  removeDirections()
  //const newDirections = {routes: [], markers: [], directionsServiceArray: []}

  routes.forEach((obj, index) => {
    const request = {
        origin: obj.directions.destination,
        destination: obj.directions.origin,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      rendererOptions = {
        preserveViewport: true,
        map: map,
        suppressMarkers: true,
        draggable: false,
        polylineOptions: {
          strokeColor: '#337ab7',
          strokeWeight: 8,
        },
        routeIndex: index,
      }
    directions.routes[index] = new window.google.maps.DirectionsRenderer(
      rendererOptions,
    )
    directions.directionsServiceArray[index] =
      new window.google.maps.DirectionsService()

    directions.directionsServiceArray[index].route(
      request,
      function (result, status) {
        if (status === window.google.maps.DirectionsStatus.OK) {
          if (directions.routes && directions.routes[index]) {
            directions.routes[index].setDirections(result)

            directions.markers = showDestinationMarker(
              result,
              index,
              bounds,
              directions.markers,
              map,
            )
            //fitbounds is set when clicking find closest then set to false so websockets don't update bounds
            // if (fitbounds) {
            // if (index === directions.directionsServiceArray.length) {
            map.fitBounds(bounds)
            // }
            // }
          }
        }
      },
    )
  })
  setDirections(directions)
}
export const getClosestAssets = ({
  latlng,
  assets,
  map,
  setGridOpen,
  setAssetListOpen,
}) => {
  // const setDirections = useMapStore.getState().setDirections

  //called from search map, location click or info window
  let routeAssets = []
  const bounds = new window.google.maps.LatLngBounds() //reset bounds

  if (directions.route) {
    removeDirections()
  }
  //add distance to asset objects in dataset
  const distanceArray = getAssetDistances(latlng, bounds, assets)
  useMapStore.setState({
    showingNearestAssetDistances: true,
    activeMarker: undefined,
    nearByDistances: distanceArray,
  })
  routeAssets = distanceArray.slice(0, 3)
  displayRoutes(routeAssets, bounds, map)
  if (setGridOpen) {
    setGridOpen(true)
  }
  if (setAssetListOpen) {
    setAssetListOpen(false)
  }
}
