import * as React from 'react'
import {
  SliderContainer,
  Slider,
  Track,
  Range,
  LeftThumb,
  RightThumb,
  Bubble,
  Container,
  Labels,
} from './rangeSliderStyles'
import {parse, addSeconds} from 'date-fns'
import {
  createLabels,
  bubbleLabels,
  getSliderValue,
} from './rangeSlider.helpers.js'
import {useStore} from 'common/useStore'
import {TagSimple} from 'phosphor-react'

const initialState = {
  selectionStart: 0,
  selectionEnd: 1000,
  labelPosition: 500,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'newValues':
      return {
        ...state,
        selectionStart: action.data.selectionStart,
        selectionEnd: action.data.selectionEnd,
        labelPosition: action.data.labelPosition,
        bubbleStart: action.data.bubbleStart,
        bubbleEnd: action.data.bubbleEnd,
        newStartDate: action.data.newStartDate,
        newEndDate: action.data.newEndDate,
      }
    case 'setLabels':
      return {
        ...initialState,
        startDateLabel: action.data.startDateLabel,
        endDateLabel: action.data.endDateLabel,
        bubbleStart: action.data.startDateLabel,
        bubbleEnd: action.data.endDateLabel,
        newStartDate: action.data.start,
        newEndDate: action.data.end,
      }
  }
}

const RangeSlider = ({
  start,
  end,
  rangeChanged,
  resetCounter,
  selectionDateRange,
  points,
}) => {
  const userConfig = useStore(state => state.userConfig)
  let dateFormat = `M/d ${
    userConfig.Time_Format === '12HR' ? 'h:mm a ' : 'HH:mm'
  }`
  if (userConfig?.Date_Format) {
    if (userConfig.Date_Format === 'd/M/yyyy')
      dateFormat = `d/M ${
        userConfig.Time_Format === '12HR' ? 'h:mm a ' : 'HH:mm'
      }`
  }

  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [initialLoad, setInitialLoad] = React.useState(true)
  React.useEffect(() => {
    if (start) {
      const labels = createLabels({start, end, dateFormat})
      dispatch({type: 'setLabels', data: labels})
    }
  }, [start, end, dateFormat, resetCounter])

  React.useEffect(() => {
    if (initialLoad && points.length > 0) {
      const newValues = getSliderValue({
        start,
        end,
        selectionStartDate: selectionDateRange.startDate,
        selectionEndDate: selectionDateRange.endDate,
        dateFormat,
      })

      let newLabelPosition = 500

      if (
        newValues.startDateLabel !== state.startDateLabel ||
        newValues.endDateLabel !== state.endDateLabel
      ) {
        newLabelPosition = Math.max(
          1,
          (parseInt((newValues.newEndValue - newValues.newStartValue) / 2) +
            newValues.newStartValue) /
            10,
        )
      }
      dispatch({
        type: 'newValues',
        data: {
          selectionStart: newValues.newStartValue,
          selectionEnd: newValues.newEndValue,
          labelPosition: newLabelPosition,
          bubbleStart: newValues.startDateLabel,
          bubbleEnd: newValues.endDateLabel,
          newStartDate: selectionDateRange.startDate,
          newEndDate: selectionDateRange.endDate,
        },
      })

      rangeChanged({
        startDate: selectionDateRange.startDate,
        endDate: selectionDateRange.endDate,
      })
      setInitialLoad(false)
    }
  }, [
    dateFormat,
    end,
    initialLoad,
    points,
    rangeChanged,
    selectionDateRange.endDate,
    selectionDateRange.startDate,
    start,
    state.endDateLabel,
    state.startDateLabel,
  ])

  function setValue(e) {
    let newStartValue = state.selectionStart
    let newEndValue = state.selectionEnd

    if (e.target.name === 'input-left') {
      newStartValue = Math.min(
        parseInt(e.target.value),
        parseInt(state.selectionEnd) - 1,
      )
    } else {
      newEndValue = Math.max(
        parseInt(e.target.value),
        parseInt(state.selectionStart) + 1,
      )
    }

    const newBubbleLabels = bubbleLabels({
      start,
      end,
      selectionStart: newStartValue,
      selectionEnd: newEndValue,
      dateFormat,
    })

    let newLabelPosition = 500

    if (
      newBubbleLabels.startDateLabel !== state.startDateLabel ||
      newBubbleLabels.endDateLabel !== state.endDateLabel
    ) {
      newLabelPosition = Math.max(
        1,
        (parseInt((newEndValue - newStartValue) / 2) + newStartValue) / 10,
      )
    }
    dispatch({
      type: 'newValues',
      data: {
        selectionStart: newStartValue,
        selectionEnd: newEndValue,
        labelPosition: newLabelPosition,
        bubbleStart: newBubbleLabels.startDateLabel,
        bubbleEnd: newBubbleLabels.endDateLabel,
        newStartDate: newBubbleLabels.newStartDate,
        newEndDate: newBubbleLabels.newEndDate,
      },
    })
  }

  const returnRange = () => {
    const selectionStartDate = parse(
      state.bubbleStart,
      dateFormat,
      state.newStartDate,
    )
    const selectionEndDate = parse(
      state.bubbleEnd,
      dateFormat,
      state.newEndDate,
    )
    const newEndDate = addSeconds(selectionEndDate, 59)
    rangeChanged({startDate: selectionStartDate, endDate: newEndDate})
  }

  return (
    <Container>
      <SliderContainer data-cy="rangeSlider">
        <input
          onChange={setValue}
          onMouseUp={returnRange}
          type="range"
          id="input-left"
          name="input-left"
          min="0"
          max="999"
          value={state.selectionStart}
        />
        <input
          onChange={setValue}
          onMouseUp={returnRange}
          type="range"
          id="input-right"
          name="input-right"
          min="1"
          max="1000"
          value={state.selectionEnd}
        />
        <Bubble position={state.labelPosition}>
          {state.bubbleStart} - {state.bubbleEnd}
        </Bubble>
        <Slider>
          <Track />
          <Range start={state.selectionStart} end={state.selectionEnd} />
          <LeftThumb start={state.selectionStart}>
            <TagSimple weight="duotone" color="var(--asc-brown)" />
          </LeftThumb>
          <RightThumb end={state.selectionEnd}>
            <TagSimple weight="duotone" color="var(--asc-brown)" />
          </RightThumb>
        </Slider>

        <Labels>
          <div>
            <span>{state?.startDateLabel?.split(' ')[0]}</span>
            {` ${state?.startDateLabel?.split(' ')[1]} ${
              state?.startDateLabel?.split(' ')[2]
                ? state?.startDateLabel?.split(' ')[2]
                : ''
            }`}
          </div>
          <div>
            <span>{state?.endDateLabel?.split(' ')[0]}</span>
            {` ${state?.endDateLabel?.split(' ')[1]} ${
              state?.endDateLabel?.split(' ')[2]
                ? state?.endDateLabel?.split(' ')[2]
                : ''
            }`}
          </div>
        </Labels>
      </SliderContainer>
    </Container>
  )
}
export default RangeSlider
