import * as React from 'react'
import TripShareModal from 'tripshare/TripShareModal/TripShareModal'
import TripShareConfirmModal from 'tripshare/TripShareModal/TripShareConfirmModal'
import {useTripShareModal} from 'tripshare/TripShareModal/hooks/useTripShareModal'
import {
  ReducerAction,
  SubmitValues,
  AssetType,
} from 'tripshare/TripShareModal/tripShareTypes'

type Props = {
  setShowTripModal: (showModal: boolean) => void
  asset: AssetType
}

type State = {
  showingQuickReportsModal: boolean
  showTripShareModal: boolean
  showConfirmModal: boolean
}

const reducer = (state: State, action: ReducerAction) => {
  switch (action.type) {
    case 'setState':
      return {...state, ...action.data}
    default:
      break
  }
}

const initialState = {
  showingQuickReportsModal: false,
  showTripShareModal: true,
  showConfirmModal: false,
}

const TripShare = ({setShowTripModal, asset}: Props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  // const handleTripShare = (showModal: boolean) => {
  //   dispatch({
  //     type: 'setState',
  //     data: {showTripShareModal: showModal},
  //   })
  // }

  const setShowTripModalConfirm = (showModal: boolean) => {
    dispatch({
      type: 'setState',
      data: {showConfirmModal: showModal},
    })
    if (showModal === false) {
      setShowTripModal(false)
    }
  }

  const {mutateAsync: postTrip, data: postTripData} = useTripShareModal()

  function handlePostTrip(data: SubmitValues) {
    dispatch({
      type: 'setState',
      data: {postTripData: data},
    })
    postTrip(data)
  }

  return (
    <>
      {!state.showConfirmModal && (
        <TripShareModal
          showingTripShareModal={!state.showConfirmModal}
          setShowTripShareModal={setShowTripModal}
          setShowConfirmModal={setShowTripModalConfirm}
          asset={asset}
          onPostTrip={handlePostTrip}
        />
      )}

      {state.showConfirmModal && (
        <TripShareConfirmModal
          setShowConfirmModal={setShowTripModalConfirm}
          showConfirmModal={state.showConfirmModal}
          submitValues={state.postTripData}
          postTripData={postTripData}
          asset={asset?.Asset_Label}
        />
      )}
    </>
  )
}

export default TripShare
