import axios from 'axios'
// import {format} from 'date-fns'
import Swal from 'sweetalert2'

export const getCameraChannels = (data, id) => {
  const assetSelected = data?.find(camera => Number(camera.idAsset) === id)
  const availableChannelsText = assetSelected
    ? assetSelected.AvailableChannels?.split(',')
    : [`1`]
  const availableChannels = availableChannelsText?.map((item, index) => {
    return {value: item, label: `Camera ${item}`, index: index}
  })
  return availableChannels
}
export const getAvailableCameras = data => {
  const availableCamera = data?.map((item, index) => {
    return {
      value: item.idAsset,
      label: item.Asset_Label,
      index: index,
      idDevice_Type: item.idDevice_Type,
      asset: item,
    }
  })

  return availableCamera
}
export const liveLookInitialState = {
  channel: 1,
  availableChannels: [],
  cameraList: [],
  selectedCamera: null,
  intervalDelay: 1000,
  cameraData: {},
  playing: false,
  loaded: false,
  url: '',
}
export const liveLookReducer = (state = liveLookInitialState, action) => {
  switch (action.type) {
    case 'setState': {
      const newUpdate = {
        ...state,
        ...action.data,
      }
      return newUpdate
    }
    default:
      return state
  }
}
export const getCameraSnapshot = async (
  idAsset,
  camera,
  abortControllerRef,
) => {
  try {
    const response = await axios.get(
      `/camera/getlivesnapshot/${idAsset}/${camera}`,
      {
        signal: abortControllerRef.signal,
      },
    )
    if (response?.data) return response.data
  } catch (e) {
    await timeout(2000)
    try {
      const response = await axios.get(
        `/camera/getlivesnapshot/${idAsset}/${camera}`,
        {
          signal: abortControllerRef.signal,
        },
      )
      if (response?.data) return response.data
    } catch (error) {
      try {
        await timeout(4000)
        const response = await axios.get(
          `/camera/getlivesnapshot/${idAsset}/${camera}`,
          {
            signal: abortControllerRef.signal,
          },
        )
        if (response?.data) return response.data
      } catch (error) {
        return null
      }
    }
  }
}

export const getLiveVideo = async (idAsset, camera) => {
  try {
    const {data} = await axios.get(
      `/camera/GetLiveVideo/${idAsset}/${camera}?SubSamplingInterval=0`,
    )
    if (data) {
      if (JSON.parse(data).Code === 202) {
        Swal.fire(JSON.parse(data).Message)
      }
      return JSON.parse(data)
    }
  } catch (error) {
    Swal.fire(
      'Camera request error',
      'There was an error connecting to this camera. Please, try again later.',
      'error',
    )
  }
}

export const timeout = delay => {
  return new Promise(res => setTimeout(res, delay))
}

const startSnapshotFetch = async ({state, dispatch}) => {
  try {
    const snapshot = await getCameraSnapshot(
      state.selectedCamera,
      state.channel,
    )
    //timer to shut off live look
    if (new Date() - state.timeStart > 5 * 60 * 1000) {
      dispatch({
        type: 'setState',
        data: {
          intervalDelay: null,
        },
      })
    } else {
      if (snapshot) {
        const cameraData = JSON.parse(snapshot)
        cameraData.coordinates = {
          lat: cameraData.Latitude,
          lng: cameraData.Longitude,
        }

        dispatch({type: 'setState', data: {cameraData, loading: false}})

        if (state.intervalDelay) {
          await timeout(state.intervalDelay)
          // not sure why eslint is giving this error
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          startSnapshotFetch()
        }
      }
    }
  } catch (error) {
    dispatch({
      type: 'setState',
      data: {intervalDelay: null, error: true},
    })
    Swal.fire(
      'Image requested from device',
      'There was an error connecting to this camera. Please try again later.',
      'error',
    )
  }
}
