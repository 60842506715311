import * as React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {QueryClient, useQueryClient} from '@tanstack/react-query'
import IsActiveCell from './LocationIsActiveCell'
import {
  ListContainer,
  Spinner,
  BreadCrumbs,
  Crumb,
  Page,
} from 'common/components'
import {deleteDialog} from 'common/deleteDialog'
import TableActionButtons from 'common/tables/TableActionButtons'
import BulkLocationImport from './BulkLocationImport'
import {useStore} from 'common/useStore'
import AlertModal from 'common/components/AlertModal'
import CheckboxCell from 'common/components/CheckboxCell'
import ResizableTable from 'common/tables/ResizableTable/ResizableTable'
import ResizableTableHeader from 'common/tables/ResizableTable/ResizableTableHeader'
import useBulkDeleteLocations from './hooks/useBulkDeleteLocations'
import {Plus, Trash} from 'phosphor-react'
import {CellContext, ColumnDef} from '@tanstack/react-table'
import BulkOrderByLocationModal from 'routingAndDispatch/Orders/BulkOrderByLocationModal'
import useLocations, {LandmarkType} from './hooks/useLocations'
import CollapsibleFilter from 'routingAndDispatch/common/CollapsibleFilter'
import {
  LocationLinkCell,
  LocationListActionTypes,
  LocationListState,
  locationListInitialState,
  locationListReducer,
} from './LocationListHelper'
import styled from 'styled-components/macro'

const deleteLocation = (locationToDelete: LandmarkType, cache: QueryClient) => {
  deleteDialog({
    deleteURL: `/landmarks/${locationToDelete.idLandmark}`,
    id: locationToDelete.idLandmark,
    idField: 'idLandmark',
    cache,
    reactQueryKey: 'locations',
    title: locationToDelete.Landmark_Name,
  })
}

const StyledListContainer = styled(ListContainer)`
  width: calc(100% - 305px);
`

interface Props {
  isRouteOptimization?: boolean
}

const LocationList: React.FC<Props> = ({isRouteOptimization = false}) => {
  const location = useLocation()
  const {data: locations, status} = useLocations()
  const cache = useQueryClient()
  const navigate = useNavigate()
  const setBreadcrumbs = useStore(state => state.setBreadcrumbs)
  const {mutateAsync: bulkDeleteLocations} = useBulkDeleteLocations()

  const [state, dispatch]: [
    state: LocationListState,
    dispatch: React.Dispatch<LocationListActionTypes>,
  ] = React.useReducer(locationListReducer, locationListInitialState)

  const ActionCell = (
    instance: CellContext<LandmarkType, unknown>,
    isRouteOptimization: boolean,
  ) => {
    if (instance.row.original.idLandmark) {
      const location = instance.row.original
      return (
        <React.Fragment>
          <TableActionButtons
            editPath={`/locations/${location.idLandmark}`}
            onDelete={() =>
              dispatch({type: 'setState', data: {locationToDelete: location}})
            }
            testIds={{delete: 'delete-location', edit: 'edit-location'}}
            customDeleteText="Delete Location"
            customDropdownItems={
              isRouteOptimization
                ? [
                    {
                      text: 'Add Order',
                      onClick: () => {
                        dispatch({
                          type: 'setState',
                          data: {
                            showAddSingleOrderDialog: true,
                            selectedSingleRowIdLocations: [
                              instance.row.original.idLandmark,
                            ],
                          },
                        })
                      },

                      disabled: false,
                      testId: 'add-order-button',
                    },
                  ]
                : undefined
            }
            editState={{isRouteOptimization: isRouteOptimization}}
          />
        </React.Fragment>
      )
    }
    return null
  }

  const toggleSelect = (idLandmark: number) => {
    if (state.selectedIdLocations.includes(idLandmark)) {
      // If the landmark is already selected, remove it
      dispatch({
        type: 'setState',
        data: {
          selectedIdLocations: state.selectedIdLocations.filter(
            landmark => landmark !== idLandmark,
          ),
        },
      })
    } else {
      // If the landmark is not already selected, add it
      dispatch({
        type: 'setState',
        data: {selectedIdLocations: [...state.selectedIdLocations, idLandmark]},
      })
    }
  }

  const columns: ColumnDef<LandmarkType>[] = [
    {
      header: '',
      accessorKey: 'idLandmark',
      cell: row => (
        <CheckboxCell<number>
          id={row.getValue() as number}
          idKey={'idLandmark'}
          data={locations || []}
          selectedItemIds={state.selectedIdLocations}
          toggleSelect={toggleSelect}
          selectAll={false}
        />
      ),
      meta: {disableExport: true},
      id: 'checkbox',
      enableSorting: false,
      enableResizing: false,
      size: 20,
    },
    {
      header: isRouteOptimization
        ? 'Location (Customer) Name'
        : 'Location Name',
      id: 'name',
      accessorKey: 'Landmark_Name',
      cell: instance => LocationLinkCell({instance, isRouteOptimization}),
      size: 230,
    },
    {
      header: 'Description',
      accessorKey: 'Landmark_Label',
    },
    {
      header: 'Address',
      accessorKey: 'Landmark_Address_Line1',
    },
    {
      header: 'City',
      accessorKey: 'Landmark_City',
      size: 130,
    },
    {
      header: 'State',
      accessorKey: 'Landmark_Region',
      size: 40,
    },
    {
      header: 'Type',
      accessorKey: 'Landmark_Style',
      size: 130,
    },
  ]

  if (!isRouteOptimization) {
    columns.push(
      {
        header: 'Unique Id',
        accessorKey: 'Landmark_UUID',
      },

      {
        header: 'Active',
        accessorFn: row => (row.IsActive ? 'Yes' : 'No'),
        cell: row => IsActiveCell(row),
        enableColumnFilter: false,
        enableResizing: false,
        meta: {
          headerAlign: 'center',
        },
      },
    )
  }
  columns.push({
    header: '',
    id: 'Action',
    cell: (row: CellContext<LandmarkType, unknown>) =>
      ActionCell(row, isRouteOptimization),
    enableColumnFilter: false,
    enableResizing: false,
    enableSorting: false,
    meta: {
      disableExport: true,
    },
  })

  const initialFilters = location?.state?.filters
    ? location.state.filters
    : {filters: [], globalFilter: ''}

  React.useEffect(() => {
    //clear location state after loading so it doesn't persist
    window.history.replaceState({}, document.title)
  }, [])

  React.useEffect(() => {
    setBreadcrumbs(
      <BreadCrumbs>
        <Crumb href="/map">Home</Crumb>
        <Crumb>Locations</Crumb>
      </BreadCrumbs>,
    )
  }, [setBreadcrumbs])

  const onDelete = () => {
    if (state.locationToDelete) {
      deleteLocation(state.locationToDelete, cache)
    } else {
      bulkDeleteLocations(state.selectedIdLocations)
    }
    dispatch({type: 'setState', data: {selectedIdLocations: []}})
  }

  const getSelectableIds = React.useCallback(() => {
    const selectableOrderIDs: number[] = []
    locations?.map(location => selectableOrderIDs.push(location.idLandmark))
    return selectableOrderIDs
  }, [locations])

  const getSelectionActionButtons = () => {
    return isRouteOptimization
      ? [
          {
            text: `ADD ${state.selectedIdLocations.length} ORDERS`,
            actionFunction: () =>
              dispatch({type: 'setState', data: {showAddOrdersDialog: true}}),
            icon: <Plus weight="fill" size={20} />,
          },
          {
            text: `Delete ${state.selectedIdLocations.length} Selected`,
            actionFunction: () =>
              dispatch({type: 'setState', data: {showDeleteDialog: true}}),
            icon: (
              <Trash
                color={
                  state.selectedIdLocations.length > 0
                    ? 'var(--asc-vermilion)'
                    : undefined
                }
                weight="fill"
                size={20}
              />
            ),
          },
        ]
      : [
          {
            text: `Delete ${state.selectedIdLocations.length} Selected`,
            actionFunction: () =>
              dispatch({type: 'setState', data: {showDeleteDialog: true}}),
            icon: (
              <Trash
                color={
                  state.selectedIdLocations.length > 0
                    ? 'var(--asc-vermilion)'
                    : undefined
                }
                weight="fill"
                size={20}
              />
            ),
          },
        ]
  }

  React.useEffect(() => {
    if (
      status === 'success' &&
      locations &&
      locations.length !== state.allLocations.length
    ) {
      dispatch({type: 'loadLocations', data: {locations}})
    }
  }, [locations, state.allLocations.length, status])

  return (
    <Page>
      <StyledListContainer data-cy="location-content">
        {(status === 'loading' || state.loading) && (
          <Spinner data-cy="spinner" />
        )}

        {status === 'error' && (
          <div>There was an error loading the locations.</div>
        )}
        {status === 'success' && locations && (
          <>
            <ResizableTableHeader
              title={'Locations'}
              totalItems={locations.length}
              selectedItems={state.selectedIdLocations.length}
              addButtonText="ADD LOCATION"
              showBackButton={false}
              addAction={() => navigate('/location')}
              secondCustomAddButton={
                <BulkLocationImport
                  setLoading={(loading: boolean) =>
                    dispatch({type: 'setState', data: {loading}})
                  }
                />
              }
            />
            <ResizableTable<LandmarkType, number>
              data={state.filteredLocations}
              columns={columns}
              title={'Locations'}
              initialFilters={initialFilters}
              showSelectAll={true}
              rowSelection={{
                idPropertyName: 'idLandmark',
                setSelectedIDs: (ids: number[]) =>
                  dispatch({
                    type: 'setState',
                    data: {selectedIdLocations: ids},
                  }),
                selectedIDs: state.selectedIdLocations,
                selectableIDs: getSelectableIds(),
              }}
              selectionActionButtons={getSelectionActionButtons()}
              status={status} // onDelete={onDelete}
            ></ResizableTable>
            <AlertModal
              showDialog={
                state.showDeleteDialog ||
                Boolean(state.locationToDelete?.idLandmark)
              }
              onSave={() => {
                dispatch({
                  type: 'setState',
                  data: {showDeleteDialog: false, locationToDelete: null},
                })

                onDelete && onDelete()
              }}
              onDismiss={() => {
                dispatch({
                  type: 'setState',
                  data: {showDeleteDialog: false, locationToDelete: null},
                })
              }}
              showDenyButton={true}
              title={
                state.locationToDelete?.Landmark_Name
                  ? 'Are you sure you want to delete ' +
                    state.locationToDelete.Landmark_Name +
                    '?'
                  : `Are you sure you want to delete the ${
                      state.selectedIdLocations.length + ' selected items?'
                    }`
              }
              text={'This action cannot be undone.'}
              type={'delete'}
            />
            {state.showAddOrdersDialog && (
              <BulkOrderByLocationModal
                showAddOrdersDialog={state.showAddOrdersDialog}
                setShowAddOrdersDialog={(show: boolean) =>
                  dispatch({
                    type: 'setState',
                    data: {showAddOrdersDialog: show},
                  })
                }
                setSelectedIdLocations={(idLocations: number[]) =>
                  dispatch({
                    type: 'setState',
                    data: {selectedIdLocations: idLocations},
                  })
                }
                selectedIdLocations={state.selectedIdLocations}
              />
            )}
            {!!state.showAddSingleOrderDialog && (
              <BulkOrderByLocationModal
                showAddOrdersDialog={state.showAddSingleOrderDialog}
                setShowAddOrdersDialog={(show: boolean) =>
                  dispatch({
                    type: 'setState',
                    data: {showAddSingleOrderDialog: show},
                  })
                }
                setSelectedIdLocations={(idLocations: number[]) =>
                  dispatch({
                    type: 'setState',
                    data: {selectedSingleRowIdLocations: idLocations},
                  })
                }
                selectedIdLocations={state.selectedSingleRowIdLocations}
              />
            )}
          </>
        )}
      </StyledListContainer>
      <CollapsibleFilter
        filterState={state}
        title={'Filter Locations'}
        dispatch={dispatch}
        filters={state.filters}
      />
    </Page>
  )
}
export default LocationList
