import {
  numberWithCommas,
  isNumeric,
  roundToDecimals,
} from 'common/helpersNumber'

type valueChangedProps = {
  e: {target: {value: string}}
  prevValue: string | number
  decimals: number
  noCommas: boolean
}

type GetReturnAndDisplayValuesProps = {
  enteredValue: string | number
  prevValue: string | number
  decimals: number
}

const getReturnAndDisplayValuesForNumbers = ({
  enteredValue,
  prevValue,
  decimals,
}: GetReturnAndDisplayValuesProps) => {
  let newDisplayValue = enteredValue === undefined ? '' : enteredValue
  let returnValue
  if (typeof enteredValue === 'string') {
    newDisplayValue = enteredValue.replace(/,/g, '')
  }

  //TODO - fix this typescript weirdness
  // @ts-expect-error - not sure how to check this without first converting to Number
  if (newDisplayValue && !isNumeric(Number(newDisplayValue))) {
    //should be a number but it isn't return previous
    newDisplayValue = 'previousValue'
    if (newDisplayValue === '') {
      returnValue = null
    } else {
      returnValue = prevValue
    }
  } else {
    //valid number add commas
    returnValue = roundToDecimals(Number(newDisplayValue), decimals)
    newDisplayValue = numberWithCommas(newDisplayValue, decimals)
  }
  return {returnValue, newDisplayValue}
}

export const valueChanged = ({
  e,
  prevValue,
  decimals,
  noCommas,
}: valueChangedProps) => {
  const enteredValue = e.target.value === undefined ? '' : e.target.value
  let returnValue
  let newDisplayValue = ''
  if (enteredValue !== '') {
    const returnedObject = getReturnAndDisplayValuesForNumbers({
      enteredValue,
      prevValue,
      decimals,
    })
    newDisplayValue = returnedObject.newDisplayValue
    //allow a trailing zero
    if (
      newDisplayValue + '0' === enteredValue ||
      newDisplayValue + '.0' === enteredValue
    ) {
      newDisplayValue = enteredValue
    }
    returnValue = returnedObject.returnValue
  } else {
    returnValue = enteredValue
  }
  if (noCommas) {
    newDisplayValue = newDisplayValue.replace(/,/g, '')
  }
  return {returnValue, newDisplayValue}
}
