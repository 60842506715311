import Axios from 'axios'
import {useStore} from 'common/useStore'
import {setColorsBasedOnFlavor, setFavicon} from './helpers'
import {netAPIInstance} from './components/netAPIAxios'
// import {activityDetector} from 'common/activityDetector'

// let activityDetectorStarted = false
type SetTokenType = {
  token?: string
  impersonating?: boolean
  remember?: boolean
  acceptedEULA?: boolean | null
  freshToken?: boolean
  ignoreEULA?: boolean
}

export const setToken = async ({
  token,
  impersonating,
  remember,
  acceptedEULA,
  ignoreEULA,
  freshToken = false,
}: SetTokenType) => {
  const user = useStore.getState().user
  // console.log('in setToken', user?.token, user.role);

  //page may have been refreshed, check session storage for token
  //if we are impersonating we will have a token but we need to get new one for
  //user we are impersonating
  if (!user?.token || impersonating || freshToken) {
    const newToken =
      token || localStorage.getItem('token') || sessionStorage.getItem('token')
    if (newToken) {
      // if (!activityDetectorStarted) {
      //   activityDetectorStarted = true
      //   activityDetector.init('active')
      //   // console.log('starting activity detector')
      //   activityDetector.on('idle', () => console.info('idle'))
      //   activityDetector.on('active', () => console.info('active'))
      // }
      const decodedToken = window.atob(newToken)
      const decodedTokenArray = decodedToken.split(':')
      const email = decodedTokenArray[0]
      let impersonatingEmail = ''
      if (decodedTokenArray.length > 2) {
        impersonatingEmail = decodedTokenArray[2]
      }
      const data = await setAxiosAndUser({
        token: newToken,
        email,
        impersonatingEmail,
        remember,
        acceptedEULA,
        ignoreEULA,
      })
      return data
    } else {
      return
    }
  }
}

type SetAxiosAndUserType = {
  token: string
  email: string
  impersonatingEmail?: string
  remember?: boolean
  acceptedEULA?: boolean | null
  ignoreEULA?: boolean
}

export const setAxiosAndUser = async ({
  token,
  email,
  impersonatingEmail,
  remember,
  acceptedEULA,
  ignoreEULA,
}: SetAxiosAndUserType) => {
  Axios.defaults.headers.common['Authorization'] = `Basic ${token}`
  netAPIInstance.defaults.headers.common['Authorization'] = `Basic ${token}`
  try {
    Axios.get('/users?a=getuser') //only so login date gets updated
    const {data} = await Axios.get('/users/authuser')
    if (data && data.Email_Address) {
      //update remember unless coming from logout
      if (remember !== undefined) {
        localStorage.setItem('remember', String(remember))
      }
      if (acceptedEULA !== undefined && acceptedEULA !== null && !ignoreEULA) {
        localStorage.setItem('acceptedEULA', String(acceptedEULA))
      }
      localStorage.setItem('token', token)
      if (remember) {
        if (!impersonatingEmail) {
          localStorage.setItem('lookup', token)
        }
      } else if (remember === false) {
        localStorage.removeItem('lookup')
      }
      setFavicon()
      if (data.Date_Format) {
        data.Date_Format = data.Date_Format.replace('mm', 'M')
        data.Date_Format = data.Date_Format.replace('m', 'M')
        data.Date_Format = data.Date_Format.replace('dd', 'd')
      } else {
        data.Date_Format = 'M/d/yyyy'
      }
      if (data.CustomerConfigurations) {
        data.CustomerConfigurations?.forEach(
          (config: {ConfigGroup: string; ConfigSettings: string}) => {
            if (config.ConfigGroup === 'disabledfeatures') {
              data.disabledFeatures = config.ConfigSettings.split(',')
            }
            if (config.ConfigGroup === 'customcolors') {
              const colorsString = config.ConfigSettings.split('"')
              const colors = {
                primary: colorsString[3],
                secondary: colorsString[7],
              }
              document.body.style.setProperty('--primary', colors.primary)
              document.body.style.setProperty('--secondary', colors.secondary)
            } else {
              setColorsBasedOnFlavor()
            }
          },
        )
      }
      useStore.setState({
        userConfig: data,
        user: {
          token,
          email,
          impersonatingEmail: impersonatingEmail || '',
          role: data?.Role_Id,
        },
      })
    }
    return data
  } catch (error) {
    throw new Error()
  }
}
