import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getGridSchema = async () => {
  const [schema, threshold] = await Promise.all([
    Axios.get(`/schema?schematype=grid`),
    Axios.get(`/customerthreshold`),
  ])
  let data = []
  if (schema?.data && threshold?.data) {
    schema.data.forEach(s => {
      let newItem = {...s}
      const tIndex = threshold.data.findIndex(
        t => t.idCustomerField === s.idCustomerField,
      )
      if (tIndex > -1) {
        newItem.threshold = threshold.data[tIndex]
      }
      data.push(newItem)
    })
  }
  return data
}

const useGridSchema = () => {
  const user = useStore(state => state.user)
  return useQuery(['gridSchema'], () => getGridSchema(), {
    enabled: Boolean(user.token),
    keepPreviousData: true,
  })
}

export default useGridSchema
