import * as React from 'react'
import styled from 'styled-components/macro'
import {Popup} from 'semantic-ui-react'

const ToolTipTrigger = styled.span``
const StyledPopup = styled(Popup)`
  && {
    border-radius: 6px;
    opacity: ${props => props.opacity};
  }
`

interface Props {
  tooltip?: string | React.JSX.Element
  trigger: React.JSX.Element
  position: string
  disabled?: boolean
  inverted?: boolean
  opacity?: number
  className?: string
}

const Tooltip = ({
  tooltip,
  trigger,
  position,
  disabled = false,
  inverted = true,
  opacity = 0.9,
  className = '',
}: Props) => {
  return (
    <StyledPopup
      content={tooltip}
      on="hover"
      trigger={<ToolTipTrigger>{trigger}</ToolTipTrigger>}
      position={position ? position : 'top left'}
      inverted={inverted}
      disabled={!tooltip ? true : disabled}
      opacity={opacity}
      className={className}
    />
  )
}

export default Tooltip
