import * as React from 'react'
import styled from 'styled-components/macro'
import {useAsyncDebounce} from 'react-table'
import {QUERIES} from 'common/constants'
import {applyColumnFilter} from 'grid/gridTableHelpers'
import {Filters, Column} from 'grid/gridTypes'
import {FilterAssetsFromListProps} from 'opening/openingHelpers'
import {useGridFilterStore} from './useGridFilterStore'

const Wrapper = styled.span`
  @media ${QUERIES.tabletAndUp} {
    margin: 0;
    flex: inherit;
  }
`

const Input = styled.input`
  border: 1px solid var(--asc-graphite);
  border-radius: 3px;
  display: flex;
`

type Props = {
  filters: Filters
  setFilters: (filters: Filters) => void
  filterAssetsFromList: (props: FilterAssetsFromListProps) => void
  column: Column
  columns: Column[]
}

const ColumnFilter = ({filterAssetsFromList, column, columns}: Props) => {
  const columnFilters = useGridFilterStore(state => state.columnFilters)
  const globalFilter = useGridFilterStore(state => state.globalFilter)
  const setColumnFilter = useGridFilterStore(state => state.setColumnFilter)
  const [thisColumnFilterValue, setThisColumnFilterValue] = React.useState('')
  const thisColumn = columnFilters.find(c => c.name === column.id)

  React.useEffect(() => {
    setThisColumnFilterValue(thisColumn?.value || '')
  }, [column.id, thisColumn?.value])

  const onChange = (value: string) => {
    const currentFilters = {
      globalFilter,
      columnFilters,
    }
    const newFilters = applyColumnFilter({
      name: column.id,
      value,
      filters: currentFilters,
    })
    setColumnFilter({name: column.id, value: value})
    updateMap(newFilters)
  }

  const updateMap = useAsyncDebounce(newFilters => {
    filterAssetsFromList({
      gridFilters: {
        filters: newFilters,
        columns,
      },
    })
  }, 200)

  return (
    <Wrapper>
      <Input
        value={thisColumnFilterValue || ''}
        onChange={e => {
          onChange(e.target.value)
        }}
        placeholder={`Search...`}
        data-lpignore="true"
        data-cy="grid-column-filter"
      />
    </Wrapper>
  )
}

export default ColumnFilter
