import {GOOGLE_KEYS} from 'common/constants'
import * as React from 'react'
import {Icon} from 'semantic-ui-react'
import {RenderMarker} from './cameraFootageHelper'
import GoogleMapReact from 'google-map-react'

const MapFootage = ({coordinates, setMapRendered, mapRendered}) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={GOOGLE_KEYS}
      zoom={17}
      center={coordinates}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({map}) => (map ? setMapRendered(true) : null)}
    >
      {mapRendered && (
        <RenderMarker
          lat={coordinates?.lat}
          lng={coordinates?.lng}
          text="Marker"
        >
          <Icon name="map marker alternate" color="red" size="huge"></Icon>
        </RenderMarker>
      )}
    </GoogleMapReact>
  )
}
export default MapFootage
