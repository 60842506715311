import * as React from 'react'
import {useQueryClient} from '@tanstack/react-query'
import Axios from 'axios'
import styled from 'styled-components/macro'
import Toggle from 'common/components/Toggle'

const updateIsActive = async (location, checked) => {
  try {
    const {data} = await Axios.put(`/landmarks/${location.idLandmark}`, {
      IsActive: checked,
      Landmark_Category: location.Landmark_Category,
      idLandmark: location.idLandmark,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}
const CheckboxActiveButton = styled.div`
  text-align: center;
`
const IsActiveCell = row => {
  const location = row.row.original
  const cache = useQueryClient()
  const [isChecked, setIsChecked] = React.useState(true)
  React.useEffect(() => {
    const checked = location ? location.IsActive : false
    setIsChecked(checked)
  }, [location])

  const updateActive = async (e, {checked}) => {
    setIsChecked(Boolean(checked))
    await updateIsActive(location, checked)
    cache.invalidateQueries([`locationDetail-${location?.idLandmark}`])
    cache.invalidateQueries(['locations'])
  }
  return (
    <CheckboxActiveButton>
      <Toggle onChange={updateActive} checked={isChecked} />
    </CheckboxActiveButton>
  )
}

export default IsActiveCell
