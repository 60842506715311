import React, {Fragment} from 'react'
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useBlockLayout,
} from 'react-table'
import {useSticky} from 'react-table-sticky'
import {useExportData} from 'react-table-plugins'
import {getExportFileBlob, compareIgnoreCase} from './tableWithHooksHelpers'
import GlobalFilter from './GlobalFilter'
import {useStore} from 'common/useStore'
import TableButtons from './TableButtons'
import {
  Styles,
  TableHeaders,
  TableHeadersAll,
  // TableSubHeaders,
  NoRecordsRow,
  StyledMenu,
  Table,
  TitleButtons,
  ButtonsSection,
  Header,
  Body,
  SortedArrow,
  ItemBadge,
  ItemBadgeContainer,
  TitleButton,
  StyledTableRow,
  TitleAndBadge,
  // IconHolder,
  // StyledGroupButton,
  // StyledButton,
  MobileMenu,
  TableButtonsPlaceHolder,
} from './tableStyles'
import TablePaginationControls from './TablePaginationControls'
import {DefaultColumnFilter} from './Filters'
import {Icon} from 'semantic-ui-react'
import {Spinner} from 'common/components'
import {ArrowUp, ArrowDown} from 'phosphor-react'
import {nFormatter} from 'common/helpersNumber'
// import {Table as TableIcon, ListBullets} from 'phosphor-react'
// import Tooltip from 'common/components/Tooltip'
import useWindowSize from 'common/useWindowSize'

function TableWithHooks(props) {
  const {
    columns,
    data = [],
    initPageSize = 100,
    tableTitle,
    // filterTable = true,
    initSort = [],
    hiddenColumns = [],
    newButton = {url: '', text: 'Add', state: {}},
    hiddenNewButton,
    hiddenExportButton,
    hiddenGlobalFilter,
    hiddenTitle,
    // hiddenToggleCardRowViewButton,
    dateRange,
    rowClick,
    tableTitleButtons,
    selectedRow,
    selectedRowArray,
    children,
    status,
    initialFilters,
    widthPadding = 0,
    className,
    height,
    hasPieChart = false,
    subtractWidth = 0,
    hideHeader = false,
    customTableButton = null,
  } = props
  const bodyRef = React.useRef(null)
  const scrollRef = React.useRef()
  const skipPageResetRef = React.useRef()
  // const [showCard, setShowCard] = React.useState(false)
  const leftMenuWidth = useStore(state => state.leftMenuWidth)
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      minWidth: 50, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 3000, // maxWidth is only used as a limit for resizing
    }),
    [],
  )
  const {isMobile} = useWindowSize()
  const memoizedData = React.useMemo(() => {
    // When data gets updated with this function, set a flag
    // to disable all of the auto resetting
    skipPageResetRef.current = true
    return data
  }, [data])

  React.useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false
  })

  const exportFile = React.useCallback(
    props => {
      const newProps = {
        ...props,
        fileName:
          props.fileName === 'all-data'
            ? tableTitle || 'Report'
            : `${tableTitle || 'Report'}-filtered`,
      }
      return getExportFileBlob(newProps)
    },
    [tableTitle],
  )

  const sortTypes = React.useMemo(() => {
    return {
      alphanumeric: (row1, row2, columnName) => {
        return compareIgnoreCase(
          row1.values[columnName],
          row2.values[columnName],
        )
      },
    }
  }, [])

  const cols = React.useMemo(() => {
    //calculate width to fill space
    if (columns.length > 12) return columns // if 12 columns or more just use 150
    let showingColumns = []
    if (hiddenColumns.length) {
      columns.forEach(col => {
        if (hiddenColumns.findIndex(hc => hc === col.id) === -1) {
          showingColumns.push(col)
        }
      })
    } else {
      showingColumns = [...columns]
    }
    const availableWidth =
      window.innerWidth -
      subtractWidth -
      leftMenuWidth -
      widthPadding -
      showingColumns.length * 2 -
      35 //space for borders and padding
    const widthSpecified = showingColumns.reduce(
      (sum, {width = 0}) => sum + width,
      0,
    )
    const colsWithoutWidth = showingColumns.filter(c => !c.width).length
    let spreadWidth = (availableWidth - widthSpecified) / colsWithoutWidth
    if (spreadWidth < 100) spreadWidth = 100
    const newColumns = columns.map(col => {
      if (hiddenColumns.findIndex(hc => hc === col.id) === -1) {
        return col.width ? col : {...col, width: spreadWidth}
      } else {
        return {...col, width: 0}
      }
    })
    return newColumns
    //since we are converting to resizable I don't think it's worth the work to fix the hidden columns - Jade
    //to-do?- adding hidden columns causes max depth error when no hiddencolumns exist
    //This shouldn't be a problem because hiddenColumns will always exist when this component is built
    //but something to look into
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, subtractWidth, leftMenuWidth, widthPadding])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    rows,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize, globalFilter},
    exportData,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns: cols || [],
      data: memoizedData,
      defaultColumn,
      getExportFileBlob: exportFile,
      sortTypes: sortTypes,
      initialState: {
        sortBy: initSort,
        pageSize: initPageSize,
        hiddenColumns: hiddenColumns,
        filters: initialFilters?.filters || [],
        globalFilter: initialFilters?.globalFilter || '',
      },
      // autoResetPage: skipPageReset === undefined ? true : !skipPageReset,
      autoResetPage: !skipPageResetRef.current,
      autoResetGlobalFilter: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
    },
    useResizeColumns,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useExportData,
    useSticky,
  )

  const headerProps = (props, {column}) => getStyles(props, column.align)
  const cellProps = (props, {cell}) => getStyles(props, cell.column.align)
  const getStyles = (props, align = 'left') => [
    props,
    {
      style: {
        justifyContent:
          align === 'right'
            ? 'flex-end'
            : align === 'center'
            ? 'center'
            : 'flex-start',
        display: 'flex',
      },
    },
  ]

  React.useEffect(() => {
    if (rows.length >= 0) {
      const tableContainer = document.getElementsByClassName('table')
      if (tableContainer) {
        tableContainer[0].scrollTop = 0
      }
    }
  }, [rows.length])

  return (
    <Styles
      className={className}
      rowClick={rowClick}
      id="styles"
      // showCard={showCard}
    >
      {!hideHeader && (
        <>
          <StyledMenu className={'styled-menu'}>
            <TitleButtons className={'title-buttons'}>
              {!hiddenTitle && tableTitle && (
                <TitleAndBadge>
                  <h4>{tableTitle}</h4>{' '}
                  <ItemBadgeContainer>
                    <ItemBadge className="item-badge">
                      {rows?.length !== memoizedData.length
                        ? `${nFormatter(rows.length, 0)} of ${nFormatter(
                            memoizedData.length,
                            0,
                          )} `
                        : nFormatter(memoizedData.length, 0)}
                    </ItemBadge>
                  </ItemBadgeContainer>
                </TitleAndBadge>
              )}
            </TitleButtons>
            <TitleButtons>
              {!hiddenGlobalFilter &&
                ((status && status === 'success') || status === undefined) && (
                  <GlobalFilter
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    totalCount={preGlobalFilteredRows.length}
                  />
                )}
              <ButtonsSection className={'button-section'}>
                {tableTitleButtons?.length &&
                  tableTitleButtons.map((item, index) => {
                    return (
                      <TitleButton
                        className={'title-button'}
                        key={index}
                        onClick={item.onClick}
                      >
                        {item.icon && <Icon name={item.icon}></Icon>}
                        <span>{item.title}</span>
                      </TitleButton>
                    )
                  })}
                {customTableButton && customTableButton}
                {React.Children.map(children, child =>
                  React.cloneElement(child, {tableRows: rows}),
                )}
              </ButtonsSection>
            </TitleButtons>
            {(status && status === 'success') || status === undefined ? (
              <TableButtons
                isMobile={isMobile}
                exportData={exportData}
                newButton={newButton}
                hiddenNewButton={hiddenNewButton}
                hiddenExportButton={isMobile || hiddenExportButton}
                dateRange={dateRange}
                hiddenGlobalFilter={hiddenGlobalFilter}
                // showCard={showCard}
              />
            ) : (
              <TableButtonsPlaceHolder />
            )}
          </StyledMenu>

          {!hiddenGlobalFilter && (
            <MobileMenu
              className={'styled-menu'}
              // showCard={showCard}
              newButton={newButton}
              hiddenNewButton={hiddenNewButton}
            >
              <TitleButtons className={'title-buttons'}>
                {tableTitle && (
                  <TitleAndBadge>
                    <h4>{tableTitle}</h4>{' '}
                    <ItemBadgeContainer>
                      <ItemBadge>
                        {rows?.length !== memoizedData.length
                          ? `${nFormatter(rows.length, 0)} of ${nFormatter(
                              memoizedData.length,
                              0,
                            )} `
                          : nFormatter(memoizedData.length, 0)}
                      </ItemBadge>
                    </ItemBadgeContainer>
                  </TitleAndBadge>
                )}
              </TitleButtons>
              <TitleButtons>
                {!hiddenGlobalFilter && (
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                )}

                <ButtonsSection className={'button-section'}>
                  {tableTitleButtons?.length &&
                    tableTitleButtons.map((item, index) => {
                      return (
                        <TitleButton
                          className={'title-button'}
                          key={index}
                          onClick={item.onClick}
                        >
                          {item.icon && <Icon name={item.icon}></Icon>}
                          <span>{item.title}</span>
                        </TitleButton>
                      )
                    })}
                </ButtonsSection>
              </TitleButtons>
              {status === 'success' && (
                <TableButtons
                  isMobile={isMobile}
                  exportData={exportData}
                  newButton={newButton}
                  hiddenNewButton={hiddenNewButton}
                  hiddenExportButton={isMobile || hiddenExportButton}
                  dateRange={dateRange}
                  // showCard={showCard}
                />
              )}
            </MobileMenu>
          )}
        </>
      )}
      <Table {...getTableProps()} className="table sticky" ref={scrollRef}>
        <TableHeadersAll className="header">
          {headerGroups.map((headerGroup, index) => (
            <Fragment key={`hgtr-${index}`}>
              <div
                {...headerGroup.getHeaderGroupProps({
                  // style: { paddingRight: '15px' },
                })}
              >
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting.
                  <TableHeaders
                    // key={`hgth-${colIndex}`}
                    {...column.getHeaderProps(headerProps)}
                    className={`th ${
                      index !== headerGroups.length - 1 &&
                      column?.Header?.name !== undefined &&
                      'groupingHeader'
                    }`}
                    // showCard={showCard}
                  >
                    <span
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="td"
                    >
                      <Header
                        id="table-header"
                        sorted={
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'desc'
                              : 'asc'
                            : ''
                        }
                        align={column?.align}
                        // showCard={showCard}
                      >
                        {/* {JSON.stringify(column)} */}
                        <span>{column.render('Header')}</span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortedArrow>
                              <ArrowDown weight={'bold'} />
                            </SortedArrow>
                          ) : (
                            <SortedArrow>
                              <ArrowUp weight={'bold'} />
                            </SortedArrow>
                          )
                        ) : (
                          <div />
                        )}
                      </Header>
                    </span>
                    {/* Use column.getResizerProps to hook up the events correctly */}
                    {column.canResize && index === headerGroups.length - 1 && (
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${
                          column.isResizing ? 'isResizing' : ''
                        }`}
                      />
                    )}
                  </TableHeaders>
                ))}
              </div>
              {/* {filterTable && index === headerGroups.length - 1 && (
                <div className="tr subheader">
                  {headerGroup.headers.map((column, colIndex) => (
                    <TableSubHeaders
                      className="th"
                      key={`shth-${colIndex}`}
                      {...column.getHeaderProps(headerProps)}
                    >
                      {column.canFilter ? column.render('Filter') : null}
                    </TableSubHeaders>
                  ))}
                </div>
              )} */}
            </Fragment>
          ))}
        </TableHeadersAll>
        <Body
          {...getTableBodyProps()}
          ref={bodyRef}
          showingPagination={rows.length > pageSize}
          className="body"
          // showCard={showCard}
          height={isMobile ? 'calc(100vh - 225px)' : height}
          hasPieChart={hasPieChart}
        >
          {status === 'loading' ? (
            <Spinner type={'partial'} />
          ) : rows.length > 0 ? (
            <>
              {page.map((row, index) => {
                prepareRow(row)
                return (
                  <StyledTableRow
                    index={index}
                    data-cy="tableRow"
                    // showCard={showCard}
                    onClick={rowClick ? () => rowClick(row) : null}
                    {...row.getRowProps()}
                    className={
                      selectedRow
                        ? row?.original[selectedRow.field] === selectedRow.id
                          ? 'tr active-item'
                          : 'tr'
                        : selectedRowArray &&
                          selectedRowArray.id.find(
                            item =>
                              row?.original[selectedRowArray.field] === item,
                          )
                        ? 'tr active-item'
                        : 'tr'
                    }
                  >
                    {row.cells.map(cell => {
                      return (
                        <div {...cell.getCellProps(cellProps)} className="td">
                          {cell.render('Cell')}
                        </div>
                      )
                    })}
                  </StyledTableRow>
                )
              })}
              {/* <NoRecordsRow colSpan={100} data-cy="noRecords">
                You&apos;ve reached the end of the list.
              </NoRecordsRow> */}
            </>
          ) : (
            <div>
              <NoRecordsRow colSpan={100} data-cy="noRecords">
                No records found
              </NoRecordsRow>
            </div>
          )}
        </Body>
      </Table>
      <div>
        {rows.length > pageSize && (
          <TablePaginationControls
            scrollRef={scrollRef}
            nextPage={nextPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            canNextPage={canNextPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </div>
    </Styles>
  )
}

export default React.memo(TableWithHooks)
