import {UserConfig, useStore} from './useStore'
import {utcToZonedTime, format, zonedTimeToUtc} from 'date-fns-tz'
import {formatISO, parse, isValid} from 'date-fns'
import {StopType} from 'routingAndDispatch/types/typesModule'
import timezones from './timezones'
import {HistoryData} from 'driverSafetyDashboard/safetyHelpers'
import {Range} from 'react-date-range'

export function isValidDate(d: Date) {
  // @ts-expect-error could be a number representing a date
  return d instanceof Date && !isNaN(d)
}

export const convertToUsersDateFormat = function (
  date: Date | string,
  userConfig?: UserConfig,
) {
  var d = new Date(date)
  if (!userConfig) {
    userConfig = useStore.getState().userConfig || undefined
  }
  if (!isValidDate(d)) return date
  if (userConfig && userConfig.Date_Format) {
    return format(d, userConfig.Date_Format.replace('mm', 'MM'))
  } else {
    return format(d, 'MM/dd/yyyy')
  }
}
export const convertToUsersTimeFormat = function (
  date: Date | string,
  userConfig?: UserConfig,
) {
  var d = new Date(date)
  if (!userConfig) {
    userConfig = useStore.getState().userConfig || undefined
  }
  if (userConfig && userConfig.Time_Format) {
    return userConfig.Time_Format === '12HR'
      ? format(d, 'h:mm a')
      : format(d, 'HH:mm')
  } else {
    return format(d, 'h:mm a')
  }
}

export const convertToUsersDateAndTimeFormat = function (date: Date) {
  const userConfig = useStore.getState().userConfig
  var d = new Date(date)
  if (!isValidDate(d)) return date
  let returnValue
  if (userConfig && userConfig.Date_Format) {
    returnValue = format(d, userConfig.Date_Format.replace('mm', 'MM'))
  } else {
    returnValue = format(d, 'MM/dd/yyyy')
  }
  if (userConfig && userConfig.Time_Format) {
    returnValue += ` ${
      userConfig.Time_Format === '12HR'
        ? format(d, 'h:mm a')
        : format(d, 'HH:mm')
    }`
  } else {
    returnValue += ` ${format(d, 'h:mm a')}`
  }
  return returnValue
}

export const convertToTimeFromUsersDateFormat = (formattedDate: string) => {
  const userConfig = useStore.getState().userConfig
  if (userConfig?.Date_Format && formattedDate) {
    return parse(formattedDate, userConfig?.Date_Format, new Date())
  } else {
    return null
  }
}

export const convertToUserUTCformat = (dateUTC: Date) => {
  const userConfig = useStore.getState().userConfig
  if (dateUTC) {
    const isoDate = formatISO(dateUTC)
    //convert to utc time zone then convert to user time zone
    if (userConfig) {
      if (userConfig.TZ_Moment_Name) {
        return utcToZonedTime(isoDate, userConfig.TZ_Moment_Name)
      }
    }
    return new Date(isoDate)
  }
}

export const timezoneOptions = timezones.map(t => ({
  key: t.TZ_Index,
  value: t.TZ_Index,
  text: t.TZ_Description,
}))
export const customerTimezoneOptions = timezones.map(t => ({
  key: t.TZ_Index,
  value: t.TZ_Index,
  name: t.TZ_Description,
}))
export const dateFormats = [
  {key: 'mm/dd/yyyy', value: 'mm/dd/yyyy', text: 'mm/dd/yyyy'},
  {key: 'dd/mm/yyyy', value: 'dd/mm/yyyy', text: 'dd/mm/yyyy'},
  {key: 'yyyy/mm/dd', value: 'yyyy/mm/dd', text: 'yyyy/mm/dd'},
]
export const timeFormats = [
  {key: '12HR', value: '12HR', text: '12 HR'},
  {key: '24HR', value: '24HR', text: '24 HR'},
]
export const dSTOptions = [
  {key: 'true', value: true, text: 'Yes'},
  {key: 'false', value: false, text: 'No'},
]

export const getBeforeAfterStringForAPI = (
  dateRange: {startDate: Date; endDate?: Date},
  inspectionsFormat?: boolean,
) => {
  const user = useStore.getState().userConfig
  let dateString = ''
  if (user) {
    const utcStartDate = utcToZonedTime(
      dateRange.startDate,
      user.TZ_Moment_Name,
    )
    const utcEndDate = dateRange.endDate
      ? utcToZonedTime(dateRange.endDate, user.TZ_Moment_Name)
      : null

    dateString = `${inspectionsFormat ? 'startedAfter' : 'after'}=${format(
      utcStartDate,
      "yyyy-MM-dd'T'HH:mm:ss",
    )}`
    if (utcEndDate) {
      dateString += `&${
        inspectionsFormat ? 'startedBefore' : 'before'
      }=${format(utcEndDate, "yyyy-MM-dd'T'HH:mm:ss")}`
    }
  }
  return dateString
}

export const changeTimeZone = (date: Date, timeZone: string) => {
  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  )
}

// export const returnTimeInUsersTimeZone = value => {
//   const user = useStore.getState().userConfig
//   const timezone = user.TZ_Moment_Name
//   return value ? utcToZonedTime(value, timezone) : null
// }

export const convertFromUsersTimeZone = (value: string) => {
  const user = useStore.getState().userConfig
  if (user) {
    const timezone = user.TZ_Moment_Name
    return value ? zonedTimeToUtc(value, timezone) : null
  }
  return null
}

export const returnDateTimeFromUsersDateTimeString = (dateString: string) => {
  const userConfig = useStore.getState().userConfig
  if (dateString && userConfig?.Date_Format) {
    const date = parse(dateString, userConfig?.Date_Format, new Date())
    return date
  }
  return null
}

export const formatDate = (value: Date) => {
  const user = useStore.getState().userConfig

  return value
    ? format(new Date(value), user?.Date_Format ? user.Date_Format : 'M/d/yyyy')
    : null
}

export const getServiceWindowString = (stop: StopType) => {
  let windowString = ''
  if (stop.ServiceStartDateTime && isValid(stop.ServiceStartDateTime)) {
    windowString += convertToUsersTimeFormat(stop.ServiceStartDateTime)
  }
  windowString += ' - '
  if (stop.ServiceEndDateTime && isValid(stop.ServiceEndDateTime)) {
    windowString += convertToUsersTimeFormat(stop.ServiceEndDateTime)
  }
  return windowString
}

export const incidentDateFormat = (userConfig: UserConfig | null) => {
  return (
    (userConfig?.Date_Format || '') +
    (userConfig?.Time_Format === '12HR' ? ' h:mm:ss a' : ' HH:mm:ss')
  )
}

export const getIncidentFormattedDateRange = ({
  historyData,
  dateRange,
  userConfig,
}: {
  historyData: HistoryData | undefined
  dateRange: Range | undefined
  userConfig: UserConfig | null
}) => {
  const startFormattedDate = format(
    parse(
      historyData?.startTimestampLocal ||
        format(
          dateRange?.startDate || new Date(),
          incidentDateFormat(userConfig),
        ),
      incidentDateFormat(userConfig),
      new Date(),
    ),
    userConfig?.Date_Format || 'mm/dd/yyyy',
  )
  const endFormattedDate = format(
    parse(
      historyData?.endTimestampLocal ||
        format(
          dateRange?.endDate || new Date(),
          incidentDateFormat(userConfig),
        ),
      incidentDateFormat(userConfig),
      new Date(),
    ),
    userConfig?.Date_Format || 'mm/dd/yyyy',
  )
  return startFormattedDate + ' - ' + endFormattedDate
}
