import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {useMapStore} from 'map/useMapStore'
import {calcGutAssetValues} from 'gut/gutHelpers'

const getOpening1 = async () => {
  const {data} = await Axios.get('/opening/1')
  if (data) {
    const newCounts = calcGutAssetValues(data.assets)
    useMapStore.setState({
      gutAssetData: newCounts,
    })
  }
  return data
}

const refetchIntervalMs = 1000 * 60 * 5 // 1000 * 60 * 5 = 5 minutes
const staleTimeMs = 1000 * 10 //don't refetch if fetched within the last 10 seconds

export default function useOpening1() {
  const user = useStore(state => state.user)
  return useQuery(['opening1'], getOpening1, {
    enabled: Boolean(user?.token),
    refetchInterval: refetchIntervalMs,
    staleTime: staleTimeMs,
  });
}
