import * as React from 'react'
import styled from 'styled-components/macro'
import LiveLook from 'common/components/LiveLook'
import SurfSightLiveFeed from 'cameraEvents/SurfSightLiveFeed'
import Modal from 'common/Modal'

const StyledModal = styled(props => <Modal {...props} />)`
  display: flex;
  > div {
    width: ${p => (p.windowWidth ? p.windowWidth : 'fit-content')} !important;
    height: fit-content;
  }
`

const CameraModal = ({showLiveView, setState, asset}) => {
  const [assetLabel, setAssetLabel] = React.useState(asset?.Asset_Label || '')

  return (
    <StyledModal
      showDialog={showLiveView && asset}
      title={assetLabel}
      onDismiss={() => setState({showLiveView: false})}
      windowWidth={asset?.Device?.idDevice_Type === 35 ? '700px' : '1500px'}
    >
      {asset?.Device?.idDevice_Type === 35 ? (
        <SurfSightLiveFeed
          idDevice={asset?.idDevice}
          setAssetLabel={value => setAssetLabel(value)}
        />
      ) : (
        <LiveLook idAsset={asset?.idAsset} />
      )}
    </StyledModal>
  )
}
export default CameraModal
