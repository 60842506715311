import Axios from 'axios'
import {useMutation, useQueryClient} from '@tanstack/react-query'

function usePutAssetsAndUsers() {
  const cache = useQueryClient()
  return useMutation(async assetsAndUsers => {
    if (assetsAndUsers.idGroup) {
      const {data} = await Axios.put(
        `/groups/update/assetsandusers`,
        assetsAndUsers,
      )
      cache.invalidateQueries(['groups'])
      cache.invalidateQueries(['groupDetail', `${assetsAndUsers.idGroup}`])
      cache.invalidateQueries(['useGroupUsers', `${assetsAndUsers.idGroup}`])
      return data
    }
  });
}

export default usePutAssetsAndUsers
