import * as React from 'react'
import styled from 'styled-components/macro'
import {useAsyncDebounce} from 'react-table'
import {QUERIES} from 'common/constants'
import {applyGlobalFilter} from 'grid/gridTableHelpers'
import {Filters, Column} from 'grid/gridTypes'
import {FilterAssetsFromListProps} from 'opening/openingHelpers'
import {useGridFilterStore} from './useGridFilterStore'

const Wrapper = styled.span`
  margin: 0 10px 0 15px;
  @media ${QUERIES.tabletAndUp} {
    margin-bottom: 0;
    flex: inherit;
    height: 100%;
  }
  max-width: 300px;
`

const Input = styled.input`
  border: 1px solid var(--asc-graphite);
  border-radius: 7px;
  padding: 3px 10px;
  display: flex;
  @media ${QUERIES.tabletAndUp} {
    min-width: 250px;
    max-width: 300px;
  }
  :focus-visible {
    border: 1px solid transparent;
    outline: 1px solid var(--asc-darkolive) !important;
    box-shadow: 0 0px 5px 0 var(--asc-lime);
  }
`

type Props = {
  filters: Filters
  setFilters: (filters: Filters) => void
  totalAssetCount: number
  filterAssetsFromList: (props: FilterAssetsFromListProps) => void
  columns: Column[]
}

const GlobalFilter = ({
  totalAssetCount,
  filterAssetsFromList,
  columns,
}: Props) => {
  const globalFilter = useGridFilterStore(state => state.globalFilter)
  const columnFilters = useGridFilterStore(state => state.columnFilters)
  const setGlobalFilter = useGridFilterStore(state => state.setGlobalFilter)
  const onChange = useAsyncDebounce((value: string) => {
    const currentFilters = {
      globalFilter,
      columnFilters,
    }
    const newFilters = applyGlobalFilter({value, filters: currentFilters})
    setGlobalFilter(value)
    filterAssetsFromList({
      gridFilters: {
        filters: newFilters,
        columns,
      },
    })
  }, 200)

  return (
    <Wrapper>
      <Input
        value={globalFilter || ''}
        onChange={e => {
          setGlobalFilter(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`Search ${totalAssetCount} records...`}
        data-lpignore="true"
      />
    </Wrapper>
  )
}

export default GlobalFilter
