import * as React from 'react'
import useOpening1 from 'opening/hooks/useOpening1'
import {getClosestAssets} from './findNearestAssetsHelpers'
import {useStore} from 'common/useStore'

const FindNearestAssets = ({map, lat, lng, clearAssetFilter}) => {
  const setGridOpen = useStore(state => state.setGridOpen)
  const setAssetListOpen = useStore(state => state.setAssetListOpen)
  const {data: opening1} = useOpening1()
  const assets = opening1?.assets
  const [latlng, setLatlng] = React.useState(null)

  const findAssets = () => {
    clearAssetFilter()
    getClosestAssets({
      latlng,
      assets,
      setGridOpen,
      setAssetListOpen,
      map,
    })
  }

  React.useEffect(() => {
    let newLatlng = null
    if (lat && lng) {
      newLatlng = new window.google.maps.LatLng(lat, lng)
      setLatlng(newLatlng)
    }
  }, [lat, lng])

  return (
    <div onClick={findAssets}>
      <span data-cy="find-nearest-assets">Find Nearest Assets</span>
    </div>
  )
}

export default FindNearestAssets
