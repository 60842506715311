import * as React from 'react'
import styled from 'styled-components/macro'
import {Icon} from 'semantic-ui-react'
import {thresholdclass} from './gridMapContainerHelpers'
import {
  oneDecimalRender,
  formatDateTime,
} from 'common/tables/tableWithHooksHelpers'

const AlertIcon = styled(Icon)`
  &&& {
    margin-top: -3px;
  }
`

const FieldDataContent = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  justify-content: ${p => (p.type === 'number' ? 'flex-end' : 'flex-start')};
`
const DataContent = styled.div`
  display: flex;
  align-items: center;
  padding-left: 7px;
  color: #252525;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 95%;
  display: block;
  overflow: hidden;
`
const Tooltip = styled.div`
  &&& {
    background: white;
    padding: 5px;
    position: fixed;
    margin-top: -5px;
    margin-left: ${p => (p.marginLeft ? `${p.marginLeft - 18}px` : '0px')};
  }
`

const useIsOverflow = (ref, callback) => {
  const [isOverflow, setIsOverflow] = React.useState(undefined)

  React.useLayoutEffect(() => {
    const {current} = ref

    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth

      setIsOverflow(hasOverflow)

      if (callback) callback(hasOverflow)
    }

    if (current) {
      trigger()
    }
  }, [callback, ref])

  return isOverflow
}

const RenderCell = (row, field, userDateTimeFormat) => {
  const dataRef = React.useRef()
  const [showTooltip, setShowTooltip] = React.useState(false)
  const {value} = row
  let dataField = value || ''
  if (field.type === 'number' && row?.value) {
    dataField = oneDecimalRender(row, field.precision)
  } else if (field.type === 'date' && row?.value) {
    dataField = formatDateTime(row, userDateTimeFormat)
  }

  const alertClass = field?.threshold ? thresholdclass(field, value, row) : ''

  const isOverflow = useIsOverflow(dataRef)

  return (
    <FieldDataContent
      type={field.type}
      isOverflow={isOverflow}
      showTooltip={showTooltip}
    >
      <DataContent
        ref={dataRef}
        onMouseOver={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}
      >
        {alertClass && (
          <AlertIcon name="exclamation triangle" color={alertClass} />
        )}
        {dataField !== '' ? `${dataField} ${field?.UOM || ''}` : ''}
      </DataContent>
      {isOverflow && showTooltip && (
        <Tooltip marginLeft={field.width}>
          {dataField !== '' ? `${dataField} ${field?.UOM || ''}` : ''}
        </Tooltip>
      )}
    </FieldDataContent>
  )
}

export default RenderCell
