import * as React from 'react'
import {GOOGLE_KEYS, MARKER_COLORS, MarkerColor} from 'common/constants'
import GoogleMapReact from 'google-map-react'
import {MapContainer} from './routeDetailStyles'
import {
  createMapOptions,
  // onMapLoad,
  drawHistoryTrail,
  getSegmentLatLngs,
  renderRoutes,
} from './routeDetailHelpers'

import {useRouteDetailStore} from './hooks/useRouteDetailStore'

import Marker from 'map/Marker'
import {DesktopInfoWindow} from 'map/mapStyles'
import {AlertModal} from 'common/components'
import {AssetType} from 'assets/assetDetailTypes'
import {
  MapMarker,
  RouteDetailMapState,
  initialState,
  reducer,
} from './routeDetailMapHelpers'
import {AssetWithColor} from '../PlanReview/planReviewHelper'
import {SegmentWithColor} from '../PlanReview/PlanReview'
import RenderStop from './RenderStop'
import {HistoryTrailPoints} from 'historyTrail/hooks/historyTrailTypes'
import {ActionTypes} from 'common/types/typesModule'
// import {useHistoryTrailStore} from 'historyTrail/hooks/useHistoryTrailStore'

interface MapProps {
  segmentArray: SegmentWithColor[] | null
  //detailed asset information
  assignedAssets?: AssetWithColor[]
  historyTrailPoints?: HistoryTrailPoints
  // mapRef: React.MutableRefObject<{map: google.maps.Map} | undefined> | undefined
  selectedSegments?: string[]
  detail: boolean //true if on route detail rather than plan summary
}

const RouteDetailMap: React.FC<MapProps> = ({
  segmentArray,
  assignedAssets,
  historyTrailPoints,
  // mapRef,
  selectedSegments,
  detail,
}) => {
  const map = useRouteDetailStore(state => state.map)
  const setMap = useRouteDetailStore(state => state.setMap)
  const emptyStore = useRouteDetailStore(state => state.emptyStore)
  const removeDirection = useRouteDetailStore(state => state.removeDirection)

  const [state, dispatch]: [
    state: RouteDetailMapState,
    dispatch: (action: ActionTypes) => void,
  ] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    if (detail && segmentArray && map) {
      let newBounds: google.maps.LatLngBounds =
        new window.google.maps.LatLngBounds()
      if (historyTrailPoints?.length && !state.historyTrailDrawn) {
        newBounds = drawHistoryTrail({
          data: historyTrailPoints,
          map,
          bounds: newBounds,
        })

        dispatch({type: 'setState', data: {historyTrailDrawn: true}})
      }
      const segment = segmentArray[0]
      if (state.routeDrawnStopCount !== segment.stops.length) {
        removeDirection(segment.idRouteDispatchDriver)
        const points = getSegmentLatLngs(segment)
        renderRoutes({
          points,
          color: '#5f3b0c',
          bounds: newBounds,
          id: segment.idRouteDispatchDriver,
          type: 'add', //this will make sure it renders even if called multiple times
        })

        const padding = {top: 100, right: 100, bottom: 100, left: 100}

        map.fitBounds(newBounds, padding)

        dispatch({
          type: 'setState',
          data: {routeDrawnStopCount: segment.stops.length},
        })
      }
    } else if (!detail && segmentArray && map) {
      let bounds = new window.google.maps.LatLngBounds()
      segmentArray.forEach(segment => {
        if (!selectedSegments || selectedSegments?.length === 0) {
          const location = {
            lat: segment.stops[0].location.lat,
            lng: segment.stops[0].location.lng,
          }
          segment.stops[0].location && bounds.extend(location)
          if (assignedAssets && assignedAssets.length > 0) {
            assignedAssets.forEach(asset => {
              if (asset.Device.Latitude && asset.Device.Longitude) {
                const assetLocation = {
                  lat: asset.Device.Latitude,
                  lng: asset.Device.Longitude,
                }
                bounds.extend(assetLocation)
              }
            })
          }
        } else {
          const isSelected = selectedSegments?.find(segmentId => {
            return segmentId === segment.idRouteDispatchDriver
          })
          if (isSelected)
            segment.stops.forEach(stop => {
              //ignore stops that have no location, found from idPlan 958 Khalid Route 1017 - 3
              if (stop.location.lat !== 0 || stop.location.lng !== 0) {
                const location = {
                  lat: stop.location.lat,
                  lng: stop.location.lng,
                }
                stop.location && bounds.extend(location)
              }
            })
        }
      })
      map.fitBounds(bounds)
    }
  }, [
    assignedAssets,
    detail,
    historyTrailPoints,
    map,
    removeDirection,
    segmentArray,
    selectedSegments,
    state.historyTrailDrawn,
    state.routeDrawnStopCount,
  ])

  React.useEffect(() => {
    return () => {
      emptyStore()
    }
  }, [emptyStore])

  const clickedMarker = (asset?: AssetType) => {
    if (assignedAssets?.length) {
      if (asset) {
        dispatch({
          type: 'setState',
          data: {showInfoWindow: true, clickedAsset: asset},
        })
      } else {
        dispatch({type: 'setState', data: {showInfoWindow: true}})
      }
    }
  }

  const toggleStreetView = () => {
    if (state.clickedAsset && map) {
      let panorama = map.getStreetView()
      if (panorama) {
        const toggle = panorama.getVisible()
        if (toggle === false) {
          const sv = new window.google.maps.StreetViewService()
          if (
            !state.clickedAsset.Device.Latitude ||
            !state.clickedAsset.Device.Longitude
          ) {
            dispatch({
              type: 'setState',
              data: {
                dialogTitle: 'No location data',
                dialogText: 'No location data found for this asset.',
                showDialog: true,
              },
            })
          } else {
            const position = {
              lat: state.clickedAsset.Device.Latitude,
              lng: state.clickedAsset.Device.Longitude,
            }
            /**Check in a perimeter of 1000 meters**/
            const checkaround = 1000
            //@ts-expect-error -- google type is missing this call for some reason
            sv.getPanoramaByLocation(
              position,
              checkaround,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              function (data: any, status: any) {
                if (
                  status === window.google.maps.StreetViewStatus.OK &&
                  panorama &&
                  state.clickedAsset
                ) {
                  panorama.setPano(data.location.pano)
                  panorama.setPov({
                    heading: state.clickedAsset.Device.Last_Heading || 0,
                    pitch: 0,
                  })
                  panorama.setVisible(true)
                } else {
                  dispatch({
                    type: 'setState',
                    data: {
                      dialogTitle: 'Street View not available',
                      dialogText:
                        'Street view is not available in this location.',
                      showDialog: true,
                    },
                  })
                }
              },
            )
          }
        } else {
          panorama.setVisible(false)
        }
      }
    }
  }

  return (
    <MapContainer data-cy={'route-detail-map'}>
      {segmentArray && (
        <GoogleMapReact
          bootstrapURLKeys={GOOGLE_KEYS}
          defaultCenter={{lat: 38.09024, lng: -95.712891}}
          defaultZoom={3}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map}) => {
            setMap(map)
            // setMaps(maps)
          }}
        >
          {state.showInfoWindow && state.clickedAsset && (
            <DesktopInfoWindow
              lat={state.clickedAsset.Device.Latitude}
              lng={state.clickedAsset.Device.Longitude}
              closeInfoWindow={() => {
                dispatch({type: 'setState', data: {showInfoWindow: false}})
              }}
              clearAssetFilter={undefined}
              activeMarker={{
                following: false,
                heading: undefined,
                idAsset: state.clickedAsset.idAsset,
                idDevice: state.clickedAsset.idDevice,
                label: state.clickedAsset.Asset_Label,
                lat: state.clickedAsset.Device.Latitude,
                lng: state.clickedAsset.Device.Longitude,
              }}
              map={map}
              toggleFollow={() => null}
              toggleStreetView={toggleStreetView}
              assets={[state.clickedAsset]}
              isMobile={false}
              fromRouteDetail={true}
              left={'-350px'}
              bottom={'30px'}
            />
          )}
          {segmentArray &&
            !selectedSegments?.length &&
            segmentArray.map((segment, index) => (
              <MapMarker
                key={index}
                lat={segment.stops[0]?.location.lat || 0}
                lng={segment.stops[0]?.location.lng || 0}
                text={
                  segment.driverName.split(' ')[0].charAt(0) +
                  segment.driverName.split(' ')[1].charAt(0)
                }
                segment={segment}
                plan={undefined}
              />
            ))}
          {assignedAssets &&
            assignedAssets.length > 0 &&
            assignedAssets.map((thisAssignedAsset, index) => {
              return thisAssignedAsset.Device.Latitude &&
                thisAssignedAsset.Device.Longitude ? (
                <Marker
                  key={`${thisAssignedAsset.idAsset}-${index}`}
                  onClick={() => clickedMarker(thisAssignedAsset)}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore */
                  // ignore lat & lng as props since Marker doesn't use them but they are required for Google Maps
                  lat={thisAssignedAsset.Device.Latitude}
                  lng={thisAssignedAsset.Device.Longitude}
                  point={{
                    geometery: {
                      coordinates: [
                        thisAssignedAsset.Device.Longitude,
                        thisAssignedAsset.Device.Latitude,
                      ],
                    },
                    properties: {
                      ...thisAssignedAsset,
                      color:
                        MARKER_COLORS[
                          thisAssignedAsset.Icon_Color as keyof MarkerColor
                        ],
                    },
                    type: 'Feature',
                  }}
                  showDriverName={thisAssignedAsset.CurrentDriver || ''}
                />
              ) : (
                <></>
              )
            })}
          {/* Display start and end markers for segments */}
          {/* {segmentArray && segmentArray.map(segment => renderSegment(segment))} */}
          {segmentArray.map(segment => {
            const selected =
              !selectedSegments ||
              selectedSegments.find(
                seg => seg === segment.idRouteDispatchDriver,
              )
            if (selected) {
              return segment.stops.map((stop, index) => (
                <RenderStop
                  key={index}
                  index={index}
                  stop={stop}
                  segmentToRender={segment}
                  lat={Number(stop.location.lat)}
                  lng={Number(stop.location.lng)}
                  detail={detail}
                />
              ))
            } else {
              return null
            }
          })}
        </GoogleMapReact>
      )}
      <AlertModal
        showDialog={state.showDialog}
        onSave={() => {
          dispatch({type: 'setState', data: {showDialog: false}})
        }}
        type={'info'}
        title={state.dialogTitle}
        text={state.dialogText}
        showDenyButton={false}
      />
    </MapContainer>
  )
}
export default RouteDetailMap
