import * as React from 'react'
import styled from 'styled-components/macro'
import {format} from 'date-fns'
import Modal from 'common/Modal'
import {useStore} from 'common/useStore'
import {
  TEMEDA_REPORTING_SERVER,
  AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE,
} from 'common/constants'
import {getAppFlavorBrandingImage} from 'reports/reportHelpers'
import useOpening2 from 'opening/hooks/useOpening2'
import Spinner from './Spinner'

const Container = styled.div`
  padding: 10px;
`

const List = styled.ul`
  height: 400px;
  overflow-y: scroll;
  margin: 10px;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 0;
`

const Item = styled.li`
  list-style: none;
  padding: 5px;
  border-bottom: 1px solid var(--table-borders);
  cursor: pointer;
  :hover {
    background-color: var(--grey-100);
  }
`

const createReport = (
  reportType,
  idAsset,
  assetLabel,
  userConfig,
  user,
  password,
) => {
  //Report Definitions
  const currentDate = new Date()
  const startDate = format(currentDate, 'MM/dd/yyyy') + '+00:00'
  const endDate = format(currentDate, 'MM/dd/yyyy') + '+23:59'
  const startDateFormat =
    format(currentDate, userConfig.Date_Format) + ' 12:00 am '
  const endDateFormat =
    format(currentDate, userConfig.Date_Format) + ' 11:59 pm '
  const rdURL = reportType
  if (reportType) {
    const formData = {
      //'rdReport': asset.reportSelected.reportName + urlExtension,
      rdReport: rdURL,
      ihVelocity: userConfig.Speed_UOM,
      ihDistance: userConfig.Distance_UOM,
      ihFuelEconomy: userConfig.Fuel_Rate_UOM.toLowerCase(),
      ihTemperature: userConfig.Temperature_UOM,
      ihVolume: userConfig.Volume_UOM,
      ihSelectedAsset: encodeURIComponent(assetLabel),
      islAssets: idAsset,
      irbAssetSelOptions: 'Asset',
      islDateRange: 'Today',
      inpStartDate: startDate,
      inpEndDate: endDate,
      inpStartDateDisp: startDateFormat,
      inpEndDateDisp: endDateFormat,
      username: user,
      password: password,
      token: 'Basic ' + window.btoa(user + ':' + password),
      idUser: userConfig.idUser,
      brandingImage: getAppFlavorBrandingImage(userConfig),
      ids: idAsset,
      dateselect: true,
      rowCount: 50,
      fuelCost: userConfig.Volume_UOM.toLowerCase() === 'l' ? '0.83' : '3.33',
    }

    formData.reportingServer = TEMEDA_REPORTING_SERVER
    runReport(formData)
  }
}

function fixBrandingImage(brandingImage, azureImagesStorageAccountUrlBase) {
  const splitImageString = brandingImage.split('/')
  splitImageString[0] = azureImagesStorageAccountUrlBase
  return splitImageString.join('/')
}

function runReport(formData) {
  if (formData.brandingImage && AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE) {
    const azureImagesStorageAccountUrlBase =
      AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE.substring(
        8,
        AZURE_IMAGE_STORAGE_ACCOUNT_URL_BASE.length,
      )
    formData.brandingImage = fixBrandingImage(
      formData.brandingImage,
      azureImagesStorageAccountUrlBase,
    )
  }
  const form = document.createElement('form')
  form.target = '_blank'
  form.setAttribute('method', 'post')
  form.setAttribute('action', TEMEDA_REPORTING_SERVER)
  for (const i in formData) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = i
    input.value = formData[i]
    form.appendChild(input)
  }
  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}

const QuickReportModal = ({
  showingQuickReportsModal,
  setShowingQuickReportsModal,
  asset,
}) => {
  const {data: opening2Data, status} = useOpening2()
  const userConfig = useStore(state => state.userConfig)
  const user = useStore(state => state.user)
  let password
  const splitToken = window.atob(user.token).split(':')
  const username = splitToken[0]
  if (user.impersonatingEmail) {
    password = splitToken[1] + ':' + user.impersonatingEmail
  } else {
    password = splitToken[1]
  }
  const onSelect = Report_Name => {
    createReport(
      Report_Name,
      asset.idAsset,
      asset.Asset_Label,
      userConfig,
      username,
      password,
    )
    setShowingQuickReportsModal(false)
  }

  if (status === 'error') {
    return <div>There was an error loading the reports. Please try again</div>
  }

  return (
    <Modal
      showDialog={showingQuickReportsModal}
      title={asset.Asset_Label}
      width={'400px'}
      renderFooter={false}
      onDismiss={() => setShowingQuickReportsModal(false)}
    >
      <Container>
        <h4>Select Quick Report:</h4>
        <List>
          {status === 'loading' ? (
            <Spinner type="partial" />
          ) : (
            <>
              {opening2Data?.reportlist?.map(report => {
                if (report.Quick_Report) {
                  return (
                    <Item
                      key={report.Report_Name}
                      onClick={() => onSelect(report.Report_Name)}
                    >
                      {report.Report_Label}
                    </Item>
                  )
                } else return null
              })}
            </>
          )}
        </List>
      </Container>
    </Modal>
  )
}
export default QuickReportModal
