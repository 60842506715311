import * as React from 'react'
import {
  ClusterContainer,
  ClusterMarkerImg,
  ClusterCount,
  ClusterLabel,
} from './mapStyles'
import SmallCluster from '../images/map/SmallCluster.svg'
import MediumCluster from '../images/map/MediumCluster.svg'
import LargeCluster from '../images/map/LargeCluster.svg'
import Alert from 'images/map/warning.svg'
import MaintDue from 'images/map/wrench.svg'

const ClusterMarker = ({cluster, pointCount}) => {
  let image = SmallCluster
  if (cluster.properties.point_count > 9999) {
    image = LargeCluster
  } else if (cluster.properties.point_count > 99) {
    image = MediumCluster
  }
  return (
    <ClusterContainer data-cy="clustermarker">
      <ClusterMarkerImg src={image} />
      <ClusterCount count={pointCount}>
        {Number(pointCount).toLocaleString()}
      </ClusterCount>
      {cluster.properties.alerts || cluster.properties.maint ? (
        <ClusterLabel>
          {cluster.properties.alerts ? <img src={Alert} /> : <></>}
          {cluster.properties.maint ? <img src={MaintDue} /> : <></>}
        </ClusterLabel>
      ) : null}
    </ClusterContainer>
  )
}
export default ClusterMarker
