import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'
import {HistoryTrailPoints} from './historyTrailTypes'
import {formatDateToStringForAPI} from 'common/helpers'

const getPoints = async (id: number | null, dates: string) => {
  const {data}: {data: HistoryTrailPoints} = await Axios.get(
    `/assets?a=geteventshistory&id=${id}&date=${dates}&alerts=on`,
  )
  return data
}

//exported for tests only
export const getDateString = (startDate: Date, endDate: Date) => {
  if (startDate) {
    const formattedStart = formatDateToStringForAPI(startDate)
    const formattedEnd = formatDateToStringForAPI(endDate)

    return formattedStart + ',' + formattedEnd
  } else {
    return ''
  }
}

const useHistoryTrailPoints = (
  id: number | null,
  startDate: Date | '' | undefined,
  endDate: Date | '' | undefined,
) => {
  const user = useStore(state => state.user)
  const datesString =
    (startDate && endDate && getDateString(startDate, endDate)) || ''
  return useQuery(
    [`historyTrail-id:${id}-dates:${datesString}`],
    () => getPoints(id, datesString),
    {
      enabled: Boolean(id && user?.token && datesString),
      refetchOnWindowFocus: false,
      refetchInterval: 300000,
    },
  )
}

export default useHistoryTrailPoints
