import * as React from 'react'
import styled from 'styled-components/macro'
import MainMenu from 'common/MainMenu'
import Header from 'common/Header'
import {QUERIES} from 'common/constants'
import {useStore} from './useStore'

const RootDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  @media print {
    height: fit-content !important;
  }
`

const MainDiv = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    'mobileheader'
    'mainarea';
  grid-template-columns: 1fr;
  grid-template-rows: ${(p: {
      showMainMenuHeader: boolean
      leftMenuWidth: number
    }) => (p.showMainMenuHeader ? '70px' : '')} auto;
  @media ${QUERIES.tabletAndUp} {
    grid-template-areas: ${p =>
      p.showMainMenuHeader
        ? `
            'leftmenu header' 
            'leftmenu mainarea'
          `
        : 'mainarea'};
    grid-template-columns: ${p =>
        p.showMainMenuHeader ? p.leftMenuWidth + 'px' : ''} 1fr;
    grid-template-rows: ${p => (p.showMainMenuHeader ? '46px' : '')} auto;
  }
  overflow: auto;
  @media print {
    height: fit-content !important;
  }
`

const MainAreaWrapper = styled.div`
  grid-area: mainarea;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: var(--asc-cultured);
  @media print {
    height: fit-content !important;
  }
`

const MobileMainMenu = styled(MainMenu)`
  @media ${QUERIES.tabletAndUp} {
    display: none;
  }
`

const DesktopMainMenu = styled(MainMenu)`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: block;
  }
`

type AppLayoutProps = {
  showMainMenuHeader: boolean
  title?: string | undefined
  children: React.ReactNode
}

const AppLayout = (props: AppLayoutProps) => {
  const leftMenuWidth = useStore(state => state.leftMenuWidth)
  return (
    <RootDiv>
      <MainDiv
        showMainMenuHeader={props.showMainMenuHeader}
        leftMenuWidth={leftMenuWidth}
      >
        <Header showMainMenuHeader={props.showMainMenuHeader} />
        <MobileMainMenu
          isMobile={true}
          title={props.title}
          showMainMenuHeader={props.showMainMenuHeader}
        />
        <DesktopMainMenu
          isMobile={false}
          title={props.title}
          showMainMenuHeader={props.showMainMenuHeader}
        />
        <MainAreaWrapper>{props.children}</MainAreaWrapper>
      </MainDiv>
    </RootDiv>
  )
}

export default AppLayout
