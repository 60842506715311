import Axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useStore} from 'common/useStore'

const getLocations = async () => {
  const {data} = await Axios.get(
    `/landmarks/?a=getlandmarksforhtmlformsbycategory&category=1`,
  )
  data.sort((a, b) => {
    const x = a.Landmark_Name.toUpperCase()
    const y = b.Landmark_Name.toUpperCase()
    return x < y ? -1 : x > y ? 1 : 0
  })
  return data
}

export default function useLocationsIncludeShared() {
  const user = useStore(state => state.user)
  return useQuery(['locations'], getLocations, {
    enabled: Boolean(user?.token),
  });
}
