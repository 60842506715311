import * as React from 'react'
import {changePageAndScroll} from 'common/tables/tableWithHooksHelpers'
import {
  PaginationFooter,
  PaginationPageCounts,
  PaginationPageDropDown,
} from 'common/tables/tableStyles'
import styled from 'styled-components/macro'
import {ArrowCircleLeft, ArrowCircleRight} from 'phosphor-react'

const StyledSelect = styled.select`
  margin-left: 5px;
  border-radius: 10px !important;
  color: var(--asc-graphite);
  height: auto !important;
  padding: 10px 7px !important;
`

const StyledInput = styled.input`
  color: var(--asc-graphite);
  border-radius: 10px !important;
  padding: 12px 7px !important;
`
const StyledFooter = styled(PaginationFooter)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  background: var(--asc-cultured);
`

const TablePaginationControls = ({
  scrollRef,
  nextPage,
  previousPage,
  canPreviousPage,
  gotoPage,
  canNextPage,
  pageIndex,
  pageOptions,
  setPageSize,
  pageSize,
}) => {
  const clickedPreviousPage = () =>
    changePageAndScroll('previous', scrollRef, nextPage, previousPage)
  const clickedNextPage = () =>
    changePageAndScroll('next', scrollRef, nextPage, previousPage)
  const pageSizeClicked = e => {
    setPageSize(Number(e.target.value))
  }
  const pageNumberInput = e => {
    const page = Number(e.target.value) - 1
    gotoPage(page)
  }

  const pageSizesAvailable = [25, 50, 100, 500]
  return (
    <StyledFooter>
      <PaginationPageDropDown>
        <label>
          Display
          <StyledSelect value={pageSize} onChange={pageSizeClicked}>
            {pageSizesAvailable.map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </StyledSelect>
        </label>
      </PaginationPageDropDown>

      <PaginationPageCounts>
        Page{' '}
        <StyledInput
          type="number"
          data-cy="paginationPageNumber"
          onChange={pageNumberInput}
          value={pageIndex + 1}
        />{' '}
        of {pageOptions.length}
      </PaginationPageCounts>
      <ArrowCircleLeft
        data-cy="previous-button"
        onClick={clickedPreviousPage}
        size={45}
        weight={'light'}
        color={canPreviousPage ? 'var(--asc-graphite)' : 'var(--asc-concrete)'}
        style={{cursor: canPreviousPage ? 'pointer' : 'default'}}
      />
      <ArrowCircleRight
        data-cy="next-button"
        onClick={clickedNextPage}
        size={45}
        weight={'light'}
        color={canNextPage ? 'var(--asc-graphite)' : 'var(--asc-concrete)'}
        style={{cursor: canNextPage ? 'pointer' : 'default'}}
      />
    </StyledFooter>
  )
}
export default TablePaginationControls
